import React, { useState, useEffect } from 'react';
import './RegisteredClientsModal.css';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { collection, query, where, getDocs } from "firebase/firestore";
import moment from "moment"; // if you are using moment for time checks
import BasicProgressOverlay from './components/BasicProgressOverlay'; 
import { set } from 'date-fns';



const firebaseConfig = {
    apiKey: "AIzaSyCXf7GgCR7pkClTsKy3ewFuOAOtHw14SQk",
    authDomain: "notify-pro-accb3.firebaseapp.com",
    projectId: "notify-pro-accb3",
    storageBucket: "notify-pro-accb3.appspot.com",
    messagingSenderId: "944566302130",
    appId: "1:944566302130:web:51a32b80937874fcef60ab",
    measurementId: "G-41998SHFSR"
  };
  
  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);
                                                                                                                                                                                                                                                                                              

function RegisteredClientsModal({ open, onClose, accountEmail, providerName }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(null);
    

    

    const handleSearchChange = async (e) => {
        setSearchTerm(e.target.value);
      
        if (e.target.value.trim() !== '') {
          try {
            const response = await fetch(`https://nproapiserver.herokuapp.com/searchClients?term=${e.target.value}&accountEmail=${accountEmail}&providerName=${providerName}`);
            const data = await response.json();
            setSearchResults(data);
            // console.log(data)
          } catch (error) {
            console.error("Error fetching search results", error);
          }
        } else {
          setSearchResults([]);
        }
    }

    const handleClientSelect = (client) => {
        setSelectedClient(client);
        // console.log(client)
        setIsDetailModalOpen(true);
        setSearchTerm('');
        setSearchResults([]);
    }

    return (
        open && (
            <div className="modal-background">
                { isDetailModalOpen 
                    ? <DetailModal client={selectedClient} onClose={() => setIsDetailModalOpen(false)} accountEmail={accountEmail} providerName={providerName} />
                    : (
                        <div className="modal-content">
                            <Tooltip title="Close">
                                <CancelIcon className="close-icon" onClick={onClose} />
                            </Tooltip>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <h2>Registered Clients Search</h2>
                                </Grid>
                                <Grid item xs={12}>
                                    <input type="text" value={searchTerm} onChange={handleSearchChange} placeholder="Search..." />
                                </Grid>
                                <Grid item xs={12}>
                                <div className="search-results">
                                {searchResults.map(result => (
                                    <div key={`${result.phoneNumber}-${result.dateOfBirth}`} onClick={() => handleClientSelect(result)}>
                                    {result.firstName} {result.lastName} - DOB: {result.dateOfBirth} - Tel: {result.phoneNumber}
                                    </div>
                                ))}
                                </div>

                                </Grid>
                            </Grid>
                        </div>
                    )
                }
            </div>
        )
    );
}

function DetailModal({ client, onClose, accountEmail, providerName }) {

    const [formData, setFormData] = useState(client);
    const [originalData, setOriginalData] = useState(client);
    const [isUpdateEnabled, setIsUpdateEnabled] = useState(false);
    const [isInProcess, setIsInProcess] = useState(false);


    useEffect(() => {
        // On load, save the original data for comparison
        setOriginalData(client);
        setFormData(client); // Reset form data on load
    }, [client]);



    const formatTime = (time) => {
        if (typeof time !== 'string') {
            return "NO TIME";
        }
      
        // Remove periods and convert to upper case
        let cleanedTime = time.toUpperCase().replace(/\./g, '');
      
        // Check if it has AM/PM or looks like a 24-hour format
        const hasAmPm = /AM|PM/.test(cleanedTime);
        const looksLike24Hour = /^\d{1,2}:\d{2}$/.test(cleanedTime);
      
        // If it looks like a 24-hour format and doesn't have AM/PM, handle it as 24-hour time
        if (looksLike24Hour && !hasAmPm) {
            const momentTime = moment(cleanedTime, "HH:mm");
            if (momentTime.isValid()) {
                return momentTime.format("h:mm A");
            }
        } else {
            // Try to parse it with moment assuming various formats including AM/PM
            const momentTime = moment(cleanedTime, ["h:mm A", "h:mmA", "hA", "HH:mm"]);
            if (momentTime.isValid()) {
                return momentTime.format("h:mm A");
            }
        }
      
        return "NO TIME";
      };


    const fetchAppointmentsAptSearch = async (fromDate, toDate) => {
        try {
          // This will hold appointments from *all* calendars
          let allAppointments = [];
      
          // 1) Reference to the "calendars" collection
          //    (Make sure userEmail/physician are defined in your scope)
          const calendarsRef = collection(
            firestore, 
            "accounts", 
            accountEmail, 
            "physician", 
            providerName, 
            "calendars"
          );
      
          // 2) Get all calendar documents
          const calendarsSnapshot = await getDocs(calendarsRef);
      
          // 3) For each calendar doc, query its "appointments" subcollection
          for (const calendarDoc of calendarsSnapshot.docs) {
            // The calendar’s ID (e.g., "CalendarA", "CalendarB", etc.)
            const calendarId = calendarDoc.id;
      
            // Reference the appointments subcollection for this calendar
            const appointmentRef = collection(
              firestore, 
              "accounts", 
              accountEmail, 
              "physician", 
              providerName, 
              "calendars", 
              calendarId, 
              "appointments"
            );
      
            // Create a query to filter appointments by date
            const dateRangeQuery = query(
              appointmentRef, 
              where("date", ">=", fromDate),
              where("date", "<=", toDate)
            );
      
            // Fetch the documents within the date range from this calendar
            const querySnapshot = await getDocs(dateRangeQuery);
      
            // 4) Transform each document into the structure you need
            const appointments = querySnapshot.docs.map(doc => {
              const data = doc.data();
      
              // Optionally store the document ID
              // if you need it later for updates
              data.docId = doc.id;  
      
              // Validate/convert the date field
              const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
              if (dateFormat.test(data.date)) {
                data.newDate = data.date;
              }
      
              // Filter based on date range (redundant if the Firestore query is correct,
              // but leaving it here if you still want to double-check):
              const newDateISO = data.newDate || "";
              if (newDateISO < fromDate || newDateISO > toDate) {
                return null; 
              }
      
              // Time formatting (assuming you have formatTime function)
              let finalTime = data.time ? formatTime(data.time) : "NO TIME";
              if (!moment(finalTime, "h:mm A").isValid()) {
                finalTime = "NO TIME";
              }
      
              const time24 = finalTime === "NO TIME" 
                ? "00:00" 
                : moment(finalTime, "h:mm A").format("HH:mm");
      
              // Return the structured appointment
              return {
                allDay: true,
                start: data.newDate,
                end: data.newDate,
                resource: data, // original data here
                time: finalTime,
                time24: time24,
                tag: "appointment",
                // Optionally store which calendar it came from:
                calendarId: calendarId
              };
            })
            .filter(appointment => appointment !== null);
      
            // 5) Combine these appointments with the master list
            allAppointments.push(...appointments);
          }
      
          // Return the merged list of appointments from ALL calendars
          return allAppointments;
      
        } catch (error) {
          console.error("Error fetching appointments:", error);
          // Return an empty array if something goes wrong
          return [];
        }
      };


    const handleChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData({
            ...formData,
            [e.target.name]: value
        });
    };

    // Check if any field except for `dateOfBirth` has changed
    useEffect(() => {
        if (
            formData.firstName !== originalData.firstName ||
            formData.middleName !== originalData.middleName ||
            formData.lastName !== originalData.lastName ||
            formData.email !== originalData.email ||
            formData.optIn !== originalData.optIn ||
            formData.otherInfo !== originalData.otherInfo
        ) {
            setIsUpdateEnabled(true); // Enable update button
        } else {
            setIsUpdateEnabled(false); // Disable update button if nothing has changed
        }
    }, [formData, originalData]);



    const handleUpdateDOB = async () => {
        setIsInProcess(true);
        const newDateOfBirth = formData.dateOfBirth;         // The date the user has picked
        const originalDateOfBirth = originalData.dateOfBirth; // The original DOB from initial data
      
        // Step 1) If user canceled or left the date blank (depending on your UI)
        if (!newDateOfBirth) {
          // The user didn't enter or pick a date
          setIsInProcess(false);
          return;
        }
      
        // Step 2) Check if the user actually changed the DOB
        if (newDateOfBirth === originalDateOfBirth) {
          alert('First change the date of birth.');
          setIsInProcess(false);
          return;
        }
        
        try {
          // 1) Before calling the server, fetch future appointments from NOW to +5 years
          const fromDate = moment().format('YYYY-MM-DD');
          const toDate = moment().add(5, 'years').format('YYYY-MM-DD');
      
          const appointments = await fetchAppointmentsAptSearch(fromDate, toDate);
          // console.log("Fetched Appointments:", appointments);
      
          // 2) Filter appointments for this specific client
          //    MATCH CRITERIA:
          //    - resource.phone === formData.phoneNumber (the current phone)
          //    - resource.dateOfBirth === originalDateOfBirth (the OLD DOB)
          //    - resource.name === formData.firstName + " " + formData.lastName
          const fullName = formData.firstName + ' ' + formData.lastName;
          const filteredAppointments = appointments.filter(appt => {
            const resource = appt.resource || {};
            return (
              resource.phone === formData.phoneNumber &&
              resource.dateOfBirth === originalDateOfBirth &&
              resource.name === fullName
            );
          });
      
          // 3) Collect doc IDs to update
          const docIds = filteredAppointments
            .map(appt => appt.resource?.docId)
            .filter(Boolean);
      
          // 4) Now call your existing server route to update the main DB record
          //    (this will create the new doc with new DOB, delete old doc, etc.)
          //    AND also update the future appointments’ dateOfBirth field
          const response = await fetch(`https://nproapiserver.herokuapp.com/updateClientDOB`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              oldDateOfBirth: originalDateOfBirth,
              newDateOfBirth,
              accountEmail,
              providerName,
              phoneNumber: formData.phoneNumber,
              clientData: formData,
              // Pass the doc IDs for appointments
              appointmentDocIds: docIds,
            }),
          });
      
          if (response.ok) {
            setIsInProcess(false);
            alert('Date of birth updated successfully on the main database and all future appointments for this client!');
            // Update local formData to reflect new DOB
            setFormData({ ...formData, dateOfBirth: newDateOfBirth });
            onClose();
          } else {
            setIsInProcess(false);
            throw new Error('Failed to update date of birth');
          }
        } catch (error) {
          setIsInProcess(false);
          console.error('Error updating date of birth', error);
        }
      };
      

    
    // const handleUpdateDOB = async () => {
    //     const newDateOfBirth = formData.dateOfBirth; // The date already picked
    //     const originalDateOfBirth = originalData.dateOfBirth; // The original date of birth

    //     if (newDateOfBirth === originalDateOfBirth) {
    //         alert('First change the date of birth.');
    //         return;
    //     }
    
    //     try {
    //         const response = await fetch(`https://nproapiserver.herokuapp.com/updateClientDOB`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 oldDateOfBirth: client.dateOfBirth,
    //                 newDateOfBirth,
    //                 accountEmail,
    //                 providerName,
    //                 phoneNumber: formData.phoneNumber, // Needed to identify the client document
    //                 clientData: formData
    //             })
    //         });
            
    //         if (response.ok) {
    //             alert('Date of birth updated successfully!');
    //             setFormData({ ...formData, dateOfBirth: newDateOfBirth });
    //             onClose();
    //         } else {
    //             throw new Error('Failed to update date of birth');
    //         }
    //     } catch (error) {
    //         console.error("Error updating date of birth", error);
    //     }
    // };


    const handleUpdatePhone = async () => {
        // const oldPhoneNumber = formData.phoneNumber;
        // console.log("Old Phone: ", oldPhoneNumber);
        setIsInProcess(true);
        const newPhoneNumber = prompt('Enter new phone number (e.g., 1787XXXXXXX):', '');

        // If user clicks "Cancel", prompt returns null
        if (newPhoneNumber === null) {
          setIsInProcess(false);
          return;
        }
      
        // If the input is empty or does not match 11 digits
        if (!newPhoneNumber || !/^\d{11}$/.test(newPhoneNumber)) {
          alert('Invalid phone number format.');
          setIsInProcess(false);
          return;
        }
      
        try {
          // 1) Calculate date range for next 5 years
          const fromDate = moment().format('YYYY-MM-DD');      // "today"
          const toDate = moment().add(5, 'years').format('YYYY-MM-DD'); // 5 years from now
      
          // 2) Fetch appointments within date range
          const appointments = await fetchAppointmentsAptSearch(fromDate, toDate);
        //   console.log("Feteched Appts: ", appointments);
      
          // 3) Filter appointments for this specific client
          //    (Depending on how your data is structured, adjust the matching logic.)
          const filteredAppointments = appointments.filter(appt => {
            const resource = appt.resource || {};
            // Example matching logic: name, phone, dateOfBirth
            // console.log("Resource: ", resource);
            // console.log("FormData: ", formData);
            // console.log("Resource Phone: ", resource?.phone);
            // console.log("FormData Phone: ", formData.phoneNumber);
            // console.log("Resource DOB: ", resource?.dateOfBirth);
            // console.log("FormData DOB: ", formData.dateOfBirth);
            // console.log("Resource Name: ", resource?.name);
            // console.log("FormData Name: ", formData.firstName + ' ' + formData.lastName);
            return (
              resource?.phone === formData.phoneNumber &&
              resource?.dateOfBirth === formData.dateOfBirth &&
              resource?.name === formData.firstName + ' ' + formData.lastName
            );
          });
      
          // Collect docIds from those filtered appointments
          const docIds = filteredAppointments.map(appt => appt.resource?.docId).filter(Boolean);
          console.log("DocIds: ", docIds);

          // 4) Send data to server to update:
          //    - the "registeredClientDetails" doc (old phone -> new phone)
          //    - each matching appointment doc’s `phone` property
          const response = await fetch(`https://nproapiserver.herokuapp.com/updateClientPhoneNumber`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              oldPhoneNumber: formData.phoneNumber,
              newPhoneNumber,
              accountEmail,
              providerName,
              dateOfBirth: formData.dateOfBirth,
              clientData: formData,
              // Pass the docIds so the server can update them
              appointmentDocIds: docIds,
            }),
          });
      
          if (response.ok) {
            setIsInProcess(false);
            alert('Phone number updated successfully on the main database and all future appointments for this client!');
            setFormData({ ...formData, phoneNumber: newPhoneNumber });
            onClose();
          } else {
            setIsInProcess(false);
            throw new Error('Failed to update phone number');
          }
        } catch (error) {
            setIsInProcess(false);
          console.error('Error updating phone number', error);
        }
      };
    

    // const handleUpdatePhone = async () => {
    //     const newPhoneNumber = prompt('Enter new phone number (e.g., 1787XXXXXXX):', '');
    //     if (!newPhoneNumber || !/^\d{11}$/.test(newPhoneNumber)) {
    //         alert('Invalid phone number format.');
    //         return;
    //     }

    //     try {
    //         const response = await fetch(`https://nproapiserver.herokuapp.com/updateClientPhoneNumber`, {
    //             method: 'POST',
    //             headers: {
    //               'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 oldPhoneNumber: formData.phoneNumber,
    //                 newPhoneNumber,
    //                 accountEmail,
    //                 providerName,
    //                 dateOfBirth: formData.dateOfBirth,
    //                 clientData: formData
    //             })
    //         });
            
    //         if (response.ok) {
    //             alert('Phone number updated successfully!');
    //             setFormData({ ...formData, phoneNumber: newPhoneNumber });
    //             onClose();
    //         } else {
    //             throw new Error('Failed to update phone number');
    //         }
    //     } catch (error) {
    //         console.error("Error updating phone number", error);
    //     }
    // };

    


const handleUpdate = async () => {
    setIsInProcess(true);
  const newDateOfBirth = formData.dateOfBirth; // The date already picked
  const originalDateOfBirth = originalData.dateOfBirth; // The original date of birth

  // 1) Prevent changing DOB from here
  if (newDateOfBirth !== originalDateOfBirth) {
    alert(
      'The date of birth cannot be updated from here. ' +
      'Please use the "Update DOB" button located to the right of the date of birth field. ' +
      'This action must be completed separately, either before or after updating the other client details.'
    );
    return;
  }

  const dateOfBirth = formData.dateOfBirth;

  try {
    // 2) First, update the main DB record (registeredClientDetails) as before
    const response = await fetch(
      `https://nproapiserver.herokuapp.com/updateClientDetailsByFrontEnd?phoneNumber=${client.phoneNumber}&accountEmail=${accountEmail}&providerName=${providerName}&dateOfBirth=${dateOfBirth}`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      }
    );

    if (!response.ok) {
        setIsInProcess(false);
      throw new Error('Failed to update client details');
    }

    // // If we reach here, the client details were updated successfully
    // alert('Client details updated successfully!');

    // 3) Now do the future appointments update for name/email

    // A) Define date range: from today up to 5 years
    const fromDate = moment().format('YYYY-MM-DD');
    const toDate = moment().add(5, 'years').format('YYYY-MM-DD');

    // B) Fetch all future appointments
    const appointments = await fetchAppointmentsAptSearch(fromDate, toDate);

    // C) Filter by matching scenarios:
    //    phone (client.phoneNumber), dateOfBirth (formData.dateOfBirth), 
    //    plus one or more of (email) or (firstName + lastName) or both
    const fullName = formData.firstName + ' ' + formData.lastName;
    const filteredAppointments = appointments.filter(appt => {
      const resource = appt.resource || {};
      const phoneMatch = resource.phone === client.phoneNumber; // or formData.phoneNumber if that’s more accurate
      const dobMatch = resource.dateOfBirth === formData.dateOfBirth;
      const emailMatch = resource.email === formData.email;
      const nameMatch = resource.name === fullName;

      // Scenario 1: phone + DOB + email
      const scenario1 = phoneMatch && dobMatch && emailMatch;
      // Scenario 2: phone + DOB + name
      const scenario2 = phoneMatch && dobMatch && nameMatch;
      // Scenario 3: phone + DOB + email + name
      const scenario3 = phoneMatch && dobMatch && emailMatch && nameMatch;

      return scenario1 || scenario2 || scenario3;
    });

    // D) Collect docIds
    const docIds = filteredAppointments
      .map(appt => appt.resource?.docId)
      .filter(Boolean);

    // E) If we found any matching appointments, update them
    if (docIds.length > 0) {
      const newName = fullName;
      const newFirstName = formData.firstName;
      const newMiddleName = formData.middleName || '';
      const newLastName = formData.lastName;
      const newEmail = formData.email;

      // Make a POST request to our new endpoint
      const apptUpdateResp = await fetch(
        'https://nproapiserver.herokuapp.com/updateAppointmentsNameEmail',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            phoneNumber: client.phoneNumber,
            accountEmail,
            providerName,
            dateOfBirth,
            appointmentDocIds: docIds,
            newName,
            newFirstName,
            newMiddleName,
            newLastName,
            newEmail
          }),
        }
      );

      if (apptUpdateResp.ok) {
        setIsInProcess(false);
            // If we reach here, the client details were updated successfully
        alert('Client details in database updated successfully, and for all future appointments!');
        console.log(`Appointments name/email updated for docIds: `, docIds);
      } else {
        setIsInProcess(false);
        console.error('Failed to update name/email in appointments');
      }
    } else {
        setIsInProcess(false);
      console.log('No future appointments matched the given scenarios');
    }
  } catch (error) {
    setIsInProcess(false);
    console.error('Error updating client details or appointments:', error);
    alert(`Error: ${error.message}`);
  }
};

    
    
    
    // const handleUpdate = async () => {

    //     const newDateOfBirth = formData.dateOfBirth; // The date already picked
    //     const originalDateOfBirth = originalData.dateOfBirth; // The original date of birth

    //     if (newDateOfBirth !== originalDateOfBirth) {
    //         alert('The date of birth cannot be updated from here. Please use the "Update DOB" button located to the right of the date of birth field. This action must be completed separately, either before or after updating the other client details.');
    //         return;
    //     }

    //     const dateOfBirth= formData.dateOfBirth
    //     try {
    //         // Sending an update request to your server with accountEmail as a query param
    //         const response = await fetch(`https://nproapiserver.herokuapp.com/updateClientDetailsByFrontEnd?phoneNumber=${client.phoneNumber}&accountEmail=${accountEmail}&providerName=${providerName}&dateOfBirth=${dateOfBirth}`, {
    //             method: 'PUT',
    //             headers: {
    //               'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(formData)
    //         });
            
    //         if (response.ok) {
    //             alert('Client details updated successfully!');
    //         } else {
    //             throw new Error('Failed to update client details');
    //         }
    //     } catch (error) {
    //         console.error("Error updating client details", error);
    //     }
    // };
    
    const handleDelete = async () => {
        setIsInProcess(true);
        const dateOfBirth= formData.dateOfBirth
        const firstName = formData.firstName;
        const isConfirmed = window.confirm('Are you sure you want to delete this client?');
        if (isConfirmed) {
            try {
                // Sending a delete request to your server with accountEmail as a query param
                const response = await fetch(`https://nproapiserver.herokuapp.com/deleteClientByFrontEnd?phoneNumber=${client.phoneNumber}&accountEmail=${accountEmail}&providerName=${providerName}&dateOfBirth=${dateOfBirth}&firstName=${firstName}`, {
                    method: 'DELETE'
                });
    
                if (response.ok) {
                    onClose(); // close the modal
                    setIsInProcess(false);
                    alert('Client deleted successfully!');
                } else {
                    setIsInProcess(false);
                    throw new Error('Failed to delete client');
                }
            } catch (error) {
                setIsInProcess(false);
                console.error("Error deleting client", error);
            }
        }
    };
    
    return (
        <>
          <div className="modal-content">
            <Tooltip title="Close">
              <CancelIcon className="close-icon" onClick={onClose} />
            </Tooltip>
            <h2>Client Details</h2>
            <Grid container spacing={2} className="client-details-form">
              <Grid item xs={12} sm={4}>
                <label>Phone Number:</label>
              </Grid>
              <Grid item xs={12} sm={8} style={{ display: 'flex', alignItems: 'center' }}>
                {formData.phoneNumber}
                <Tooltip title="Update Phone Number">
                  <button 
                    className="update-phone-button" 
                    onClick={handleUpdatePhone} 
                    style={{ marginLeft: '109px' }}
                  >
                    <strong>Update Phone</strong>
                  </button>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>First Name:</label>
              </Grid>
              <Grid item xs={12} sm={8}>
                <input 
                  name="firstName" 
                  value={formData.firstName} 
                  onChange={handleChange} 
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>Middle Name:</label>
              </Grid>
              <Grid item xs={12} sm={8}>
                <input 
                  name="middleName" 
                  value={formData.middleName} 
                  onChange={handleChange} 
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>Last Name:</label>
              </Grid>
              <Grid item xs={12} sm={8}>
                <input 
                  name="lastName" 
                  value={formData.lastName} 
                  onChange={handleChange} 
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label htmlFor="dateOfBirth">Date of Birth:</label>
              </Grid>
              <Grid item xs={12} sm={8} style={{ display: 'flex', alignItems: 'center' }}>
                <input 
                  id="dateOfBirth"
                  type="date"
                  name="dateOfBirth" 
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                  style={{ width: '43.7%', height: '5px', padding: '10px', boxSizing: 'border-box' }}
                />
                <Tooltip title="Update Date of Birth">
                  <button 
                    className="update-phone-button"  
                    onClick={handleUpdateDOB} 
                    style={{ marginLeft: '30px' }}
                  >
                    <strong>Update DOB</strong>
                  </button>
                </Tooltip>
              </Grid>
              {/* <Grid item xs={12} sm={4}>
                  <label htmlFor="dateOfBirth">Date of Birth:</label>
              </Grid>
              <Grid item xs={12} sm={8}>
                  <input 
                      id="dateOfBirth"
                      type="date"
                      name="dateOfBirth" 
                      value={formData.dateOfBirth}
                      onChange={handleChange}
                      style={{ width: '68.25%', height: '5px', padding: '10px', boxSizing: 'border-box' }}
                  />
              </Grid> */}
              <Grid item xs={12} sm={4}>
                <label>Email:</label>
              </Grid>
              <Grid item xs={12} sm={8}>
                <input 
                  name="email" 
                  value={formData.email} 
                  onChange={handleChange} 
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label>App Notifications:</label>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControlLabel
                  control={
                    <Checkbox 
                      name="optIn" 
                      checked={formData.optIn} 
                      onChange={handleChange} 
                      color="primary"
                    />
                  }
                  label="Opt In"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <label htmlFor="otherInfo">Other Info:</label>
              </Grid>
              <Grid item xs={12}>
                <textarea
                  id="otherInfo"
                  name="otherInfo" 
                  value={formData.otherInfo} 
                  onChange={handleChange}
                  rows={3}
                  style={{ 
                    width: '100%', 
                    height: '80px',
                    padding: '10px', 
                    boxSizing: 'border-box', 
                    marginBottom: '20px', 
                    borderRadius: '4px', 
                    border: '1px solid #ccc',
                    resize: 'none'
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} justifyContent="center">
              <Grid item>
                <button
                  className="update"
                  onClick={handleUpdate}
                  disabled={!isUpdateEnabled}
                  style={{
                    backgroundColor: !isUpdateEnabled ? '#ccc' : '',
                    cursor: !isUpdateEnabled ? 'not-allowed' : 'pointer',
                    opacity: !isUpdateEnabled ? 0.6 : 1,
                  }}
                >
                  <strong>Update</strong>
                </button>
              </Grid>
              <Grid item>
                <button className="cancel" onClick={onClose}>
                  <strong>Cancel</strong>
                </button>
              </Grid>
              <Grid item>
                <button className="delete" onClick={handleDelete}>
                  <strong>Delete</strong>
                </button>
              </Grid>
            </Grid>
          </div>
    
          {/* Keep the overlay here, but wrap in the same top-level element */}
          <BasicProgressOverlay isInProcess={isInProcess} />
        </>
      );

    
}




export default RegisteredClientsModal;
