import React, { useState, useEffect, useRef, useReducer, useMemo } from 'react';
import { Box, Grid, Tooltip, Typography, TextField, Button, Switch, FormControlLabel, Dialog, DialogContent  } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import PrintIcon from '@mui/icons-material/Print';
import { Calendar, momentLocalizer, Views, Navigate } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  doc, 
  setDoc, 
  deleteDoc, 
  addDoc, 
  updateDoc, 
  getDoc, 
  deleteField, 
  startAfter, 
  orderBy, 
  documentId, 
  limit,
  Timestamp // Adding Timestamp if you need to work with Firestore timestamps
} from 'firebase/firestore';
import Modal from '@mui/material/Modal';
import './CalendarModalPlus.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Checkbox, FormControl, InputLabel, Select, MenuItem, Menu, Popover } from '@mui/material';
import TextareaAutosize from 'react-textarea-autosize';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import { Fade } from '@mui/material';
import { CircularProgress } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import EventNoteIcon from '@mui/icons-material/EventNote';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SearchCircleIcon from './SearchCircleIcon';
import LinearProgress from '@mui/material/LinearProgress';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import * as ExcelJS from 'exceljs/dist/exceljs';
import GetAppIcon from '@mui/icons-material/GetApp';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import HistoryIcon from '@mui/icons-material/History';
import FormGroup from '@mui/material/FormGroup';
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { blue } from '@mui/material/colors';
import ProgressOverlaySpotSearch from './components/ProgressOverlaySpotSearch'; // Adjust the import path as necessary
import BasicProgressOverlay from './components/BasicProgressOverlay'; 
import NoSpotModal from './NoSpotModal';
import OverrideModal from './OverrideModal';
import OverridePastEventModal from './OverridePastEventModal';
import PastOverrideModal from './PastOverrideModal';
import { ca, is } from 'date-fns/locale';
import { set } from 'date-fns';
import MinimizeIcon from '@mui/icons-material/Minimize';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import axios from 'axios';
import TextsmsIcon from '@mui/icons-material/Textsms';
import Backdrop from '@mui/material/Backdrop';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';








const RightClickPopover = ({ position, event, open = false, onClose, userEmail, physician, firestore, groupedEvents, events, setEvents, filteredEvents, setFilteredEvents, originalEvents, setOriginalEvents, selectedCalendar, currentActiveUser, selectedEvent }) => {


  // Function to record an event
async function recordEvent(data, action, tag, event) {
  // console.log("Starting to log event...");
  // console.log("Selected event:", event);
  // console.log("Data:", data);
  // console.log("Action:", action);

  const db = firestore; // Assuming `firestore` is already initialized Firestore instance
  // const event = selectedEvent;

  // Generate the current timestamp in ISO 8601 format

const now = new Date();
const timestamp = now.toISOString(); // e.g., "2024-02-05T19:58:00.069Z"

// Adjust to include up to the minute
const dateUpToMinute = timestamp.slice(0, 16); // Result: "2024-02-05T19:58"

  // console.log("Generated timestamp:", timestamp);

  // Logging the collection path for debug
  // console.log("Firestore collection path:", 'accounts', userEmail, 'physician', physician, 'eventEditHistory');

   // Define the subcollection path
   const subcollectionPath = `accounts/${userEmail}/physician/${physician}/eventEditHistory`

  // Create a query to find existing documents with the same timestamp
  const eventsQuery = query(
    collection(db, subcollectionPath),
    where('date', '==', dateUpToMinute),
    orderBy(documentId(), 'desc'), // Modular SDK usage
    limit(1)
  );
  
  
  // console.log("Executing query to find existing documents with orderBy on potentially non-existent field...");
  
  const querySnapshot = await getDocs(eventsQuery);
  let sequenceNumber = 0;
  
  if (!querySnapshot.empty) {
    // console.log("Found existing documents, calculating sequence number...");
    const lastEventId = querySnapshot.docs[0].id; // Assuming eventId is stored as document id
    const match = lastEventId.match(/\d+$/); // Match the sequence number at the end of the string
    if (match) {
      sequenceNumber = parseInt(match[0], 10) + 1;
    }
  } else {
    console.log("No existing documents found or orderBy field doesn't exist, proceeding with initial sequence number.");
  }
  
  // Construct the document ID with the new sequence number
  const newDocumentId = `${timestamp}-${sequenceNumber.toString().padStart(6, '0')}`;
  // console.log("Constructed document ID:", newDocumentId);

  // console.log(data)


  // let eventData;

// if (tag === "new appointment") {
//   // This block runs if the event is a new appointment
//   eventData = {
//     phone: data.phone || "",
//     email: data.email || "",
//     firstName: data.name.split(" ")[0] || "",
//     lastName: data.name.split(" ").slice(1).join(" ") || "",
//     language: data.language || "",
//     date: data.date || "",
//     time: data.time || "",
//     encType: data.encounterType || "",
//     user: currentActiveUser || "",
//     action: action,
//     eventType: "Appointment",
//     eventId: newDocId || "",
//     dateOfBirth: data.dateOfBirth || "",
//     data: [data], // Assuming 'data' itself is the detailed info you want to keep
//     calendar: selectedCalendar || ""
//   };
// } else {
//   // This block runs for other types of events
//   eventData = {
//     phone: event.resource.phone || "",
//     email: event.resource.email || "",
//     firstName: event.resource.name.split(" ")[0] || "",
//     lastName: event.resource.name.split(" ").slice(1).join(" ") || "",
//     language: event.resource.xLanguage || event.resource.language || "",
//     date: event.resource.newDate || event.resource.date || "",
//     time: event.resource.newGroup || event.resource.time || "",
//     encType: event.resource.encType || event.resource.encounterType || "",
//     user: currentActiveUser || "",
//     action: action,
//     eventType: event.tag === "appointment" ? "Appointment" : "Notification",
//     eventId: event.resource.id || event.resource.docId || "",
//     dateOfBirth: event.tag === "appointment" ? event.resource.dateOfBirth || "" : "",
//     data: event.tag === "appointment" ? [data] : [],
//     calendar: selectedCalendar || ""
//   };
// }


  // Prepare the event data, including conditional fields
  let eventData = {
    phone: event.resource.phone || event.resource["phone 2"] || "",
    email: event.resource.email || "",
    firstName: event.resource.name.split(" ")[0] || "",
    lastName: event.resource.name.split(" ").slice(1).join(" ") || "",
    language: event.resource.xLanguage || event.resource.language || "",
    date: event.resource.newDate || event.resource.date || "",
    time: event.resource.newGroup || event.resource.time || "",
    encType: event.resource.encType || event.resource.encounterType || "",
    user: currentActiveUser || "",
    action: action,
    eventType: event.tag === "appointment" ? "Appointment" : "Notification",
    eventId: event.id || event.resource.docId || "",
    dateOfBirth: event.tag === "appointment" ? event.resource.dateOfBirth || "" : "",
    data: [data],
    calendar: selectedCalendar || "",
    docId: newDocumentId
  };



  // console.log("Event data prepared:", eventData);

  // Path to the specific document in the subcollection
  const docRef = doc(db, subcollectionPath, newDocumentId);

  // console.log("Saving event information to Firestore...");
  await setDoc(docRef, eventData);

  // console.log(`Event recorded with ID: ${newDocumentId}`);
  return newDocumentId; // Optionally return the new document ID
}


  const updateDocument = async (updateData, tag) => {

    // console.log(event)
    try {

     
      // Find the group using the groupId
      const group = groupedEvents.find(g => g.some(e => e.groupId === event.groupId));

  
      if (!group || group.length === 0) {
        alert("No events found in the group.");
        return;
      }


// Assume 'group' is your initial unsorted array of events
const appointmentEvent = group.find(e => e.tag === "appointment");
const nonAppointmentEvents = group.filter(e => e.tag !== "appointment");

// Sort non-appointment events in descending chronological order
nonAppointmentEvents.sort((a, b) => {
  const dateA = new Date(a.resource.dateId);
  const dateB = new Date(b.resource.dateId);
  return dateB - dateA; // For descending order
});

// Combine them, ensuring an appointment event is first if it exists
const sortedGroup = appointmentEvent ? [appointmentEvent, ...nonAppointmentEvents] : nonAppointmentEvents;

// Retain only the first event from the sorted group as representativeEvent
const representativeEvent = sortedGroup[0];

// Use representativeEvent as needed
// console.log("Representative Event:", representativeEvent);




      // if (filteredEvents.length === 0) {
      //     setFilteredEvents(events)
      // }

    //   if (originalEvents.length === 0) {
    //     setOriginalEvents(events)
    // }
  
      let updatedEvents = [...events];  // Make a copy of the current events
      let updatedFilteredEvents = !filteredEvents.length === 0 ? [...filteredEvents] : [...events];
      let updatedOriginalEvents = !originalEvents.length === 0 ? [...originalEvents] : [...events];


      for (let groupEvent of group) {
        let docRef;
  
        if (!event.id && !event.resource.docId) {
          alert("Event missing necessary ID data.");
          continue;  // Skip to the next event in the group
        }
  
        // Determine the correct document reference
        if (event.tag === "appointment") {
          docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'appointments', event.resource.docId);
        } else {
          docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'history', event.id);
        }
  
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          console.log("Event not found, skipping...");
          continue;  // Skip to the next event in the group
        }
  
        // Update the document in Firebase
        await updateDoc(docRef, updateData);
        console.log('Updated event.');



    //     const eventData = {
    //       phone: specificEvent.resource.phone || "",
    //       email: specificEvent.resource.email || "",
    //       name: specificEvent.resource.name,
    //       language: specificEvent.resource.xLanguage || specificEvent.resource.language || "",
    //       date: specificEvent.resource.newDate || specificEvent.resource.date || "",
    //       time: specificEvent.resource.newGroup || specificEvent.resource.time || "",
    //       encounterType: specificEvent.resource.encType || specificEvent.resource.encounterType || "",
    //       dateOfBirth: specificEvent.tag === "appointment" ? specificEvent.resource.dateOfBirth || "" : "",
    //       aptSpecLabel: specificEvent.resource.aptSpecLabel || "",
    //       otherInfo: specificEvent.resource.otherInfo || "",
    //     };

    //     console.log(specificEvent)
    //     console.log(eventData)
    //     console.log(tag)

    // // After successful update, record the event
    // const docuId = specificEvent.id || specificEvent.resource.docId || "";
    // const action = `${tag} Event (ID: ${docuId})`;
    // await recordEvent(eventData, action, tag, specificEvent)
    //   .then(docuId => {
    //     console.log(`Successfully recorded user event: ${docuId}`);
    //   })
    //   .catch(error => {
    //     console.error("Error recording user event:", error);
    //   });


  
        // Update the event in the local state
        updatedEvents = updatedEvents.map(e => {
          const eventId = e.id || e.resource.docId;
          if (eventId === (event.id || event.resource.docId)) {
            return { ...e, resource: { ...e.resource, ...updateData }};
          }
          return e;
        });
      

              // Update the filtered events in the local state
              updatedFilteredEvents = updatedFilteredEvents.map(e => {
                const eventId = e.id || e.resource.docId;
                if (eventId === (event.id || event.resource.docId)) {
                  return { ...e, resource: { ...e.resource, ...updateData }};
                }
                return e;
              });

                            // Update the original events in the local state
              updatedOriginalEvents = updatedOriginalEvents.map(e => {
                const eventId = e.id || e.resource.docId;
                if (eventId === (event.id || event.resource.docId)) {
                  return { ...e, resource: { ...e.resource, ...updateData }};
                }
                return e;
              });
            }


            // Find the group of events by groupId
            const eventGroupIndex = groupedEvents.findIndex(group => group[0].groupId === event.groupId);

            if (eventGroupIndex !== -1) { // Check if the group exists
              // Map through the events in the found group to update the matching event(s)
              const updatedGroup = groupedEvents[eventGroupIndex].map(e => {
                const eventId = e.id || e.resource.docId;
                if (eventId === (event.id || event.resource.docId)) {
                  // Update the event with the new data
                  return { ...e, resource: { ...e.resource, ...updateData }};
                }
                return e; // Return the event unchanged if it doesn't match
              });

              // Update the group in the groupedEvents array with the updated group
              groupedEvents[eventGroupIndex] = updatedGroup;
            }



     const eventId = event.id || event.resource.docId;

            // Record event only if it is the representativeEvent
      if (eventId === representativeEvent.id || representativeEvent.resource.docId) {
        const eventData = {
          phone: representativeEvent.resource.phone || event.resource["phone 2"] || "",
          email: representativeEvent.resource.email || "",
          name: representativeEvent.resource.name,
          language: representativeEvent.resource.xLanguage || representativeEvent.resource.language || "",
          date: representativeEvent.resource.newDate || representativeEvent.resource.date || "",
          time: representativeEvent.resource.newGroup || representativeEvent.resource.time || "",
          encounterType: representativeEvent.resource.encType || representativeEvent.resource.encounterType || "",
          dateOfBirth: representativeEvent.tag === "appointment" ? representativeEvent.resource.dateOfBirth || "" : "",
          aptSpecLabel: representativeEvent.resource.aptSpecLabel || "",
          otherInfo: representativeEvent.resource.otherInfo || "",
          changedData: updateData || "",
        };

        const docuId = representativeEvent.id || representativeEvent.resource.docId || "";
        const action = `${tag} Event ID: ${docuId}`;
        await recordEvent(eventData, action, tag, representativeEvent)
          .then(docuId => {
            console.log(`Successfully recorded user event.`);
          })
          .catch(error => {
            console.error("Error recording user event:", error);
          });
      }

  
      setEvents(updatedEvents);  // Update the state with the modified events
      setFilteredEvents(updatedFilteredEvents)
      setOriginalEvents(updatedOriginalEvents)
      onClose();  // Close the popover
      // alert("Successfully updated the notification status for all events in the group!");
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("An error occurred while updating the document");
    }
  };
  





  const updateDocumentForHiddenDelete = async (updateData, tag) => {
    try {
      // Find the group using the groupId
      const group = groupedEvents.find(g => g.some(e => e.groupId === event.groupId));
  
      if (!group || group.length === 0) {
        alert("No events found in the group.");
        return;
      }


// Assume 'group' is your initial unsorted array of events
const appointmentEvent = group.find(e => e.tag === "appointment");
const nonAppointmentEvents = group.filter(e => e.tag !== "appointment");

// Sort non-appointment events in descending chronological order
nonAppointmentEvents.sort((a, b) => {
  const dateA = new Date(a.resource.dateId);
  const dateB = new Date(b.resource.dateId);
  return dateB - dateA; // For descending order
});

// Combine them, ensuring an appointment event is first if it exists
const sortedGroup = appointmentEvent ? [appointmentEvent, ...nonAppointmentEvents] : nonAppointmentEvents;

// Retain only the first event from the sorted group as representativeEvent
const representativeEvent = sortedGroup[0];

// Use representativeEvent as needed
// console.log("Representative Event:", representativeEvent);

  
      let updatedEvents = [...events];  // Make a copy of the current events
  
      for (let groupEvent of group) {
        let docRef;
  
        if (!event.id && !event.resource.docId) {
          alert("Event missing necessary ID data.");
          continue;  // Skip to the next event in the group
        }
  
        // Determine the correct document reference
        if (event.tag === "appointment") {
          docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'appointments', event.resource.docId);
        } else {
          docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'history', event.id);
        }
  
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          console.log("Event not found, skippin...");
          continue;  // Skip to the next event in the group
        }
  
        // Delete the 'hidden' field from the Firestore document
        await updateDoc(docRef, { hidden: deleteField() });
        // console.log('Deleted hidden field from Firestore event:', groupEvent);
  
        // Update the event in the local state
        updatedEvents = updatedEvents.map(e => {
          const eventId = e.id || e.resource.docId;
          if (eventId === (event.id || event.resource.docId)) {
            // Remove the hidden property from the resource
            const { hidden, ...updatedResource } = e.resource;
            return { ...e, resource: updatedResource };
          }
          return e;
        });
      }



      const eventId = event.id || event.resource.docId;

            // Record event only if it is the representativeEvent
      if (eventId === representativeEvent.id || representativeEvent.resource.docId) {
        const eventData = {
          phone: representativeEvent.resource.phone || event.resource["phone 2"] || "",
          email: representativeEvent.resource.email || "",
          name: representativeEvent.resource.name,
          language: representativeEvent.resource.xLanguage || representativeEvent.resource.language || "",
          date: representativeEvent.resource.newDate || representativeEvent.resource.date || "",
          time: representativeEvent.resource.newGroup || representativeEvent.resource.time || "",
          encounterType: representativeEvent.resource.encType || representativeEvent.resource.encounterType || "",
          dateOfBirth: representativeEvent.tag === "appointment" ? representativeEvent.resource.dateOfBirth || "" : "",
          aptSpecLabel: representativeEvent.resource.aptSpecLabel || "",
          otherInfo: representativeEvent.resource.otherInfo || "",
        };

        const docuId = representativeEvent.id || representativeEvent.resource.docId || "";
        const action = `${tag} Event ID: ${docuId}`;
        await recordEvent(eventData, action, tag, representativeEvent)
          .then(docuId => {
            console.log(`Successfully recorded user event.`);
          })
          .catch(error => {
            console.error("Error recording user event:", error);
          });
      }


  
      setEvents(updatedEvents);  // Update the state with the modified events
  
      onClose();  // Close the popover
      // alert("Successfully removed the hidden status from all events in the group!");
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("An error occurred while updating the document");
    }
  };

  const updateDocumentForUnconfirm = async (updateData, tag) => {
    try {
      // Find the group using the groupId
      const group = groupedEvents.find(g => g.some(e => e.groupId === event.groupId));
  
      if (!group || group.length === 0) {
        alert("No events found in the group.");
        return;
      }



      // Assume 'group' is your initial unsorted array of events
const appointmentEvent = group.find(e => e.tag === "appointment");
const nonAppointmentEvents = group.filter(e => e.tag !== "appointment");

// Sort non-appointment events in descending chronological order
nonAppointmentEvents.sort((a, b) => {
  const dateA = new Date(a.resource.dateId);
  const dateB = new Date(b.resource.dateId);
  return dateB - dateA; // For descending order
});

// Combine them, ensuring an appointment event is first if it exists
const sortedGroup = appointmentEvent ? [appointmentEvent, ...nonAppointmentEvents] : nonAppointmentEvents;

// Retain only the first event from the sorted group as representativeEvent
const representativeEvent = sortedGroup[0];

// Use representativeEvent as needed
// console.log("Representative Event:", representativeEvent);


  
      let updatedEvents = [...events];  // Make a copy of the current events
  
      for (let groupEvent of group) {
        let docRef;
  
        if (!event.id && !event.resource.docId) {
          alert("Event missing necessary ID data.");
          continue;  // Skip to the next event in the group
        }
  
        // Determine the correct document reference
        if (event.tag === "appointment") {
          docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'appointments', event.resource.docId);
        } else {
          docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'history', event.id);
        }
  
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          console.log("Event not found, skipping...");
          continue;  // Skip to the next event in the group
        }
  
        // Delete the 'hidden' field from the Firestore document
        await updateDoc(docRef, { manualConfirm: deleteField() });
        // console.log('Deleted manualConfirm field from Firestore event:', groupEvent);
  
        // Update the event in the local state
        updatedEvents = updatedEvents.map(e => {
          const eventId = e.id || e.resource.docId;
          if (eventId === (event.id || event.resource.docId)) {
            // Remove the hidden property from the resource
            const { manualConfirm, ...updatedResource } = e.resource;
            return { ...e, resource: updatedResource };
          }
          return e;
        });
      }

// Find the group of events by groupId
const eventGroupIndex = groupedEvents.findIndex(group => group[0].groupId === event.groupId);

if (eventGroupIndex !== -1) { // Check if the group exists
  // Map through the events in the found group to update the matching event(s)
  const updatedGroup = groupedEvents[eventGroupIndex].map(e => {
    const eventId = e.id || e.resource.docId;
    if (eventId === (event.id || event.resource.docId)) {
      // Remove the manualConfirm property from the resource
      const { manualConfirm, ...updatedResource } = e.resource;
      // Update the event with the new resource
      return { ...e, resource: updatedResource };
    }
    return e; // Return the event unchanged if it doesn't match
  });

  // Update the group in the groupedEvents array with the updated group
  groupedEvents[eventGroupIndex] = updatedGroup;
}

      const eventId = event.id || event.resource.docId;

            // Record event only if it is the representativeEvent
      if (eventId === representativeEvent.id || representativeEvent.resource.docId) {
        const eventData = {
          phone: representativeEvent.resource.phone || event.resource["phone 2"] || "",
          email: representativeEvent.resource.email || "",
          name: representativeEvent.resource.name,
          language: representativeEvent.resource.xLanguage || representativeEvent.resource.language || "",
          date: representativeEvent.resource.newDate || representativeEvent.resource.date || "",
          time: representativeEvent.resource.newGroup || representativeEvent.resource.time || "",
          encounterType: representativeEvent.resource.encType || representativeEvent.resource.encounterType || "",
          dateOfBirth: representativeEvent.tag === "appointment" ? representativeEvent.resource.dateOfBirth || "" : "",
          aptSpecLabel: representativeEvent.resource.aptSpecLabel || "",
          otherInfo: representativeEvent.resource.otherInfo || "",
        };

        const docuId = representativeEvent.id || representativeEvent.resource.docId || "";
        const action = `${tag} Event ID: ${docuId}`;
        await recordEvent(eventData, action, tag, representativeEvent)
          .then(docuId => {
            console.log(`Successfully recorded user event.`);
          })
          .catch(error => {
            console.error("Error recording user event:", error);
          });
      }

  
      setEvents(updatedEvents);  // Update the state with the modified events
  
      onClose();  // Close the popover
      // alert("Successfully cleared confirmation status from all events in the group!");
    } catch (error) {
      console.error("Error updating document: ", error);
      alert("An error occurred while updating the document");
    }
  };
  
  const placeholder = "placeholder"
  const confirmTag = "Confirmed the"
  const cancelTag = "Cancelled the"
  const resetTag = "Reset to Original Status the"
  const hideTag = "Hide the"
  const unhideTag = "Unhide the"
  const noShowTag = "Marked as No Show the"
  const removeNoShowTag = "Removed No Show for the"
  const labelTag = "Edited Label of the"

  const handleConfirm = () => updateDocument({ manualConfirm: 'confirmed' }, confirmTag );
  const handleCancel = () => updateDocument({ manualConfirm: 'cancelled' }, cancelTag );
  const handleClearStatus = () =>  updateDocumentForUnconfirm( placeholder, resetTag );
  const handleHide = async () => {
    await updateDocument({ hidden: 'true' }, hideTag );
    // fetchEvents();
  }
  const handleUnhide = async () => {
    await updateDocumentForHiddenDelete( placeholder, unhideTag );
    // fetchEvents();
  }
  const handleNoShow = () => updateDocument({ noShow: true }, noShowTag );
  const handleNoShowRemove = () => updateDocument({ noShow: false }, removeNoShowTag );

  const handleAddOtherInfo = () => {
    // Check if aptSpecLabel exists, use it if it does, or default to an empty string
    const currentAptSpecLabel = event.resource.aptSpecLabel ? event.resource.aptSpecLabel : "";

    // Show prompt with current value or empty for editing
    const newAptSpecLabel = prompt("Add/Edit Label:", currentAptSpecLabel === "deletedLabel" ? "" : currentAptSpecLabel);


    // If the user clicked 'OK'
    if (newAptSpecLabel !== null) {
        // Use "deletedLabel" if newAptSpecLabel is an empty string, otherwise use newAptSpecLabel
        const labelToUpdate = newAptSpecLabel === "" ? "deletedLabel" : newAptSpecLabel;

        // Update the aptSpecLabel, creating it if it doesn't exist
        updateDocument({ aptSpecLabel: labelToUpdate }, labelTag);
    }
};

//   const handleAddOtherInfo = () => {
//     // Check if aptSpecLabel exists, use it if it does, or default to an empty string
//     const currentAptSpecLabel = event.resource.aptSpecLabel ? event.resource.aptSpecLabel : "";

//     // Show prompt with current value or empty for editing
//     const newAptSpecLabel = prompt("Add/Edit Label:", currentAptSpecLabel);

//     // If the user clicked 'OK'
//     if (newAptSpecLabel !== null) {
//         // Update the aptSpecLabel, creating it if it doesn't exist
//         updateDocument({ aptSpecLabel: newAptSpecLabel });
//     }
// };



  const isHidden = event.resource.hidden === 'true'; 
  const isNoShow = event.resource.noShow === true; 

  return (
    <Popover
        id="right-click-popover"
        open={open && event.tag !== "fake"} // Add this condition
        anchorReference="anchorPosition"
        anchorPosition={{ top: position.y, left: position.x }}
        onClose={onClose}
    >
        <MenuItem onClick={handleConfirm}>Confirm</MenuItem>
        <MenuItem onClick={handleCancel}>Cancel</MenuItem>
        <MenuItem onClick={handleClearStatus}>Reset Original Status</MenuItem>
        {isNoShow 
          ? <MenuItem onClick={handleNoShowRemove}>Remove "Now Show" Status</MenuItem> 
          : <MenuItem onClick={handleNoShow}>Mark "No Show" Status</MenuItem>}
        <MenuItem onClick={handleAddOtherInfo}>Add/Edit Label</MenuItem>
        {isHidden 
          ? <MenuItem onClick={handleUnhide}>Unhide</MenuItem> 
          : <MenuItem onClick={handleHide}>Hide</MenuItem>}
        <MenuItem onClick={onClose} style={{ backgroundColor: '#3f51b5', color: '#fff', display: 'flex', justifyContent: 'center' }}>
            Close
        </MenuItem>
    </Popover>
  );
};

// const RightClickPopover = ({ position, event, open = false, onClose, userEmail, physician, firestore, groupedEvents }) => {
 

//   const updateDocument = async (updateData) => {
//     try {
//       let docRef;
//       let targetEvent = null;

//       // Find the group using the groupId
//       const group = groupedEvents.find(g => g.some(e => e.groupId === event.groupId));

//       console.log(group)

//       if (group) {
//         // If the event has 'appointment' tag, select the second event in the group
//         // Otherwise, select the first event in the group
//         targetEvent = event.tag === "appointment" ? group[0] : group[0];
//       }

//       if (!targetEvent || !targetEvent.resource.id) {
//         // Alternative path for appointments without notifications
//         docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', 'mainCalendar', 'appointments', event.resource.docId);
//       } else {
//         // Original path
//         docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'history', targetEvent.resource.id);
//       }
  
//       // Check if the document exists
//       const docSnap = await getDoc(docRef);
//       if (!docSnap.exists()) {
//         alert("Could not update appointment due to missing data.");
//         return;
//       }
  
//       // Update the document
//       await updateDoc(docRef, updateData);
//       onClose(); // Close the popover
//       alert("Successfully updated the notification status!");
//     } catch (error) {
//       console.error("Error updating document: ", error);
//       alert("An error occurred while updating the document");
//     }
//   };
  
//   const handleConfirm = () => updateDocument({ manualConfirm: 'confirmed' });
//   const handleCancel = () => updateDocument({ manualConfirm: 'cancelled' });
//   const handleHide = () => updateDocument({ hidden: 'true' });
//   const handleUnhide = () => updateDocument({ hidden: 'false' });

//   const isHidden = event.resource.hidden === 'true'; // Check if the event is hidden
//   // console.log(isHidden)

//   return (
//     <Popover
//         id="right-click-popover"
//         open={open}
//         anchorReference="anchorPosition"
//         anchorPosition={{ top: position.y, left: position.x }}
//         onClose={onClose}
//     >
//         <MenuItem onClick={handleConfirm}>Confirm</MenuItem>
//         <MenuItem onClick={handleCancel}>Cancel</MenuItem>
//         {isHidden 
//           ? <MenuItem onClick={handleUnhide}>Unhide</MenuItem> 
//           : <MenuItem onClick={handleHide}>Hide</MenuItem>}
//         <MenuItem onClick={onClose} style={{ backgroundColor: '#3f51b5', color: '#fff', display: 'flex', justifyContent: 'center' }}>
//             Close
//         </MenuItem>
//     </Popover>
//   );
// };





const CalendarModalPlus = ({ open, handleClose, userEmail, physician, firestore, currentActiveUser, adminPassword, is2FAActive, startFetchEvents, users, makeApiCall, setItemsBeingProcessed }) => {
    // Setting the locale for moment to English 
    moment.locale('en');

    const nameRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const middleNameRef = useRef();
    const nicknameRef = useRef();
    const nameAptSearchRef = useRef();
    const phoneRef = useRef();
    const phoneAptSearchRef = useRef();
    const dateRef = useRef();
    const paDateRef = useRef();
    const timeRef = useRef();
    const paTimeRef = useRef();
    const emailRef = useRef();
    const encounterTypeRef = useRef();
    const otherInfoRef = useRef();
    const orInfoRef = useRef();
    const nameEncRef = useRef();
    const categoryRef = useRef();
    const aptLangRef = useRef();
    const dateOfBirthRef = useRef();
    const aptSpecLabelRef = useRef();
    const updatedOtherInfoRef = useRef();
    const lastEncounterTypeRef = useRef();

    const fromDateRef = useRef();
    const toDateRef = useRef();

    const colorEncTypeRef = useRef('#ffffff'); // Initialize with default color

    const hasRunOnce = useRef(false); 


    // const [hasRunOnce, setHasRunOnce] = useState(false);

    const [isResultsVisible, setIsResultsVisible] = useState(false);

    const [savedNewEncounterType, setSavedNewEncounterType] = useState(false);

    const [foundEncTypes, setFoundEncTypes] = useState([])

    const [encTypeColor, setEncTypeColor] = useState('');

    const [encTypeView, setEncTypeView] = useState(false)

    const [spotEncType, setSpotEncType] = useState([])

    const [spotAvailabilityResult, setSpotAvailabilityResult] = useState("")

    const [spotResult, setSpotResult] = useState("")

    const [eventsForSpotSearch, setEventsForSpotSearch] = useState([])

    const [startTime, setStartTime] = useState('')

    const [isLoadingSpotSearch, setIsLoadingSpotSearch] = useState(false)

    const [searchResults, setSearchResults] = useState([]);

    const [shouldContinue, setShouldContinue] = useState(true)
    const shouldContinueRef = useRef(shouldContinue);


    const[calendars, setCalendars] = useState([])
    const [selectedCalendar, setSelectedCalendar] = useState("mainCalendar")
    const [selectedCalendarView, setSelectedCalendarView] = useState("All")
    const [rulesStatus, setRulesStatus] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const [openDatePicker, setOpenDatePicker] = useState(false);

    const [selectedPatient, setSelectedPatient] = useState(null);
    const searchResultRef = useRef([]);
    const patientListRef = useRef();

    const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());

    const [fColor, setFColor] = useState('white');

    const [lastSearchDate, setLastSearchDate] = useState('');
    const [lastEncounterType, setLastEncounterType] = useState('');


    const [wmName, setWmName] = useState('');
    const [name, setName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [nickname, setNickname] = useState('');
    const [nameAptSearch, setNameAptSearch] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneAptSearch, setPhoneAptSearch] = useState('');
    const [date, setDate] = useState('');
    const [openingDate, setOpeningDate] = useState('');
    const [paDate, setPaDate] = useState('');
    const [time, setTime] = useState('');
    const [openingTime, setOpeningTime] = useState('');
    const [paTime, setPaTime] = useState('');
    const [email, setEmail] = useState('');
    const [encounterType, setEncounterType] = useState('');
    const [aptSpecLabel, setAptSpecLabel] = useState('')
    const [otherInfo, setOtherInfo] = useState('');
    const [orInfo, setOrInfo] = useState('');
    const [aptLang, setAptLang] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [dateOfBirthAptSearch, setDateOfBirthAptSearch] = useState('')

    

    const [events, setEvents] = useState([]);
    const [eventLogs, setEventLogs] = useState([]);
    const [eventsAptSearch, setEventsAptSearch] = useState([]);
    const [showConfirmed, setShowConfirmed] = useState(false);
    const [showByColor, setShowByColor] = useState(false);
    const [showOrInfo, setShowOrInfo] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [datesUpdated, setDatesUpdated] = useState(false);
    const [previousCurrentDate, setPreviousCurrentDate] = useState(null);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [currentView, setCurrentView] = useState('month');
    const calendarRef = useRef(null);
    const localizer = momentLocalizer(moment);
    const [encTypeFilter, setEncTypeFilter] = useState(["No Filter"]);
    const encTypeFilterRef = useRef(encTypeFilter);
    const [userFilter, setUserFilter] = useState(["No Filter"]);
    const [originalEvents, setOriginalEvents] = useState([]);
    const [userAnchorEl, setUserAnchorEl] = React.useState(null);
    const [encTypeAnchorEl, setEncTypeAnchorEl] = React.useState(null);
    const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
    const [openDialog, setOpenDialog] = useState(false);
    const [openNewAptModal, setOpenNewAptModal] = useState(false);
    const [openExistentAptModal, setOpenExistentAptModal] = useState(false);
    const [openSettingsModal, setOpenSettingsModal] = useState(false);
    const [eventData, setEventData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingAptSearch, setIsLoadingAptSearch] = useState(false);
    const [filteredEvents, setFilteredEvents] = useState([]);
    const [triggerCustomEventRender, setTriggerCustomEventRender] = useState(false);

    // const [updating, setUpdating] = useState(false);
    // const [allEventsForUpdatingCheck, setAllEventsForUpdatingCheck] = useState([]);


    const [selectedEvent, setSelectedEvent] = useState(null);
    const [groupedEvents, setGroupedEvents] = useState([])
    const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
    const [selectedEventIndex, setSelectedEventIndex] = useState(null);
    const [appointmentId, setAppointmentId] = useState('')

    const [selectedAptEvent, setSelectedAptEvent] = useState(null);

    const [showNotificationDetails, setShowNotificationDetails] = useState(false);

    const [activeScreen, setActiveScreen] = useState(false);

    const [noSpotModalOpen, setNoSpotModalOpen] = useState(false);
    const [overrideModalOpen, setOverrideModalOpen] = useState(false);
    const [overridePastEventModalOpen, setOverridePastEventModalOpen] = useState(false);
    const [pastOverrideModalOpen, setPastOverrideModalOpen] = useState(false);
    const [allowProceed, setAllowProceed] = useState(false); // New state variable
    const [specialUseDataForSpot, setSpecialUseDataForSpot] = useState({});
    const [selectedUpdateEvent, setSelectedUpdateEvent] = useState(null);
    const [readyToHideOldNotifications, setReadyToHideOldNotifications] = useState(false);
    const [modalSize, setModalSize] = useState('default'); // Values: 'default', 'minimized', 'maximized'
    const [secondModalSize, setSecondModalSize] = useState('default');  // 'default', 'minimized', 'maximized'



      // New Appointment Modal States
    //   const [newAppointment, setNewAppointment] = useState({
    //     name: '',
    //     date: '',
    //     time: '',
    //     phone: '',
    //     email: '',
    //     encounterType: '',
    //     otherInfo: '',
    //   });


      const [encounterTypes, setEncounterTypes] = useState([]);
      const [encounterTypesForDetails, setEncounterTypesForDetails] = useState([]);
      const [selectedEncounterType, setSelectedEncounterType] = useState('');
      const [newEncounterType, setNewEncounterType] = useState({ name: '', category: '' });

      const [showLabel, setShowLabel] = useState(true); // Step 1: Define a new state

      const [popoverState, setPopoverState] = useState({ show: false, event: null, position: { x: 0, y: 0 } });

      const [showAllEvents, setShowAllEvents] = useState (false)

      const [anchorEl, setAnchorEl] = useState(null);

      const [optionsAnchorPosition, setOptionsAnchorPosition] = useState(null);

      const [exportAnchorPosition, setExportAnchorPosition] = useState(null);

      const [isUpdating, setIsUpdating] = useState(false);

      const [isInProcess, setIsInProcess] = useState(false);

      const [isUpdatingOldNotifications, setIsUpdatingOldNotifications] = useState(false);

      const [firstClickHappened, setFirstClickHappened] = useState(false);

      const [datesAndDayNamesState, setDatesAndDayNamesState] = useState([]);

      const charCountRef = useRef(null);
      const maxChars = 30;

      const [calendarHeight, setCalendarHeight] = useState(window.innerHeight * 0.72);

      useEffect(() => {
        const handleResize = () => {
          const viewportHeight = window.innerHeight; // Dynamically get the viewport height
          setCalendarHeight(viewportHeight * 0.72); // 72% of viewport height
        };
    
        window.addEventListener('resize', handleResize); // Recalculate on resize
        handleResize(); // Initial calculation
    
        return () => window.removeEventListener('resize', handleResize);
      }, []);

      const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const currentLength = inputValue.length;
    
        // Update character count display using the ref
        if (charCountRef.current) {
          charCountRef.current.textContent = `${currentLength}/${maxChars}`;
        }
      };


      useEffect(() => {
        function handleClickOutside(e) {
          if (patientListRef.current && !patientListRef.current.contains(e.target)) {
            patientListRef.current.innerHTML = '';
          }
        }
      
        function handleTabPress(e) {
          if (e.key === 'Tab') {
            // Clear the list when Tab is pressed
            if (patientListRef.current) {
              patientListRef.current.innerHTML = '';
            }
          }
        }
      
        // Listen for mouse clicks
        document.addEventListener('mousedown', handleClickOutside);
        // Listen for keyboard presses (specifically Tab)
        document.addEventListener('keydown', handleTabPress);
      
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
          document.removeEventListener('keydown', handleTabPress);
        };
      }, []);
      


      // useEffect(() => {
      //   function handleClickOutside(e) {
      //     if (patientListRef.current && !patientListRef.current.contains(e.target)) {
      //       patientListRef.current.innerHTML = ''; // Clear the list
      //     }
      //   }
      
      //   document.addEventListener('mousedown', handleClickOutside);
      //   return () => {
      //     document.removeEventListener('mousedown', handleClickOutside);
      //   };
      // }, []);



      const handleNoSpotModalOpen = (event) => {
        console.log("Opening No Spot Modal...")
        setNoSpotModalOpen(true);
      }
      const handleNoSpotModalClose = () => {
        setIsUpdating(false);
        setNoSpotModalOpen(false);
        setIsInProcess(false);
      }

      const handleStopSearch = () => {
        // Logic to stop the search
        setIsLoadingSpotSearch(false); // Assuming you have a state setter like this
        setShouldContinue(false);
        setFirstClickHappened(false);
      };

      const handleOverridePastEventModalOpen = async () => {
        console.log("Opening Override Past Event Modal...")
        setOverridePastEventModalOpen(true);
      }

      const handleOverridePastEventModalClose = () => {

        setOverridePastEventModalOpen(false);
        setIsInProcess(false);
      }



      const handleOverrideClick = (event) => {
        event.stopPropagation();
    
        const isAdmin = currentActiveUser === "admin";
        const isSpecialUser = users.some(user => 
            user.id === currentActiveUser && user.specialUser === true
        );
    
        if (isAdmin || isSpecialUser) {
            setNoSpotModalOpen(false);
            handleAppointmentAction();
            return;
        }
    
        if (is2FAActive) {
            setNoSpotModalOpen(false);
            setOverrideModalOpen(true);
        } else {
            setNoSpotModalOpen(false);
            handleAppointmentAction();
        }
    };
    

    //   const handleOverrideClick = (event) => {
    //     event.stopPropagation();

    //     if (is2FAActive) {
    //     setNoSpotModalOpen(false);
    //     setOverrideModalOpen(true);
    //     } else {
    //       setNoSpotModalOpen(false);
    //       handleAppointmentAction();
    //     }
    // };

// This function is called when "Override" is clicked in the first modal
const handlePastOverrideClick = (event) => {
  event.stopPropagation();
  setOverridePastEventModalOpen(false); // Close the first modal
  setPastOverrideModalOpen(true); // Open the second modal
};

// This function handles the action after successful 2FA verification
const handlePastAppointmentAction = () => {
  if (allowProceed === false) {
  setAllowProceed(true); // Update state to allow proceeding
}
  setPastOverrideModalOpen(false); // Close the second modal
};

// useEffect(() => {
//   console.log("Allow Proceed:", allowProceed);
// }, [allowProceed]);
     

      const handleOverrideModalClose = () => {
        setOverrideModalOpen(false);
        // setIsInProcess(false);
      }

      const handlePastOverrideModalClose = () => {
        setPastOverrideModalOpen(false);
        setIsInProcess(false);
      }

      const hideOldNotificationsOnApptUpdate = async (event) => {

        console.log("Starting to check for hiding old notifications...");

        const updateData = { hidden: 'true' }
    
        const tag = "Auto-hide the"
    
        // const event = selectedUpdateEvent || selectedAptEvent;
    
        console.log(event)
        try {
    
         
          // Find the group using the groupId
          const group = groupedEvents.find(g => g.some(e => e.groupId === event.groupId));

          console.log(group)
    
      
          if (!group || group.length === 0) {
            alert("No events found in the group.");
            return;
          }
    
    
    // Assume 'group' is your initial unsorted array of events
    const appointmentEvent = group.find(e => e.tag === "appointment");
    const nonAppointmentEvents = group.filter(e => e.tag !== "appointment");

    if (!nonAppointmentEvents) {
      console.log("No non-appointment events found, skipping...");
      return; 
    }
    
    // Sort non-appointment events in descending chronological order
    nonAppointmentEvents.sort((a, b) => {
      const dateA = new Date(a.resource.dateId);
      const dateB = new Date(b.resource.dateId);
      return dateB - dateA; // For descending order
    });
    
    // // Combine them, ensuring an appointment event is first if it exists
    // const sortedGroup = appointmentEvent ? [appointmentEvent, ...nonAppointmentEvents] : nonAppointmentEvents;
    
  // Ignore and remove any appointment event
  const sortedGroup = nonAppointmentEvents;

  console.log("Sorted Group:", sortedGroup);
  console.log("Appointment Event:", appointmentEvent);

  if (sortedGroup.length === 0) {
    console.log("No need to hide notifications!");
    return;
  }

  if (appointmentEvent.length > 0) {
    console.log("Appointment event found, skipping hidding of notifications...");
    return; 
  }
  
  
    // Retain only the first event from the sorted group as representativeEvent
    const representativeEvent = sortedGroup[0];
    
    // Use representativeEvent as needed
    // console.log("Representative Event:", representativeEvent);
    
    
    
      
          let updatedEvents = [...events];  // Make a copy of the current events
          let updatedFilteredEvents = !filteredEvents.length === 0 ? [...filteredEvents] : [...events];
          let updatedOriginalEvents = !originalEvents.length === 0 ? [...originalEvents] : [...events];
    
    
          for (let groupEvent of group) {
            let docRef;
      
            if (!representativeEvent.id && !representativeEvent.resource.docId) {
              alert("Event missing necessary ID data.");
              continue;  // Skip to the next event in the group
            }
      
            // // Determine the correct document reference
            // if (event.tag === "appointment") {
            //   docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'appointments', event.resource.docId);
            // } else {
              docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'history', representativeEvent.id);
            // }
      
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists()) {
              console.log("Event not found, skipping...");
              continue;  // Skip to the next event in the group
            }
      
            // Update the document in Firebase
            await updateDoc(docRef, updateData);
            console.log('Updated event.');
    
    
      
            // Update the event in the local state
            updatedEvents = updatedEvents.map(e => {
              const eventId = e.id || e.resource.docId;
              if (eventId === (representativeEvent.id || representativeEvent.resource.docId)) {
                return { ...e, resource: { ...e.resource, ...updateData }};
              }
              return e;
            });
          
    
                  // Update the filtered events in the local state
                  updatedFilteredEvents = updatedFilteredEvents.map(e => {
                    const eventId = e.id || e.resource.docId;
                    if (eventId === (representativeEvent.id || representativeEvent.resource.docId)) {
                      return { ...e, resource: { ...e.resource, ...updateData }};
                    }
                    return e;
                  });
    
                                // Update the original events in the local state
                  updatedOriginalEvents = updatedOriginalEvents.map(e => {
                    const eventId = e.id || e.resource.docId;
                    if (eventId === (representativeEvent.id || representativeEvent.resource.docId)) {
                      return { ...e, resource: { ...e.resource, ...updateData }};
                    }
                    return e;
                  });
                }
    
    
                // Find the group of events by groupId
                const eventGroupIndex = groupedEvents.findIndex(group => group[0].groupId === representativeEvent.groupId);
    
                if (eventGroupIndex !== -1) { // Check if the group exists
                  // Map through the events in the found group to update the matching event(s)
                  const updatedGroup = groupedEvents[eventGroupIndex].map(e => {
                    const eventId = e.id || e.resource.docId;
                    if (eventId === (representativeEvent.id || representativeEvent.resource.docId)) {
                      // Update the event with the new data
                      return { ...e, resource: { ...e.resource, ...updateData }};
                    }
                    return e; // Return the event unchanged if it doesn't match
                  });
    
                  // Update the group in the groupedEvents array with the updated group
                  groupedEvents[eventGroupIndex] = updatedGroup;
                }
    
    
    
         const eventId = representativeEvent.id || representativeEvent.resource.docId;
    
                // Record event only if it is the representativeEvent
          if (eventId === representativeEvent.id || representativeEvent.resource.docId) {
            const eventData = {
              phone: representativeEvent.resource.phone || representativeEvent.resource["phone 2"] || "",
              email: representativeEvent.resource.email || "",
              name: representativeEvent.resource.name || "",
              firstName: representativeEvent.resource.firstName || "",
              middleName: representativeEvent.resource.middleName || "",
              lastName: representativeEvent.resource.lastName || "",
              language: representativeEvent.resource.xLanguage || representativeEvent.resource.language || "",
              date: representativeEvent.resource.newDate || representativeEvent.resource.date || "",
              time: representativeEvent.resource.newGroup || representativeEvent.resource.time || "",
              encounterType: representativeEvent.resource.encType || representativeEvent.resource.encounterType || "",
              dateOfBirth: representativeEvent.tag === "appointment" ? representativeEvent.resource.dateOfBirth || "" : "",
              aptSpecLabel: representativeEvent.resource.aptSpecLabel || "",
              otherInfo: representativeEvent.resource.otherInfo || "",
              changedData: updateData || "",
            };
    
            const docuId = representativeEvent.id || representativeEvent.resource.docId || "";
            const action = `${tag} Event ID: ${docuId}`;
            await recordEvent(eventData, action, tag, representativeEvent)
              .then(docuId => {
                console.log(`Successfully recorded user event.`);
              })
              .catch(error => {
                console.error("Error recording user event:", error);
              });
          }
    
      
          setEvents(updatedEvents);  // Update the state with the modified events
          setFilteredEvents(updatedFilteredEvents)
          setOriginalEvents(updatedOriginalEvents)
          setIsUpdatingOldNotifications(false);
          setSelectedUpdateEvent(null);
          // alert("Successfully updated the notification status for all events in the group!");
        } catch (error) {
          console.error("Error updating document: ", error);
          alert("An error occurred while updating the document");
        }
      };

        // The function to be triggered based on `isUpdating`
  async function handleAppointmentAction() {

    console.log("Starting to handle appointment action..." )
    console.log(selectedUpdateEvent)

    

    if (isUpdating) {
      await handleUpdateAppointment(); // Call the update function if isUpdating is true
      await hideOldNotificationsOnApptUpdate(selectedUpdateEvent);
      fetchEvents();
      setCurrentView('day');
      const newDateObj = date; // This seems redundant, you might want to use 'newDate' directly
  
      // Split the date string into components
      const [year, month, day] = newDateObj.split('-').map(Number);
  
      // Create a new date object using local time zone
      const adjustedDate = new Date(year, month - 1, day);
      setCurrentDate(adjustedDate);
      handleCloseExistentAptModal();
      setIsUpdating(false);
      console.log("Appointment updated successfully!");
      alert('Appointment updated successfully!');
      setIsInProcess(false);
      hasRunOnce.current = false
      return;
    } else {
      await handleSaveAppointment(); // Call the save function if isUpdating is false
      hasRunOnce.current = false
      return;
    }
  }

// Function to record an event
async function recordEvent(data, action, tag, newDocId) {
  // console.log("Starting to log event...");
  // console.log("Selected event:", selectedEvent);
  // console.log("Data:", data);
  // console.log("Action:", action);

  const db = firestore; // Assuming `firestore` is already initialized Firestore instance
  const event = selectedEvent;

  // Generate the current timestamp in ISO 8601 format

const now = new Date();
const timestamp = now.toISOString(); // e.g., "2024-02-05T19:58:00.069Z"

// Adjust to include up to the minute
const dateUpToMinute = timestamp.slice(0, 16); // Result: "2024-02-05T19:58"

  // console.log("Generated timestamp:", timestamp);

  // Logging the collection path for debug
  // console.log("Firestore collection path:", 'accounts', userEmail, 'physician', physician, 'eventEditHistory');

   // Define the subcollection path
   const subcollectionPath = `accounts/${userEmail}/physician/${physician}/eventEditHistory`

  // Create a query to find existing documents with the same timestamp
  const eventsQuery = query(
    collection(db, subcollectionPath),
    where('date', '==', dateUpToMinute),
    orderBy(documentId(), 'desc'), // Modular SDK usage
    limit(1)
  );
  
  
  // console.log("Executing query to find existing documents with orderBy on potentially non-existent field...");
  
  const querySnapshot = await getDocs(eventsQuery);
  let sequenceNumber = 0;
  
  if (!querySnapshot.empty) {
    // console.log("Found existing documents, calculating sequence number...");
    const lastEventId = querySnapshot.docs[0].id; // Assuming eventId is stored as document id
    const match = lastEventId.match(/\d+$/); // Match the sequence number at the end of the string
    if (match) {
      sequenceNumber = parseInt(match[0], 10) + 1;
    }
  } else {
    console.log("No existing documents found or orderBy field doesn't exist, proceeding with initial sequence number.");
  }
  
  // Construct the document ID with the new sequence number
  const newDocumentId = `${timestamp}-${sequenceNumber.toString().padStart(6, '0')}`;
  // console.log("Constructed document ID:", newDocumentId);

  // console.log(data)


  let eventData;

if (tag === "new appointment") {
  // This block runs if the event is a new appointment
  eventData = {
    phone: data.phone || data["phone 2"] || "",
    email: data.email || "",
    firstName: data.firstName || data.name.split(" ")[0] || "",
    lastName: data.lastName || data.name.split(" ").slice(1).join(" ") || "",
    middleName: data.middleName || "",
    language: data.language || "",
    date: data.date || "",
    time: data.time || "",
    encType: data.encounterType || "",
    user: currentActiveUser || "",
    action: action,
    eventType: "Appointment",
    eventId: newDocId || "",
    dateOfBirth: data.dateOfBirth || "",
    data: [data], // Assuming 'data' itself is the detailed info you want to keep
    calendar: selectedCalendar || ""
  };
} else {
  // This block runs for other types of events
  eventData = {
    phone: event.resource.phone || event.resource["phone 2"] || "",
    email: event.resource.email || "",
    firstName: event.resource.firstName || event.resource.name.split(" ")[0] || "",
    lastName: event.resource.lastName || event.resource.name.split(" ").slice(1).join(" ") || "",
    middleName: event.resource.middleName || "",
    language: event.resource.xLanguage || event.resource.language || "",
    date: event.resource.newDate || event.resource.date || "",
    time: event.resource.newGroup || event.resource.time || "",
    encType: event.resource.encType || event.resource.encounterType || "",
    user: currentActiveUser || "",
    action: action,
    eventType: event.tag === "appointment" ? "Appointment" : "Notification",
    eventId: event.resource.id || event.resource.docId || "",
    dateOfBirth: event.tag === "appointment" ? event.resource.dateOfBirth || "" : "",
    data: event.tag === "appointment" ? [data] : [],
    calendar: selectedCalendar || ""
  };
}


  // // Prepare the event data, including conditional fields
  // const eventData = {
  //   phone: event.resource.phone || "",
  //   email: event.resource.email || "",
  //   firstName: event.resource.name.split(" ")[0] || "",
  //   lastName: event.resource.name.split(" ").slice(1).join(" ") || "",
  //   language: event.resource.xLanguage || event.resource.language || "",
  //   date: event.resource.newDate || event.resource.date || "",
  //   time: event.resource.newGroup || event.resource.time || "",
  //   encType: event.resource.encType || event.resource.encounterType || "",
  //   user: currentActiveUser || "",
  //   action: action,
  //   eventType: event.tag === "appointment" ? "Appointment" : "Notification",
  //   eventId: event.resource.id || event.resource.docId || "",
  //   dateOfBirth: event.tag === "appointment" ? event.resource.dateOfBirth || "" : "",
  //   data: event.tag === "appointment" ? [data] : [],
  //   calendar: selectedCalendar || ""
  // };

  console.log("Event data prepared.");

  // Path to the specific document in the subcollection
  const docRef = doc(db, subcollectionPath, newDocumentId);

  // console.log("Saving event information to Firestore...");
  await setDoc(docRef, eventData);

  console.log(`Event recorded with ID.`);
  return newDocumentId; // Optionally return the new document ID
}


// Example usage (ensure you replace placeholders with actual values/functions)
// recordEvent(firestore, 'user@example.com', 'physicianId', event, 'activeUserId', 'docId', 'selectedCalendar')
//   .then(documentId => {
//     console.log(`Successfully recorded event: ${documentId}`);
//   })
//   .catch(error => {
//     console.error("Error recording event:", error);
//   });





      const handleExportClick = (event) => {
          setExportAnchorPosition({ top: event.clientY, left: event.clientX });
      };
  
      const handleExportOption = (option) => {
          // Implement your export logic based on the option
          generateTemplateFile(option);
          // console.log(`Export option selected: ${option}`);
          setExportAnchorPosition(null);
      };


      const generateTemplateFile = async (exportOption) => {
        let filteredEvents = [];

        console.log("currentDate", currentDate)

        const formattedDate = currentDate.toLocaleDateString('en-CA'); // Canadian locale uses YYYY-MM-DD format
        console.log("formattedDate", formattedDate)
     
    
    
        // Filter events based on exportOption and update their confirmedTags
        if (exportOption === 'noShow') {
          filteredEvents = events.filter(event => 
            event.resource.noShow === true &&
            event.start === formattedDate &&
            event.tag !== "fake" &&
            (showAllEvents || event.resource.hidden !== "true" || !event.resource.hasOwnProperty('hidden'))
        );        
        } else if (exportOption === 'cancellations') {

          // Step 1: Identify Appropriate Groups
const unconfirmedGroupIds = [];
const cancelledGroupIds = [];
groupedEvents.forEach(group => {
    let hasCancelled = false;
    let hasConfirmed = false;

    group.forEach(groupEvent => {
      // Consolidate the status check for 'cancelled'
      let manualConfirmCancelled = groupEvent.manualConfirm ? groupEvent.manualConfirm : "";
      if (manualConfirmCancelled === 'cancelled' || 
          groupEvent.confirmByEmail === 'cancelled' || 
          groupEvent.confirmBySms === 'cancelled' || 
          groupEvent.confirmByVoice === 'cancelled') {
          hasCancelled = true;
      }
  
      // Consolidate the status check for 'confirmed'
      let manualConfirmConfirmed = groupEvent.manualConfirm ? groupEvent.manualConfirm : "";
      if (manualConfirmConfirmed === 'confirmed' || 
          groupEvent.confirmByEmail === 'confirmed' || 
          groupEvent.confirmBySms === 'confirmed' || 
          groupEvent.confirmByVoice === 'confirmed') {
          hasConfirmed = true;
      }
  });

  if (!hasCancelled && !hasConfirmed) {
    unconfirmedGroupIds.push(group[0].groupId); // assuming groupId is accessible here
}
 

    if (hasCancelled && !hasConfirmed) {
        cancelledGroupIds.push(group[0].groupId); // assuming groupId is accessible here
    }
});


// Ensure unique group IDs
const uniqueUnconfirmedGroupIds = [...new Set(unconfirmedGroupIds)];

// Ensure unique group IDs
const uniqueCancelledGroupIds = [...new Set(cancelledGroupIds)];

// Step 2: Mark Events as Cancelled in `events` Array and Filter
if (exportOption === 'cancellations') {
    events.forEach(event => {
        if (uniqueCancelledGroupIds.includes(event.groupId)) {
            event.confirmedTag = "cancelled";
        }
    });

    filteredEvents = events.filter(event =>
        event.confirmedTag === "cancelled" &&
        event.start === formattedDate &&
        event.tag !== "fake" &&
        (showAllEvents || event.resource.hidden !== "true" || !event.resource.hasOwnProperty('hidden'))
    );
}




// if (exportOption === 'unconfirmed') {
//   events.forEach(event => {
//       if (uniqueUnconfirmedGroupIds.includes(event.groupId)) {
//           event.confirmedTag = "cancelled";
//       }
//   });

//   filteredEvents = events.filter(event =>
//       event.start === formattedDate &&
//       (showAllEvents || event.resource.hidden !== "true" || !event.resource.hasOwnProperty('hidden'))
//   );
// }

} else if (exportOption === 'unconfirmed') {

  // Step 1: Identify Appropriate Groups
const unconfirmedGroupIds = [];
const cancelledGroupIds = [];
groupedEvents.forEach(group => {
let hasCancelled = false;
let hasConfirmed = false;

group.forEach(groupEvent => {
// Consolidate the status check for 'cancelled'
let manualConfirmCancelled = groupEvent.manualConfirm ? groupEvent.manualConfirm : "";
if (manualConfirmCancelled === 'cancelled' || 
  groupEvent.confirmByEmail === 'cancelled' || 
  groupEvent.confirmBySms === 'cancelled' || 
  groupEvent.confirmByVoice === 'cancelled') {
  hasCancelled = true;
}

// Consolidate the status check for 'confirmed'
let manualConfirmConfirmed = groupEvent.manualConfirm ? groupEvent.manualConfirm : "";
if (manualConfirmConfirmed === 'confirmed' || 
  groupEvent.confirmByEmail === 'confirmed' || 
  groupEvent.confirmBySms === 'confirmed' || 
  groupEvent.confirmByVoice === 'confirmed') {
  hasConfirmed = true;
}
});

if (!hasCancelled && !hasConfirmed) {
unconfirmedGroupIds.push(group[0].groupId); // assuming groupId is accessible here
}


if (hasCancelled && !hasConfirmed) {
cancelledGroupIds.push(group[0].groupId); // assuming groupId is accessible here
}
});


// Ensure unique group IDs
const uniqueUnconfirmedGroupIds = [...new Set(unconfirmedGroupIds)];

// Ensure unique group IDs
const uniqueCancelledGroupIds = [...new Set(cancelledGroupIds)];

if (exportOption === 'unconfirmed') {
  // Filter events that match the date and visibility conditions,
  // and also belong to the 'uniqueUnconfirmedGroupIds'
  filteredEvents = events.filter(event =>
      event.start === formattedDate &&
      event.tag !== "fake" &&
      uniqueUnconfirmedGroupIds.includes(event.groupId) &&
      (showAllEvents || event.resource.hidden !== "true" || !event.resource.hasOwnProperty('hidden'))
  );
}


// ...rest of your code for exporting to Excel...

            
        } else if (exportOption === 'allCurrentDay') {
            filteredEvents = events.filter(event => 
                event.start === formattedDate && 
                event.tag !== "fake" &&
                (showAllEvents || event.resource.hidden !== "true" || !event.resource.hasOwnProperty('hidden'))
            );
            
        } else {
            console.error("Invalid export option");
            return;
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        worksheet.columns = [    
          { header: 'Cell Phone', key: 'phone', width: 15 },
          { header: 'Email', key: 'email', width: 30 },
          { header: 'Name', key: 'name', width: 20 },
          { header: 'Language', key: 'xLanguage', width: 15 },
          { header: 'Old Date', key: 'oldDate', width: 15, style: { numFmt: 'yyyy-mm-dd' } },
          { header: 'New Date', key: 'newDate', width: 15, style: { numFmt: 'yyyy-mm-dd' } },
          { header: 'Time/Group', key: 'newGroup', width: 15, style: { numFmt: '@' } },
          { header: 'Encounter Type', key: 'encType', width: 20 },
        ];


        filteredEvents.forEach(event => {
          // Convert oldDate to a Date object if it's a valid date string
          const oldDate = event.resource.newDate || event.resource.date;
          const parsedOldDate = oldDate ? new Date(oldDate) : null;
      
          worksheet.addRow({
            phone: event.resource.phone || event.resource["phone 2"] || "",
            email: event.resource.email || "",
            name: event.resource.name || "",
            xLanguage: event.resource.xLanguage || event.resource.language,
            oldDate: parsedOldDate || null, // Ensure it's either a Date object or null
            newDate: null, // Set as null if no new date is provided
            newGroup: event.resource.newGroup || event.resource.time,
            encType: event.resource.encounterType || event.resource.encType || ""
          });
      });

        // filteredEvents.forEach(event => {
        //     worksheet.addRow({
        //       phone: event.resource.phone || event.resource["phone 2"] || "",
        //       email: event.resource.email || "",
        //       name: event.resource.name || "",
        //       xLanguage: event.resource.xLanguage || event.resource.language,
        //       oldDate: event.resource.newDate || event.resource.date,
        //       newDate: "",
        //       newGroup: event.resource.newGroup || event.resource.time,
        //       encType: event.resource.encounterType || event.resource.encType || ""
        //     });
        // });

        workbook.xlsx.writeBuffer().then(function(data) {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.download = 'export.xlsx';
            link.href = url;
            link.click();
        });
    };




      if (groupedEvents[selectedGroupIndex] && groupedEvents[selectedGroupIndex][selectedEventIndex]) {
        // console.log(groupedEvents[selectedGroupIndex][selectedEventIndex].resource ? groupedEvents[selectedGroupIndex][selectedEventIndex].resource : 'No value');
    } else {
        console.log('No value');
    }
    
      const handleClickOptions = (event) => {
          setOptionsAnchorPosition({ top: event.clientY, left: event.clientX });
      };
      
  
      const handleCloseOptions = () => {
          setAnchorEl(null);
      };

      const handleEventRightClick = (e, event) => {
          e.preventDefault(); // Prevents the default context menu
          setPopoverState({ show: true, event, position: { x: e.clientX, y: e.clientY } });
      };
  
      const handleShowAllEventsOn = () => {
        setShowAllEvents(true)
    };

    const handleShowAllEventsOff = () => {
      setShowAllEvents(false)
  };

      const closePopover = () => {
          setPopoverState({ ...popoverState, show: false });
      };






          // Define forceUpdate
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    // When an event is selected, update the selectedGroupIndex and selectedEventIndex
    const handleEventSelect = (groupIndex, eventIndex) => {
      setSelectedGroupIndex(groupIndex);
      setSelectedEventIndex(eventIndex);
    };

    const formatTime = (time) => {
        if (typeof time !== 'string') {
            return "NO TIME";
        }
    
        // Remove periods and convert to upper case
        let cleanedTime = time.toUpperCase().replace(/\./g, '');
    
        // Check if it has AM/PM or looks like a 24-hour format
        const hasAmPm = /AM|PM/.test(cleanedTime);
        const looksLike24Hour = /^\d{1,2}:\d{2}$/.test(cleanedTime);
    
        // If it looks like a 24-hour format and doesn't have AM/PM, handle it as 24-hour time
        if (looksLike24Hour && !hasAmPm) {
            const momentTime = moment(cleanedTime, "HH:mm");
            if (momentTime.isValid()) {
                return momentTime.format("h:mm A");
            }
        } else {
            // Try to parse it with moment assuming various formats including AM/PM
            const momentTime = moment(cleanedTime, ["h:mm A", "h:mmA", "hA", "HH:mm"]);
            if (momentTime.isValid()) {
                return momentTime.format("h:mm A");
            }
        }
    
        return "NO TIME";
    };
    

    // const formatTime = (time) => {
    //     if (typeof time !== 'string') {
    //         return "NO TIME";
    //     }
    
    //     const removedPeriods = time.toUpperCase().replace(/\./g, '');
    //     const parts = removedPeriods.split(' ');
    
    //     const isFullTime = parts[0].includes(':');
    //     let hour = parts[0].split(':')[0];
    //     hour = hour.length === 1 ? `0${hour}` : hour;
    //     const formattedTime = isFullTime ? `${hour}:${parts[0].split(':')[1]}` : `${hour}:00`;
    //     return `${formattedTime} ${parts[1]}`;
    // }

    // Create a function that extracts unique users from the day's events:
    
    const extractUsersFromEvents = (events, date) => {
        if (!(date instanceof Date)) {
            console.error("date is not a Date object");
            return [];
        }
        
        const dayEvents = events.filter(event => {
            if (!(event.start instanceof Date)) {
                console.error("event.start is not a Date object");
                return false;
            }
            return event.start.toDateString() === date.toDateString();
        });
    
        const usersSet = new Set(dayEvents
          .map(event => {
            if (!event.resource || typeof event.resource.currentUserData !== 'string') {
                console.error("Unexpected event structure", event);
                return '';
            }
            return event.resource.currentUserData.split(' ')[0]
          })
          .filter(user => user && user.trim() !== "" && 
              !/^\p{C}*$|^\s*$/u.test(user) && // checks for non-printable characters or only whitespace
              [...user].some(char => /\p{L}|\p{P}/u.test(char))) // checks for at least one letter or punctuation character
        );
    
        return Array.from(usersSet).sort();
    };
    


    // const extractEncTypesFromEvents = (events, currentDate, viewType) => {
    //     let encTypes = [];
    
    //     for (let event of events) {
    //         let eventDate = new Date(event.start);
    
    //         let isSamePeriod = false;
    //         switch(viewType) {
    //             case 'day':
    //                 isSamePeriod = eventDate.toLocaleDateString() === currentDate.toLocaleDateString();
    //                 break;
    //             case 'week':
    //                 const startOfWeek = currentDate.getDate() - currentDate.getDay();
    //                 const endOfWeek = startOfWeek + 6;
    //                 isSamePeriod = eventDate.getDate() >= startOfWeek && eventDate.getDate() <= endOfWeek;
    //                 break;
    //             case 'month':
    //                 isSamePeriod = eventDate.getMonth() === currentDate.getMonth() && eventDate.getFullYear() === currentDate.getFullYear();
    //                 break;
    //             default:
    //                 break;
    //         }
    
    //         if (isSamePeriod && event.resource && event.resource.encType && !encTypes.includes(event.resource.encType)) {
    //             encTypes.push(event.resource.encType);
    //         }
    //     }
    
    //     return encTypes;
    // };

    const extractEncTypesFromEvents = (events, viewType) => {
      let encTypes = new Set();  // Use Set to ensure unique values
  
      // Convert currentDate to YYYY-MM-DD string
      let currentDateStr = currentDate.toISOString().split('T')[0];
  
      for (let event of events) {
          // Parse the event start date string into a Date object
          let eventDate = new Date(event.start);
  
          let isSamePeriod = false;
          switch(viewType) {
              case 'day':
                  isSamePeriod = event.start === currentDateStr;
                  break;
              case 'week':
                  // Start of the week
                  const startOfWeek = new Date(currentDate);
                  startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
  
                  // End of the week
                  const endOfWeek = new Date(startOfWeek);
                  endOfWeek.setDate(startOfWeek.getDate() + 6);
  
                  // Parse the event start date for comparison
                  const eventStartDate = new Date(event.start);
  
                  isSamePeriod = eventStartDate >= startOfWeek && eventStartDate <= endOfWeek;
                  break;
              case 'month':
                  isSamePeriod = eventDate.getMonth() === currentDate.getMonth() && eventDate.getFullYear() === currentDate.getFullYear();
                  break;
              default:
                  break;
          }
  
          // Assign to variables for clarity and avoid repeated access
          const { encType, encounterType } = event.resource || {};
          const encTypeToCheck = (encType || encounterType || '').toUpperCase();  // Normalize to uppercase
  
          // Check conditions and add to Set if the period matches and the encounter type is valid
          if (isSamePeriod && encTypeToCheck) {
              encTypes.add(encTypeToCheck);
          }
      }
  
      return Array.from(encTypes);  // Convert Set to Array
  };
  
    
  //   const extractEncTypesFromEvents = (events, viewType) => {
  //     let encTypes = [];
  
  //   // Convert currentDate to YYYY-MM-DD string
  //   let currentDateStr = currentDate.toISOString().split('T')[0];
  //     // console.log(currentDate)
  
  //     for (let event of events) {
  //         // Parse the event start date string into a Date object
  //         let eventDate = new Date(event.start);
  //         // console.log(eventDate)

  
  //         let isSamePeriod = false;
  //         switch(viewType) {
  //             case 'day':
  //                 isSamePeriod = event.start === currentDateStr;
  //                 break;
  //             case 'week':
  //               case 'week':
  //                 // Start of the week
  //                 const startOfWeek = new Date(currentDate);
  //                 startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
              
  //                 // End of the week
  //                 const endOfWeek = new Date(startOfWeek);
  //                 endOfWeek.setDate(startOfWeek.getDate() + 6);
              
  //                 // Parse the event start date for comparison
  //                 const eventStartDate = new Date(event.start);
              
  //                 isSamePeriod = eventStartDate >= startOfWeek && eventStartDate <= endOfWeek;

  //                 break;
  //             case 'month':
  //                 isSamePeriod = eventDate.getMonth() === currentDate.getMonth() && eventDate.getFullYear() === currentDate.getFullYear();
  //                 break;
  //             default:
  //                 break;
  //         }
  
  //       // Assign to variables for clarity and to avoid repeated access.
  //       const { encType, encounterType } = event.resource || {};
  //       const encTypeToCheck = encType || encounterType;

  //       // Check conditions with explicit grouping for clarity.
  //       if (isSamePeriod && ((encType || encounterType) && !encTypes.includes(encTypeToCheck))) {
  //           encTypes.push(encTypeToCheck);
  //       }
  //     }
  
  //     return encTypes;
  // };
  
    
    

    // const extractEncTypesFromEvents = (events, currentDate) => {
    //     let encTypes = [];
    //     for (let event of events) {
    //       if (
    //         new Date(event.start).toLocaleDateString() === currentDate.toLocaleDateString() &&
    //         event.resource &&
    //         event.resource.encType &&
    //         !encTypes.includes(event.resource.encType)
    //       ) {
    //         encTypes.push(event.resource.encType);
    //       }
    //     }
    //     return encTypes;
    //   };

    const convertYYYYMMDDHHMMToISOWithTimezone = (dateString, timeString) => {
      // Split the YYYY-MM-DD string into its components
      const [year, month, day] = dateString.split('-');
      
      // Calculate the timezone offset in minutes
      const offsetInMinutes = new Date().getTimezoneOffset();
      
      // Calculate hours and minutes offset
      const offsetHours = Math.abs(Math.floor(offsetInMinutes / 60)).toString().padStart(2, '0');
      const offsetMinutes = Math.abs(offsetInMinutes % 60).toString().padStart(2, '0');
      
      // Determine the sign of the offset
      const offsetSign = offsetInMinutes > 0 ? "-" : "+";
      
      // Construct the offset string
      const timezoneOffset = `${offsetSign}${offsetHours}:${offsetMinutes}`;
      
      // Manually create the ISO string with the timezone offset and include the time
      return `${year}-${month}-${day}T${timeString}:00.000${timezoneOffset}`;
  };
  
    
    // console.log(convertYYYYMMDDToISOWithoutDateObject("2023-08-13"));

    
  //   const generateFakeEvents = (events) => {
  //     const fakeEvents = [];
  //     const hoursWithEvents = new Set();
  
  //     // Identify hours that have events
  //     events.forEach(event => {
  //         const eventHour = moment(event.start).startOf('hour');
  //         hoursWithEvents.add(eventHour.format());
  //     });
  
  //     // Create a fake event for each hour with events
  //     hoursWithEvents.forEach(hour => {
  //         const hourStart = moment(hour);
  //         const hourEnd = moment(hour).endOf('hour');
  //         const eventsInHour = events.filter(event => 
  //             moment(event.start).isBetween(hourStart, hourEnd, undefined, '[)')
  //         );
  
  //         fakeEvents.push({
  //             allDay: false,
  //             start: hourStart.toDate(),
  //             end: hourEnd.toDate(),
  //             resource: {
  //                 name: `${hourStart.format('h:mm A')} - Count: ${eventsInHour.length}`
  //             },
  //             tag: "fake", // Tag for fake events
  //             // Other properties as needed
  //         });
  //     });
  
  //     return fakeEvents;
  // };





  function createFakeEvent(hour, date, eventsCount, confirmedCount) {
    let formattedHour = moment(`${hour}:00`, "HH:mm").format("h:mm A");
    // Split the string into parts
let parts = formattedHour.split(" "); // Splits into ["08:30", "PM"]
let hourPart = parts[0].split(":")[0]; // Takes "08" from "08:30"

// Reconstruct the desired format
let resultTime = hourPart + " " + parts[1]; // "08 PM"
    // Assuming 'date' is a string in the format "YYYY-MM-DD"
    const verboseDate = moment(date).format('MMMM D, YYYY');
    const formattedHour24 = moment(formattedHour, ["h:mm A", "hh:mm A"]).format("HH:mm");
    return {
        allDay: true,
        start: date,
        end: date,
        manualConfirm: "No",
        tag: "fake",
        confirmBySms: "",
        confirmByEmail: "",
        confirmByVoice: "",
        time: `${formattedHour}`,
        time24: `${formattedHour24}`,
        resource: {
            name: `${resultTime} - Count: ${eventsCount}`,
            onlyConfirmedName: `${resultTime} - Count: ${confirmedCount}`,
            basicName: `${resultTime}`,
            phone: "555-555-5555",
            newDate: date,
            confirmBySms: "",
            confirmByEmail: "",
            confirmByVoice: "",
            currentUserData: `TimeSpacer on ${verboseDate} ${formattedHour}`,
            time: `${formattedHour}`
        },
    };
}





const processAndRegroupEvents = async (groupedEventsObj) => {

let allEvents = Object.values(groupedEventsObj).flat();

console.log(allEvents)

  // Convert event.resource.newGroup || event.resource.time to 24-hour format and insert as event.time24
  allEvents.forEach(event => {
        // Reset event.tagNumber to 0 for all events
        event.tagNumber = 0;

    const timeSource = event.resource.newGroup || event.resource.time || "00:00 AM";
    const time24 = moment(timeSource, ["h:mm A", "HH:mm"]).format("HH:mm");
    event.time24 = time24; // Add the converted time as a new property
  });


  // Sort allEvents by time and name
allEvents = allEvents.sort((a, b) => {
  const timeA = moment(a.time24, "HH:mm");
  const timeB = moment(b.time24, "HH:mm");
  if (timeA.isBefore(timeB)) return -1;
  if (timeA.isAfter(timeB)) return 1;
  if (timeA.isSame(timeB)) {
      const nameA = (a.resource.name && a.resource.name[0].toLowerCase()) || "";
      const nameB = (b.resource.name && b.resource.name[0].toLowerCase()) || "";
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
  }
  return 0;
});


// Calculate the range of hours for the events
let minHour = 24, maxHour = 0;
allEvents.forEach(event => {
    console.log(event.time24)
    const hour = moment(event.time24, "HH:mm").hour();
    minHour = Math.min(minHour, hour);
    maxHour = Math.max(maxHour, hour);
});



// Insert fake events at the start of each hour
for (let hour = minHour; hour <= maxHour; hour++) {
  // Filter the events for the current hour
  const eventsThisHour = allEvents.filter(event => {
      const eventHour = moment(event.time24, "HH").hour();
      return eventHour === hour;
  });
  
// console.log(eventsThisHour)

// eventsThisHour.forEach(event => {
//   if (event.start === "2024-01-03") {
//     console.log(event);
//     console.log(event.resource.name);
//     console.log(event.start);
//     console.log(event.resource.phone);
//   }
// });

let eventsByDate = new Map();
let confirmedEventsByDate = new Map();
let ignoreKeys = new Set(); // Set to store keys of events to be ignored

// Additional sets to track fake events and appointment events
let fakeEventKeys = new Set();
let fakeConfirmedEventKeys = new Set();
let appointmentEventKeys = new Set(); // Set to track appointment event keys

eventsThisHour.forEach(event => {
  const lowerCaseName = event.resource.name.toLowerCase()
    .replace(/-/g, ' ')  // Replace all hyphens with spaces
    .replace(/\s+/g, ' ');  // Replace multiple spaces with a single space

  let xTime = event.resource.newGroup || event.resource.time || "00:00 AM";
  // Standardize the AM/PM part and convert to 24-hour format
  xTime = xTime.replace(/\s*(a\.m\.|am|A\.M\.)/i, ' AM').replace(/\s*(p\.m\.|pm|P\.M\.)/i, ' PM');
  const timeParts = xTime.match(/(\d+):(\d+) (AM|PM)/i);
  if (timeParts) {
    let hours = parseInt(timeParts[1], 10);
    const minutes = timeParts[2];
    const ampm = timeParts[3].toUpperCase();
    if (ampm === 'PM' && hours < 12) hours += 12;
    if (ampm === 'AM' && hours === 12) hours = 0;
    xTime = `${hours.toString().padStart(2, '0')}:${minutes}`;
  }


  // Standardize the phone number format
let standardizedPhone = event.resource.phone;
if (standardizedPhone) {
    // Remove any non-digit characters first, if necessary
    standardizedPhone = standardizedPhone.replace(/\D/g, '');

    if (standardizedPhone.length === 11 && standardizedPhone.startsWith('1')) {
        // If the phone number starts with '1' and is 11 digits long, remove the leading '1'
        standardizedPhone = standardizedPhone.substring(1);
    } else if (standardizedPhone.length > 10) {
        // If the phone number is longer than 10 digits but does not start with '1', or
        // if you need to handle other specific cases, adjust as necessary
        // Example: truncate or log a warning, etc.
        console.warn('Unexpected phone number length/format:', standardizedPhone);
        // Adjust or truncate as necessary, example:
        // standardizedPhone = standardizedPhone.slice(-10);
    }
    // If the phone number is exactly 10 digits, it is used as is.
}

// Create a key for each event based on name, phone, start date, and currentUserData (if exists, otherwise "nodata")
let key = `${lowerCaseName}-${standardizedPhone}-${event.start}-${xTime}-${
  event.resource.currentUserData ? event.resource.currentUserData : "nodata"}`;



  // Create a key for each event based on name, phone, and start date
  // let key = `${lowerCaseName}-${event.resource.phone}-${event.start}-${xTime}`;

  // Track appointment events
  if (event.tag === 'appointment') {
    appointmentEventKeys.add(key);
  }

  // // If the event is hidden or fake, add the key to the ignore set
  // if (event.resource.hidden === 'true' || event.tag === 'fake') {
  //   ignoreKeys.add(key);
  //   if (event.tag === 'fake') {
  //     fakeEventKeys.add(key); // Also track fake events separately
  //   }
  //   return;
  // }

// Check if the event is hidden and should be ignored
if ('hidden' in event.resource && event.resource.hidden === 'true') {
  ignoreKeys.add(key); // Add to ignore list for being hidden
  console.log(`Ignoring hidden event with key: ${key}`); // Optional: for debugging
  return; // Skip further processing for this hidden event
}

// Separately, check if the event is fake and handle accordingly
if (event.tag === 'fake') {
  ignoreKeys.add(key); // Also ignore fake events, but track separately
  fakeEventKeys.add(key); // Track fake events separately for any special handling
  console.log(`Ignoring fake event with key: ${key}`); // Optional: for debugging
  return; // Skip further processing for this fake event
}



  // If this date is not already in the map and the key is not in the ignore set, add it with an empty Set
  if (!eventsByDate.has(event.start) && !ignoreKeys.has(key)) {
    eventsByDate.set(event.start, new Set());
  }

  // Add the unique key to the Set for this date if it's not in the ignore set
  if (!ignoreKeys.has(key)) {
    eventsByDate.get(event.start).add(key);
  }

  // For confirmed events
  if (event.resource.askConfirmation) {
    let confirmedKey = `${key}-confirmed`;
    if (!confirmedEventsByDate.has(event.start) && !ignoreKeys.has(confirmedKey)) {
      confirmedEventsByDate.set(event.start, new Set());
    }
    if (!ignoreKeys.has(confirmedKey)) {
      confirmedEventsByDate.get(event.start).add(confirmedKey);
    }
    if (event.tag === 'fake') {
      fakeConfirmedEventKeys.add(confirmedKey);
    }
  }
});

// Now modify the logic for counting events, considering appointment rules
eventsByDate.forEach((uniqueEvents, date) => {
  let eventCount = 0;
  let nonAppointmentEventCount = 0;

  console.log(uniqueEvents)

  uniqueEvents.forEach(key => {
    if (!ignoreKeys.has(key)) {
      if (appointmentEventKeys.has(key)) {
        if (nonAppointmentEventCount === 0) {
          // Only count the appointment event if there are no other events with the same key
          eventCount = 1;
        }
      } else {
        // Count non-appointment events
        nonAppointmentEventCount++;
        eventCount = nonAppointmentEventCount;
      }
    }
  });

  // Adjust count for fake events
  if (Array.from(uniqueEvents).some(key => fakeEventKeys.has(key))) {
    eventCount = Math.max(0, eventCount - 1);
  }

    // // Adjust count for fake eventss
    // if (Array.from(uniqueEvents).some(key => ignoreKeys.has(key))) {
    //   eventCount = Math.max(0, eventCount - 1);
    // }

  // if (date === "2024-01-24") {
  //   console.log(`Unique Events for 2024-01-24: ${Array.from(uniqueEvents).join(', ')}`);
  // }

  let confirmedCount = confirmedEventsByDate.has(date) ? confirmedEventsByDate.get(date).size : 0;
  // Subtract 1 if any fake confirmed event is found in this set
  if (confirmedEventsByDate.has(date) && Array.from(confirmedEventsByDate.get(date)).some(key => fakeConfirmedEventKeys.has(key))) {
    confirmedCount = Math.max(0, confirmedCount - 1);
  }

  const fakeEvent = createFakeEvent(hour, date, eventCount, confirmedCount);
  allEvents.push(fakeEvent);
});
}



// Sort all events again including fake events
    allEvents = allEvents.sort((a, b) => {
        const timeA = moment(a.time24, "HH:mm");
        const timeB = moment(b.time24, "HH:mm");
        if (timeA.isBefore(timeB)) return -1;
        if (timeA.isAfter(timeB)) return 1;
        if (timeA.isSame(timeB)) {
            const nameA = (a.resource.name && a.resource.name[0].toLowerCase()) || "";
            const nameB = (b.resource.name && b.resource.name[0].toLowerCase()) || "";
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
        }
        return 0;
}).map(event => {
    if (event.time !== "NO TIME") {
        event.time = moment(event.time24, "HH:mm").format("h:mm A");
    }
    delete event.time24;
    // console.log(event)
    return event;
});


  

    // let groupedEventsObj = {};
    let counter = 0;
    let latestDateIds = {};
    
    allEvents.forEach(event => {
        // Convert event.resource.name to lowercase
        const lowerCaseName = event.resource.name.toLowerCase()
        .replace(/-/g, ' ')  // Replace all hyphens with spaces
        .replace(/\s+/g, ' ');  // Replace multiple spaces with a single space
    

        let xTime = event.resource.newGroup || event.resource.time;
        let formattedTime;

        if (xTime) {
          // Parsing the time considering various AM/PM formats
          // This will handle formats like HH:MM, H:MM, am/pm, AM/PM, a.m./p.m., A.M./P.M.
          formattedTime = moment(xTime, [
              'h:mm A', 'h:mm a', 
              'h:mmA', 'h:mma', 
              'HH:mm A', 'HH:mm a', 
              'HH:mmA', 'HH:mma', 
              'h:mm A.M.', 'h:mm a.m.', 
              'h:mm P.M.', 'h:mm p.m.',
              'HH:mm A.M.', 'HH:mm a.m.',
              'HH:mm P.M.', 'HH:mm p.m.'
          ]).format('HH:mm');
      
          // Use formattedTime as needed
          // console.log(formattedTime);
          
      } else {
          console.error('xTime is not defined');
          formattedTime = '';
      }



        

        // Construct the key
        const key = `${lowerCaseName}-${event.resource.newDate || event.resource.date}-${event.resource.phone.slice(-10)}-${formattedTime}`;

    
    
        // Check if this is a new group
        if (!groupedEventsObj[key]) {
            groupedEventsObj[key] = [];
            // Create a unique groupId for this new group
            const groupId = `${key}`;
            event.groupId = groupId;
        } else {
            // For existing groups, use the groupId of the first event in the group
            event.groupId = groupedEventsObj[key][0].groupId;
        }
    
        groupedEventsObj[key].push(event);

        
    });
    return groupedEventsObj;
}











    const fetchAppointments = async () => {
      // const twoWeeksAgoISO = new Date();
      // twoWeeksAgoISO.setDate(twoWeeksAgoISO.getDate() - 14); // Adjust as necessary
      // const oneMonthLaterISO = new Date();
      // oneMonthLaterISO.setMonth(oneMonthLaterISO.getMonth() + 1); // Adjust as necessary
      
  
      const appointmentRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'appointments');

      // Create a query to filter by date
      const dateRangeQuery = query(appointmentRef, 
                                   where("date", ">=", startDate),
                                   where("date", "<=", endDate));
  
      // Fetch the documents within the date range
      const querySnapshot = await getDocs(dateRangeQuery);
      
      const appointments = querySnapshot.docs.map(doc => {
          const data = doc.data();
          
          // Date format validation and conversion
          const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
          if (dateFormat.test(data.date)) {
              data.newDate = data.date;
          }
          
          // Filter based on date range
          const newDateISO = data.newDate || "";
          // console.log(startDate)
          // console.log(newDateISO)
          if (newDateISO < startDate || newDateISO > endDate) {
              return null;
          }
  
          const date = data.newDate;
          // console.log(date)
          let finalTime = data.time ? formatTime(data.time) : "NO TIME"; // Ensure formatTime function is defined and correctly formats the time
          
          if (!moment(finalTime, 'h:mm A').isValid()) {
              // console.log(`Incorrect time format for appointment ${doc.id}: ${finalTime}`);
              finalTime = "NO TIME";
          }
          
          const time24 = finalTime === "NO TIME" ? "00:00" : moment(finalTime, "h:mm A").format("HH:mm");
          
          // Format the phone number and update data.phone if necessary
          function formatPhoneNumber(number) {
            let cleanNumber = String(number).replace(/\D/g, ''); // Remove non-digit characters
            if (!cleanNumber.startsWith('1')) {
                cleanNumber = '1' + cleanNumber; // Prepend '1' if not present
            }
            return cleanNumber;
          }

          // Ensure data.phone is properly set using data.phone or data["phone 2"]
          data.phone = data.phone || data["phone 2"];
          data.phone = formatPhoneNumber(data.phone);

          // Structuring the appointment data similarly to the events
          return {
              allDay: true,
              start: date,
              end: date,
              resource: data, // Embedding the original data as a resource
              manualConfirm: data.manualConfirm || "No",
              time: finalTime,
              time24: time24
          };
      }).filter(appointment => appointment !== null); // Remove null appointments after date range filtering
      
      // console.log(appointments);
      return appointments;
  };
  
    
        
    


    // const fetchEvents = async () => {
    // setIsLoading(true);
    // fetchEncounterTypes();

    // const twoWeeksAgo = new Date();
    // // twoWeeksAgo.setDate(twoWeeksAgo.getDate() + 5);
    // twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 60);
    // const oneMonthLater = new Date();
    // // twoWeeksAgo.setDate(twoWeeksAgo.getDate() + 5);
    // oneMonthLater.setMonth(oneMonthLater.getMonth() + 36);

    // const twoWeeksAgoISO = twoWeeksAgo.toISOString();
    // const oneMonthLaterISO = oneMonthLater.toISOString();
    // console.log(twoWeeksAgoISO)
    // console.log(oneMonthLaterISO)

    // Log the type and value of startDate
// console.log('Type of startDate:', typeof startDate);
// console.log('Value of startDate:', startDate);

// Log the type and value of endDate
// console.log('Type of endDate:', typeof endDate);
// console.log('Value of endDate:', endDate);

// let fetchedEvents = [];

// if (selectedCalendar === "mainCalendar") {
//     // Define the collection reference
//     const historyRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'history');

//     // Create a query using the `where` function to filter by newDate
//     const dateRangeQuery = query(historyRef, 
//                                 where("newDate", ">=", startDate),
//                                 where("newDate", "<=", endDate));

//     // Fetch the documents within the date range
//     const querySnapshot = await getDocs(dateRangeQuery);

//     fetchedEvents = querySnapshot.docs.map(doc => {
//         const data = doc.data();
//     } else {


//     }

// if (selectedCalendar === "mainCalendar") {
//   // Define the collection reference
//   const historyRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'history');

//   // Create a query using the `where` function to filter by newDate
//   const dateRangeQuery = query(historyRef, 
//                               where("newDate", ">=", startDate),
//                               where("newDate", "<=", endDate));

//   // Fetch the documents within the date range
//   const querySnapshot = await getDocs(dateRangeQuery);

//   // Filter documents based on assocCal conditions for mainCalendar
//   fetchedEvents = querySnapshot.docs.map(doc => doc.data()).filter(data => {
//       // Show events where data.assocCal is "mainCalendar", "", or does not exist
//       return data.assocCal === "mainCalendar" || data.assocCal === "" || !data.hasOwnProperty('assocCal');
//   });
// } else {
// // ---- to be changed:

//     // Define the collection reference
//     const historyRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'history');

//     // Create a query using the `where` function to filter by newDate
//     const dateRangeQuery = query(historyRef, 
//                                 where("newDate", ">=", startDate),
//                                 where("newDate", "<=", endDate));

//     // Fetch the documents within the date range
//     const querySnapshot = await getDocs(dateRangeQuery);

//     fetchedEvents = querySnapshot.docs.map(doc => {
//         const data = doc.data();

// ---- end of to be changed.

// ---- to be used once implemented assocCal property:

  // // Define the collection reference
  // const historyRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'history');

  // // Create a query using the `where` function to filter by newDate and selectedCalendar
  // const dateRangeQuery = query(historyRef, 
  //                             where("newDate", ">=", startDate),
  //                             where("newDate", "<=", endDate));
  
  // // Fetch the documents within the date range
  // const querySnapshot = await getDocs(dateRangeQuery);

  // // Filter documents for a specific selectedCalendar
  // fetchedEvents = querySnapshot.docs.map(doc => doc.data()).filter(data => {
  //     // Show events where data.assocCal matches the selectedCalendar
  //     return data.assocCal === selectedCalendar;

  // ---- end of to be used.

//   });
// }


//         // Additional filtering based on status and date format validation
//         const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
//         if (!dateFormat.test(data.newDate) || (data.hasOwnProperty('status') && data.status !== "completed")) {
//             return null;
//         }

//         // Processing time information
//         let finalTime = data.newGroup ? formatTime(data.newGroup) : "NO TIME";
//         if (!moment(finalTime, 'h:mm A').isValid()) {
//             finalTime = "NO TIME";
//         }
//         const time24 = finalTime === "NO TIME" ? "00:00" : moment(finalTime, "h:mm A").format("HH:mm");

//         return {
//             allDay: true,
//             start: data.newDate,
//             end: data.newDate,
//             resource: data,
//             confirmBySms: data.confirmBySms || "",
//             manualConfirm: data.manualConfirm || "No",
//             confirmByEmail: data.confirmByEmail || "",
//             confirmByVoice: data.confirmByVoice || "",
//             time: finalTime,
//             time24: time24,
//             id: doc.id // Including the document ID
//         };
//     }).filter(event => event !== null); // Filter out events that were marked as null
// // }

async function createNeverIgnoreKeys(appointments) {
  let neverIgnoreKeys = new Set(); 

  appointments.forEach(event => {
      let xTime = event.resource.time || "00:00 AM";
      // Standardize the AM/PM part and convert to 24-hour format
      xTime = xTime.replace(/\s*(a\.m\.|am|A\.M\.)/i, ' AM').replace(/\s*(p\.m\.|pm|P\.M\.)/i, ' PM');

      const timeParts = xTime.match(/(\d+):(\d+)(?::(\d+))? (AM|PM)/i);
      if (timeParts) {
          let hours = parseInt(timeParts[1], 10);
          const minutes = timeParts[2];
          const seconds = timeParts[3] || "00"; // default to "00" if seconds are not provided
          const ampm = timeParts[4].toUpperCase();
          if (ampm === 'PM' && hours < 12) hours += 12;
          if (ampm === 'AM' && hours === 12) hours = 0;
          xTime = `${hours.toString().padStart(2, '0')}:${minutes}`;
      }

      let lowerCaseName = event.resource.name.toLowerCase(); // Ensure the lowerCaseName variable is defined
      let key;
      if (event.resource.phone || event.resource["phone 2"]) {
          // If phone is available, include it in the key, slicing to the last 10 digits
          const phone = event.resource.phone.slice(-10);
          key = `${lowerCaseName}-${event.resource.newDate || event.start}-${phone}-${xTime}`;
      } else {
          // If phone is not available, construct the key without the phone part
          key = `${lowerCaseName}-${event.resource.newDate || event.start}-${xTime}`;
      }

      if (!event.resource.hidden) {
          neverIgnoreKeys.add(key);
          // if (event.start === "2024-05-17" && event.time === "8:00 AM") {
          //     console.log(event);
          //     console.log(key);
          // }
      }
  });

  return neverIgnoreKeys;
}


async function createConfirmedIgnoreKeys(appointments) {
  let confirmedIgnoreKeys = new Set(); 

  appointments.forEach(event => {
      let xTime = event.resource.time || "00:00 AM";
      // Standardize the AM/PM part and convert to 24-hour format
      xTime = xTime.replace(/\s*(a\.m\.|am|A\.M\.)/i, ' AM').replace(/\s*(p\.m\.|pm|P\.M\.)/i, ' PM');

      const timeParts = xTime.match(/(\d+):(\d+)(?::(\d+))? (AM|PM)/i);
      if (timeParts) {
          let hours = parseInt(timeParts[1], 10);
          const minutes = timeParts[2];
          const seconds = timeParts[3] || "00"; // default to "00" if seconds are not provided
          const ampm = timeParts[4].toUpperCase();
          if (ampm === 'PM' && hours < 12) hours += 12;
          if (ampm === 'AM' && hours === 12) hours = 0;
          xTime = `${hours.toString().padStart(2, '0')}:${minutes}`;
      }

      let lowerCaseName = event.resource.name.toLowerCase(); // Ensure the lowerCaseName variable is defined
      let key;
      if (event.resource.phone || event.resource["phone 2"]) {
          // If phone is available, include it in the key, slicing to the last 10 digits
          const phone = event.resource.phone.slice(-10);
          key = `${lowerCaseName}-${event.resource.newDate || event.start}-${phone}-${xTime}`;
      } else {
          // If phone is not available, construct the key without the phone part
          key = `${lowerCaseName}-${event.resource.newDate || event.start}-${xTime}`;
      }

      if (event.resource.hidden) {
          confirmedIgnoreKeys.add(key);
          // if (event.start === "2024-05-17" && event.time === "8:00 AM") {
          //     console.log(event);
          //     console.log(key);
          // }
      }
  });

  return confirmedIgnoreKeys;
}






const fetchEvents = async () => {
  setIsLoading(true);
  fetchEncounterTypes();

    // Ensure userEmail and physician are valid before proceeding
    if (!userEmail || !physician) {
      console.error("Missing userEmail or physician for Firestore query");
      setIsLoading(false); // Ensure to stop the loading indicator
      return; // Exit the function early if validation fails
    }

  let fetchedEvents = [];

  // console.log(startDate)
  // console.log(endDate)

  const historyRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'history');
  const dateRangeQuery = query(historyRef, 
                                where("newDate", ">=", startDate),
                                where("newDate", "<=", endDate));
  const querySnapshot = await getDocs(dateRangeQuery);

  if (selectedCalendar === "mainCalendar") {
      fetchedEvents = querySnapshot.docs.map(doc => {
          const data = doc.data();
          // Conditions for mainCalendar...
          if (!data.assocCal || data.assocCal === "mainCalendar" || data.assocCal === "") {
              // Directly process and return event data if it meets conditions
              const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
              if (!dateFormat.test(data.newDate) || (data.hasOwnProperty('status') && data.status !== "completed")) {
                  return null;
              }

              let finalTime = data.newGroup ? formatTime(data.newGroup) : "NO TIME";
              if (!moment(finalTime, 'h:mm A').isValid()) {
                  finalTime = "NO TIME";
              }
              const time24 = finalTime === "NO TIME" ? "00:00" : moment(finalTime, "h:mm A").format("HH:mm");

              return {
                  allDay: true,
                  start: data.newDate,
                  end: data.newDate,
                  resource: data,
                  confirmBySms: data.confirmBySms || "",
                  manualConfirm: data.manualConfirm || "No",
                  confirmByEmail: data.confirmByEmail || "",
                  confirmByVoice: data.confirmByVoice || "",
                  time: finalTime,
                  time24: time24,
                  id: doc.id // Including the document ID
              };
          } else {
              return null;
          }
      }).filter(event => event !== null);
  } else {
      fetchedEvents = querySnapshot.docs.map(doc => {
          const data = doc.data();
          // For other calendars, filter based on selectedCalendar
          if (data.assocCal === selectedCalendar) {
              // Repeat the same data processing logic here for consistency
              const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
              if (!dateFormat.test(data.newDate) || (data.hasOwnProperty('status') && data.status !== "completed")) {
                  return null;
              }

              let finalTime = data.newGroup ? formatTime(data.newGroup) : "NO TIME";
              if (!moment(finalTime, 'h:mm A').isValid()) {
                  finalTime = "NO TIME";
              }
              const time24 = finalTime === "NO TIME" ? "00:00" : moment(finalTime, "h:mm A").format("HH:mm");

              return {
                  allDay: true,
                  start: data.newDate,
                  end: data.newDate,
                  resource: data,
                  confirmBySms: data.confirmBySms || "",
                  manualConfirm: data.manualConfirm || "No",
                  confirmByEmail: data.confirmByEmail || "",
                  confirmByVoice: data.confirmByVoice || "",
                  time: finalTime,
                  time24: time24,
                  id: doc.id // Including the document ID
              };
          } else {
              return null;
          }
      }).filter(event => event !== null);
  }


// new const appoitments

const appointments = await fetchAppointments();
appointments.forEach(appointment => {
    appointment.tag = "appointment";
});

// console.log(appointments)

// console.log(fetchedEvents)

let allEvents = fetchedEvents.concat(appointments);

// console.log(allEvents)

// Function to create a fake event for a given hour
function createFakeEvent(hour, date, eventsCount, confirmedCount, allEventsCount) {
    let formattedHour = moment(`${hour}:00`, "HH:mm").format("h:mm A");
    // Split the string into parts
let parts = formattedHour.split(" "); // Splits into ["08:30", "PM"]
let hourPart = parts[0].split(":")[0]; // Takes "08" from "08:30"

// Reconstruct the desired format
let resultTime = hourPart + " " + parts[1]; // "08 PM"
    // Assuming 'date' is a string in the format "YYYY-MM-DD"
    const verboseDate = moment(date).format('MMMM D, YYYY');
    const formattedHour24 = moment(formattedHour, ["h:mm A", "hh:mm A"]).format("HH:mm");
    return {
        allDay: true,
        start: date,
        end: date,
        manualConfirm: "No",
        tag: "fake",
        confirmBySms: "",
        confirmByEmail: "",
        confirmByVoice: "",
        time: `${formattedHour}`,
        time24: `${formattedHour24}`,
        resource: {
            name: `${resultTime} - Count: ${eventsCount}`,
            onlyConfirmedName: `${resultTime} - Count: ${confirmedCount}`,
            allEventsName: `${resultTime} - Count: ${allEventsCount}`,
            basicName: `${resultTime}`,
            phone: "555-555-5555",
            newDate: date,
            confirmBySms: "",
            confirmByEmail: "",
            confirmByVoice: "",
            currentUserData: `TimeSpacer on ${verboseDate} ${formattedHour}`,
            time: `${formattedHour}`
        },
    };
}

// Sort allEvents by time and name
allEvents = allEvents.sort((a, b) => {
    const timeA = moment(a.time24, "HH:mm");
    const timeB = moment(b.time24, "HH:mm");
    if (timeA.isBefore(timeB)) return -1;
    if (timeA.isAfter(timeB)) return 1;
    if (timeA.isSame(timeB)) {
        const nameA = (a.resource.name && a.resource.name[0].toLowerCase()) || "";
        const nameB = (b.resource.name && b.resource.name[0].toLowerCase()) || "";
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
    }
    return 0;
});

// Calculate the range of hours for the events
let minHour = 24, maxHour = 0;
allEvents.forEach(event => {
    const hour = moment(event.time24, "HH:mm").hour();
    minHour = Math.min(minHour, hour);
    maxHour = Math.max(maxHour, hour);
});



// Insert fake events at the start of each hour
for (let hour = minHour; hour <= maxHour; hour++) {
  // Filter the events for the current hour
  const eventsThisHour = allEvents.filter(event => {
      const eventHour = moment(event.time24, "HH").hour();
      return eventHour === hour;
  });
  
// console.log(eventsThisHour)

// eventsThisHour.forEach(event => {
//   if (event.start === "2024-01-03") {
//     console.log(event);
//     console.log(event.resource.name);
//     console.log(event.start);
//     console.log(event.resource.phone);
//   }
// });

let eventsByDate = new Map();
let confirmedEventsByDate = new Map();
let allWithHidenEvents = new Map();
let ignoreKeys = new Set(); // Set to store keys of events to be ignored
let otherIgnoreKeys = new Set();
const neverIgnoreKeys = await createNeverIgnoreKeys(appointments)
const confirmedIgnoreKeys = await createConfirmedIgnoreKeys(appointments)
// console.log(neverIgnoreKeys)

// Additional sets to track fake events and appointment events
let fakeEventKeys = new Set();
let fakeConfirmedEventKeys = new Set();
let fakeAllWithHiddenEventsKeys = new Set();
let appointmentEventKeys = new Set(); // Set to track appointment event keys

eventsThisHour.forEach(event => {
  const lowerCaseName = event.resource.name.toLowerCase()
  .replace(/-/g, ' ')  // Replace all hyphens with spaces
  .replace(/\s+/g, ' ')  // Replace multiple spaces with a single space
  .trim();  // Remove spaces before and after


  // let xTime = event.resource.newGroup || event.resource.time || "00:00 AM";
  // // Standardize the AM/PM part and convert to 24-hour format
  // xTime = xTime.replace(/\s*(a\.m\.|am|A\.M\.)/i, ' AM').replace(/\s*(p\.m\.|pm|P\.M\.)/i, ' PM');
  // const timeParts = xTime.match(/(\d+):(\d+) (AM|PM)/i);
  // if (timeParts) {
  //   let hours = parseInt(timeParts[1], 10);
  //   const minutes = timeParts[2];
  //   const ampm = timeParts[3].toUpperCase();
  //   if (ampm === 'PM' && hours < 12) hours += 12;
  //   if (ampm === 'AM' && hours === 12) hours = 0;
  //   xTime = `${hours.toString().padStart(2, '0')}:${minutes}`;
  // }

  let xTime = event.resource.newGroup || event.resource.time || "00:00 AM";
// Standardize the AM/PM part and convert to 24-hour format
xTime = xTime.replace(/\s*(a\.m\.|am|A\.M\.)/i, ' AM').replace(/\s*(p\.m\.|pm|P\.M\.)/i, ' PM');

const timeParts = xTime.match(/(\d+):(\d+)(?::(\d+))? (AM|PM)/i);
if (timeParts) {
  let hours = parseInt(timeParts[1], 10);
  const minutes = timeParts[2];
  const seconds = timeParts[3] || "00"; // default to "00" if seconds are not provided
  const ampm = timeParts[4].toUpperCase();
  if (ampm === 'PM' && hours < 12) hours += 12;
  if (ampm === 'AM' && hours === 12) hours = 0;
  xTime = `${hours.toString().padStart(2, '0')}:${minutes}`;
}

// console.log(xTime);


  // Create a key for each event based on name, phone, and start date
  // let key = `${lowerCaseName}-${event.resource.phone}-${event.start}-${xTime}`;

  // ------

  let key;
  if (event.resource.phone || event.resource["phone 2"]) {
      // If phone is available, include it in the key, slicing to the last 10 digits
      const phone = event.resource.phone.slice(-10);
      key = `${lowerCaseName}-${event.resource.newDate || event.start}-${phone}-${xTime}`;
  } else {
      // If phone is not available, construct the key without the phone part
      key = `${lowerCaseName}-${event.resource.newDate || event.start}-${xTime}`;
  }

      // if (event.start === "2024-09-11" && event.time === "8:00 AM" && event.resource.name.toLowerCase() === "carlos delgado nunez") {
      //   console.log(event);
      //   console.log(key);
      // }

  // ------

  // Track appointment events
  // if (event.tag === 'appointment') {
  //   appointmentEventKeys.add(key);
  //   if (event.start === "2024-05-21" && event.time === "4:00 PM") {
  //     console.log(event)
  //   }
  // }

  // if (event.tag === 'appointment') {
  //   if (!appointmentEventKeys.has(key)) {
  //     appointmentEventKeys.add(key);
  //     // if (event.start === "2024-05-21" && event.time === "4:00 PM") {
  //     //   console.log(event);
  //     //   console.log(key);
  //     // }
  //   }
  //   if (!event.resource.hidden) {
  //     neverIgnoreKeys.add(key);
  //     if (event.start === "2024-05-17" && event.time === "8:00 AM") {
  //       console.log(event);
  //       console.log(key);
  //     }
  //   }

  // }

  
  if (event.tag === 'appointment') {
    if (!appointmentEventKeys.has(key)) {
      appointmentEventKeys.add(key);
      // if (event.start === "2024-09-11" && event.time === "8:00 AM") {
      //   console.log(event);
      //   console.log(key);
      // }
    }
  }




if (!neverIgnoreKeys.has(key)) {
  if (('hidden' in event.resource && event.resource.hidden === 'true') || event.tag === 'fake') {
    if (!ignoreKeys.has(key)) {
      ignoreKeys.add(key);
      // if (event.start === "2024-09-11" && event.time === "8:00 AM") {
      //   console.log(event);
      //   console.log(key);
      // }
    }
    if (event.tag === 'fake' && !fakeEventKeys.has(key)) {
      fakeEventKeys.add(key); // Also track fake events separately
    }
  }
}


if (!neverIgnoreKeys.has(key)) {
  if (event.tag === 'fake') {
    if (!otherIgnoreKeys.has(key)) {
      otherIgnoreKeys.add(key);
      // if (event.start === "2024-05-17" && event.time === "8:00 AM") {
      //   console.log(event);
      //   console.log(key);
      // }
    }
  }
}



  // If this date is not already in the map and the key is not in the ignore set, add it with an empty Set
  if (!eventsByDate.has(event.start) && !ignoreKeys.has(key)) {
    // console.log(event)
    eventsByDate.set(event.start, new Set());
  }

    // if (eventsByDate.has(event.start) && !ignoreKeys.has(key)) {
      // if (event.start === "2024-05-17") {
      //   console.log(event)
      //   console.log(key)
      // }
    // }

  // Add the unique key to the Set for this date if it's not in the ignore set
  if (!ignoreKeys.has(key)) {
    eventsByDate.get(event.start).add(key);
  }
  // console.log(eventsByDate)

  // // For confirmed events
  // if (event.resource.askConfirmation) {
  //   let confirmedKey = `${key}-confirmed`;
  //   if (!confirmedEventsByDate.has(event.start) && !ignoreKeys.has(confirmedKey)) {
  //     confirmedEventsByDate.set(event.start, new Set());
  //   }
  //   if (!ignoreKeys.has(confirmedKey)) {
  //     confirmedEventsByDate.get(event.start).add(confirmedKey);
  //   }
  //   if (event.tag === 'fake') {
  //     fakeConfirmedEventKeys.add(confirmedKey);
  //   }
  // }

  // For confirmed events
if (event.resource.askConfirmation && !event.resource.hidden) {
  let confirmedKey = `${key}-confirmed`;
  if (!confirmedEventsByDate.has(event.start)) {
    confirmedEventsByDate.set(event.start, new Set());
  }
  const eventSet = confirmedEventsByDate.get(event.start);

  if (!ignoreKeys.has(confirmedKey.slice(0, -10)) && !eventSet.has(confirmedKey) && !confirmedIgnoreKeys.has(confirmedKey.slice(0, -10))) {
    eventSet.add(confirmedKey);
  }

  if (event.tag === 'fake') {
    fakeConfirmedEventKeys.add(confirmedKey);
  }
}



// // Set to keep track of hidden keys
// const hiddenKeys = new Set();

//   // If any event with this key is hidden, add the key to the hiddenKeys set
//   if (event.resource.hidden) {
//     hiddenKeys.add(key);
//   }

// // For confirmed events
// if (event.resource.askConfirmation) {
//   let confirmedKey = `${key}-confirmed`;



//   // Only proceed if the key is not in the hiddenKeys set
//   if (!hiddenKeys.has(confirmedIgnoreKeys)) {
//     if (!confirmedEventsByDate.has(event.start)) {
//       confirmedEventsByDate.set(event.start, new Set());
//     }
//     const eventSet = confirmedEventsByDate.get(event.start);

//     if (!ignoreKeys.has(confirmedKey.slice(0, -10)) && !eventSet.has(confirmedKey)) {
//       eventSet.add(confirmedKey);
//     }

//     if (event.tag === 'fake') {
//       fakeConfirmedEventKeys.add(confirmedKey);
//     }
//   }
// }


//---------------------------------------------------------



// For confirmed events
if (allWithHidenEvents) {
  let allWithHiddenKey = `${key}-allEvents`;
  if (!allWithHidenEvents.has(event.start)) {
    allWithHidenEvents.set(event.start, new Set());
  }
  const eventSet = allWithHidenEvents.get(event.start);

  if (!otherIgnoreKeys.has(allWithHiddenKey) && !eventSet.has(allWithHiddenKey)) {
    eventSet.add(allWithHiddenKey);
  }

  if (event.tag === 'fake') {
    fakeAllWithHiddenEventsKeys.add(allWithHiddenKey);
  }
}


  // // For confirmed events
  // if (allWithHidenEvents) {
  //   let allWithHiddenKey = `${key}-allEvents`;
  //   if (!allWithHidenEvents.has(event.start) && !otherIgnoreKeys.has(allWithHiddenKey)) {
  //     allWithHidenEvents.set(event.start, new Set());
  //   }
  //   if (!ignoreKeys.has(allWithHiddenKey)) {
  //     allWithHidenEvents.get(event.start).add(allWithHiddenKey);
  //   }
  //   if (event.tag === 'fake') {
  //     fakeAllWithHiddenEventsKeys.add(allWithHiddenKey);
  //   }
  // }


//---------------------------------------------------------
});


// Now modify the logic for counting events, considering appointment rules
eventsByDate.forEach((uniqueEvents, date) => {
  let eventCount = 0;
  let nonAppointmentEventCount = 0;
  let appointmentEventCount = 0;

  uniqueEvents.forEach(key => {
    if (!ignoreKeys.has(key)) {
      if (appointmentEventKeys.has(key)) {
        if (nonAppointmentEventCount === 0  || appointmentEventCount.length === 0  || !appointmentEventCount) {
          // Only count the appointment event if there are no other events with the same key
          appointmentEventCount++;
          eventCount = appointmentEventCount;
        }
      } else {

        // if (date === "2024-09-05") {
        //   console.log(uniqueEvents)
        //   console.log(appointmentEventCount)
        //   console.log(nonAppointmentEventCount)
        // }
        // Count non-appointment events


        // Count non-appointment events
        nonAppointmentEventCount++;
        eventCount = uniqueEvents.size
      }
    }
  });

  // Adjust count for fake events
  if (Array.from(uniqueEvents).some(key => fakeEventKeys.has(key))) {
    eventCount = Math.max(0, eventCount - 1);
  }

  // if (date === "2024-05-17") {
  //   console.log(`Unique Events for 2024-05-17: ${Array.from(uniqueEvents).join(', ')}`);
  // }

  // let confirmedCount = confirmedEventsByDate.has(date) ? confirmedEventsByDate.get(date).size : 0;
  // // Subtract 1 if any fake confirmed event is found in this set
  // if (confirmedEventsByDate.has(date) && Array.from(confirmedEventsByDate.get(date)).some(key => fakeConfirmedEventKeys.has(key))) {
  //   confirmedCount = Math.max(0, confirmedCount - 1);
  // }

  let confirmedCount = confirmedEventsByDate.has(date) ? confirmedEventsByDate.get(date).size : 0;

// Log the keys being counted
if (confirmedEventsByDate.has(date)) {
  const keysBeingCounted = Array.from(confirmedEventsByDate.get(date));
  // console.log(`Keys being counted for date ${date}:`, keysBeingCounted);
}

// Subtract 1 if any fake confirmed event is found in this set
if (confirmedEventsByDate.has(date) && Array.from(confirmedEventsByDate.get(date)).some(key => fakeConfirmedEventKeys.has(key))) {
  confirmedCount = Math.max(0, confirmedCount - 1);
}


  let allEventsCount = allWithHidenEvents.has(date) ? allWithHidenEvents.get(date).size : 0;
  // Subtract 1 if any fake confirmed event is found in this set
  if (allWithHidenEvents.has(date) && Array.from(allWithHidenEvents.get(date)).some(key => fakeAllWithHiddenEventsKeys.has(key))) {
    allEventsCount = Math.max(0, allEventsCount - 1);
  }

  const fakeEvent = createFakeEvent(hour, date, eventCount, confirmedCount, allEventsCount);
  // console.log(fakeEvent)
  allEvents.push(fakeEvent);
});
}



// Sort all events again including fake events
    allEvents = allEvents.sort((a, b) => {
        const timeA = moment(a.time24, "HH:mm");
        const timeB = moment(b.time24, "HH:mm");
        if (timeA.isBefore(timeB)) return -1;
        if (timeA.isAfter(timeB)) return 1;
        if (timeA.isSame(timeB)) {
            const nameA = (a.resource.name && a.resource.name[0].toLowerCase()) || "";
            const nameB = (b.resource.name && b.resource.name[0].toLowerCase()) || "";
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
        }
        return 0;
}).map(event => {
    if (event.time !== "NO TIME") {
        event.time = moment(event.time24, "HH:mm").format("h:mm A");
    }
    delete event.time24;
    // console.log(event)
    return event;
});


  

    let groupedEventsObj = {};
    let counter = 0;
    let latestDateIds = {};
    
    allEvents.forEach(event => {
        // Convert event.resource.name to lowercase
        const lowerCaseName = event.resource.name.toLowerCase()
        .replace(/-/g, ' ')  // Replace all hyphens with spaces
        .replace(/\s+/g, ' ')  // Replace multiple spaces with a single space
        .trim();  // Remove spaces before and after
    

        let xTime = event.resource.newGroup || event.resource.time;
        let formattedTime;

        if (xTime) {
          // Parsing the time considering various AM/PM formats
          // This will handle formats like HH:MM, H:MM, am/pm, AM/PM, a.m./p.m., A.M./P.M.
          formattedTime = moment(xTime, [
              'h:mm A', 'h:mm a', 
              'h:mmA', 'h:mma', 
              'HH:mm A', 'HH:mm a', 
              'HH:mmA', 'HH:mma', 
              'h:mm A.M.', 'h:mm a.m.', 
              'h:mm P.M.', 'h:mm p.m.',
              'HH:mm A.M.', 'HH:mm a.m.',
              'HH:mm P.M.', 'HH:mm p.m.'
          ]).format('HH:mm');
      
          // Use formattedTime as needed
          // console.log(formattedTime);
          
      } else {
          console.error('Still pending additional data for processing!');
          formattedTime = '';
      }

  

      if (!event.resource.phone) {
        console.log('Phone number missing for event:', event);
      }
      
      let key;
      if (event.resource.phone || event.resource["phone 2"]) {
        // If phone is available, include it in the key, slicing to the last 10 digits
          const phone = event.resource.phone.slice(-10);
          key = `${lowerCaseName}-${event.resource.newDate || event.resource.date}-${phone}-${formattedTime}`;
      } else {
          // If phone is not available, construct the key without the phone part
          key = `${lowerCaseName}-${event.resource.newDate || event.resource.date}-${formattedTime}`;
      }

      

        // Construct the key
        // const key = `${lowerCaseName}-${event.resource.newDate || event.resource.date}-${event.resource.phone.slice(-10)}-${formattedTime}`;
        // const keyB = `${lowerCaseName}-${event.resource.newDate || event.resource.date}-${event.resource.phone.slice(-10)}`;
    
    
        // Check if this is a new group
        if (!groupedEventsObj[key]) {
            groupedEventsObj[key] = [];
            // Create a unique groupId for this new group
            const groupId = `${key}`;
            event.groupId = groupId;
        } else {
            // For existing groups, use the groupId of the first event in the group
            event.groupId = groupedEventsObj[key][0].groupId;
        }
    
        groupedEventsObj[key].push(event);


    });




// --- worked but not fully:

// if (selectedCalendar !== "mainCalendar") {
//   // Initial filtering for required tags
//   groupedEventsObj = Object.keys(groupedEventsObj).reduce((filteredObj, key) => {
//     const hasRequiredTag = groupedEventsObj[key].some(event => 
//       event.tag === "appointment"
//     );

//     if (hasRequiredTag) {
//       filteredObj[key] = groupedEventsObj[key];
//     }

//     return filteredObj;
//   }, {});
// }



// if (selectedCalendar !== "mainCalendar") {
//   groupedEventsObj = await processAndRegroupEvents(groupedEventsObj);

//   // Iterate over each group in groupedEventsObj to remove duplicates
//   Object.keys(groupedEventsObj).forEach(groupKey => {
//       const group = groupedEventsObj[groupKey];
//       const uniqueGroup = group.reduce((uniqueEvents, currentEvent) => {
//           // Using JSON.stringify might not be the most efficient for large datasets or complex objects
//           // but it's one of the simpler ways to deep compare objects in JavaScript
//           const isDuplicate = uniqueEvents.some(event => JSON.stringify(event) === JSON.stringify(currentEvent));
//           if (!isDuplicate) {
//               uniqueEvents.push(currentEvent);
//           }
//           return uniqueEvents;
//       }, []);

//       groupedEventsObj[groupKey] = uniqueGroup;
//   });
// }

// ---- end of worked but not fully.



    

    
        // Assigning tagNumber to each event
        Object.values(groupedEventsObj).forEach(group => {
          const nonAppointmentEvents = group.filter(event => event.tag !== "appointment" && event.tag !== "fake");
          const nonAppointmentCount = nonAppointmentEvents.length;          
          group.forEach(event => {
              event.tagNumber = nonAppointmentCount;
          });
      });

    // allEvents.forEach(event => {
    //     const key = `${event.resource.name}-${event.resource.newDate}-${event.resource.phone}`;
    //     if (!groupedEventsObj[key]) {
    //         groupedEventsObj[key] = [];
    //         groupId++;
    //     }
        
    //     event.groupId = groupId;
    //     groupedEventsObj[key].push(event);
    // });

    
    


    let groupedEventsArray = Object.values(groupedEventsObj);

    groupedEventsArray.forEach(group => {
        group.sort((a, b) => {
            const extractDateTime = (str) => {
                if (typeof str !== 'string') return null;
                const match = str.match(/on (.+?)(?: at )?(\d{1,2}:\d{2} [APMapm]{2})/);
                if (match) {
                    const [_, dateStr, timeStr] = match;
                    const dateTimeStr = `${dateStr} ${timeStr}`;
                    return new Date(dateTimeStr);
                }
                return null;
            };
            const dateA = extractDateTime(a.resource.currentUserData);
            const dateB = extractDateTime(b.resource.currentUserData);
            if (dateA && dateB) {
                return dateB - dateA;
            } else if (dateA) {
                return -1;
            } else {
                return 1;
            }
        });
    });

    // if (selectedCalendar !== "mainCalendar") {
    //   console.log(groupedEventsArray)
    // }

    let finalEvents = [];
for (let group of groupedEventsArray) {
    // Try to find an event with the appointment tag
    let representativeEvent = group.find(event => event.tag === "appointment" || event.tag === "fake");

    // If no event with the appointment tag is found, use the first event as before
    if (!representativeEvent) {
        representativeEvent = { ...group[0] };
    }

    // Count the number of events with the appointment tag
    let appointmentEvents = group.filter(event => event.tag === "appointment");
    
    // Check if the representativeEvent meets the criteria or find the most current event with a valid aptSpecLabel
    if (!(representativeEvent.resource && representativeEvent.resource.aptSpecLabel) ||
        (appointmentEvents.length > 1 && representativeEvent.tag === "appointment")) {
        let mostCurrentEvent = null;

        // If there are multiple appointment events, find the most current appointment event
        if (appointmentEvents.length > 1) {
            for (let event of appointmentEvents) {
                if (event.resource && event.resource.aptSpecLabel) {
                    if (!mostCurrentEvent || new Date(event.resource.dateId) > new Date(mostCurrentEvent.resource.dateId)) {
                        mostCurrentEvent = event;
                    }
                }
            }
        } else {
            // Otherwise, find the most current event with a valid aptSpecLabel
            for (let event of group) {
                if (event.resource && event.resource.aptSpecLabel) {
                    if (!mostCurrentEvent || new Date(event.resource.dateId) > new Date(mostCurrentEvent.resource.dateId)) {
                        mostCurrentEvent = event;
                    }
                }
            }
        }

        // If a most current event with a valid aptSpecLabel is found, use its aptSpecLabel
        if (mostCurrentEvent) {
            representativeEvent.resource = representativeEvent.resource || {};
            representativeEvent.resource.aptSpecLabel = mostCurrentEvent.resource.aptSpecLabel;
        }
    }

    // Push the chosen event to the finalEvents array
    finalEvents.push(representativeEvent);
}






  
// finalEvents.sort((a, b) => {
//     // Parse the times in their original format (with AM/PM)
//     const timeA = moment(a.time, ["h:mm A", "hh:mm A"]);
//     const timeB = moment(b.time, ["h:mm A", "hh:mm A"]);

//     if (timeA.isBefore(timeB)) return -1;
//     if (timeA.isAfter(timeB)) return 1;
//     return 0;
// });

finalEvents.sort((a, b) => {
  // Extract the hour part to compare if both events are within the same hour
  const hourA = moment(a.time, ["h:mm A", "hh:mm A"]).hour();
  const hourB = moment(b.time, ["h:mm A", "hh:mm A"]).hour();

  // If both events are within the same hour
  if (hourA === hourB) {
      // If one event is fake and the other is not, prioritize the fake event
      if (a.tag === "fake" && b.tag !== "fake") return -1;
      if (a.tag !== "fake" && b.tag === "fake") return 1;

      // If both are fake or both are not fake, sort by their full time
      const minuteA = moment(a.time, ["h:mm A", "hh:mm A"]).minutes();
      const minuteB = moment(b.time, ["h:mm A", "hh:mm A"]).minutes();
      if (minuteA < minuteB) return -1;
      if (minuteA > minuteB) return 1;
      
      // If both have the exact same time, prioritize the fake event
      // This is redundant given the earlier fake checks, but it's here for clarity
      if (a.tag === "fake") return -1;
      if (b.tag === "fake") return 1;
  } else {
      // If not within the same hour, simply sort by the hour
      return hourA - hourB;
  }

  // Given the checks above, this is theoretically unreachable for fake vs. non-fake within the same hour,
  // but it serves as a catch-all for identical times where neither is fake, or other unforeseen cases.
  return 0;
});




  



    
    // Filter out only 'fake' events and sort them by start time
const sortedFakeEvents = finalEvents.filter(event => event.tag === 'fake')
.sort((a, b) => moment(a.start).diff(moment(b.start)));

// Reset isFirstEventOfDay for all events
finalEvents.forEach(event => {
event.isFirstEventOfDay = false;
});

// Mark the earliest 'fake' event of each day
let currentDay = null;
sortedFakeEvents.forEach(event => {
const eventDay = moment(event.start).format('YYYY-MM-DD');
if (eventDay !== currentDay) {
event.isFirstEventOfDay = true;
currentDay = eventDay;
}
});

  

    // finalEvents.forEach((event, index, self) => {
    //     if (index === 0 || !moment(event.start).isSame(self[index - 1].start, 'day')) {
    //         event.isFirstEventOfDay = true;
    //     } else {
    //         event.isFirstEventOfDay = false;
    //     }
    // });

    // console.log(finalEvents)

    setOriginalEvents(finalEvents);
    setEvents(finalEvents);
    setGroupedEvents(groupedEventsArray);
    setSelectedEventIndex(0);
    setIsLoading(false);
    setSelectedCalendarView("All")
    setEncTypeFilter(["No Filter"])
    setPreviousCurrentDate(currentDate);

    // if (isUpdatingOldNotifications) {
    // setReadyToHideOldNotifications(true);
    // } else {
    // setReadyToHideOldNotifications(false);
    // }

};



// Apt Search Fetching



const fetchAppointmentsAptSearch = async (fromDate, toDate) => {
// console.log(fromDate)
// console.log(toDate)
  

  const appointmentRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'appointments');

  // Create a query to filter by date
  const dateRangeQuery = query(appointmentRef, 
                               where("date", ">=", fromDate),
                               where("date", "<=", toDate));

  // Fetch the documents within the date range
  const querySnapshot = await getDocs(dateRangeQuery);
  
  const appointments = querySnapshot.docs.map(doc => {
      const data = doc.data();
      
      // Date format validation and conversion
      const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
      if (dateFormat.test(data.date)) {
          data.newDate = data.date;
      }
      
      // Filter based on date range
      const newDateISO = data.newDate || "";
      // console.log(startDate)
      // console.log(newDateISO)
      if (newDateISO < fromDate || newDateISO > toDate) {
          return null;
      }

      const date = data.newDate;
      // console.log(date)
      let finalTime = data.time ? formatTime(data.time) : "NO TIME"; // Ensure formatTime function is defined and correctly formats the time
      
      if (!moment(finalTime, 'h:mm A').isValid()) {
          // console.log(`Incorrect time format for appointment ${doc.id}: ${finalTime}`);
          finalTime = "NO TIME";
      }
      
      const time24 = finalTime === "NO TIME" ? "00:00" : moment(finalTime, "h:mm A").format("HH:mm");
      
      // Structuring the appointment data similarly to the events
      return {
          allDay: true,
          start: date,
          end: date,
          resource: data, // Embedding the original data as a resource
          time: finalTime,
          time24: time24,
          tag: "appointment"
      };
  }).filter(appointment => appointment !== null); // Remove null appointments after date range filtering
  
  // console.log("Fetched appointments:", appointments);
  return appointments;
};

    
const fetchEventsAptSearch = async (fromDate, toDate) => {
  // Define the collection reference
  const historyRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'history');

  // Create a query using the `where` function to filter by newDate
  const dateRangeQuery = query(historyRef, 
                            where("newDate", ">=", fromDate),
                            where("newDate", "<=", toDate));

  // Fetch the documents within the date range
  const querySnapshot = await getDocs(dateRangeQuery);

  let fetchedEvents = querySnapshot.docs
    .map(doc => {
        const data = doc.data();

        // Additional filtering based on status and date format validation
        const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateFormat.test(data.newDate) || (data.hasOwnProperty('status') && data.status !== "completed")) {
            return null;
        }

        // Processing time information
        let finalTime = data.newGroup ? formatTime(data.newGroup) : "NO TIME";
        if (!moment(finalTime, 'h:mm A').isValid()) {
            finalTime = "NO TIME";
        }
        const time24 = finalTime === "NO TIME" ? "00:00" : moment(finalTime, "h:mm A").format("HH:mm");

        console.log(selectedCalendar)
        // Check if the event matches the selected calendar criteria
        const matchesSelectedCalendar = selectedCalendar === "mainCalendar" ? 
          (data.assocCal === "mainCalendar" || data.assocCal === "" || !data.hasOwnProperty('assocCal')) : 
          data.assocCal === selectedCalendar;

        if (!matchesSelectedCalendar) {
          return null; // Skip this event if it doesn't match the selected calendar criteria
        }

        return {
            allDay: true,
            start: data.newDate,
            end: data.newDate,
            resource: data,
            time: finalTime,
            time24: time24,
            id: doc.id // Including the document ID
        };
    })
    .filter(event => event !== null); // Filter out events that were marked as null


// const fetchEventsAptSearch = async (fromDate, toDate) => {

// // Define the collection reference
// const historyRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'history');

// // Create a query using the `where` function to filter by newDate
// const dateRangeQuery = query(historyRef, 
//                           where("newDate", ">=", fromDate),
//                           where("newDate", "<=", toDate));

// // Fetch the documents within the date range
// const querySnapshot = await getDocs(dateRangeQuery);

// let fetchedEvents = querySnapshot.docs
//   .map(doc => {
//       const data = doc.data();

//       // Additional filtering based on status and date format validation
//       const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
//       if (!dateFormat.test(data.newDate) || (data.hasOwnProperty('status') && data.status !== "completed")) {
//           return null;
//       }

//       // Processing time information
//       let finalTime = data.newGroup ? formatTime(data.newGroup) : "NO TIME";
//       if (!moment(finalTime, 'h:mm A').isValid()) {
//           finalTime = "NO TIME";
//       }
//       const time24 = finalTime === "NO TIME" ? "00:00" : moment(finalTime, "h:mm A").format("HH:mm");

//       return {
//           allDay: true,
//           start: data.newDate,
//           end: data.newDate,
//           resource: data,
//           time: finalTime,
//           time24: time24,
//           id: doc.id // Including the document ID
//       };
//   })
//   .filter(event => event !== null); // Filter out events that were marked as null


  const appointments = await fetchAppointmentsAptSearch(fromDate, toDate);
  // appointments.forEach(appointment => {
  //     appointment.tag = "appointment";
  // });

  // console.log(appointments)

  let allEvents = fetchedEvents.concat(appointments);

  allEvents = allEvents.sort((a, b) => {
      const timeA = moment(a.time24, "HH:mm");
      const timeB = moment(b.time24, "HH:mm");
      if (timeA.isBefore(timeB)) return -1;
      if (timeA.isAfter(timeB)) return 1;
      if (timeA.isSame(timeB)) {
          const nameA = (a.resource.name && a.resource.name[0].toLowerCase()) || "";
          const nameB = (b.resource.name && b.resource.name[0].toLowerCase()) || "";
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
      }
      return 0;
  }).map(event => {
      if (event.time !== "NO TIME") {
          event.time = moment(event.time24, "HH:mm").format("h:mm A");
      }
      delete event.time24;
      // console.log(event)
      return event;
  });

  // console.log("Combined events and appointments:", allEvents);
  return allEvents;
  
}


const handleClearSearchClick = async () => {

  setSelectedPatient(null);
  setNameAptSearch('');
  setPhoneAptSearch('');
  setFromDate('');
  setToDate('');
  fromDateRef.current.value = "";
  toDateRef.current.value = "";
  nameAptSearchRef.current.value = "";
  phoneAptSearchRef.current.value = "";
  setSearchResults([]);
  setIsLoadingAptSearch(false);

}


const handleSearchClick = async () => {

setIsLoadingAptSearch(true);

  try {
      let searchName = selectedPatient ? nameAptSearch : nameAptSearchRef.current.value;
      let searchPhone = selectedPatient ? phoneAptSearch : phoneAptSearchRef.current.value;
      let searchFromDate = fromDateRef.current.value;
      let searchToDate = toDateRef.current.value;

      
      // Extract the last 10 digits for the searchPhone
      searchPhone = searchPhone.replace(/\D/g, ''); // Remove non-digit characters
      if (searchPhone.length > 10) {
          searchPhone = searchPhone.slice(-10);
      }

      const searchDateOfBirth = selectedPatient ? dateOfBirthAptSearch : undefined;

      setNameAptSearch(searchName);
      // console.log(searchName)
    
    
      setPhoneAptSearch(searchPhone);
      // console.log(searchPhone)
    
    
    setFromDate(searchFromDate);
    // console.log(searchFromDate)
    
    setToDate(searchToDate);
    // console.log(searchToDate)

      const allEvents = await fetchEventsAptSearch(searchFromDate, searchToDate);

      // console.log(allEvents)

      if (!Array.isArray(allEvents)) {
          console.error('Expected allEvents to be an array, received:', allEvents);
          setSearchResults([]);
          return;
      }

      let finalFilteredResults = allEvents.filter(event => {

        // console.log(event)
        let eventPhone = event.resource.phone || event.resource["phone 2"];

        if (!eventPhone) {
          return;
        }

// console.log(eventPhone)

        // Format the phone number and update data.phone if necessary
function formatPhoneNumber(number) {
  let cleanNumber = String(number).replace(/\D/g, ''); // Remove non-digit characters
  if (eventPhone.length > 10) {
    cleanNumber = eventPhone.slice(-10); // Keep only the last 10 digits
  }
  return cleanNumber;
}

// console.log(eventPhone)
// Ensure data.phone is properly set using data.phone or data["phone 2"]

eventPhone = formatPhoneNumber(eventPhone);

// console.log(eventPhone)
    
        // Lowercase comparison for names
        const eventNameLower = event.resource.name.toLowerCase();
        const searchNameLower = searchName.toLowerCase();
    
        // Determine if we should compare by name, phone, or both
        let matchesName = searchName === "" || eventNameLower.includes(searchNameLower);
        let matchesPhone = searchPhone === "" || eventPhone === searchPhone;
    
        const isAppointment = event.tag === 'appointment';
        const matchesDateOfBirth = !searchDateOfBirth || event.resource.dateOfBirth === searchDateOfBirth;
    
        return (matchesName && matchesPhone) && (!isAppointment || matchesDateOfBirth);
    });
    

      // Sort by date in ascending order
      finalFilteredResults.sort((a, b) => a.start.localeCompare(b.start));

      setSearchResults(finalFilteredResults);
      setIsLoadingAptSearch(false);
  } catch (error) {
      console.error('Error occurred during search:', error);
      setSearchResults([]);
      setIsLoadingAptSearch(false);
  }
};





// const handleSearchClick = async () => {


//   // setFromDate(fromDateRef.current.value)
//   // setToDate(toDateRef.current.value)
//   console.log(fromDate)
//   console.log(toDate)

//   try {
//       const searchName = selectedPatient ? name : nameRef.current.value;
//       const searchPhone = selectedPatient ? phone : phoneRef.current.value;
//       const searchDateOfBirth = selectedPatient ? dateOfBirth : undefined;

//       console.log(searchName)
//       console.log(searchPhone)
//       console.log(searchDateOfBirth)

//       // Assuming fromDate and toDate are your date range states

//       const allEvents = await fetchEventsAptSearch(fromDate, toDate);

//       console.log(allEvents)

//       if (!Array.isArray(allEvents)) {
//           console.error('Expected allEvents to be an array, received:', allEvents);
//           setSearchResults([]);
//           return;
//       }

//       const filteredResults = allEvents.filter(event => {
//         const matchesNameAndPhone = event.resource.phone === searchPhone && event.resource.name.includes(searchName);
//         const isAppointment = event.tag === 'appointment';
//         const matchesDateOfBirth = !searchDateOfBirth || event.resource.dateOfBirth === searchDateOfBirth;
    
//         return matchesNameAndPhone && (!isAppointment || matchesDateOfBirth);
//     });

//       setSearchResults(filteredResults);
//   } catch (error) {
//       console.error('Error occurred during search:', error);
//       setSearchResults(["No appointment found with given name and phone number!"]);
//   }
// };


  

    
function formatDate(date) {
  const options = { 
      weekday: 'short', 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric', 
      timeZoneName: 'short' 
  };
  return date.toLocaleDateString('en-US', options);
}


const fetchDatesAndDayNames = async () => {
  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  startDate.setDate(startDate.getDate() - 7);
  
  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 7);
  
  const datesAndDayNamesPromises = [];

  for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
    const dateString = date.toISOString().split('T')[0];
    const dayNameDocRef = doc(firestore, 'accounts', userEmail, 'physician', physician || 'NONE', 'calendars', selectedCalendar, 'rules', 'byDay', dateString, 'dayName');
    
    const fetchDocPromise = getDoc(dayNameDocRef).then(docSnap => {
      if (docSnap.exists()) {
        const dayName = docSnap.data().name;
        if (dayName) {
          return { date: dateString, dayName };
        }
      }
      return null;
    }).catch(error => {
      console.error(`Error fetching data for ${dateString}:`, error);
      return null;
    });

    datesAndDayNamesPromises.push(fetchDocPromise);
  }

  const datesAndDayNames = (await Promise.all(datesAndDayNamesPromises)).filter(Boolean);

  setDatesAndDayNamesState(datesAndDayNames);
};


// const fetchDatesAndDayNames = async () => {
//   // Calculate the start date (7 days before the start of the month)
//   const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
//   startDate.setDate(startDate.getDate() - 7);
  
//   // Calculate the end date (7 days after the end of the month)
//   const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 7);
  
//   const datesAndDayNames = [];

//   for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
//     const dateString = date.toISOString().split('T')[0];
//     const dayNameDocRef = doc(firestore, 'accounts', userEmail, 'physician', physician || 'NONE', 'calendars', selectedCalendar, 'rules', 'byDay', dateString, 'dayName');
    
//     try {
//       const docSnap = await getDoc(dayNameDocRef);
//       if (docSnap.exists()) {
//         const dayName = docSnap.data().name;
//         if (dayName) {
//           datesAndDayNames.push({ date: dateString, dayName });
//         }
//       }
//     } catch (error) {
//       console.error(`Error fetching data for ${dateString}:`, error);
//     }
//   }

//   setDatesAndDayNamesState(datesAndDayNames);
//   // console.log(datesAndDayNames);
// };




useEffect(() => {
  fetchEvents();
}, [startFetchEvents]);

useEffect(() => {
  if (selectedCalendar.startsWith('or')) {
    setShowOrInfo(true);
  } else {
    setShowOrInfo(false);
  }
}, [selectedCalendar]);


// useEffect(() => {
//   if (selectedCalendar === 'orCalendar' || selectedCalendar === 'orCalendar2') {
//     setShowOrInfo(true);
//   } else {
//     setShowOrInfo(false);
//   }
// }, [selectedCalendar]);

useEffect(() => {
  if (showAllEvents === true) {
    setShowConfirmed(false);
  }
}, [showAllEvents]);

useEffect(() => {
  if (showConfirmed === true) {
    setShowAllEvents(false);
  }
}, [showConfirmed]);


async function fetchCalRulesActiveStatus() {
  console.log(userEmail);
  const docPath = `accounts/${userEmail}/physician/${physician}/calendars/${selectedCalendar}`;
  const docRef = doc(firestore, docPath); // Create a document reference with the path

  try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
          const data = docSnap.data();
          if (data.hasOwnProperty('calRulesActive')) { // Check if the property exists
              console.log('Fetched calRulesActive:', data.calRulesActive); // Optionally log the fetched status
              setRulesStatus(data.calRulesActive); // Set the fetched status to the state
          } else {
              console.log('calRulesActive property does not exist in the document.');
              setRulesStatus(false); // Set rulesStatus to null or another appropriate value
          }
      } else {
          console.log('No such document!');
          setRulesStatus(false); // Set status as false if document does not exist
      }
  } catch (error) {
      console.error('Error fetching document:', error);
      setRulesStatus(false); // Consider error handling by setting false or handling differently
  }
}


useEffect(() => {
  if (selectedCalendar && physician) {
    fetchCalRulesActiveStatus();
}
}, [selectedCalendar, open]);

useEffect(() => {
console.log(rulesStatus)
}, [setRulesStatus]);

useEffect(() => {

  // Function to check if the month has changed and to get the start and end dates
  const checkMonthChangeAndGetDates = () => {

    const current = currentDate;
    const previous = previousCurrentDate;
    // console.log(current)
    // console.log(previous)
    const currentDt = current;
    const previousDt = previous || "";

    const currentDtformatted = current.toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit' });
    

    let previousDtformatted;
    if (!previous) {
        // Assign a default value if previous is null/undefined
        previousDtformatted = "1111-11";
    } else {
        // Format the previous date
        previousDtformatted = previous.toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit' });
    }

// console.log("currentfmt: " + currentDtformatted + ", previousfmt: " + previousDtformatted)

    if (currentDtformatted !== previousDtformatted) {
        // Start of the month at 12:00 AM
        const realCurrentDt = new Date(); // Your existing Date object or the current date/time

        // Get the timezone offset in minutes and convert it to milliseconds
        const timezoneOffset = realCurrentDt.getTimezoneOffset() * 60000; 
        
        // Start of the month at 12:00 AM local time
        const startDt = new Date(currentDt.getFullYear(), currentDt.getMonth(), 1);
        startDt.setHours(0, 0, 0, 0);
        startDt.setTime(startDt.getTime() - timezoneOffset); // Adjust for timezone
        
        // End of the month at 11:59 PM local time
        const endDt = new Date(currentDt.getFullYear(), currentDt.getMonth() + 1, 0);
        endDt.setHours(23, 59, 0, 0);
        endDt.setTime(endDt.getTime() - timezoneOffset); // Adjust for timezone

        // Function to format dates to YYYY-MM-DD
        const formatToYYYYMMDD = (date) => {
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        return { 
            hasChanged: true,
            startDate: formatToYYYYMMDD(startDt), 
            endDate: formatToYYYYMMDD(endDt)
        };
    }
    return { hasChanged: false };
  };

  // Extract the values
  const { hasChanged, startDate, endDate } = checkMonthChangeAndGetDates();

  // console.log("Has Changed? " + hasChanged)
  // Call fetchEvents and set dates if the month has changed
  if (hasChanged) {
      setStartDate(startDate);
      // console.log(startDate);
      setEndDate(endDate);
      // console.log(endDate);
      setDatesUpdated(true);
      
      
  }

}, [currentDate, physician]);


useEffect(() => {
  // This function will be called after startDate and endDate have been updated
  if (datesUpdated) {
    if (!calendars || calendars.length === 0) {
      // Fetch calendars only if the array is empty, null, or undefined
      fetchCalendars();
    }
    fetchDatesAndDayNames();
    fetchEvents();
    setDatesUpdated(false); // Reset the flag after fetching events
    setEncTypeView(false);
    setEncTypeFilter(['No Filter']);
  }
}, [datesUpdated]); // Make sure to include calendars in the dependency array



useEffect(() => {
      if (open) {
          fetchDatesAndDayNames();
          fetchEvents();
      }
}, [selectedCalendar]);


// useEffect(() => {
  // Check if selectedEvent is not null, not undefined, and not an empty object
  // if (!selectedEvent || Object.keys(selectedEvent).length === 0) {
  //   console.log('Selected event is null, undefined, or empty. Skipping fetchEventLogs.');
  //   return; // Skip the rest of the useEffect hook if selectedEvent is not valid
  // }

  const fetchEventLogs = async (event) => {
    // console.log("Started to fetch event logs!")
    const db = firestore;
    const subcollectionPath = `accounts/${userEmail}/physician/${physician}/eventEditHistory`;
    const eventLogsCollectionRef = collection(db, subcollectionPath);
    
    // Get the groupId of the currently selected event
    const currentGroupId = event.groupId
    // const currentGroupId = groupedEvents[selectedGroupIndex][selectedEventIndex].groupId
    // const currentGroupId = selectedEvent.groupId || groupedEvents[selectedGroupIndex][selectedEventIndex].groupId

    // console.log(currentGroupId);

    // Find all events with the same groupId
    const relatedEvents = groupedEvents.find(g => g.some(e => e.groupId === currentGroupId));

    if (!relatedEvents || relatedEvents.length === 0) {
      alert("No events found in the group.");
      return;
    }

    // Extract the eventIds of the related events
    const eventIds = relatedEvents.map(event => event.id || event.resource.docId);

    // Create a query to fetch documents with matching eventIds
    const eventsQuery = query(eventLogsCollectionRef, where("eventId", "in", eventIds));

    const querySnapshot = await getDocs(eventsQuery);
    const sortedEventLogs = querySnapshot.docs
      .map(doc => ({ ...doc.data(), id: doc.id }))
      // Sort by documentId (timestamp) in descending order
      .sort((a, b) => b.id.localeCompare(a.id));

    setEventLogs(sortedEventLogs);
    // console.log(sortedEventLogs);
  };

//   fetchEventLogs();
// }, [selectedGroupIndex]); // This dependency ensures useEffect is called whenever selectedEvent changes



// useEffect(() => {
//   if (activeScreen === 'nono') {
//       setActiveScreen('notification');
//   }
// }, [activeScreen]);


useEffect(() => {
  if (Array.isArray(encTypeFilter) && encTypeFilter.length === 1 && encTypeFilter[0] === "No Filter") {
      setSelectedCalendarView("All");
  }
}, [encTypeFilter]);


// useEffect(() => {


//     fetchEvents();

  
// }, [showConfirmed]); // Include all relevant dependencies


// useEffect(() => {
//     if (open) {
//         fetchEvents();
//     }
// }, [open, showConfirmed]);

// // This useEffect is responsible for setting the selected event
// useEffect(() => {
//     if (groupedEvents[selectedGroupIndex] && groupedEvents[selectedGroupIndex].length > 0) {
//         console.log(selectedGroupIndex)
//         console.log(groupedEvents[selectedGroupIndex])
//         setSelectedEventIndex(0); // setting to the most recent event after sorting
//     } 
// // }, [groupedEvents, selectedGroupIndex]);
// }, [groupedEvents]);


useEffect(() => {
    if (filteredEvents.length > 0) {
        // Optionally, you can include your logic to tag the first event here
        
        // Setting the actual events to the filtered ones
        setEvents(filteredEvents);
        setTriggerCustomEventRender(prevState => !prevState);
    }
}, [filteredEvents]);

useEffect(() => {
    encTypeFilterRef.current = encTypeFilter;
}, [encTypeFilter]);


useEffect(() => {
  const fetchEncounterTypes = async () => {
    // Check if all necessary variables are non-empty
    if (!userEmail || !physician || !selectedCalendar) {
      console.error('Missing required data for Firestore query');
      return; // Exit the function if any variable is empty
    }

    const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes'));
    const querySnapshot = await getDocs(q);
    let types = [];
    querySnapshot.forEach((doc) => {
      types.push(doc.data());
    });
    types.sort((a, b) => a.name.localeCompare(b.name)); // Sorting alphabetically
    setEncounterTypes(types);
    setEncounterTypesForDetails(types);
  };

  fetchEncounterTypes();
}, [open]); // Added dependencies


// useEffect(() => {
//     const fetchEncounterTypes = async () => {
//       const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes'));
//       const querySnapshot = await getDocs(q);
//       let types = [];
//       querySnapshot.forEach((doc) => {
//         types.push(doc.data());
//       });
//       types.sort((a, b) => a.name.localeCompare(b.name)); // Sorting alphabetically
//       setEncounterTypes(types);
//     };

//     fetchEncounterTypes();
//   }, [open]);

  
const fetchEncounterTypes = async () => {
  // Check if all necessary variables are non-empty
  if (!userEmail || !physician || !selectedCalendar) {
    console.error('Missing required data for Firestore query');
    return; // Exit the function if any variable is empty
  }

  const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes'));
  const querySnapshot = await getDocs(q);
  let types = [];
  querySnapshot.forEach((doc) => {
    types.push(doc.data());
  });
  types.sort((a, b) => a.name.localeCompare(b.name)); // Sorting alphabetically
  console.log(types)
  setEncounterTypes(types);
  setEncounterTypesForDetails(types);
};

//   const fetchEncounterTypes = async () => {
//     const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes'));
//     const querySnapshot = await getDocs(q);
//     let types = [];
//     querySnapshot.forEach((doc) => {
//         types.push(doc.data());
//     });
//     types.sort((a, b) => a.name.localeCompare(b.name)); // Sorting alphabetically
//     setEncounterTypes(types);
// };


const fetchCalendars = async () => {
  // Ensure userEmail and physician are valid before proceeding
  if (!userEmail || !physician) {
    console.error("Missing required information for Firestore query: userEmail or physician is undefined.");
    return; // Exit the function early if validation fails
  }

  const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars'));
  const querySnapshot = await getDocs(q);
  let calendarNames = [];
  querySnapshot.forEach((doc) => {
      calendarNames.push(doc.data()); // Assuming extracting the whole data object is intended; adjust if only a specific property is needed.
  });
  // Uncomment the following line if you need to sort calendarNames by a specific property, e.g., name.
  // calendarNames.sort((a, b) => (a.name).localeCompare(b.name)); // Adjusted sorting to use a property, assuming 'name' is the intended one.
  setCalendars(calendarNames); // Setting the names
  // console.log(calendarNames)
};


// const fetchCalendars = async () => {
//   const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars'));
//   const querySnapshot = await getDocs(q);
//   let calendarNames = [];
//   querySnapshot.forEach((doc) => {
//       calendarNames.push(doc.data()); // Extracting the 'name' property
//   });
//   // calendarNames.sort((a, b) => a.localeCompare(b)); // Sorting the names alphabetically
//   setCalendars(calendarNames); // Setting the sorted names
//   // console.log(calendarNames)
// };


const handleSelectingCurrentEvent = async (event) => {

  await fetchEventLogs(event)

  const clickedGroupId = event.groupId; 

  const groupIndex = groupedEvents.findIndex(group =>
    group.some(event => event.groupId === clickedGroupId)
  );

  setSelectedGroupIndex(groupIndex);
  setSelectedEventIndex(0);

}



const handleEventClick = async (event) => {

  console.log(event)
  // Ensure event is defined before proceeding
  if (!event) {
      console.error("Event is undefined.");
      return;
  }

  if (event.tag === 'fake') {
    console.error("This is not an event.");
    return;
}
  
  await handleSelectingCurrentEvent(event);

  // Check for 'fake' tag early to exit if present
  if (event.tag === "fake") {
      return;
  }

  if (event.tag === "appointment") {


      // Set the screen and fields for an appointment
      setActiveScreen('appointment');
      setName('');
      setFirstName('');
      setMiddleName('');
      setLastName('');
      setPhone('');
      setDate('');
      setTime('');
      setEmail('');
      setEncounterType('');
      setAptSpecLabel('');
      setOtherInfo('');
      setAptLang('');
      setDateOfBirth('');
      setOrInfo('');
      setPaDate('');
      setPaTime('');
      setSelectedEvent(event);

      // Safeguard against undefined docId
      const docId = event.resource?.docId;
      if (docId) {
          setAppointmentId(docId);
          handleOpenExistentAptModal(true, event);
      } else {
          console.error("Document ID is undefined for the appointment event.");
      }
      
      return;
  }
  
  // Handle non-appointment events
  setActiveScreen('notification');

  if (selectedGroupIndex !== -1) {
      setOpenDialog(true, event);
  } else {
      console.error("Group not found for the clicked event.");
  }
};


// const handleEventClick = async (event) => {
//     // console.log(event)
//     // console.log(event.groupId)
//     // setSelectedEvent(event)
    
//     await handleSelectingCurrentEvent(event)

//     // const clickedGroupId = event.groupId; 

//     if (event.tag === "fake") {
//       return
//     }

//     if (event.tag === "appointment") {
//         // Logica nueva para appointment
//         // const groupIndex = groupedEvents.findIndex(group =>
//         //   group.some(event => event.groupId === clickedGroupId)
//         // );
//         // console.log(event.groupId)
//         // console.log(groupIndex)
//         setActiveScreen('appointment')
//         setName('');
//         setPhone('');
//         setDate('');
//         setTime('');
//         setEmail('');
//         setEncounterType('');
//         setAptSpecLabel('')
//         setOtherInfo('');
//         setAptLang('');
//         setDateOfBirth('');
//         setOrInfo('');
//         setPaDate('');
//         setPaTime('');
//         // setSelectedGroupIndex(groupIndex);
//         // setSelectedEventIndex(0);
//         setSelectedEvent(event)
//         setAppointmentId(event.resource.docId)
//         // console.log(event.resource.docId)
//         // fetchEncounterTypes()
//         handleOpenExistentAptModal(true, event);
        
//         return;
//     }
    
//     // Logica vieja para single
//     // const groupIndex = groupedEvents.findIndex(group => group[0].groupId === clickedGroupId);
//     // console.log(event.groupId)
//     // console.log(groupIndex)
//     setActiveScreen('notification')
//     // setSelectedGroupIndex(groupIndex);
//     // setSelectedEventIndex(0);
//     // setSelectedEvent(event)

//     if (selectedGroupIndex !== -1) {
//         setOpenDialog(true, event);
//     } else {
//         console.error("Group not found for the clicked event.");
//     }
    
// };







    const handleDialogClose = () => {
      setOpenDialog(false);
      setActiveScreen(false)
      setShowOrInfo(false)
      // setSelectedEvent(null)
    };




    // ----------------------- 


    const handleOpenNewAptModal = () => {
      if (openExistentAptModal === true) {
          // Warning message for the user
          const proceed = window.confirm(
              "You have an Existing Appointment Window already open (It may be minimized!). By clicking OK, the Existing Appointment Window will be closed in order to open the New Appointment Window. By doing so, you will lose any already entered information/data in the Existing Appointment Window. Do you want to proceed?"
          );
  
          if (!proceed) {
              return; // Stop execution if the user cancels the action
          }
  
          // Close the Existing Appointment Modal
          setOpenExistentAptModal(false);
          setModalSize('default')
          setSecondModalSize('default')
      }
  
      setIsUpdating(false);
      
      // Ensure currentDate is a Date object and format it
      if (currentDate instanceof Date) {
          const year = currentDate.getFullYear();
          const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
          const day = currentDate.getDate().toString().padStart(2, '0');
  
          // Format the date into "YYYY-MM-DD"
          const formattedDate = `${year}-${month}-${day}`;
  
          // Set formattedDate if currentView is 'day'
          setDate(currentView === 'day' ? formattedDate : '');
      } else {
          console.error('currentDate is not a Date object:', currentDate);
      }
  
      // Reset form fields
      setName('');
      setFirstName('');
      setMiddleName('');
      setLastName('');
      setPhone('');
      setTime('');
      setEmail('');
      setEncounterType('');
      setAptSpecLabel('');
      setOtherInfo('');
      setAptLang('');
      setDateOfBirth('');
      setOrInfo('');
      setPaDate('');
      setPaTime('');
  
      // Open the New Appointment Modal
      setOpenNewAptModal(true);
  };
  

  //   const handleOpenNewAptModal = () => {
  //     setIsUpdating(false);
  //     // Ensure currentDate is a Date object and format it
  //     if (currentDate instanceof Date) {
  //         // Extract the year, month, and day, ensuring month and day are in "MM" and "DD" format
  //         const year = currentDate.getFullYear();
  //         const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
  //         const day = currentDate.getDate().toString().padStart(2, '0');
  
  //         // Format the date into "YYYY-MM-DD"
  //         const formattedDate = `${year}-${month}-${day}`;
  
  //         // If currentView is 'day', set formattedDate, otherwise set as an empty string
  //         setDate(currentView === 'day' ? formattedDate : '');
  //     } else {
  //         console.error('currentDate is not a Date object:', currentDate);
  //         // Handle the case where currentDate is not a Date object appropriately
  //     }
  
  //     // Rest of your function...
  //     setName('');
  //     setPhone('');
  //     setTime('');
  //     setEmail('');
  //     setEncounterType('');
  //     setAptSpecLabel('');
  //     setOtherInfo('');
  //     setAptLang('');
  //     setDateOfBirth('');
  //     setOrInfo('');
  //     setPaDate('');
  //     setPaTime('');
  //     setOpenNewAptModal(true);
  // };
  





    const handleCloseNewAptModal = () => {
      console.log('Closing new appointment modal...');
      // setSelectedEvent(null)
      setTime('');
      setDate('');
      setFirstClickHappened(false);
      setLastSearchDate('');
      setLastEncounterType('');
      setOpenNewAptModal(false);
      if (selectedCalendar === 'orCalendar' || selectedCalendar === 'orCalendar2' || selectedCalendar === 'orCalendar3' || selectedCalendar === 'orCalendar4' || selectedCalendar === 'orCalendar5') {
        setShowOrInfo(true);
      } else {
        setShowOrInfo(false);
          }
        }

//   useEffect(() => {

//     console.log(openNewAptModal)
//     if (!openNewAptModal) {
//       console.log('Resetting fields...');
//         setTime('');
//         setDate('');
//     }
// }, [openNewAptModal]);
        
        useEffect(() => {
        if (spotResult) {
          setSpotResult("");
        }
        }, [time, date]);

        const handleOpenExistentAptModal = (open, event = null) => {
          if (openNewAptModal === true) {
              // Warning message for the user
              const proceed = window.confirm(
                  "You have a New Appointment Window already open (It may be minimized!). By clicking OK the New Appointment Window will be closed in order to open the Existing Appointment Window. By doing so, you will lose any already entered information/data in the New Appointment Window. Do you want to proceed?"
              );
      
              if (!proceed) {
                  return; // Stop execution if the user cancels the action
              }
      
              // Close the New Appointment Modal and reset any related states
              setOpenNewAptModal(false); 
              setModalSize('default')
              setSecondModalSize('default')
          }
      
          // Continue opening the Existing Appointment Modal
          setIsUpdating(false);
          setSelectedAptEvent(event); 
          setOpenExistentAptModal(open);
      };

    // const handleOpenExistentAptModal = (open, event = null) => {

    //     if (openNewAptModal === true) {
    //       alert("You have a New Appointment Window already open. By clicking OK the New Appointment Window will be closed in order to open the Existing Appointment Window. By doing so you will loose any already entered information/data in the New Appointment Window. Do you want to proceed?");

    //     setIsUpdating(false);
    //     // Saving event data in a state variable to be accessed inside useEffect
    //     setSelectedAptEvent(event); 
    //     // setSelectedEvent(event);
    //     setOpenExistentAptModal(open);
    // };

    useEffect(() => {

        if (selectedAptEvent && selectedAptEvent.resource && encounterTypes && openExistentAptModal === true) {
            setShowOrInfo(selectedAptEvent.resource.orAppt || false)
            setName(selectedAptEvent.resource.name || '');

            if (selectedAptEvent.resource.middleName && selectedAptEvent.resource.middleName.trim()) {
              setWmName(`${selectedAptEvent.resource.firstName} ${selectedAptEvent.resource.middleName.trim()} ${selectedAptEvent.resource.lastName}`);
            } else {
              setWmName(`${selectedAptEvent.resource.name}`);
            }
          

            setFirstName(selectedAptEvent.resource.firstName || '');
            setMiddleName(selectedAptEvent.resource.middleName || '');
            setLastName(selectedAptEvent.resource.lastName || '');
            setPhone(selectedAptEvent.resource.phone || '');
            setDate(selectedAptEvent.resource.date || '');
            setTime(moment(selectedAptEvent.resource.time, ["h:mm A"]).format("HH:mm") || '');
            setOpeningDate(selectedAptEvent.resource.date || '');
            setOpeningTime(moment(selectedAptEvent.resource.time, ["h:mm A"]).format("HH:mm") || '');
            setEmail(selectedAptEvent.resource.email || '');
            setOtherInfo(selectedAptEvent.resource.otherInfo || '');
            setEncounterType(selectedAptEvent.resource.encounterType || selectedAptEvent.resource.encType || '');
            setAptSpecLabel(selectedAptEvent.resource.aptSpecLabel || '');
            setAptLang(selectedAptEvent.resource.language || '');
            setDateOfBirth(selectedAptEvent.resource.dateOfBirth || '');
            setOrInfo(selectedAptEvent.resource.orInfo || '');
            setPaDate(selectedAptEvent.resource.paDate || '');
            setPaTime(selectedAptEvent.resource.paTime || '');
            // setShowLabel(true);
            
        }
    }, [openExistentAptModal]);

    // useEffect(() => {
    //   console.log(selectedAptEvent)
    //   console.log(encounterType)
    // }, [encounterType]);
    
    
  // useEffect(() => {

  //     console.log(showOrInfo)

  // }, [showOrInfo]);


    const handleCloseExistentAptModal = () => {
        // setShowLabel(false);
        setFirstClickHappened(false);
        setLastSearchDate('')
        setLastEncounterType('');
        if (selectedCalendar === 'orCalendar' || selectedCalendar === 'orCalendar2') {
          setShowOrInfo(true);
        } else {
          setShowOrInfo(false);
            }
        setName('');
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setPhone('');
        setDate('');
        setTime('');
        setEmail('');
        setEncounterType('');
        setAptSpecLabel('')
        setOtherInfo('');
        setAptLang('');
        setDateOfBirth('');
        setOrInfo('');
        setPaDate('');
        setPaTime('');
        setShowNotificationDetails(false)
        setOpenExistentAptModal(false);
        setActiveScreen(false)
        setSelectedEvent(null)
        
    };





  
    const handleOpenSettingsModal = (event) => {
      event.stopPropagation();

      const newName = nameRef.current.value;
      const newFirstName = firstNameRef.current.value;
      const newMiddleName = middleNameRef.current.value;
      const newLastName = lastNameRef.current.value;
      const newPhone = phoneRef.current.value;
      const newDate = dateRef.current.value;
      const newTime = timeRef.current.value;
      const newEmail = emailRef.current.value;
      const newEncounterType = encounterTypeRef.current.value;
      const newAptSpecLabel = aptSpecLabelRef.current.value;
      const newAptLang = aptLangRef.current.value;
      const newDateOfBirth = dateOfBirthRef.current.value;

      let newOtherInfo
      let newOrInfo
      let newPaDate
      let newPaTime
      if (showOrInfo === false) {
        newOtherInfo = otherInfoRef.current ? otherInfoRef.current.value : ''; // Assign value with safety check
    } else {
        newOrInfo = orInfoRef.current ? orInfoRef.current.value : ''; // Assign value with safety check
        newPaDate = paDateRef.current ? paDateRef.current.value : ''; // Assign value with safety check
        newPaTime = paTimeRef.current ? paTimeRef.current.value : ''; // Assign value with safety check
    }



        setName(newName);
        setFirstName(newFirstName);
        setMiddleName(newMiddleName);
        setLastName(newLastName);
        setPhone(newPhone);
        setDate(newDate);
        setTime(newTime);
        setEmail(newEmail);
        setEncounterType(newEncounterType);
        setAptSpecLabel(newAptSpecLabel)
        setOtherInfo(newOtherInfo);
        setAptLang(newAptLang);
        setDateOfBirth(newDateOfBirth);
        setOrInfo(newOrInfo);
        setPaDate(newPaDate);
        setPaTime(newPaTime);
        
        fetchEncounterTypes()

        // Clear the selected encounter type and reset the input fields
        setSelectedEncounterType('');
        setNewEncounterType({ name: '', category: '' });
        nameEncRef.current = ''; // resetting the ref value
        colorEncTypeRef.current = '#ffffff';
        categoryRef.current = ''; // resetting the ref value

        setOpenSettingsModal(true);
    }

    const handleCloseSettingsModal = () => {
      
      setOpenSettingsModal(false);
      setSavedNewEncounterType(false);
                  // Clear the selected encounter type and reset the input fields
                  setSelectedEncounterType('');
                  setNewEncounterType({ name: '', category: '', color: '' });
                  nameEncRef.current = ''; // resetting the ref value
                  categoryRef.current = ''; // resetting the ref value
                  colorEncTypeRef.current = '#ffffff'; // resetting the color ref value
                  document.getElementById('cancel-button').textContent = 'Cancel';
    }



  
    const handleSaveAppointment = async (
      xName,
      xFirstName,
      xMiddleName,
      xLastName,
      xPhone,
      xDate,
      xTime,
      xEmail,
      xEncounterType,
      xAptSpecLabel,
      xOtherInfo,
      xAptLang,
      xDateOfBirth,
      xOrInfo,
      xPaDate,
      xPaTime
    ) => {
      console.log("xMiddleName:", xMiddleName);
    
      try {
        const newName = (xName || firstName + " " + lastName).trim().toLowerCase();
        const newFirstName = (xFirstName || firstName).trim().toLowerCase();
        const newMiddleName = (xMiddleName || middleName || '').trim().toLowerCase();
        const newLastName = (xLastName || lastName).trim().toLowerCase();
    
        // Function to capitalize the first letter of each word
        const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1);
    
        // Check for invalid characters in newName
        const invalidCharactersRegex = /[^a-zA-Z\s]/;
        if (invalidCharactersRegex.test(newName)) {
          alert("Invalid characters detected in name. Only regular letters and spaces are allowed.");
          setIsInProcess(false);
          return; // Stop the execution of the function if the name is invalid
        }
    
        // Split the name into words based on spaces
        const nameParts = newName.split(/\s+/);
    
        const firstNameParts = newFirstName.split(/\s+/);
        const middleNameParts = newMiddleName.split(/\s+/);
        const lastNameParts = newLastName.split(/\s+/);
    
        // List of prohibited words
        const prohibitedWords = [
          "sr", "sra", "mr", "mrs", "ms", "miss", "jr", "II", "III",
          "lic", "dr", "dra", "ing", "eng", "prof", "professor", "profesor", "profesora"
        ];
    
        // Check for prohibited words
        const containsProhibitedWords = nameParts.some(part =>
          prohibitedWords.includes(part.toLowerCase())
        );
        if (containsProhibitedWords) {
          alert(
            "First name, middle name or last name must not include abbreviations like Mr., Mrs., Dr., etc. Please remove any such abbreviations."
          );
          setIsInProcess(false);
          return; // Stop the execution if prohibited words are found
        }
    
        // Transform each word to capitalize the first letter
        const capitalizedNewName = nameParts.map(capitalizeFirstLetter).join(' ');
        const capitalizedNewFirstName = firstNameParts.map(capitalizeFirstLetter).join(' ');
        const capitalizedNewMiddleName = middleNameParts.map(capitalizeFirstLetter).join(' ');
        const capitalizedNewLastName = lastNameParts.map(capitalizeFirstLetter).join(' ');
    
        console.log('Capitalized Name:', capitalizedNewName);
        console.log('Capitalized First Name:', capitalizedNewFirstName);
        console.log('Capitalized Middle Name:', capitalizedNewMiddleName);
        console.log('Capitalized Last Name:', capitalizedNewLastName);
    
        let newPhone; // Declare newPhone without initializing
    
        const originalPhone = xPhone || phone;
        console.log(originalPhone);
        const cleanedPhone = originalPhone.replace(/[\s()-\+]/g, '');
        console.log(cleanedPhone);
    
        if (cleanedPhone.length !== 11 || cleanedPhone[0] !== '1') {
          alert(
            "Invalid phone number. It must be 11 digits long and start with '1', without spaces or dashes. Please include your region and area code. For example, 1787XXXXXXX."
          );
          setIsInProcess(false);
          return; // Stop execution if the phone number doesn't meet requirements
        } else {
          // Parse the phone number using libphonenumber-js
          const phoneNumber = parsePhoneNumberFromString(cleanedPhone, 'US');
    
          if (!phoneNumber || !phoneNumber.isValid()) {
            alert("The phone number is not valid. Please enter a valid US phone number.");
            setIsInProcess(false);
            return; // Stop the execution if the phone number is not valid
          }
    
          newPhone = cleanedPhone;
          console.log('Valid Phone:', newPhone);
        }
    
        const newDate = xDate || date;
        console.log("newDate:", newDate);
    
        const newTime = xTime || time;
        console.log("newTime:", newTime);
    
        const newEmail = (xEmail || email).toLowerCase();
        console.log("newEmail:", newEmail);
    
        const newEncounterType = xEncounterType || encounterType;
        console.log("newEncounterType:", newEncounterType);
    
        const newAptSpecLabel = xAptSpecLabel || aptSpecLabel || '';
        console.log("newAptSpecLabel:", newAptSpecLabel);
    
        let newOtherInfo = '';
        let newOrInfo = '';
        let newPaDate = '';
        let newPaTime = '';
    
        if (showOrInfo === false) {
          newOtherInfo = xOtherInfo || otherInfo ? xOtherInfo || otherInfo : '';
        } else {
          newOrInfo = xOrInfo || orInfo ? xOrInfo || orInfo : '';
          newPaDate = xPaDate || paDate ? xPaDate || paDate : '';
          newPaTime = xPaTime || paTime ? xPaTime || paTime : '';
        }
    
        const newAptLang = xAptLang || aptLang;
        console.log("newAptLang:", newAptLang);
    
        const newDateOfBirth = xDateOfBirth || dateOfBirth;
        console.log("newDateOfBirth:", newDateOfBirth);
    
        // Convert 24-hour format time to 12-hour format with AM/PM
        const formattedTime = moment(newTime, "HH:mm").format("hh:mm A");
    
        // Create new appointment object without otherInfo
        const newAppointment = {
          name: capitalizedNewName,
          firstName: capitalizedNewFirstName,
          middleName: capitalizedNewMiddleName,
          lastName: capitalizedNewLastName,
          phone: newPhone,
          date: newDate,
          time: formattedTime,
          email: newEmail,
          encounterType: newEncounterType,
          aptSpecLabel: newAptSpecLabel,
          otherInfo: newOtherInfo,
          language: newAptLang,
          dateOfBirth: newDateOfBirth,
          orInfo: newOrInfo,
          paDate: newPaDate,
          paTime: newPaTime,
          orAppt: showOrInfo
        };
    
        if (newName && newPhone && newDate && formattedTime && newEmail && newDateOfBirth) {
          const appointmentsRef = collection(
            firestore,
            'accounts',
            userEmail,
            'physician',
            physician,
            'calendars',
            selectedCalendar,
            'appointments'
          );
    
          // Save the new appointment
          const docRef = await addDoc(appointmentsRef, {
            ...newAppointment,
            createdBy: currentActiveUser // Assuming createdBy needs to be included
          });
    
          // Update the document to include its own ID in the field 'docId'
          await updateDoc(docRef, { docId: docRef.id });
    
          // -----------------------
          // NEW FORMAT LOGIC START
          // -----------------------
          // The first word is the first name
          const firstNamePart = capitalizedNewFirstName;
          const middleNamePart = capitalizedNewMiddleName;
          const lastNamePart = capitalizedNewLastName;
    
          // 1) Always build the "new format" doc ID: <phone> <DOB> <firstName>
          const newDocId = newPhone + " " + newDateOfBirth + " " + firstNamePart;
          const newDocRef = doc(
            firestore,
            'accounts',
            userEmail,
            'physician',
            physician,
            'registeredClientDetails',
            newDocId
          );
    
          // Check if the new-format doc exists
          const newDocSnap = await getDoc(newDocRef);
    
          if (!newDocSnap.exists()) {
            // Fallback to old-format doc => <phone> <DOB>
            const oldDocId = newPhone + " " + newDateOfBirth;
            const oldDocRef = doc(
              firestore,
              'accounts',
              userEmail,
              'physician',
              physician,
              'registeredClientDetails',
              oldDocId
            );
    
            const oldDocSnap = await getDoc(oldDocRef);
    
            if (oldDocSnap.exists()) {
              // Rename old doc => new doc
              await setDoc(newDocRef, oldDocSnap.data(), { merge: true });
              await deleteDoc(oldDocRef);
            }
          }
    
          try {
            // Finally, update/create the new-format doc with your latest values
            await setDoc(
              newDocRef,
              {
                firstName: firstNamePart,
                middleName: middleNamePart,
                lastName: lastNamePart,
                dateOfBirth: newDateOfBirth,
                language: newAptLang,
                email: newEmail,
                providerName: physician,
                otherInfo: newOtherInfo,
                optIn: true
              },
              { merge: true }
            );
            console.log('Document successfully written/updated!');
          } catch (error) {
            console.error("Error writing/updating document: ", error);
          }
          // -----------------------
          // NEW FORMAT LOGIC END
          // -----------------------
    
          // Setting the appointment data without otherInfo
          const savedAppointmentData = {
            name: capitalizedNewName,
            firstName: capitalizedNewFirstName,
            middleName: capitalizedNewMiddleName,
            lastName: capitalizedNewLastName,
            phone: newPhone,
            date: newDate,
            time: newTime,
            email: newEmail,
            encounterType: newEncounterType,
            aptSpecLabel: newAptSpecLabel,
            otherInfo: newOtherInfo,
            language: newAptLang,
            dateOfBirth: newDateOfBirth,
            orInfo: newOrInfo,
            paDate: newPaDate,
            paTime: newPaTime,
            orAppt: showOrInfo
          };
    
          const newDocIdValue = docRef.id;
          const tag = "new appointment";
    
          // After successful save, record the event
          const action = `Created New Appoinment (ID: ${newDocIdValue})`;
          await recordEvent(savedAppointmentData, action, tag, newDocIdValue)
            .then(() => {
              console.log(`Successfully recorded user event.`);
            })
            .catch(error => {
              console.error("Error recording user event:", error);
            });
    
          alert('Appointment saved successfully!');
          setIsInProcess(false);
          handleCloseExistentAptModal();
          handleCloseNewAptModal();
          fetchEvents();
          setCurrentView('day');
          const newDateObj = newDate;
          console.log(newDateObj);
    
          // Split the date string into components
          const [year, month, day] = newDateObj.split('-').map(Number);
    
          // Create a new date object using local time zone
          const adjustedDate = new Date(year, month - 1, day);
          setCurrentDate(adjustedDate);
    
        } else {
          alert('Please fill in all required fields.');
          setIsInProcess(false);
        }
      } catch (error) {
        console.error("Error saving appointment: ", error);
        alert('Failed to save appointment. Please try again later.');
        setIsInProcess(false);
      }
    };
    

//     const handleSaveAppointment = async (xName, xFirstName, xMiddleName, xLastName, xPhone, xDate, xTime, xEmail, xEncounterType, xAptSpecLabel, xOtherInfo, xAptLang, xDateOfBirth, xOrInfo, xPaDate, xPaTime) => {
      

//         console.log("xMiddleName:", xMiddleName);
  

//       // console.log("xName:", xName);
//       // console.log("xPhone:", xPhone);
//       // console.log("xDate:", xDate);
//       // console.log("xTime:", xTime);
//       // console.log("xEmail:", xEmail);
//       // console.log("xEncounterType:", xEncounterType);
//       // console.log("xAptSpecLabel:", xAptSpecLabel);
//       // console.log("xOtherInfo:", xOtherInfo);
//       // console.log("xAptLang:", xAptLang);
//       // console.log("xDateOfBirth:", xDateOfBirth);
//       // console.log("xOrInfo:", xOrInfo);
//       // console.log("xPaDate:", xPaDate);
//       // console.log("xPaTime:", xPaTime);

//       // console.log("newName:", name);
//       // console.log("newPhone:", phone);
//       // console.log("newDate:", date);
//       // console.log("newTime:", time);
//       // console.log("newEmail:", email);
//       // console.log("newEncounterType:", encounterType);
//       // console.log("newAptSpecLabel:", aptSpecLabel);
//       // console.log("newOtherInfo:", otherInfo);
//       // console.log("newAptLang:", aptLang);
//       // console.log("newDateOfBirth:", dateOfBirth);
//       // console.log("newOrInfo:", orInfo);
//       // console.log("newPaDate:", paDate);
//       // console.log("newPaTime:", paTime);
      
      
//       try {
//         // Capture current values from refs
//         // const newName = nameRef.current.value;

//         // // Check for invalid characters in newName
//         // const invalidCharactersRegex = /[^a-zA-Z\s]/;
//         // if (invalidCharactersRegex.test(newName)) {
//         //     alert("Invalid characters detected in name. Only regular letters and spaces are allowed.");
//         //     return; // Stop the execution of the function if the name is invalid
//         // }


//         const newName = (xName || firstName + " " + lastName).trim().toLowerCase();
//         const newFirstName = (xFirstName || firstName).trim().toLowerCase();
//         const newMiddleName = (xMiddleName || middleName || '').trim().toLowerCase();
//         const newLastName = (xLastName || lastName).trim().toLowerCase();

//         // Function to capitalize the first letter of each word
//         const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1);
        
//         // Check for invalid characters in newName
//         const invalidCharactersRegex = /[^a-zA-Z\s]/;
//         if (invalidCharactersRegex.test(newName)) {
//             alert("Invalid characters detected in name. Only regular letters and spaces are allowed.");
//             setIsInProcess(false);
//             return; // Stop the execution of the function if the name is invalid
//         }


        
//         // Split the name into words based on spaces
//         const nameParts = newName.split(/\s+/);

//         const firstNameParts = newFirstName.split(/\s+/);
//         const middleNameParts = newMiddleName.split(/\s+/);
//         const lastNameParts = newLastName.split(/\s+/);
        

//         // // Assuming 'nameParts' is an array of words from the user's input
//         // if (nameParts.length < 2 || nameParts.length > 4) {
//         //   alert("The name must consist of a first name and last name(s) only; please do not include middle names, titles, or their abbreviations.");
//         //   setIsInProcess(false);
//         //   return; // Stop the execution if the word count is not within the required range
//         // }

//         // // Eliminate the second word if there are 4 words
//         // if (nameParts.length === 4) {
//         //   nameParts.splice(1, 1); // This removes the second element
//         // }

//         // // Check each word for the minimum length of 3 characters
//         // const allWordsValidLength = nameParts.every(word => word.length >= 2);
//         // if (!allWordsValidLength) {
//         //   alert("Each name or last name must be 2 characters or longer.");
//         //   setIsInProcess(false);
//         //   return; // Stop the execution if any word is shorter than 3 characters
//         // }

//         // List of prohibited words
//         const prohibitedWords = ["sr", "sra", "mr", "mrs", "ms", "miss", "jr", "II", "III", "lic", "dr", "dra", "ing", "eng", "prof", "professor", "profesor", "profesora"];

//         // Check for prohibited words
//         const containsProhibitedWords = nameParts.some(part => prohibitedWords.includes(part.toLowerCase()));
//         if (containsProhibitedWords) {
//           alert("First name, middle name or last name must not include abbreviations like Mr., Mrs., Dr., etc. Please remove any such abbreviations.");
//           setIsInProcess(false);
//           return; // Stop the execution if prohibited words are found
//         }

        
//         // Transform each word to capitalize the first letter
//         const capitalizedNewName = nameParts.map(capitalizeFirstLetter).join(' ');
//         const capitalizedNewFirstName = firstNameParts.map(capitalizeFirstLetter).join(' ');
//         const capitalizedNewMiddleName = middleNameParts.map(capitalizeFirstLetter).join(' ');
//         const capitalizedNewLastName = lastNameParts.map(capitalizeFirstLetter).join(' ');
        
//         // Use capitalizedNewName for your needs
//         console.log('Capitalized Name:', capitalizedNewName);
//         console.log('Capitalized First Name:', capitalizedNewFirstName);
//         console.log('Capitalized Middle Name:', capitalizedNewMiddleName);
//         console.log('Capitalized Last Name:', capitalizedNewLastName);

        


//         let newPhone; // Declare newPhone without initializing

//         const originalPhone = xPhone || phone;
//         console.log(originalPhone)
//         const cleanedPhone = originalPhone.replace(/[\s()-\+]/g, '');
//         console.log(cleanedPhone)

//         if (cleanedPhone.length !== 11 || cleanedPhone[0] !== '1') {
//           alert("Invalid phone number. It must be 11 digits long and start with '1', without spaces or dashes. Please include your region and area code. For example, 1787XXXXXXX.");
//           setIsInProcess(false);
//           return; // Stop the execution of the function if the phone number doesn't meet the initial requirements
//         } else {
//           // Parse the phone number using libphonenumber-js
//           const phoneNumber = parsePhoneNumberFromString(cleanedPhone, 'US');
          
//           // Check if the phone number is valid and if the area code (the 2nd, 3rd, and 4th digits) is valid
//           if (!phoneNumber || !phoneNumber.isValid()) {
//             alert("The phone number is not valid. Please enter a valid US phone number.");
//             setIsInProcess(false);
//             return; // Stop the execution if the phone number is not valid
//           }
          
//           // At this point, the phone number is valid. libphonenumber-js handles the validation of area codes inherently.
//           // If you need to explicitly check the area code, you might need additional logic or data,
//           // as libphonenumber-js does not expose a direct method to validate an area code in isolation.
//           newPhone = cleanedPhone;
//           console.log('Valid Phone:', newPhone);
//         }
        

        
//         const newDate = xDate || date;
//         console.log("newDate:", newDate);
        
//         const newTime = xTime || time;
//         // const newTime = moment((xTime || time), "HH:mm").format("hh:mm A");
//         console.log("newTime:", newTime);
        
//         const newEmail = (xEmail || email).toLowerCase();
//         console.log("newEmail:", newEmail);
        
//         const newEncounterType = xEncounterType || encounterType;
//         console.log("newEncounterType:", newEncounterType);
        
//         const newAptSpecLabel = xAptSpecLabel || aptSpecLabel || '';
//         console.log("newAptSpecLabel:", newAptSpecLabel);
        
//         // const newOtherInfo = otherInfoRef.current.value;
//         // console.log("newOtherInfo:", newOtherInfo);


//         let newOtherInfo = '';
//         let newOrInfo = '';
//         let newPaDate = '';
//         let newPaTime = ''; 

//         if (showOrInfo === false) {
//             newOtherInfo = xOtherInfo || otherInfo ? xOtherInfo || otherInfo : ''; // Assign value with safety check
//         } else {
//             newOrInfo = xOrInfo || orInfo ? xOrInfo || orInfo : ''; // Assign value with safety check
//             newPaDate = xPaDate || paDate ? xPaDate || paDate : ''; // Assign value with safety check
//             newPaTime = xPaTime || paTime ? xPaTime || paTime : ''; // Assign value with safety check
//         }
        
//         // Now you can use newOtherInfo, newOrInfo, newPaDate, and newPaTime outside of the if-else blocks
        

        
//         const newAptLang = xAptLang || aptLang;
//         console.log("newAptLang:", newAptLang);
        
//         const newDateOfBirth = xDateOfBirth || dateOfBirth;
//         console.log("newDateOfBirth:", newDateOfBirth);

        
//         // const newOrInfo = orInfoRef.current.value;
//         // const newPaDate = paDateRef.current.value;
//         // const newPaTime = paTimeRef.current.value;
        
    
//         // Convert 24-hour format time to 12-hour format with AM/PM
//         const formattedTime = moment(newTime, "HH:mm").format("hh:mm A");
    
//         // Create new appointment object without otherInfo
//         const newAppointment = {
//           name: capitalizedNewName,
//           firstName: capitalizedNewFirstName,
//           middleName: capitalizedNewMiddleName,
//           lastName: capitalizedNewLastName,
//           phone: newPhone,
//           date: newDate,
//           time: formattedTime,
//           email: newEmail,
//           encounterType: newEncounterType,
//           aptSpecLabel: newAptSpecLabel,
//           otherInfo: newOtherInfo,
//           language: newAptLang,
//           dateOfBirth: newDateOfBirth,
//           orInfo: newOrInfo,
//           paDate: newPaDate,
//           paTime: newPaTime,
//           orAppt: showOrInfo
//         };
    
//         if (newName && newPhone && newDate && formattedTime && newEmail && newDateOfBirth) {
//           const appointmentsRef = collection(
//             firestore,
//             'accounts',
//             userEmail,
//             'physician',
//             physician,
//             'calendars',
//             selectedCalendar,
//             'appointments'
//           );
    
//           // Save the new appointment
//           const docRef = await addDoc(appointmentsRef, {
//             ...newAppointment,
//             createdBy: currentActiveUser // Assuming createdBy needs to be included
//           });
    
//           // Update the document to include its own ID in the field 'docId'
//           await updateDoc(docRef, { docId: docRef.id });
    
// // Reference to the specific document in Firestore
// const otherInfoDocRef = doc(
//   firestore,
//   'accounts',
//   userEmail,
//   'physician',
//   physician,
//   'registeredClientDetails',
//   newPhone + " " + newDateOfBirth
// );

// // // Function to capitalize the first letter of a word and make the rest lowercase
// // const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

// // // Splitting the newName into an array of words and capitalizing each word
// // const nameParts = capitalizedNewName.split(' ').map(capitalize);

// // The first word is the first name
// const firstNamePart = capitalizedNewFirstName;

// const middleNamePart = capitalizedNewMiddleName;

// // The rest of the words are joined back into a string for the last name
// const lastNamePart = capitalizedNewLastName;

// // console.log(firstNamePart)
// // console.log(lastNamePart)


// try {
// // Update the document with new values
// await setDoc(otherInfoDocRef, {
//   firstName: firstNamePart,
//   middleName: middleNamePart,
//   lastName: lastNamePart,
//   dateOfBirth: newDateOfBirth,
//   language: newAptLang,
//   email: newEmail,
//   providerName: physician,
//   otherInfo: newOtherInfo,
//   optIn: true
// }, { merge: true });


//         // Setting the appointment data without otherInfo
//         const savedAppointmentData = {
//           name: capitalizedNewName,
//           firstName: capitalizedNewFirstName,
//           middleName: capitalizedNewMiddleName,
//           lastName: capitalizedNewLastName,
//           phone: newPhone,
//           date: newDate,
//           time: newTime,
//           email: newEmail,
//           encounterType: newEncounterType,
//           aptSpecLabel: newAptSpecLabel,
//           otherInfo: newOtherInfo,
//           language: newAptLang,
//           dateOfBirth: newDateOfBirth,
//           orInfo: newOrInfo,
//           paDate: newPaDate,
//           paTime: newPaTime,
//           orAppt: showOrInfo
//         };


//         const newDocId = docRef.id
//         const tag = "new appointment"

//         // After successful save, record the event

//         const action = `Created New Appoinment (ID: ${newDocId})`;
//         await recordEvent(savedAppointmentData, action, tag, newDocId)
//           .then(newDocId => {
//             console.log(`Successfully recorded user event.`);
//           })
//           .catch(error => {
//             console.error("Error recording user event:", error);
//           });



// console.log('Document successfully written/updated!');
// } catch (error) {
//   console.error("Error writing/updating document: ", error);
// }
    
//           alert('Appointment saved successfully!');
//           setIsInProcess(false);
//           handleCloseExistentAptModal();
//           handleCloseNewAptModal();
//           fetchEvents();
//           setCurrentView('day')
//           const newDateObj = newDate; // Replace with your actual date string
//           console.log(newDateObj)

//           // Split the date string into components
//           const [year, month, day] = newDateObj.split('-').map(Number);
      
//           // Create a new date object using local time zone
//           // Note: Month in JavaScript Date is 0-indexed (0 for January, 1 for February, etc.)
//           const adjustedDate = new Date(year, month - 1, day);
//           setCurrentDate(adjustedDate)
    
//                     // // Update state with new values
//                     // setName(newName);
//                     // setPhone(newPhone);
//                     // setDate(newDate);
//                     // setTime(formattedTime);
//                     // setEmail(newEmail);
//                     // setEncounterType(newEncounterType);
//                     // setAptSpecLabel(newAptSpecLabel);
//                     // setOtherInfo(newOtherInfo);
//                     // setAptLang(newAptLang);
//                     // setDateOfBirth(newDateOfBirth);
//                     // setOrInfo(newOrInfo);
//                     // setPaDate(newPaDate);
//                     // setPaTime(newPaTime);

//           // // Update state with new values
//           // setName('');
//           // setPhone('');
//           // setDate('');
//           // setTime('');
//           // setEmail('');
//           // setEncounterType('');
//           // setAptSpecLabel('');
//           // setOtherInfo('');
//           // setAptLang('');
//           // setDateOfBirth('');
//           // setOrInfo('');
//           // setPaDate('');
//           // setPaTime('');
//         } else {
//           alert('Please fill in all required fields.');
//           setIsInProcess(false);
//         }
//       } catch (error) {
//         console.error("Error saving appointment: ", error);
//         alert('Failed to save appointment. Please try again later.');
//         setIsInProcess(false);
//       }
//     };
    

   
    

    const handleUpdateAppointment = async (xName, xFirstName, xMiddleName, xLastName, xPhone, xDate, xTime, xEmail, xEncounterType, xAptSpecLabel, xOtherInfo, xAptLang, xDateOfBirth, xOrInfo, xPaDate, xPaTime) => {
      try {
        // Directly accessing the current values of the refs
        // const newName = nameRef.current.value;
    
        // // Check for invalid characters in newName
        // const invalidCharactersRegex = /[^a-zA-Z\s]/;
        // if (invalidCharactersRegex.test(newName)) {
        //   alert("Invalid characters detected in name. Only regular letters and spaces are allowed.");
        //   return; // Stop the execution of the function if the name is invalid
        // }


        const newName = ((xName) || (name) || (xFirstName + " " + xLastName ) || (firstName + " " + lastName)).trim().toLowerCase();
        const newFirstName = (xFirstName || firstName).trim().toLowerCase();
        const newMiddleName = (xMiddleName || middleName || '').trim().toLowerCase();
        const newLastName = (xLastName || lastName).trim().toLowerCase();

        // Function to capitalize the first letter of each word
        const capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1);
        
        // Check for invalid characters in newName
        const invalidCharactersRegex = /[^a-zA-Z\s]/;
        if (invalidCharactersRegex.test(newName)) {
            alert("Invalid characters detected in name. Only regular letters and spaces are allowed.");
            setIsInProcess(false);
            return; // Stop the execution of the function if the name is invalid
        }
        
        // Split the name into words based on spaces
        const nameParts = newName.split(/\s+/);
        const firstNameParts = newFirstName.split(/\s+/);
        const middleNameParts = newMiddleName.split(/\s+/);
        const lastNameParts = newLastName.split(/\s+/);
        

        // // Assuming 'nameParts' is an array of words from the user's input
        // if (nameParts.length < 2 || nameParts.length > 4) {
        //   alert("The name must consist of a first name and last name(s) only; please do not include middle names, titles, or their abbreviations.");
        //   setIsInProcess(false);
        //   return; // Stop the execution if the word count is not within the required range
        // }

        // // Eliminate the second word if there are 4 words
        // if (nameParts.length === 4) {
        //   nameParts.splice(1, 1); // This removes the second element
        // }

        // // Check each word for the minimum length of 3 characters
        // const allWordsValidLength = nameParts.every(word => word.length >= 2);
        // if (!allWordsValidLength) {
        //   alert("Each name or last name must be 2 characters or longer.");
        //   setIsInProcess(false);
        //   return; // Stop the execution if any word is shorter than 3 characters
        // }

        // List of prohibited words
        const prohibitedWords = ["sr", "sra", "mr", "mrs", "ms", "miss", "jr", "II", "III", "lic", "dr", "dra", "ing"];

        // Check for prohibited words
        const containsProhibitedWords = nameParts.some(part => prohibitedWords.includes(part.toLowerCase()));
        if (containsProhibitedWords) {
          alert("Your name must not include abbreviations like Mr., Mrs., Dr., etc. Please remove any such abbreviations.");
          setIsInProcess(false);
          return; // Stop the execution if prohibited words are found
        }

        
        // Transform each word to capitalize the first letter
        const capitalizedNewName = nameParts.map(capitalizeFirstLetter).join(' ');
        const capitalizedNewFirstName = firstNameParts.map(capitalizeFirstLetter).join(' ');
        const capitalizedNewMiddleName = middleNameParts.map(capitalizeFirstLetter).join(' ');
        const capitalizedNewLastName = lastNameParts.map(capitalizeFirstLetter).join(' ');
        console.log('Capitalized Name:', capitalizedNewName);
        console.log('Capitalized First Name:', capitalizedNewFirstName);
        console.log('Capitalized Middle Name:', capitalizedNewMiddleName);
        console.log('Capitalized Last Name:', capitalizedNewLastName);
        
        // Use capitalizedNewName for your needs
        console.log('Capitalized Name:', capitalizedNewName);



    
        let newPhone; // Declare newPhone without initializing

        const originalPhone = xPhone || phone;
        console.log(originalPhone)
        const cleanedPhone = originalPhone.replace(/[\s()-\+]/g, '');
        console.log(cleanedPhone)

        if (cleanedPhone.length !== 11 || cleanedPhone[0] !== '1') {
          alert("Invalid phone number. It must be 11 digits long and start with '1', without spaces or dashes. Please include your region and area code. For example, 1787XXXXXXX.");
          setIsInProcess(false);
          return; // Stop the execution of the function if the phone number doesn't meet the initial requirements
        } else {
          // Parse the phone number using libphonenumber-js
          const phoneNumber = parsePhoneNumberFromString(cleanedPhone, 'US');
          
          // Check if the phone number is valid and if the area code (the 2nd, 3rd, and 4th digits) is valid
          if (!phoneNumber || !phoneNumber.isValid()) {
            alert("The phone number is not valid. Please enter a valid US phone number.");
            setIsInProcess(false);
            return; // Stop the execution if the phone number is not valid
          }
          
          // At this point, the phone number is valid. libphonenumber-js handles the validation of area codes inherently.
          // If you need to explicitly check the area code, you might need additional logic or data,
          // as libphonenumber-js does not expose a direct method to validate an area code in isolation.
          newPhone = cleanedPhone;
        }
        
        
    
        const newDate = xDate || date;
        const newTime = moment((xTime || time), "HH:mm").format("hh:mm A");
        const newEmail = (xEmail || email).toLowerCase();
        const newEncounterType = xEncounterType || encounterType;
        const newAptSpecLabel = xAptSpecLabel || aptSpecLabel || '';
        // console.log(newAptSpecLabel);        
        // const newOtherInfo = otherInfoRef.current.value;
        const newAptLang = xAptLang || aptLang;
        const newDateOfBirth = xDateOfBirth || dateOfBirth;
        // const newOrInfo = orInfoRef.current.value;
        // const newPaDate = paDateRef.current.value;
        // const newPaTime = paTimeRef.current.value;

        let newOtherInfo = '';
        let newOrInfo = '';
        let newPaDate = '';
        let newPaTime = ''; 

        if (showOrInfo === false) {
            newOtherInfo = xOtherInfo || otherInfo ? xOtherInfo || otherInfo : ''; // Assign value with safety check
        } else {
            newOrInfo = xOrInfo || orInfo ? xOrInfo || orInfo : ''; // Assign value with safety check
            newPaDate = xPaDate || paDate ? xPaDate || paDate : ''; // Assign value with safety check
            newPaTime = xPaTime || paTime ? xPaTime || paTime : ''; // Assign value with safety check
        }
    
        // Setting the appointment data without otherInfo
        const updatedAppointmentData = {
          name: capitalizedNewName,
          firstName: capitalizedNewFirstName,
          middleName: capitalizedNewMiddleName,
          lastName: capitalizedNewLastName,
          phone: newPhone,
          date: newDate,
          time: newTime,
          email: newEmail,
          encounterType: newEncounterType,
          aptSpecLabel: newAptSpecLabel,
          otherInfo: newOtherInfo,
          language: newAptLang,
          dateOfBirth: newDateOfBirth,
          orInfo: newOrInfo,
          paDate: newPaDate,
          paTime: newPaTime,
          orAppt: showOrInfo
        };
    
        const appointmentRef = doc(
          firestore,
          'accounts',
          userEmail,
          'physician',
          physician,
          'calendars',
          selectedCalendar,
          'appointments',
          appointmentId
        );
    
        // Update the appointment data
        await updateDoc(appointmentRef, updatedAppointmentData);
    
        // After successful update, record the event
        const docuId = selectedEvent.resource.id || selectedEvent.resource.docId || "";
        const action = `Updated Appointment (ID: ${docuId})`;
        await recordEvent(updatedAppointmentData, action)
          .then(docuId => {
            console.log(`Successfully recorded user event.`);
          })
          .catch(error => {
            console.error("Error recording user event:", error);
          });
    
        // Continue with the rest of the function logic after recording the event
        // alert('Appointment updated successfully!');
        // handleCloseExistentAptModal();
        // setIsUpdating(false);
        // fetchEvents();
        // setCurrentView('day');
        // const newDateObj = newDate; // This seems redundant, you might want to use 'newDate' directly
    
        // // Split the date string into components
        // const [year, month, day] = newDateObj.split('-').map(Number);
    
        // // Create a new date object using local time zone
        // const adjustedDate = new Date(year, month - 1, day);
        // setCurrentDate(adjustedDate);
      } catch (error) {
        console.error("Error updating appointment: ", error);
        alert('Failed to update appointment. Please try again later.');
        setIsUpdating(false);
        setIsInProcess(false);
      }
    };

    // console.log(selectedEvent)
     
    
//     const handleUpdateAppointment = async () => {
//       try {
//         // Directly accessing the current values of the refs
//         const newName = nameRef.current.value;

//         // Check for invalid characters in newName
//         const invalidCharactersRegex = /[^a-zA-Z\s]/;
//         if (invalidCharactersRegex.test(newName)) {
//             alert("Invalid characters detected in name. Only regular letters and spaces are allowed.");
//             return; // Stop the execution of the function if the name is invalid
//         }

//         let newPhone; // Declare newPhone without initializing

//         const originalPhone = phoneRef.current.value;
//         const cleanedPhone = originalPhone.replace(/[\s()-\+]/g, '');

//         if (cleanedPhone.length === 10) {
//             // Update newPhone without re-declaration
//             newPhone = "1" + cleanedPhone;
//         } else if (cleanedPhone.length < 10) {
//             alert("Invalid phone number. Please remember to include region and area code. Eg. 1787XXXXXXX");
//             return; // Stop the execution of the function if the phone number is invalid
//         } else {
//             newPhone = cleanedPhone;
//         }

//         const newDate = dateRef.current.value;
//         const newTime = moment(timeRef.current.value, "HH:mm").format("hh:mm A");
//         const newEmail = emailRef.current.value;
//         const newEncounterType = encounterTypeRef.current.value;
//         const newAptSpecLabel = aptSpecLabelRef.current.value;
//         console.log(newAptSpecLabel)        
//         const newOtherInfo = otherInfoRef.current.value;
//         const newAptLang = aptLangRef.current.value;
//         const newDateOfBirth = dateOfBirthRef.current.value;
    
//         // Setting the appointment data without otherInfo
//         const updatedAppointmentData = {
//           name: newName,
//           phone: newPhone,
//           date: newDate,
//           time: newTime,
//           email: newEmail,
//           encounterType: newEncounterType,
//           aptSpecLabel: newAptSpecLabel,
//           otherInfo: newOtherInfo,
//           language: newAptLang,
//           dateOfBirth: newDateOfBirth
//         };
    
//         const appointmentRef = doc(
//           firestore,
//           'accounts',
//           userEmail,
//           'physician',
//           physician,
//           'calendars',
//           selectedCalendar,
//           'appointments',
//           appointmentId
//         );
    
//         // Update the appointment data
//         await updateDoc(appointmentRef, updatedAppointmentData);
    
//         // Update the otherInfo in a different location
// // Reference to the specific document in Firestore
// const otherInfoDocRef = doc(
//   firestore,
//   'accounts',
//   userEmail,
//   'physician',
//   physician,
//   'registeredClientDetails',
//   newPhone + " " + newDateOfBirth
// );

// // Function to capitalize the first letter of a word and make the rest lowercase
// const capitalize = word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

// // Splitting the newName into an array of words and capitalizing each word
// const nameParts = newName.split(' ').map(capitalize);

// // The first word is the first name
// const firstNamePart = nameParts[0];

// // The rest of the words are joined back into a string for the last name
// const lastNamePart = nameParts.slice(1).join(' ');

// console.log(firstNamePart)
// console.log(lastNamePart)







// try {
// // Update the document with new values
// await setDoc(otherInfoDocRef, {
//   firstName: firstNamePart,
//   lastName: lastNamePart,
//   dateOfBirth: newDateOfBirth,
//   language: newAptLang,
//   email: newEmail,
//   providerName: physician,
//   otherInfo: newOtherInfo
// }, { merge: true });

// console.log('Document successfully written/updated!');
// } catch (error) {
//   console.error("Error writing/updating document: ", error);
// }

    
//         // Update state with new values
//         setName(newName);
//         setPhone(newPhone);
//         setDate(newDate);
//         setTime(newTime);
//         setEmail(newEmail);
//         setEncounterType(newEncounterType);
//         setAptSpecLabel(newAptSpecLabel);
//         setOtherInfo(newOtherInfo);
//         setAptLang(newAptLang);
//         setDateOfBirth(newDateOfBirth);
    
//         alert('Appointment updated successfully!');
//         handleCloseExistentAptModal();
//         fetchEvents();
//         setCurrentView('day')
//         const newDateObj = newDate; // Replace with your actual date string

//         // Split the date string into components
//         const [year, month, day] = newDateObj.split('-').map(Number);
    
//         // Create a new date object using local time zone
//         // Note: Month in JavaScript Date is 0-indexed (0 for January, 1 for February, etc.)
//         const adjustedDate = new Date(year, month - 1, day);
//         setCurrentDate(adjustedDate)
//       } catch (error) {
//         console.error("Error updating appointment: ", error);
//         alert('Failed to update appointment. Please try again later.');
//       }
//     };
    
    // const handleUpdateAppointment = async () => {
    //   try {
    //     // Directly accessing the current values of the refs
    //     const newName = nameRef.current.value;
    //     const newPhone = phoneRef.current.value;
    //     const newDate = dateRef.current.value;
    //     const newTime = moment(timeRef.current.value, "HH:mm").format("hh:mm A");
    //     const newEmail = emailRef.current.value;
    //     const newEncounterType = encounterTypeRef.current.value;
    //     const newAptSpecLabel = aptSpecLabelRef
    //     const newOtherInfo = otherInfoRef.current.value;
    //     const newAptLang = aptLangRef.current.value;
    //     const newDateOfBirth = dateOfBirthRef.current.value;
    
    //     // Setting the appointment data
    //     const updatedAppointmentData = {
    //       name: newName,
    //       phone: newPhone,
    //       date: newDate,
    //       time: newTime,
    //       email: newEmail,
    //       encounterType: newEncounterType,
    //       aptSpecLabel: newAptSpecLabel,
    //       otherInfo: newOtherInfo,
    //       language: newAptLang,
    //       dateOfBirth: newDateOfBirth
    //     };
    
    //     const appointmentRef = doc(
    //       firestore,
    //       'accounts',
    //       userEmail,
    //       'physician',
    //       physician,
    //       'calendars',
    //       'mainCalendar',
    //       'appointments',
    //       appointmentId
    //     );
    
    //     await updateDoc(appointmentRef, updatedAppointmentData);
    
    //     // Update state with new values
    //     setName(newName);
    //     setPhone(newPhone);
    //     setDate(newDate);
    //     setTime(newTime);
    //     setEmail(newEmail);
    //     setEncounterType(newEncounterType);
    //     setAptSpecLabel(newAptSpecLabel);
    //     setOtherInfo(newOtherInfo);
    //     setAptLang(newAptLang);
    //     setDateOfBirth(newDateOfBirth);
    
    //     alert('Appointment updated successfully!');
    //     handleCloseExistentAptModal();
    //     fetchEvents();
    //   } catch (error) {
    //     console.error("Error updating appointment: ", error);
    //     alert('Failed to update appointment. Please try again later.');
    //   }
    // };


    const handleUpdateOtherInfo = async () => {
      let newPhone; // Declare newPhone without initializing
      const originalPhone = phoneRef.current.value;
      console.log(originalPhone);
      const cleanedPhone = originalPhone.replace(/[\s()-\+]/g, '');
      console.log(cleanedPhone);
    
      if (cleanedPhone.length === 10) {
        newPhone = "1" + cleanedPhone;
      } else if (cleanedPhone.length < 10 || cleanedPhone.length > 11) {
        alert("Invalid phone number. Please remember to include region and area code. Eg. 1787XXXXXXX");
        return;
      } else {
        newPhone = cleanedPhone;
      }
    
      try {
        const otherInfoDoc = await getDoc(doc(
          firestore,
          'accounts',
          userEmail,
          'physician',
          physician,
          'registeredClientDetails',
          newPhone + " " + (dateOfBirthRef.current.value || dateOfBirth)
        ));
    
        if (otherInfoDoc.exists()) {
          // Display alert
          alert("This will load and add already stored Client Notes in the database below currently already saved or entered information!");
    
          // Append the newly loaded data, leaving a space between the old and new data
          otherInfoRef.current.value += "\n\n" + "------------------" + "\n\n" + otherInfoDoc.data().otherInfo;
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching other info: ", error);
      }
    };
    

    // const handleUpdateOtherInfo = async () => {

    //   let newPhone; // Declare newPhone without initializing

    //   const originalPhone = phoneRef.current.value;
    //   console.log(originalPhone)
    //   const cleanedPhone = originalPhone.replace(/[\s()-\+]/g, '');
    //   console.log(cleanedPhone)

    //   if (cleanedPhone.length === 10) {
    //       // Update newPhone without re-declaration
    //       newPhone = "1" + cleanedPhone;
    //   } else if (cleanedPhone.length < 10 || cleanedPhone.length > 11 ) {
    //       alert("Invalid phone number. Please remember to include region and area code. Eg. 1787XXXXXXX");
    //       return; // Stop the execution of the function if the phone number is invalid
    //   } else {
    //       newPhone = cleanedPhone;
    //   }

    //   try {

    //     // console.log(newPhone + " " + dateOfBirth)
    //     // console.log(newPhone + " " + dateOfBirthRef.current.value)

    //     const otherInfoDoc = await getDoc(doc(
    //       firestore,
    //       'accounts',
    //       userEmail,
    //       'physician',
    //       physician,
    //       'registeredClientDetails',
    //       newPhone + " " + dateOfBirthRef.current.value || newPhone + " " + dateOfBirth
    //     ));
    
    //     if (otherInfoDoc.exists()) {
    //       // Update the useRef with the value from Firestore
    //       otherInfoRef.current.value = otherInfoDoc.data().otherInfo;
    //       alert("Other Information field succesfully updated!");
    //     } else {
    //       console.log("No such document!");
    //     }
    //   } catch (error) {
    //     console.error("Error fetching other info: ", error);
    //   }
    // };
    

    const handleUnhideAppointment = async () => {
      try {
          const updatedAppointmentData = {
              hidden: ""
          };
  
          const appointmentRef = doc(
              firestore,
              'accounts',
              userEmail,
              'physician',
              physician,
              'calendars',
              selectedCalendar,
              'appointments',
              appointmentId
          );
  
          await updateDoc(appointmentRef, updatedAppointmentData);
          // Provide feedback to the user
          alert('Appointment unhidden successfully.');
              handleCloseExistentAptModal();
              fetchEvents();
      } catch (error) {
          console.error("Error hiding appointment: ", error);
          // Provide error feedback to the user
          alert('Failed to hide appointment. Please try again later.');
      }
  }


    const handleHideAppointment = async () => {
      try {
          const updatedAppointmentData = {
              hidden: "true"
          };
  
          const appointmentRef = doc(
              firestore,
              'accounts',
              userEmail,
              'physician',
              physician,
              'calendars',
              selectedCalendar,
              'appointments',
              appointmentId
          );
  
          await updateDoc(appointmentRef, updatedAppointmentData);
          // Provide feedback to the user
          alert('Appointment hidden successfully!');
              handleCloseExistentAptModal();
              fetchEvents();
      } catch (error) {
          console.error("Error hiding appointment: ", error);
          // Provide error feedback to the user
          alert('Failed to hide appointment. Please try again later.');
      }
  }
  
    
    
    const handleDeleteAppointment = async () => {

      // Confirmation prompt
      if (window.confirm("Are you sure you want to permanently delete this appointment?")) {
            try {

              setIsInProcess(true);


        // Directly accessing the current values of the refs
        const newName = nameRef.current.value;
        const newFirstName = firstNameRef.current.value;
        const newMiddleName = middleNameRef.current.value;
        const newLastName = lastNameRef.current.value;
    
        // // Check for invalid characters in newName
        // const invalidCharactersRegex = /[^a-zA-Z\s]/;
        // if (invalidCharactersRegex.test(newName)) {
        //   alert("Invalid characters detected in name. Only regular letters and spaces are allowed.");
        //   return; // Stop the execution of the function if the name is invalid
        // }
    
        let newPhone; // Declare newPhone without initializing

        const originalPhone = phoneRef.current.value;
        console.log(originalPhone)
        const cleanedPhone = originalPhone.replace(/[\s()-\+]/g, '');
        console.log(cleanedPhone)

        if (cleanedPhone.length === 10) {
            // Update newPhone without re-declaration
            newPhone = "1" + cleanedPhone;
        } else if (cleanedPhone.length < 10 || cleanedPhone.length > 11 ) {
          setIsInProcess(false);
            alert("Invalid phone number. Please remember to include region and area code. Eg. 1787XXXXXXX");
            return; // Stop the execution of the function if the phone number is invalid
        } else {
            newPhone = cleanedPhone;
        }

        let newOtherInfo = '';
        let newOrInfo = '';
        let newPaDate = '';
        let newPaTime = ''; 

        if (showOrInfo === false) {
            newOtherInfo = otherInfoRef.current ? otherInfoRef.current.value : ''; // Assign value with safety check
        } else {
            newOrInfo = orInfoRef.current ? orInfoRef.current.value : ''; // Assign value with safety check
            newPaDate = paDateRef.current ? paDateRef.current.value : ''; // Assign value with safety check
            newPaTime = paTimeRef.current ? paTimeRef.current.value : ''; // Assign value with safety check
        }
    
        const newDate = dateRef.current.value;
        const newTime = moment(timeRef.current.value, "HH:mm").format("hh:mm A");
        const newEmail = emailRef.current.value;
        const newEncounterType = encounterTypeRef.current.value;
        const newAptSpecLabel = aptSpecLabelRef.current.value;
        // console.log(newAptSpecLabel);        
        // const newOtherInfo = otherInfoRef.current.value;
        const newAptLang = aptLangRef.current.value;
        const newDateOfBirth = dateOfBirthRef.current.value;
        // const newOrInfo = orInfoRef.current.value;
        // const newPaDate = paDateRef.current.value;
        // const newPaTime = paTimeRef.current.value;
    
        // Setting the appointment data without otherInfo
        const deletedAppointmentData = {
          name: newName,
          firstName: newFirstName,
          middleName: newMiddleName,
          lastName: newLastName,
          phone: newPhone,
          date: newDate,
          time: newTime,
          email: newEmail,
          encounterType: newEncounterType,
          aptSpecLabel: newAptSpecLabel,
          otherInfo: newOtherInfo,
          language: newAptLang,
          dateOfBirth: newDateOfBirth,
          orInfo: newOrInfo,
          paDate: newPaDate,
          paTime: newPaTime
        };

        // console.log("created deleted appt data!")


              const appointmentRef = doc(
                  firestore,
                  'accounts',
                  userEmail,
                  'physician',
                  physician,
                  'calendars',
                  selectedCalendar,
                  'appointments',
                  appointmentId
              );
      
              await deleteDoc(appointmentRef);

        // After successful update, record the event
        const docuId = selectedEvent.resource.id || selectedEvent.resource.docId || "";
        const action = `Deleted Appointment (ID: ${docuId})`;
        await recordEvent(deletedAppointmentData, action)
          .then(docuId => {
            console.log(`Successfully recorded user event.`);
          })
          .catch(error => {
            console.error("Error recording user event:", error);
          });

              setIsInProcess(false);
              alert('Appointment deleted successfully!');
              handleCloseExistentAptModal();
              fetchEvents();
          } catch (error) {
            setIsInProcess(false);
              console.error("Error deleting appointment: ", error);
              alert('Failed to delete appointment. Please try again later.');
          }
      } else {
          // User clicked 'Cancel', do nothing
          setIsInProcess(false);
          console.log('Appointment deletion cancelled.');
      }
  };
  
    


  const handleSaveOrUpdateEncounterType = async () => {
    // console.log("Starting encType update!")
    // Check if the name field is not empty
    if (!nameEncRef.current) {
        alert('The name field is empty. Please enter a name.');
        return;
    }

    const encounterData = {
        name: nameEncRef.current,
        category: categoryRef.current,
        color: colorEncTypeRef.current // Adding the color to the encounter data
    };

    setSavedNewEncounterType(false);

    // console.log("Encounter type data with color created, data: " + encounterData)

    if (selectedEncounterType) {
        // Update logic
        const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes', selectedEncounterType);
        await updateDoc(docRef, encounterData);
        document.getElementById('cancel-button').textContent = 'Close';
        document.getElementById('ecnType-update-button').textContent = 'Save';
        alert(`Encounter type ( ${nameEncRef.current} ) updated succesfully!`);
    } else {
        // Save logic with checking duplicate names
        const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes'));
        const querySnapshot = await getDocs(q);

        const doesNameExist = querySnapshot.docs.some(doc => 
            doc.data().name.toLowerCase() === nameEncRef.current.toLowerCase()
        );

        if (!doesNameExist) {
            const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes', nameEncRef.current);
            await setDoc(docRef, encounterData);
            // setSavedNewEncounterType(false);
            alert(`Encounter type ( ${nameEncRef.current} ) saved succesfully!`);
            nameEncRef.current = ''; // resetting the ref value
            categoryRef.current = ''; // resetting the ref value
            colorEncTypeRef.current = '#ffffff'; // resetting the color ref value

            fetchEncounterTypes();

            // Clear the selected encounter type and reset the input fields
            setSelectedEncounterType('');
            setNewEncounterType({ name: '', category: '', color: '#ffffff' });
            setSavedNewEncounterType(true);

            // document.getElementById('cancel-button').textContent = 'Close';
        } else {
            alert('An encounter type with the same name already exists.');
        }
    }
};

useEffect(() => {
  let timer;

  // Only run the effect if savedNewEncounterType is true
  if (savedNewEncounterType) {
    timer = setTimeout(() => {
      nameEncRef.current = ''; // Resetting the name ref value
      categoryRef.current = ''; // Resetting the category ref value
      colorEncTypeRef.current = '#ffffff'; // Resetting the color ref value

      const cancelButton = document.getElementById('cancel-button');
      if (cancelButton) {
        cancelButton.textContent = 'Close';
      }

      // Optionally, reset savedNewEncounterType to false after the effect runs
      // setSavedNewEncounterType(false);
    }, 1000); // Delay of 1 second (1000 milliseconds)
  }

  // Cleanup function
  return () => {
    if (timer) {
      clearTimeout(timer);
    }
  };
}, [savedNewEncounterType]); // Dependency array includes savedNewEncounterType




    //   const handleSaveOrUpdateEncounterType = async () => {
    //     // Check if the name field is not empty
    //     if (!nameEncRef.current) {
    //         alert('The name field is empty. Please enter a name.');
    //         return;
    //     }
    
    //     if (selectedEncounterType) {
    //         // Update logic
    //         const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', 'mainCalendar', 'encTypes', selectedEncounterType);
    //         await updateDoc(docRef, {
    //             name: nameEncRef.current,
    //             category: categoryRef.current
                
    //         });
    //     } else {
    //         // Save logic with checking duplicate names
    //         const q = query(collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', 'mainCalendar', 'encTypes'));
    //         const querySnapshot = await getDocs(q);
            
    //         const doesNameExist = querySnapshot.docs.some(doc => 
    //             doc.data().name.toLowerCase() === nameEncRef.current.toLowerCase()
    //         );
    
    //         if (!doesNameExist) {
    //             const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', 'mainCalendar', 'encTypes', nameEncRef.current);
    //             await setDoc(docRef, {
    //                 name: nameEncRef.current,
    //                 category: categoryRef.current
    //             });
    //             alert(`Encounter type ( ${nameEncRef.current} ) saved/updated succesfully!`);

    //             fetchEncounterTypes()

    //                 // Clear the selected encounter type and reset the input fields
    //                 setSelectedEncounterType('');
    //                 setNewEncounterType({ name: '', category: '' });
    //                 nameEncRef.current = ''; // resetting the ref value
    //                 categoryRef.current = ''; // resetting the ref value


    //         } else {
    //             alert('An encounter type with the same name already exists.');
    //         }
    //     }
    // };
    
    
    
    
    
    const handleDeleteEncounterType = async () => {
        if (selectedEncounterType) {
            try {
                // Get a reference to the document to delete
                const docRef = doc(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypes', selectedEncounterType);
                
                // Delete the document
                await deleteDoc(docRef);
                
                alert(`Encounter type ( ${nameEncRef.current} ) deleted succesfully!`);

                fetchEncounterTypes()

                // Clear the selected encounter type and reset the input fields
                setSelectedEncounterType('');
                setNewEncounterType({ name: '', category: '', color: '#ffffff' });
                nameEncRef.current = ''; // resetting the ref value
                colorEncTypeRef.current = '#ffffff';
                categoryRef.current = ''; // resetting the ref value

                

            } catch (error) {
                console.error("Error deleting document: ", error);
            }
        } else {
            alert('No encounter type selected for deletion.');
        }
    };
    

      // ----------------------------




    const getEventDialogContentHTML = (eventData) => {
      return `
          <h2 style="text-transform: uppercase; text-decoration: underline;">Appointment Notification Details:</h2>
          <table>
              <thead>
                  <tr>
                      <th></th>
                      <th></th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td style="vertical-align: top;">Patient Name:</td>
                      <td>${eventData.name || ' '}</td>
                  </tr>
                  <tr>
                  <td style={{ verticalAlign: "top" }}>Appointment Date:</td>
                  <td>
                      ${eventData.newDate 
                          ? moment(eventData.newDate).format('MMMM D, YYYY')
                          : ' '}
                  </td>
              </tr>
              
                  <tr>
                      <td style="vertical-align: top;">Email:</td>
                      <td>${eventData.email || ' '}</td>
                  </tr>
                  <tr>
                      <td style="vertical-align: top;">Confirmation Requested:</td>
                      <td>${(eventData.askConfirmation || 'No').toString()}</td>
                  </tr>
                  <tr>
                      <td style="vertical-align: top;">Text Message Confirmation Status:</td>
                      <td>${eventData.confirmBySms || '...pending'}</td>
                  </tr>
                  <tr>
                  <td style="vertical-align: top;">Voice Confirmation Status:</td>
                  <td>${eventData.confirmByVoice === 'noInput' ? 'no input' : eventData.confirmByVoice || 'no answer'}</td>
              </tr>
              
                  <tr>
                      <td style="vertical-align: top;">Email Confirmation Status:</td>
                      <td>${eventData.confirmByEmail || '...pending'}</td>
                  </tr>
                  <tr>
                  <td style="vertical-align: top;">Manual Confirmation:</td>
                  <td>${eventData.manualConfirm || 'No'}</td>
              </tr>
                  <tr>
                      <td style="vertical-align: top;">Notified By:</td>
                      <td>${eventData.currentUserData || ' '}</td>
                  </tr>
                  <tr>
                      <td style="vertical-align: top;">Encounter Type:</td>
                      <td>${eventData.encType || ' '}</td>
                  </tr>
                  <tr>
                      <td style="vertical-align: top;">Sent Message:</td>
                      <td>${eventData.finalMessage || ' '}</td>
                  </tr>
              </tbody>
          </table>
      `;
    };
    


const printEventDialog = () => {
  // console.log(groupedEvents[selectedGroupIndex][selectedEventIndex].resource)
  const eventData = groupedEvents[selectedGroupIndex][selectedEventIndex].resource;
  // console.log(eventData)
  const printWindow = window.open('', '_blank');
  const dialogContentHTML = getEventDialogContentHTML(eventData);
  printWindow.document.write('<pre>' + dialogContentHTML + '</pre>');
  printWindow.document.close();
  printWindow.print();
};

    const handleDrillDown = (date) => {
        setCurrentDate(date);
        setCurrentView('day');
    };
    



    const eventPropGetter = (event) => {
      // Get the group of events associated with the clicked event
      const eventGroup = groupedEvents.find(group => group[0].groupId === event.groupId);
      // console.log(eventGroup)
  
      let confirmedCount = 0;
      let cancelledCount = 0;
      let foundManualConfirm = false;
      let mConfirm;
  
      eventGroup.forEach(groupEvent => {
          if (foundManualConfirm) return;
  
          if (groupEvent.resource.manualConfirm === 'confirmed') {
              confirmedCount++;
              foundManualConfirm = true;
              mConfirm = 'confirmed'
          } else if (groupEvent.resource.manualConfirm === 'cancelled') {
              cancelledCount++;
              foundManualConfirm = true;
              mConfirm = 'cancelled'
          }
  
          ['confirmBySms', 'confirmByEmail', 'confirmByVoice'].forEach(prop => {
              if (groupEvent.resource[prop] === 'confirmed') confirmedCount++;
              if (groupEvent.resource[prop] === 'cancelled') cancelledCount++;
          });
      });
  
      // Default styling
      let backgroundColor;
      let symbol;
  
      if (mConfirm === "confirmed") {
          symbol = <CheckCircleOutlineIcon style={{color: showByColor ? "black" : "white", verticalAlign: 'middle'}}/>;
          backgroundColor = 'green';
      } else if (mConfirm === "cancelled") {
          symbol = <HighlightOffIcon style={{color: showByColor ? "black" : "white", verticalAlign: 'middle'}}/>;
          backgroundColor = 'red';
      } else {
          if (confirmedCount > 0 && cancelledCount === 0 && foundManualConfirm === false) {
              symbol = <CheckCircleOutlineIcon style={{color: showByColor ? "black" : "white", verticalAlign: 'middle'}}/>;
              backgroundColor = 'green';
          } else if (cancelledCount > 0 && confirmedCount === 0 && foundManualConfirm === false) {
              symbol = <HighlightOffIcon style={{color: showByColor ? "black" : "white", verticalAlign: 'middle'}}/>;
              backgroundColor = 'red';
          } else if (confirmedCount > 0 && cancelledCount > 0 && foundManualConfirm === false) {
              symbol = <HelpOutlineIcon style={{color: showByColor ? "black" : "white", verticalAlign: 'middle'}}/>;
              backgroundColor = 'purple';
          }
      }
  
      // Additional style for 'fake' events
      let additionalStyle = {};
      if (event.tag === "fake") {
          additionalStyle = {
              color: 'black',
              backgroundColor: 'white'
              // border: '2px solid black'
          };
      } else {
          // Apply the showByColor logic only if the event is not 'fake'
          if (showByColor) {
              let color = "grey"; // Default to dijon mustard color

              let encounterTypeLowerCase;

              if (event.tag === "appointment") {
                encounterTypeLowerCase = event.resource.encounterType ? event.resource.encounterType.toLowerCase() : "";
            } else {
                encounterTypeLowerCase = event.resource.encType ? event.resource.encType.toLowerCase() : "";
            }
            

              if (event.resource.encType || event.resource.encounterType) {
                  for (let type of encounterTypes) {
                      if (type.name.toLowerCase() === encounterTypeLowerCase) {
                          color = type.color;
                          break;
                      }
                  }
              }
              backgroundColor = color;
          } else {
              // Logic for setting backgroundColor based on manualConfirm status when showByColor is false
              // console.log("Keeping original colors!")
              // if (event.resource.manualConfirm === "confirmed") {
              //     backgroundColor = 'green';
              // } else if (event.resource.manualConfirm === "cancelled") {
              //     backgroundColor = 'red';
              // } else {
              //     if (confirmedCount > 0 && cancelledCount === 0) {
              //         backgroundColor = 'green';
              //     } else if (cancelledCount > 0 && confirmedCount === 0) {
              //         backgroundColor = 'red';
              //     } else if (confirmedCount > 0 && cancelledCount > 0) {
              //         backgroundColor = 'purple';
              //     }
              // }
          }
      }
  
      // Use backgroundColor, symbol, and additionalStyle as needed
      return { style: { ...{ backgroundColor }, ...additionalStyle }, symbol };
  }
  
//     const eventPropGetter = (event) => {
//         // Get the group of events associated with the clicked event
//         const eventGroup = groupedEvents.find(group => group[0].groupId === event.groupId);
        
//         let confirmedCount = 0;
//         let cancelledCount = 0;
        
//         // Iterate over each event in the group
//         let foundManualConfirm = false;

//         eventGroup.forEach(groupEvent => {
//             // Skip the rest of the events if manualConfirm was already found in any event
//             if (foundManualConfirm) return;
        
//             // Check if manualConfirm exists and has a value of 'confirmed' or 'cancelled'
//             if (groupEvent.resource.manualConfirm === 'confirmed') {
//                 confirmedCount++;
//                 foundManualConfirm = true;
//                 return; // Skip the rest of the logic for this groupEvent
//             } else if (groupEvent.resource.manualConfirm === 'cancelled') {
//                 cancelledCount++;
//                 foundManualConfirm = true;
//                 return; // Skip the rest of the logic for this groupEvent
//             }
        
//             // Existing logic for other confirmations
//             ['confirmBySms', 'confirmByEmail', 'confirmByVoice'].forEach(prop => {
//                 if (groupEvent.resource[prop] === 'confirmed') confirmedCount++;
//                 if (groupEvent.resource[prop] === 'cancelled') cancelledCount++;
//             });
//         });
        
//      // Assuming colorsMap is a state/context that stores the mapping of encounter types to their colors
// // For example: { "routine": "#FF5733", "emergency": "#33FF57", ... }
// // Replace 'dijonMustardColor' with the actual hexadecimal color code for dijon mustard

// const dijonMustardColor = "#D2B48C"; // Example color code for dijon mustard
// let backgroundColor;
// let symbol;

// // Set the symbol based on manualConfirm status
// if (event.resource.manualConfirm === "confirmed") {
//     symbol = <CheckCircleOutlineIcon style={{color: 'white', verticalAlign: 'middle'}}/>;
// } else if (event.resource.manualConfirm === "cancelled") {
//     symbol = <HighlightOffIcon style={{color: 'white', verticalAlign: 'middle'}}/>;
// } else {
//     if (confirmedCount > 0 && cancelledCount === 0) {
//         symbol = <CheckCircleOutlineIcon style={{color: 'white', verticalAlign: 'middle'}}/>;
//     } else if (cancelledCount > 0 && confirmedCount === 0) {
//         symbol = <HighlightOffIcon style={{color: 'white', verticalAlign: 'middle'}}/>;
//     } else if (confirmedCount > 0 && cancelledCount > 0) {
//         symbol = <HelpOutlineIcon style={{color: 'white', verticalAlign: 'middle'}}/>;
//     }
// }

// if (showByColor) {
//     // console.log(encounterTypes);
//     let color = dijonMustardColor; // Default to dijon mustard color

//     // Check if encType exists and is not undefined
//     if (event.resource.encType) {
//         const encounterTypeLowerCase = event.resource.encType.toLowerCase();

//         // Find the color by comparing in a case-insensitive manner
//         for (let type of encounterTypes) {
//             if (type.name.toLowerCase() === encounterTypeLowerCase) {
//                 color = type.color;
//                 break;
//             }
//         }
//     }

//     backgroundColor = color;
// } else {
//     // Set backgroundColor based on manualConfirm status
//     if (event.resource.manualConfirm === "confirmed") {
//         backgroundColor = 'green';
//     } else if (event.resource.manualConfirm === "cancelled") {
//         backgroundColor = 'red';
//     } else {
//         if (confirmedCount > 0 && cancelledCount === 0) {
//             backgroundColor = 'green';
//         } else if (cancelledCount > 0 && confirmedCount === 0) {
//             backgroundColor = 'red';
//         } else if (confirmedCount > 0 && cancelledCount > 0) {
//             backgroundColor = 'purple';
//         }
//     }
// }

// // Use backgroundColor and symbol as needed
// return { style: { backgroundColor }, symbol };

// }


// Now use backgroundColor and symbol as needed in your component
 
        
    //     let backgroundColor = null;
    //     let symbol = null;
        
    //     if (event.resource.manualConfirm === "confirmed") {
    //       backgroundColor = 'green';
    //       symbol = <CheckCircleOutlineIcon style={{color: 'white', verticalAlign: 'middle'}}/>;
    //   } else if (event.resource.manualConfirm === "cancelled") {
    //       backgroundColor = 'red';
    //       symbol = <HighlightOffIcon style={{color: 'white', verticalAlign: 'middle'}}/>;
    //   } else {
    //       // Now, check the counts only if manualConfirm is not defined
    //       if (confirmedCount > 0 && cancelledCount === 0) {
    //           backgroundColor = 'green';
    //           symbol = <CheckCircleOutlineIcon style={{color: 'white', verticalAlign: 'middle'}}/>;
    //       } else if (cancelledCount > 0 && confirmedCount === 0) {
    //           backgroundColor = 'red';
    //           symbol = <HighlightOffIcon style={{color: 'white', verticalAlign: 'middle'}}/>;
    //       } else if (confirmedCount > 0 && cancelledCount > 0) {
    //           backgroundColor = 'purple';
    //           symbol = <HelpOutlineIcon style={{color: 'white', verticalAlign: 'middle'}}/>;
    //       }
    //   }
        
    //     return { style: { backgroundColor }, symbol };
    // };

    // console.log(encTypeView)

    
    const CustomEvent = ({ event, showAllEvents, showConfirmed, triggerRender, onEventRightClick, encTypeView }) => {
      // Check if the event should be hidden, considering the showAllEvents flag
      // console.log(encTypeFilter)

    //   if (event.start === '2024-09-11') {
    //     console.log(event);
    // }
    

      const displayedName = (showAllEvents === true) ? event.resource.allEventsName :
                      (encTypeView === true && encTypeFilter !== 'No Filter') ? event.resource.basicName + ` - Count: ${event.encTypeCountTag}` : 
                      (showConfirmed ? event.resource.onlyConfirmedName : event.resource.name);


      // const displayedName = (encTypeView === true && encTypeFilter !== (['No Filter'])) ? event.resource.basicName + ` - Count: ${event.encTypeCountTag}` : 
      // (showConfirmed ? event.resource.onlyConfirmedName : event.resource.name);


      // const displayedName = encTypeView ? event.resource.basicName + ` - Count: ${}` : (showConfirmed ? event.resource.onlyConfirmedName : event.resource.name);

      // const displayedName = showConfirmed ? event.resource.onlyConfirmedName : event.resource.name;

      if (showConfirmed) {
        // Get the group of events associated with the clicked event
        const eventGroup = groupedEvents.find(group => group[0].groupId === event.groupId);

        // Check if any event in the group meets the criteria
        const shouldDisplay = eventGroup.some(groupEvent => 
            groupEvent.resource.askConfirmation === true || groupEvent.tag === "appointment" && groupEvent.resource.askConfirmation === true || groupEvent.tag === "fake"
        );

        if (!shouldDisplay) {
            return null; // Skip rendering this event
        }
    }
    

      if (event.resource.hidden && !showAllEvents) {
          return null;  // Do not render this event if hidden and showAllEvents is not active
      }

 

      // console.log(encTypeView)

let fColor = 'white'

if (showByColor === true) {
    // console.log(encTypeView)
    fColor = 'black';
} else {
  fColor  = 'white';
}

const textStyle = {
  color: fColor
};
  
      const eventProps = eventPropGetter(event);
  
      const handleRightClick = (e) => {
         console.log("Right click event: ", event);
          e.preventDefault(); // Prevents default context menu
          onEventRightClick(e, event);
      };


      const eventGroup = groupedEvents.find(group => group[0].groupId === event.groupId);
  
      let confirmedCount = 0;
      let cancelledCount = 0;
      let foundManualConfirm = false;
  
      eventGroup.forEach(groupEvent => {
          if (foundManualConfirm) return;
  
          if (groupEvent.resource.manualConfirm === 'confirmed') {
              confirmedCount++;
              foundManualConfirm = true;
          } else if (groupEvent.resource.manualConfirm === 'cancelled') {
              cancelledCount++;
              foundManualConfirm = true;
          }
  
          ['confirmBySms', 'confirmByEmail', 'confirmByVoice'].forEach(prop => {
              if (groupEvent.resource[prop] === 'confirmed') confirmedCount++;
              if (groupEvent.resource[prop] === 'cancelled') cancelledCount++;
          });
      });
  
  
      return (

<div 
    style={{ ...eventProps.style }}
    onContextMenu={handleRightClick}
>
    <div style={{ paddingTop: event.isFirstEventOfDay ? '20px' : '0px' }}>
        <Typography style={{ margin: '5px 0', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {event.tag === "fake" ? (
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <strong>{displayedName}</strong>
                </div>                  
            ) : (
                <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                { foundManualConfirm === true && 
                  <span style={{ color: fColor, marginRight: '5px' }}>*</span>
                }
                    {event.resource.hidden === "true" && <VisibilityOffIcon style={{ color: showByColor ? "black" : "white", marginRight: '5px' }} />} 
                    {event.tag === "appointment" && <EventNoteIcon style={{ color: showByColor ? "black" : "white", marginRight: '5px' }} />} 
                    <strong style={textStyle}>
    {event.time} - {((event.resource.firstName || event.resource.lastName)
    ? ((event.resource.firstName || "") + " " + (
        event.resource.middleName 
          ? event.resource.middleName[0].toUpperCase() + "."
          : ""
      ) + " " + (event.resource.lastName || ""))
    : event.resource.name)} - {`${event.resource.encType || event.resource.encounterType ? (event.resource.encType || event.resource.encounterType).toUpperCase() : ''} ( Notified: ${event.tagNumber} )`} {(event.resource.aptSpecLabel && event.resource.aptSpecLabel !== "deletedLabel") ? `\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0*** ${event.resource.aptSpecLabel} ***` : ''}



                    </strong>
                </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        {event.resource.noShow === true && (
                            <div style={{ backgroundColor: 'white', color: 'black', padding: '0 5px', marginRight: '5%' }}>
                                NO SHOW
                            </div>
                        )}
                        {eventProps.symbol}
                    </div>
                </>
            )}
        </Typography>
    </div>
</div>

    
      );
  };


  useEffect(() => {
    if (calendars && calendars.length > 0) {
      const sortedCalendars = calendars.sort((a, b) => a.name.localeCompare(b.name));
      setSelectedCalendar(sortedCalendars[0].docId);
    }
  }, [calendars]);

  const handleCalendarChange = (event) => {
    const selectedDocId = calendars.find(calendar => calendar.name === event.target.value).docId;
    setSelectedCalendar(selectedDocId);
  };
   
   
  
  useEffect(() => {
    let newState = [];

    switch (selectedCalendarView) {
      case 'All':
        newState = [];
        // For "All", newState remains an empty array
        break;

      case 'Regular Appointment':
        // Filter encounterTypes for "Regular Appointment"
        newState = encounterTypes
          .filter(et => et.category === 'Regular Appointment')
          .map(et => et.name);
        break;

      case 'Procedure Appointment':
        // Filter encounterTypes for "Procedure Appointment"
        newState = encounterTypes
          .filter(et => et.category === 'Procedure Appointment')
          .map(et => et.name);
        break;

      default:
        // Handle any other cases if necessary
    }

    // console.log(newState)
    // setEncTypeFilter(newState)
    applyFilters(newState); // Call applyFilters with the new state
  }, [selectedCalendarView]);

  const handleCalendarViewChange = (event) => {
    setSelectedCalendarView(event.target.value);
    // console.log(encounterTypes)
  };
//   useEffect(() => {
//     if (encTypeView) {
//         console.log(encTypeView);
//         setFColor('black');
//     }
// }, [encTypeView]);

  //   const CustomEvent = ({ event, triggerRender, onEventRightClick }) => {
  //     // Check if the event should be hidden
  //     if (event.resource.hidden) {
  //         return null;  // Do not render this event
  //     }
  
  //     const eventProps = eventPropGetter(event);
  
  //     const handleRightClick = (e) => {
  //         e.preventDefault(); // Prevents default context menu
  //         onEventRightClick(e, event);
  //     };
  
  //     return (
  //         <div 
  //             style={{ ...eventProps.style }}
  //             onContextMenu={handleRightClick}
  //         >
  //             <div style={{ paddingTop: event.isFirstEventOfDay ? '20px' : '0px' }}>
  //                 <Typography style={{ margin: '5px 0', display: 'flex', alignItems: 'center' }}>
  //                     {event.tag === "appointment" && <EventNoteIcon style={{ color: 'white', marginRight: '5px' }} />} 
  //                     <strong>
  //                         {event.time} - {event.resource.name}
  //                     </strong>
  //                     <span> 
  //                         (
  //                         {event.resource.phone}/
  //                         {event.resource.email}/
  //                         <strong>{event.resource.encType ? event.resource.encType : ''}</strong>/
  //                         x {event.tagNumber}
  //                         )
  //                     </span>
  //                     {"  "} {eventProps.symbol}       
  //                 </Typography>
  //             </div>
  //         </div>
  //     );
  // };
  


  //   const CustomEvent = ({ event, triggerRender, onEventRightClick }) => {
  //     const eventProps = eventPropGetter(event);
  
  //     const handleRightClick = (e) => {
  //         e.preventDefault(); // Prevents default context menu
  //         onEventRightClick(e, event);
  //     };
  
  //     return (
  //         <div 
  //             style={{ ...eventProps.style }}
  //             onContextMenu={handleRightClick}
  //         >
  //             <div style={{ paddingTop: event.isFirstEventOfDay ? '20px' : '0px' }}>
  //                 <Typography style={{ margin: '5px 0', display: 'flex', alignItems: 'center' }}>
  //                     {event.tag === "appointment" && <EventNoteIcon style={{ color: 'white', marginRight: '5px' }} />} 
  //                     <strong>
  //                         {event.time} - {event.resource.name}
  //                     </strong>
  //                     <span> 
  //                         (
  //                         {event.resource.phone}/
  //                         {event.resource.email}/
  //                         <strong>{event.resource.encType ? event.resource.encType : ''}</strong>/
  //                         x {event.tagNumber}
  //                         )
  //                     </span>
  //                     {"  "} {eventProps.symbol}       
  //                 </Typography>
  //             </div>
  //         </div>
  //     );
  // };

    
    // const CustomEvent = ({ event, triggerRender }) => {
    //     // This function receives the event and the triggerRender as props
    //     // triggerRender is not used directly in the function, but it facilitates the component's re-rendering
    //     const eventProps = eventPropGetter(event);
    
    //     return (
    //         <div style={{ 
    //             background: eventProps.backgroundColor, // Assuming eventProps contains the background color
    //             // Other styles you want to apply to the outer div
    //         }}>
    //             <div style={{ 
    //                 paddingTop: event.isFirstEventOfDay ? '20px' : '0px', // Apply additional space if it's the first event
    //             }}>
    //                 <Typography style={{ margin: '5px 0', display: 'flex', alignItems: 'center' }}>
    //                     {event.tag === "appointment" && <EventNoteIcon style={{ color: 'white', marginRight: '5px' }} />} 
    //                     <strong>
    //                         {event.time} - {event.resource.name}
    //                     </strong>
    //                     <span> 
    //                         (
    //                         {event.resource.phone}/
    //                         {event.resource.email}/
    //                         <strong>{event.resource.encType ? event.resource.encType : ''}</strong>/
    //                         x {event.tagNumber} {/* Displaying the tagNumber here */}
    //                         )
    //                     </span>
    //                     {"  "} {eventProps.symbol}       
    //                 </Typography>
    //             </div>
    //         </div>
    //     );
    // };
    
    


 
    
    
    
    function splitString(str, maxLength) {
      str = (str == null) ? '' : str.toString();
      let parts = [];
      let currentPart = '';
  
      str.split(' ').forEach(word => {
          if ((currentPart + word).length > maxLength) {
              parts.push(currentPart.trim());
              currentPart = word + ' ';
          } else {
              currentPart += word + ' ';
          }
      });
  
      if (currentPart) {
          parts.push(currentPart.trim());
      }
  
      return parts;
  }
  
  function padString(str, length, align = 'left') {
    str = str || '';
    if (str.length > length) {
        // If the string is longer than the desired length, trim it
        str = str.substring(0, length);
    }
    let padding = ' '.repeat(length - str.length);
    return align === 'left' ? padding + str : str + padding;
}

  
function formatEvent(event, columnWidths) {
  let lines = [];
  let columns = [
      splitString(event.time, columnWidths[0]),
      splitString(event.resource.name, columnWidths[1]),
      splitString(event.resource.phone || event.resource["phone 2"], columnWidths[2]),
      splitString(event.resource.email, columnWidths[3]),
      splitString(event.resource.encounterType || event.resource.encType, columnWidths[4]),
      splitString(event.status, columnWidths[5])
  ];

  let maxLines = Math.max(...columns.map(col => col.length));

  for (let i = 0; i < maxLines; i++) {
      lines.push(
          columns.map((col, index) => padString(col[i] || '', columnWidths[index], 'left')).join('  ')
      );
  }

  return lines.join('\n');
}

  
  const handlePrint = () => {
    alert("Hidden events will not be printed!")
    // console.log(currentDate)
    const printableEvents = events.filter(event => {
      // Convert event.start to a Date object if necessary
      const eventStartDate = moment(event.start, "YYYY-MM-DD").format("ddd MMM DD YYYY");

      // const eventStartDate = event.start instanceof Date ? event.start : 
      //                        moment.isMoment(event.start) ? event.start.toDate() : 
      //                        new Date(event.start);
      // console.log(eventStartDate.toDateString());
      // console.log(currentDate)

      return eventStartDate === currentDate.toDateString() && !event.resource.hidden;
  });

  console.log(currentDate.toDateString());

    console.log(printableEvents)
  
    let printContent = `Events for ${currentDate.toDateString()}:\n\n`;

    let columnWidths = [10, 20, 15, 25, 10, 10]; // Adjust column widths as needed
    const totalWidth = columnWidths.reduce((a, b) => a + b) + (columnWidths.length - 1) * 2; // Calculate total width
    const separatorLine = '-'.repeat(totalWidth); // Create the separator line

    // Define the header
    const headerTitles = ["TIME", "NAME", "PHONE", "EMAIL", "ENC. TYPE", "STATUS"];
    const header = headerTitles.map((title, index) => padString(title, columnWidths[index], 'left')).join('  ');
    printContent += header + '\n' + separatorLine + '\n\n'; // Add the header and separator line at the top

    printableEvents.forEach((event, index) => {
        let backgroundColor = eventPropGetter(event).style.backgroundColor;
        let status = '';
        if (backgroundColor === 'green') status = "CONFIRMED";
        else if (backgroundColor === 'red') status = "CANCELLED";
        else if (backgroundColor === 'purple') status = "UNCLEAR";
        status = backgroundColor === 'green' || 'red' || 'purple' ? ` ${status}` : '';

        event.status = status;  // Set the status for the event

        printContent += formatEvent(event, columnWidths);  // Format the event
        printContent += '\n' + separatorLine + '\n\n';  // Add separator line after each event
    });

    const printWindow = window.open('', '_blank');
    printWindow.document.write('<pre>' + printContent + '</pre>');
    printWindow.document.close();
    printWindow.print();
};


  

  

    // -----------------------

    const handleUserPopoverOpen = (event) => {
        event.persist();
        setAnchorPosition({ top: event.clientY, left: event.clientX });
        setUserAnchorEl(event.currentTarget);
      };
      
      const handleEncTypePopoverOpen = (event) => {
        event.persist();
        setAnchorPosition({ top: event.clientY, left: event.clientX });
        setEncTypeAnchorEl(event.currentTarget);
      };
      
      const handleUserPopoverClose = () => {
        setUserAnchorEl(null);
      };

      
      const handleEncTypePopoverClose = () => {
        setEncTypeAnchorEl(null);
      };

      const handleUserMenuClose = () => {
        setUserAnchorEl(null);
      };

      
      const handleEncTypeMenuClose = () => {
        setEncTypeAnchorEl(null);
      };

      const handleUserFilterChange = (user) => {
        let newUserFilter = [...userFilter];
    
        if (user === 'No Filter') {
            newUserFilter = ['No Filter'];
        } else {
            if (newUserFilter.includes('No Filter')) {
                newUserFilter = [user];
            } else if (newUserFilter.includes(user)) {
                newUserFilter = newUserFilter.filter(filter => filter !== user);
            } else {
                newUserFilter.push(user);
            }
        }
    
        setUserFilter(newUserFilter);
    };

    const handleEncTypeFilterChange = (encType) => {
        let newEncTypeFilter = [...encTypeFilterRef.current];
        
        if (encType === 'No Filter') {
            newEncTypeFilter = ['No Filter'];
        } else {
            if (newEncTypeFilter.includes('No Filter')) {
                newEncTypeFilter = [encType];
            } else if (newEncTypeFilter.includes(encType)) {
                newEncTypeFilter = newEncTypeFilter.filter(filter => filter !== encType);
            } else {
                newEncTypeFilter.push(encType);
            }
        }
        
        encTypeFilterRef.current = newEncTypeFilter; // Update the ref, not the state
        // forceUpdate(); // Force a re-render
    };
    
      
    const handleFinalEncTypeFilterChange = () => {
        setEncTypeFilter(prevState => {
            const newState = encTypeFilterRef.current; // Getting the new state from the ref
            // console.log("newState: "  + newState)
            applyFilters(newState); // Applying the filters with the new state
            return newState; // Returning the new state to update encTypeFilter
        });
    };
    

    const EncTypeMenuItems = ({ encTypeFilterRef, handleEncTypeFilterChange }) => {
      const [, forceUpdate] = useReducer(x => x + 1, 0);
  
      // Function to check if the encounter type is checked
      const isChecked = (encType) => {
          // Normalize the encounter type to lowercase before checking
          return encTypeFilterRef.current.map(type => type.toLowerCase()).includes(encType.toLowerCase());
      };
  
      return (
          <>
              <MenuItem value="No Filter" onClick={() => { handleEncTypeFilterChange('No Filter'); forceUpdate(); }}>
                  <Checkbox checked={isChecked('No Filter')} />
                  No Filter
              </MenuItem>
              {extractEncTypesFromEvents(events, currentView).map((encType, i) => (
                  <MenuItem key={i} onClick={() => {
                      // Normalize the encounter type to lowercase when adding to filter
                      handleEncTypeFilterChange(encType.toLowerCase()); 
                      forceUpdate();
                  }}>
                      <Checkbox checked={isChecked(encType)} />
                      {encType}  {/* Display the original casing of encounter type */}
                  </MenuItem>
              ))}
          </>
      );
  };
  

    // const EncTypeMenuItems = ({ encTypeFilterRef, handleEncTypeFilterChange }) => {
    //     const [, forceUpdate] = useReducer(x => x + 1, 0);
    
    //     const isChecked = (encType) => {
    //         return encTypeFilterRef.current.includes(encType);
    //     };
    
    //     return (
    //         <>
    //             <MenuItem value="No Filter" onClick={() => { handleEncTypeFilterChange('No Filter'); forceUpdate(); }}>
    //                 <Checkbox checked={isChecked('No Filter')} />
    //                 No Filter
    //             </MenuItem>
    //             {extractEncTypesFromEvents(events, currentView).map((encType, i) => (
    //                 <MenuItem key={i} onClick={() => { handleEncTypeFilterChange(encType); forceUpdate(); }}>
    //                     <Checkbox checked={isChecked(encType)} />
    //                     {encType}
    //                 </MenuItem>
    //             ))}
    //         </>
    //     );
    // };
    


    const handleRescheduleLinkButton = async () => {
      const userConfirmed = window.confirm("Press OK if you want to send a reschedule link to the client for this appointment.");
    
      setItemsBeingProcessed(0)

      if (userConfirmed) {
        try {
          const response = await axios.post('https://notifypro.herokuapp.com/reschedule-link', {
            index: appointmentId,
            accountEmail: userEmail,
            providerName: physician,
            selectedCalendar: selectedCalendar,
            language: aptLang ?? aptLangRef.current,
          });

          const originDate = new Date()

          const formattedDate = new Date(originDate).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        
        const formattedTime = new Date(originDate).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });



    const xxEmail = false;
    const xxSms = true;
    const xxVoice = false;
    const askConfirmation = false;
    const currentUserData = `${currentActiveUser} sent rLINK on ${formattedDate} at ${formattedTime}.`;
    const clientsNumber = 1;
    const endName = "";
    const calledFromCalendar = true;
    const calName = selectedCalendar;
    


            const message = response.data.message;
            const messageV = response.data.message;
            const messageEng = response.data.message;
            const messageVEng = response.data. message;



            makeApiCall(phone, email, message, messageV, messageEng, messageVEng, name, date, date, time, xxSms, xxEmail, xxVoice, aptLang, originDate, userEmail, endName, askConfirmation, currentUserData, encounterType, clientsNumber, calledFromCalendar, calName)


          console.log('Response:', response.data);
        } catch (error) {
          console.error('Error sending POST request:', error);
        }
      }
    };
    
    

    const handleOrInfoChange = (event) => {
      setShowOrInfo(event.target.checked);
    };

    const CustomDateCellWrapper = ({ children, value, events, view, showAllEvents, showConfirmed, datesAndDayNamesState }) => {
  // Filter events for the specific day
  const dayEvents = events.filter(event => {
    if (showAllEvents) {
      return moment(event.start).isSame(value, 'day') && event.tag !== "fake";
    } else {
      return moment(event.start).isSame(value, 'day') && 
             (!event.resource.hidden || event.resource.hidden === false) && 
             event.tag !== "fake";
    }
  });

  let displayCount = dayEvents.length;
  
  if (showConfirmed) {
    // Create a set to keep track of confirmed groups
    const confirmedGroups = new Set();

    // Iterate over groupedEvents to determine the confirmation status of each group
    // Note: Ensure 'groupedEvents' is defined or passed as a prop if used
    groupedEvents.forEach(group => {
      group.forEach(event => {
        if ((moment(event.start).isSame(value, 'day')) && 
            (event.resource.askConfirmation === true || event.tag === "appointment")) {
          confirmedGroups.add(event.groupId);
        }
      });
    });

    // Count the number of confirmed groups
    displayCount = confirmedGroups.size;
  }

  // Apply a very light blue background for weekends
  const isWeekend = value.getDay() === 0 || value.getDay() === 6;
  const weekendStyle = isWeekend ? { backgroundColor: '#E6F7FF' } : {};

// Find matching date in datesAndDayNamesState
const matchingDate = datesAndDayNamesState && datesAndDayNamesState.length > 0
  ? datesAndDayNamesState.find(item => {
      // console.log('Item date:', item.date);
      const stateDate = moment(item.date, 'YYYY-MM-DD');
      const cellDate = moment(value);
      
      // console.log('Comparing dates:');
      // console.log('State date:', stateDate.format('YYYY-MM-DD'));
      // console.log('Cell date:', cellDate.format('YYYY-MM-DD'));
      
      return stateDate.isSame(cellDate, 'day');
    })
  : null;

const dayName = matchingDate ? matchingDate.dayName : '';

// console.log('Matching date found:', matchingDate);
// console.log('Day name:', dayName);

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%', ...weekendStyle }}>
      {dayEvents.length > 0 && (
        <div style={{
          position: 'absolute',
          top: view === 'month' ? '20px' : '0px',
          left: 0,
          backgroundColor: 'black',
          color: 'white',
          width: '100%',
          textAlign: 'center',
          zIndex: 1000,
          pointerEvents: 'none',
        }}>
          Total: {displayCount}{dayName && ` - ${dayName}`}
        </div>
      )}

      {/* Render the updated cell contents */}
      {/* {updatedChildren} */}
    </div>
  );
};

//working before label...
  //   const CustomDateCellWrapper = ({ children, value, events, view, showAllEvents, showConfirmed }) => {
  //     // Filter events for the specific day
  //     const dayEvents = events.filter(event => {
  //       if (showAllEvents) {
  //           return moment(event.start).isSame(value, 'day') && event.tag !== "fake";
  //       } else {
  //           return moment(event.start).isSame(value, 'day') && 
  //                  (!event.resource.hidden || event.resource.hidden === false) && 
  //                  event.tag !== "fake";
  //       }
  //   });


    
  
  //     let displayCount = dayEvents.length;
      
  //     if (showConfirmed) {
  //         // Create a set to keep track of confirmed groups
  //         const confirmedGroups = new Set();
  
  //         // Iterate over groupedEvents to determine the confirmation status of each group
  //         // Note: Ensure 'groupedEvents' is defined or passed as a prop if used
  //         groupedEvents.forEach(group => {
  //             group.forEach(event => {
  //                 if ((moment(event.start).isSame(value, 'day')) && 
  //                     (event.resource.askConfirmation === true || event.tag === "appointment")) {
  //                     confirmedGroups.add(event.groupId);
  //                 }
  //             });
  //         });
  
  //         // Count the number of confirmed groups
  //         displayCount = confirmedGroups.size;
  //     }
  
  //     // Apply a very light blue background for weekends
  //     const isWeekend = value.getDay() === 0 || value.getDay() === 6;
  //     const weekendStyle = isWeekend ? { backgroundColor: '#E6F7FF' } : {};
  

  
  //     return (
  //         <div style={{ position: 'relative', width: '100%', height: '100%', ...weekendStyle }}>
  //             {/* Display the adjusted count of events */}
  //             {dayEvents.length > 0 && (
  //                 <div style={{
  //                     position: 'absolute',
  //                     top: view === 'month' ? '20px' : '0px',
  //                     left: 0,
  //                     backgroundColor: 'black',
  //                     color: 'white',
  //                     width: '100%',
  //                     textAlign: 'center',
  //                     zIndex: 1000,
  //                     pointerEvents: 'none',
  //                 }}>
  //                     Total: {displayCount}
  //                 </div>
  //             )}
  
  //             {/* Render the updated cell contents */}
  //             {/* {updatedChildren} */}
  //         </div>
  //     );
  // };
    
  //   const CustomDateCellWrapper = ({ children, value, events, view, showAllEvents, showConfirmed }) => {
  //     // Filter events for the specific day
  //     const dayEvents = events.filter(event => {
  //       if (showAllEvents) {
  //           return moment(event.start).isSame(value, 'day');
  //       } else {
  //           return moment(event.start).isSame(value, 'day') && 
  //                  (!event.resource.hidden || event.resource.hidden === false);
  //       }
  //   });
    
  //   let displayCount = dayEvents.length;
    
  //   if (showConfirmed) {
  //       // Create a set to keep track of confirmed groups
  //       const confirmedGroups = new Set();
    
  //       // Iterate over groupedEvents to determine the confirmation status of each group
  //       groupedEvents.forEach(group => {
  //           group.forEach(event => {
  //               if ((moment(event.start).isSame(value, 'day')) && 
  //                   (event.resource.askConfirmation === true || event.tag === "appointment")) {
  //                   confirmedGroups.add(event.groupId);
  //               }
  //           });
  //       });
    
  //       // Count the number of confirmed groups
  //       displayCount = confirmedGroups.size;
  //   }
    
  
  //     return (
  //         <div style={{ position: 'relative', width: '100%', height: '100%' }}>
  //             {/* Display the adjusted count of events */}
  //             {dayEvents.length > 0 && (
  //                 <div style={{
  //                     position: 'absolute',
  //                     top: view === 'month' ? '20px' : '0px',
  //                     left: 0,
  //                     backgroundColor: 'black',
  //                     color: 'white',
  //                     width: '100%',
  //                     textAlign: 'center',
  //                     zIndex: 1000,
  //                     pointerEvents: 'none',
  //                 }}>
  //                     Total: {displayCount}
  //                 </div>
  //             )}
  
  //             {/* Render the original cell contents */}
  //             {children}
  //         </div>
  //     );
  // };
  
// useEffect(() => {

//         // Helper function to determine if the target or any of its ancestors is a button
//         const isEventTargetButton = (target) => {
//           while (target != null) {
//               if (target.tagName === 'BUTTON') return true; // Checks if the element is a button
//               target = target.parentNode; // Move up in the DOM tree
//           }
//           return false;
//       };

//     // Function to detect outside clicks and hide the patient list
//     const handleClickOutside = (event) => {
//         if (patientListRef.current && !patientListRef.current.contains(event.target) &&
//             nameAptSearchRef.current && !nameAptSearchRef.current.contains(event.target)) {
//               let searchName = nameAptSearchRef.current.value;
//               let searchPhone = phoneAptSearchRef.current.value;
//               let searchFromDate = fromDateRef.current.value;
//               let searchToDate = toDateRef.current.value;
        
              
//               // Extract the last 10 digits for the searchPhone
//               searchPhone = searchPhone.replace(/\D/g, ''); // Remove non-digit characters
//               if (searchPhone.length > 10) {
//                   searchPhone = searchPhone.slice(-10);
//               }
        
//               const searchDateOfBirth = dateOfBirthAptSearch
        
//               setNameAptSearch(searchName);
//               // console.log(searchName)
            
            
//               setPhoneAptSearch(searchPhone);
//               // console.log(searchPhone)
            
            
//             setFromDate(searchFromDate);
//             // console.log(searchFromDate)
            
//             setToDate(searchToDate);
//             // console.log(searchToDate)
//               setIsResultsVisible(false);
//         }
//     };

//     document.addEventListener('mousedown', handleClickOutside);
//     return () => {
//         document.removeEventListener('mousedown', handleClickOutside);
//     };
// }, []);

useEffect(() => {
  // Helper function to determine if the target or any of its ancestors is a button
  const isEventTargetButton = (target) => {
    while (target != null) {
        // Checks if the element is a button
        if (target.tagName === 'BUTTON' || target.id === 'cancelSearchModalIcon') return true;
  
        // Avoids text fields and date pickers
        if (target.tagName === 'INPUT' && (target.type === 'text' || target.type === 'date')) return false;
  
        target = target.parentNode; // Move up in the DOM tree
    }
    return false;
  };
  

  const handleClickOutside = (event) => {
    // Check if results are visible; if they are, do not proceed.
    if (!isResultsVisible) return;

    if (isResultsVisible) {
    setIsResultsVisible(false)
    return;
    }

    // Early return if the click is on a button or originated from a button
    if (isEventTargetButton(event.target)) return;

    // Proceed if the click is outside the specified references
    if (patientListRef.current && !patientListRef.current.contains(event.target) &&
        nameAptSearchRef.current && !nameAptSearchRef.current.contains(event.target)) {
        let searchName = nameAptSearchRef.current.value;
        let searchPhone = phoneAptSearchRef.current.value;
        let searchFromDate = fromDateRef.current.value;
        let searchToDate = toDateRef.current.value;

        // Clean up the phone number by extracting the last 10 digits.
        searchPhone = searchPhone.replace(/\D/g, ''); // Remove non-digit characters
        if (searchPhone.length > 10) {
            searchPhone = searchPhone.slice(-10);
        }

        // Update states or variables based on the extracted values
        setNameAptSearch(searchName);
        setPhoneAptSearch(searchPhone);
        setFromDate(searchFromDate);
        setToDate(searchToDate);
        setIsResultsVisible(false); // Assuming you might want to hide results after this action
    }
};


  document.addEventListener('mousedown', handleClickOutside);
  return () => {
      document.removeEventListener('mousedown', handleClickOutside);
  };
}, []); // Dependencies array is empty, so this effect runs only once on mount



    
    const handleChangeAndKeyDown = (e) => {
      handleSearchChange(e);
      handleManualDivUpdate();
  };

  const handleChangeAndKeyDownAptSearch = (e) => {
    handleSearchChangeAptSearch(e);
    handleManualDivUpdateAptSearch();
};

    const handleManualDivUpdate = () => {
      // Manually update the container's inner HTML
      updatePatientList();
  };

  const handleManualDivUpdateAptSearch = () => {
    // Manually update the container's inner HTML
    updatePatientListAptSearch();
};
    
  const updatePatientList = () => {
    if (patientListRef.current) {
        let content = '<div class="search-results-container">'; // Start container
        for (let patient of searchResultRef.current) {
            content += `<div class="search-result-item" onClick="window.handlePatientSelect('${patient.phoneNumber}')">
                            ${patient.firstName} ${patient.lastName} - DOB: ${patient.dateOfBirth} - Tel: ${patient.phoneNumber}
                        </div>`;
        }
        content += '</div>'; // Close container
        patientListRef.current.innerHTML = content;
    }
};

const updatePatientListAptSearch = () => {
  if (patientListRef.current) {
      let content = '<div class="search-results-container">'; // Start container
      for (let patient of searchResultRef.current) {
          content += `<div class="search-result-item" onClick="window.handlePatientSelectAptSearch('${patient.phoneNumber}')">
          ${patient.firstName} ${patient.lastName} - DOB: ${patient.dateOfBirth} - Tel: ${patient.phoneNumber}
                      </div>`;
      }
      content += '</div>'; // Close container
      patientListRef.current.innerHTML = content;
  }
};



  const handleSearchChange = async (e) => {
      const value = e.target.value;
      if (value.trim() !== '') {
          try {
              const response = await fetch(`https://nproapiserver.herokuapp.com/searchClients?term=${value}&accountEmail=${userEmail}&providerName=${physician}`);
              const data = await response.json();
              searchResultRef.current = data;
              handleManualDivUpdate();
          } catch (error) {
              console.error("Error fetching search results", error);
              handleManualDivUpdate();
          }
      } else {
          searchResultRef.current = [];
          handleManualDivUpdate();
      }
  };

  const handleSearchChangeAptSearch = async (e) => {
    const value = e.target.value;
    if (value.trim() !== '') {
        try {
            const response = await fetch(`https://nproapiserver.herokuapp.com/searchClients?term=${value}&accountEmail=${userEmail}&providerName=${physician}`);
            const data = await response.json();
            searchResultRef.current = data;
            handleManualDivUpdateAptSearch();
        } catch (error) {
            console.error("Error fetching search results", error);
            handleManualDivUpdateAptSearch();
        }
    } else {
        searchResultRef.current = [];
        handleManualDivUpdateAptSearch();
    }
};
  


  window.handlePatientSelect = async (phoneNumber) => {


    // const newName = nameRef.current.value;
    // const newPhone = phoneRef.current.value;
    const newDate = dateRef.current.value;
    const newTime = timeRef.current.value;
    // const newEmail = emailRef.current.value;
    const newEncounterType = encounterTypeRef.current.value;
    // const newAptSpecLabel = aptSpecLabelRef.current.value;
    // const newAptLang = aptLangRef.current.value;
    // const newDateOfBirth = dateOfBirthRef.current.value;

  //   let newOtherInfo
  //   let newOrInfo
  //   let newPaDate
  //   let newPaTime
  //   if (showOrInfo === false) {
  //     newOtherInfo = otherInfoRef.current ? otherInfoRef.current.value : ''; // Assign value with safety check
  // } else {
  //     newOrInfo = orInfoRef.current ? orInfoRef.current.value : ''; // Assign value with safety check
  //     newPaDate = paDateRef.current ? paDateRef.current.value : ''; // Assign value with safety check
  //     newPaTime = paTimeRef.current ? paTimeRef.current.value : ''; // Assign value with safety check
  // }



      // setName(newName);
      // setPhone(newPhone);
      setDate(newDate);
      setTime(newTime);
      // setEmail(newEmail);
      setEncounterType(newEncounterType);
      // setAptSpecLabel(newAptSpecLabel)
      // setOtherInfo(newOtherInfo);
      // setAptLang(newAptLang);
      // setDateOfBirth(newDateOfBirth);
      // setOrInfo(newOrInfo);
      // setPaDate(newPaDate);
      // setPaTime(newPaTime);


    const patient = searchResultRef.current.find(p => p.phoneNumber === phoneNumber);
    if (patient) {
        setSelectedPatient(patient);
        firstNameRef.current.value = patient.firstName || patient.name.split(" ")[0] || '';
        middleNameRef.current.value = patient.middleName || '';                                                                                                                                                                                                                                                                                                                                            
        lastNameRef.current.value = patient.lastName || patient.name.split(" ").slice(1).join(" ") || '';
        phoneRef.current.value = patient.phoneNumber;
        emailRef.current.value = patient.email;
        aptLangRef.current.value = patient.language;
        aptSpecLabelRef.current.value = patient.aptSpecLabel;
        // otherInfoRef.current.value = patient.otherInfo;

        if (showOrInfo === false) {
          if (otherInfoRef.current) otherInfoRef.current.value = patient.otherInfo;
        } else {
          if (orInfoRef.current) orInfoRef.current.value = patient.orInfo;
          if (paDateRef.current) paDateRef.current.value = patient.paDate;
          if (paTimeRef.current) paTimeRef.current.value = patient.paTime;
        }

        dateOfBirthRef.current.value = patient.dateOfBirth;
        // orInfoRef.current.value = patient.orInfo;
        // paDateRef.current.value = patient.paDate;
        // paTimeRef.current.value = patient.paTime;
        searchResultRef.current = [];
        setName(patient.name)
        setFirstName(patient.firstName)
        setMiddleName(patient.middleName)
        setLastName(patient.lastName)
        setPhone(patient.phoneNumber)
        setEmail(patient.email)
        setAptLang(patient.language)
        setAptSpecLabel(patient.aptSpecLabel)
        setOtherInfo(patient.otherInfo)
        setDateOfBirth(patient.dateOfBirth)
        setOrInfo(patient.orInfo)
        setPaDate(patient.paDate)
        setPaTime(patient.paTime)

    }

};

window.handlePatientSelectAptSearch = async (phoneNumber) => {
  const patient = searchResultRef.current.find(p => p.phoneNumber === phoneNumber);
  if (patient) {
      setSelectedPatient(patient);

      nameAptSearchRef.current.value = patient.firstName + " " + patient.lastName;
      phoneAptSearchRef.current.value = patient.phoneNumber.slice(-10);
   
     
      setNameAptSearch(patient.firstName + " " + patient.lastName)
      setPhoneAptSearch(patient.phoneNumber.slice(-10))
      setDateOfBirthAptSearch(patient.dateOfBirth)
     

  }

};

       // Open Search Modal
       const openSearchModal = () => setIsSearchModalOpen(true);

       // Close Search Modal
       const closeSearchModal = () => setIsSearchModalOpen(false); 



       const handleItemClick = (dateString) => {

        console.log(dateString); // Original date string

        // Create a Date object in local timezone at midnight
        const localDateString = dateString + 'T00:00:00';
        const date = new Date(localDateString);
    
        console.log(date); // Date object at start of the day in local timezone
    
        // Set the current date
        setCurrentDate(date);
    
        // Navigate to the day view in the calendar
        setCurrentView('day');
    
        // Close the search modal
        closeSearchModal();
    };




    const unloadSelectedEncTypeItem = () => {
      // Resetting the selected encounter type
      setSelectedEncounterType(null);
    
      // Resetting other related states or references
      nameEncRef.current = '';
      colorEncTypeRef.current = '#FFFFFF'; // Default color
      categoryRef.current = ''; // Reset category or set to default
      // ... any other resets if needed
    };
    
    
    




    const applyFilters = (newState) => {
      let encTypeFilter = newState.map(element => element.toLowerCase());
      console.log(newState)
      let filteredEvents = [...originalEvents]; // Ensure originalEvents is defined
  
      // Extract events with 'fake' tag before filtering
      const fakeTaggedEvents = filteredEvents.filter(event => event.tag === "fake");
  
      // Set default filters if they are empty
      if (!encTypeFilter || encTypeFilter.length === 0) {
          setEncTypeFilter(["No Filter"]); // Ensure setEncTypeFilter is a function
          setEncTypeView(false); // Ensure setEncTypeView is a function
          setFilteredEvents([...events]); // Ensure originalEvents is defined
      }
  
      if (!userFilter || userFilter.length === 0) {
          setUserFilter(["No Filter"]); // Ensure setUserFilter is a function
      }

      // Filter by encType while keeping 'fake' tagged events and ignoring events where event.resource.hidden is "true"
if (encTypeFilter.length > 0 && !encTypeFilter.includes("no filter")) {
  filteredEvents = filteredEvents.filter(
      (event) => 
          (event.tag === "fake" || 
           (event.resource && 
            encTypeFilter.includes((event.resource.encType || event.resource.encounterType || "").toLowerCase()) && 
            event.resource.hidden !== "true"))
  );
  setEncTypeView(true);
}

  
      // // Filter by encType while keeping 'fake' tagged events
      // if (encTypeFilter.length > 0 && !encTypeFilter.includes("No Filter")) {
      //     filteredEvents = filteredEvents.filter(
      //         (event) => event.tag === "fake" || (event.resource && encTypeFilter.includes(event.resource.encType))
      //     );
      //     setEncTypeView(true);
      // }
  
      // Filter by userFilter while keeping 'fake' tagged events
      if (userFilter.length > 0 && !userFilter.includes("No Filter")) {
          filteredEvents = filteredEvents.filter((event) =>
              event.tag === "fake" || (
                  event.resource &&
                  event.resource.currentUserData &&
                  typeof event.resource.currentUserData === 'string' &&
                  event.resource.currentUserData.trim() !== "" &&
                  !/^\p{C}*$|^\s*$/u.test(event.resource.currentUserData) &&
                  !event.resource.currentUserData.startsWith("/") &&
                  userFilter.some(filter => event.resource.currentUserData.startsWith(filter))
              )
          );
      }
  
      // Function to convert 12-hour format to 24-hour format
      function convertTo24Hour(time12h) {
          const [time, modifier] = time12h.split(' ');
          let [hours, minutes] = time.split(':');
          if (hours === '12') {
              hours = '00';
          }
          if (modifier === 'PM' && hours !== '12') {
              hours = parseInt(hours, 10) + 12;
          }
          return `${hours}:${minutes}`;
      }
  
      // Function to extract hour from 24-hour format time (HH:MM)
      function extractHour(time24) {
          return time24.split(':')[0];
      }
  
      // Group and count events by date and hour
      const groupCountMap = new Map();
      filteredEvents.forEach(event => {
          if (event.start && event.time) {
              const time24 = convertTo24Hour(event.time);
              const dateHourKey = event.start + '_' + extractHour(time24);
              if (!groupCountMap.has(dateHourKey)) {
                  groupCountMap.set(dateHourKey, 0);
              }
              groupCountMap.set(dateHourKey, groupCountMap.get(dateHourKey) + 1);
          }
      });
  
      // Add encTypeCountTag to each event
      filteredEvents.forEach(event => {
          if (event.start && event.time) {
              const dateHourKey = event.start + '_' + extractHour(convertTo24Hour(event.time));
              if (groupCountMap.has(dateHourKey)) {
                event.encTypeCountTag = groupCountMap.get(dateHourKey) - 1;
              }
          }
      });
  
      // Re-include fake tagged events if they were filtered out
      fakeTaggedEvents.forEach(fakeEvent => {
          if (!filteredEvents.includes(fakeEvent)) {
              filteredEvents.push(fakeEvent);
          }
      });
  
      // Update the state with the filtered events
      setFilteredEvents(filteredEvents); // Ensure setFilteredEvents is defined
  
      // Close any open menus
      handleUserMenuClose(); // Ensure handleUserMenuClose is defined
      handleEncTypeMenuClose(); // Ensure handleEncTypeMenuClose is defined
  
      // console.log(encTypeView);
      // console.log(filteredEvents);
  };
  
    
    
  //   const applyFilters = (newState) => {
  //     let encTypeFilter = newState;
  //     let filteredEvents = [...originalEvents]; // Ensure originalEvents is defined
  
  //     // Extract events with 'fake' tag before filtering
  //     const fakeTaggedEvents = filteredEvents.filter(event => event.tag === "fake");
  
  //     // Set default filters if they are empty
  //     if (!encTypeFilter || encTypeFilter.length === 0) {
  //         setEncTypeFilter(["No Filter"]); // Ensure setEncTypeFilter is a function
  //         setEncTypeView(false); // Ensure setEncTypeView is a function
  //     }
  
  //     if (!userFilter || userFilter.length === 0) {
  //         setUserFilter(["No Filter"]); // Ensure setUserFilter is a function
  //     }
  
  //     // Filter by encType while keeping 'fake' tagged events
  //     if (encTypeFilter.length > 0 && !encTypeFilter.includes("No Filter")) {
  //         filteredEvents = filteredEvents.filter(
  //             (event) => event.tag === "fake" || (event.resource && encTypeFilter.includes(event.resource.encType))
  //         );
  //         setEncTypeView(true);
  //     }
  
  //     // Filter by userFilter while keeping 'fake' tagged events
  //     if (userFilter.length > 0 && !userFilter.includes("No Filter")) {
  //         filteredEvents = filteredEvents.filter((event) =>
  //             event.tag === "fake" || (
  //                 event.resource &&
  //                 event.resource.currentUserData &&
  //                 typeof event.resource.currentUserData === 'string' &&
  //                 event.resource.currentUserData.trim() !== "" &&
  //                 !/^\p{C}*$|^\s*$/u.test(event.resource.currentUserData) &&
  //                 !event.resource.currentUserData.startsWith("/") &&
  //                 userFilter.some(filter => event.resource.currentUserData.startsWith(filter))
  //             )
  //         );
  //     }
  
  //     // Function to extract hour from time24 format (HH:MM)
  //     function extractHour(time24) {
  //         return time24.split(':')[0];
  //     }
  
  //     // Group and count events by date and hour
  //     const groupCountMap = new Map();
  //     filteredEvents.forEach(event => {
  //         if (event.start && event.time24) {
  //             const dateHourKey = event.start + '_' + extractHour(event.time24);
  //             if (!groupCountMap.has(dateHourKey)) {
  //                 groupCountMap.set(dateHourKey, 0);
  //             }
  //             groupCountMap.set(dateHourKey, groupCountMap.get(dateHourKey) + 1);
  //         }
  //     });
  
  //     // Add encTypeCountTag to each event
  //     filteredEvents.forEach(event => {
  //         if (event.start && event.time24) {
  //             const dateHourKey = event.start + '_' + extractHour(event.time24);
  //             if (groupCountMap.has(dateHourKey)) {
  //                 event.encTypeCountTag = groupCountMap.get(dateHourKey);
  //             }
  //         }
  //     });
  
  //     // Re-include fake tagged events if they were filtered out
  //     fakeTaggedEvents.forEach(fakeEvent => {
  //         if (!filteredEvents.includes(fakeEvent)) {
  //             filteredEvents.push(fakeEvent);
  //         }
  //     });
  
  //     // Update the state with the filtered events
  //     setFilteredEvents(filteredEvents); // Ensure setFilteredEvents is defined
  
  //     // Close any open menus
  //     handleUserMenuClose(); // Ensure handleUserMenuClose is defined
  //     handleEncTypeMenuClose(); // Ensure handleEncTypeMenuClose is defined
  //     console.log(encTypeView)
  //     console.log(filteredEvents)
     
  // };
  
  
    
    


    //   const applyFilters = () => {
    //     let filteredEvents = originalEvents;
      
    //     if (encTypeFilter.length === 0) {
    //       setEncTypeFilter(["No Filter"]);
    //     }
      
    //     if (userFilter.length === 0) {
    //       setUserFilter(["No Filter"]);
    //     }
      
    //     if (encTypeFilter.length > 0 && !encTypeFilter.includes("No Filter")) {
    //       filteredEvents = filteredEvents.filter(
    //         (event) => event.resource && encTypeFilter.includes(event.resource.encType)
    //       );
    //     }
      
    //     if (userFilter.length > 0 && !userFilter.includes("No Filter")) {
    //       filteredEvents = filteredEvents.filter((event) => 
    //         event.resource &&
    //         event.resource.currentUserData &&
    //         typeof event.resource.currentUserData === 'string' &&
    //         event.resource.currentUserData.trim() !== "" &&
    //         !/^\p{C}*$|^\s*$/u.test(event.resource.currentUserData) && // checks for non-printable characters or only whitespace
    //         !event.resource.currentUserData.startsWith("/") &&
    //         userFilter.some(filter => event.resource.currentUserData.startsWith(filter))
    //       );
    //     }
      
    //     setEvents(filteredEvents);
    //     handleUserMenuClose();
    //     handleEncTypeMenuClose();
    //   };
      
      
      

    // -----------------------
    

    const CustomToolbar = (toolbar) => {
        const goToBack = () => {

            setPreviousCurrentDate(currentDate);
            toolbar.onNavigate(Navigate.PREVIOUS);
            // console.log(currentDate)
        };

        const goToNext = () => {

            setPreviousCurrentDate(currentDate);                                                              
            toolbar.onNavigate(Navigate.NEXT);
            // console.log(currentDate)
        };

        const goToToday = () => {

            setPreviousCurrentDate(currentDate);
            toolbar.onNavigate(Navigate.TODAY);
            // console.log(currentDate)
        };

        const goToMonth = () => {
            setCurrentView('month');
            // console.log(currentDate)
        };
    
        const goToWeek = () => {
            setCurrentView('week');
            // console.log(currentDate)
        };
    
        const goToDay = () => {
            setCurrentView('day');
            // console.log(currentDate)
        };
    

        const canPrint = currentView === 'day' || (currentView === 'month' && currentDate.getDate() !== new Date().getDate());

                    // calculate if we're in 'day' view
    const inDayView = currentView === 'day';

    const isChecked = (encType) => {
        return encTypeFilterRef.current.includes(encType);
    };
    
    const toggleDetails = () => {
        setShowNotificationDetails(!showNotificationDetails);
      };

      // console.log(groupedEvents)

    const gEvents = groupedEvents[selectedGroupIndex];
    // console.log(gEvents)
    const isSingleAppointment = gEvents && gEvents.length === 1 && gEvents[0].tag === "appointment";


  //   const handleDatePickerChange = (event) => {
  //     console.log(event.target.value)
  //     setCurrentDate(event.target.value);
  //     setOpenDatePicker(false);  // Optionally close the dialog after selection
  // };

  const handleDatePickerChange = (event) => {

    console.log(event.target.value);  // Logs the date in YYYY-MM-DD

    const dateString = event.target.value; 

    console.log(dateString); // Original date string

    // Create a Date object in local timezone at midnight
    const localDateString = dateString + 'T00:00:00';
    const date = new Date(localDateString);

    console.log(date); // Date object at start of the day in local timezone

    // Set the current date
    setCurrentDate(date);
    setOpenDatePicker(false);
};


  const handleOpenDatePicker = () => {
      setOpenDatePicker(true);
  };


  //----------------------------------------------------------

  const fetchAppointmentsForSpotSearch = async (candidateDate) => {
    // const twoWeeksAgoISO = new Date();
    // twoWeeksAgoISO.setDate(twoWeeksAgoISO.getDate() - 14); // Adjust as necessary
    // const oneMonthLaterISO = new Date();
    // oneMonthLaterISO.setMonth(oneMonthLaterISO.getMonth() + 1); // Adjust as necessary

    function formatDateAsUTC(date) {
      return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
             .toISOString().split('T')[0];
    }
    
    const today = new Date();
    // const sDate = formatDateAsUTC(today); // Today's date in YYYY-MM-DD format
    const sDate = candidateDate; // Today's date in YYYY-MM-DD format
    
    const oneYearLater = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
    // const eDate = formatDateAsUTC(oneYearLater); // Date exactly one year from now in YYYY-MM-DD format
    const eDate = candidateDate; // Today's date in YYYY-MM-DD format
    
    console.log("Appts. Start Date:", sDate);
  console.log("Appts. End Date:", eDate);

    const appointmentRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'appointments');

    // Create a query to filter by date
    const dateRangeQuery = query(appointmentRef, 
                                 where("date", ">=", sDate),
                                 where("date", "<=", eDate));

    // Fetch the documents within the date range
    const querySnapshot = await getDocs(dateRangeQuery);
    
    const appointments = querySnapshot.docs.map(doc => {
        const data = doc.data();
        
        // Date format validation and conversion
        const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
        if (dateFormat.test(data.date)) {
            data.newDate = data.date;
        }
        
        // Filter based on date range
        const newDateISO = data.newDate || "";
        // console.log(startDate)
        // console.log(newDateISO)
        if (newDateISO < sDate || newDateISO > eDate) {
            return null;
        }

        const date = data.newDate;
        // console.log(date)
        let finalTime = data.time ? formatTime(data.time) : "NO TIME"; // Ensure formatTime function is defined and correctly formats the time
        
        if (!moment(finalTime, 'h:mm A').isValid()) {
            // console.log(`Incorrect time format for appointment ${doc.id}: ${finalTime}`);
            finalTime = "NO TIME";
        }
        
        const time24 = finalTime === "NO TIME" ? "00:00" : moment(finalTime, "h:mm A").format("HH:mm");
        
        // Format the phone number and update data.phone if necessary
        function formatPhoneNumber(number) {
          let cleanNumber = String(number).replace(/\D/g, ''); // Remove non-digit characters
          if (!cleanNumber.startsWith('1')) {
              cleanNumber = '1' + cleanNumber; // Prepend '1' if not present
          }
          return cleanNumber;
        }

// Ensure data.phone is properly set using data.phone or data["phone 2"]
data.phone = data.phone || data["phone 2"];
data.phone = formatPhoneNumber(data.phone);

        // Structuring the appointment data similarly to the events
        return {
            allDay: true,
            start: date,
            end: date,
            resource: data, // Embedding the original data as a resource
            manualConfirm: data.manualConfirm || "No",
            time: finalTime,
            time24: time24
        };
    }).filter(appointment => appointment !== null); // Remove null appointments after date range filtering
    
    // console.log(appointments);
    return appointments;
};

  const fetchEventsForSpotSearch = async (candidateDate) => {


    // Ensure userEmail and physician are valid before proceeding
    if (!userEmail || !physician) {
      console.error("Missing userEmail or physician for Firestore query");
      return; // Exit the function early if validation fails
    }


function formatDateAsUTC(date) {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
         .toISOString().split('T')[0];
}

const today = new Date();
// const sDate = formatDateAsUTC(today); // Today's date in YYYY-MM-DD format
const sDate = candidateDate; // Today's date in YYYY-MM-DD format

const oneYearLater = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
// const eDate = formatDateAsUTC(oneYearLater); // Date exactly one year from now in YYYY-MM-DD format
const eDate = candidateDate; // Today's date in YYYY-MM-DD format

console.log("Start Date:", sDate);
console.log("End Date:", eDate);



  let fetchedEvents = [];

  const historyRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'history');
  const dateRangeQuery = query(historyRef, 
                                where("newDate", ">=", sDate),
                                where("newDate", "<=", eDate));
  const querySnapshot = await getDocs(dateRangeQuery);
  

  if (selectedCalendar === "mainCalendar") {
      fetchedEvents = querySnapshot.docs.map(doc => {
          const data = doc.data();
          // Conditions for mainCalendar...
          if (!data.assocCal || data.assocCal === "mainCalendar" || data.assocCal === "") {
              // Directly process and return event data if it meets conditions
              const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
              if (!dateFormat.test(data.newDate) || (data.hasOwnProperty('status') && data.status !== "completed")) {
                  return null;
              }

              let finalTime = data.newGroup ? formatTime(data.newGroup) : "NO TIME";
              if (!moment(finalTime, 'h:mm A').isValid()) {
                  finalTime = "NO TIME";
              }
              const time24 = finalTime === "NO TIME" ? "00:00" : moment(finalTime, "h:mm A").format("HH:mm");

              return {
                  allDay: true,
                  start: data.newDate,
                  end: data.newDate,
                  resource: data,
                  confirmBySms: data.confirmBySms || "",
                  manualConfirm: data.manualConfirm || "No",
                  confirmByEmail: data.confirmByEmail || "",
                  confirmByVoice: data.confirmByVoice || "",
                  time: finalTime,
                  time24: time24,
                  id: doc.id // Including the document ID
              };
          } else {
              return null;
          }
      }).filter(event => event !== null);
  } else {
      fetchedEvents = querySnapshot.docs.map(doc => {
          const data = doc.data();
          // For other calendars, filter based on selectedCalendar
          if (data.assocCal === selectedCalendar) {
              // Repeat the same data processing logic here for consistency
              const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
              if (!dateFormat.test(data.newDate) || (data.hasOwnProperty('status') && data.status !== "completed")) {
                  return null;
              }

              let finalTime = data.newGroup ? formatTime(data.newGroup) : "NO TIME";
              if (!moment(finalTime, 'h:mm A').isValid()) {
                  finalTime = "NO TIME";
              }
              const time24 = finalTime === "NO TIME" ? "00:00" : moment(finalTime, "h:mm A").format("HH:mm");

              return {
                  allDay: true,
                  start: data.newDate,
                  end: data.newDate,
                  resource: data,
                  confirmBySms: data.confirmBySms || "",
                  manualConfirm: data.manualConfirm || "No",
                  confirmByEmail: data.confirmByEmail || "",
                  confirmByVoice: data.confirmByVoice || "",
                  time: finalTime,
                  time24: time24,
                  id: doc.id // Including the document ID
              };
          } else {
              return null;
          }
      }).filter(event => event !== null);
  }


// new const appoitments

const appointments = await fetchAppointmentsForSpotSearch(candidateDate);
appointments.forEach(appointment => {
    appointment.tag = "appointment";
});

// console.log(appointments)

// console.log(fetchedEvents)

let allEvents = fetchedEvents.concat(appointments);

let allEventsForUpdatingCheck = allEvents;

// console.log(allEvents)
// setAllEventsForUpdatingCheck(allEvents);

// console.log(allEvents)

// Function to create a fake event for a given hour
function createFakeEvent(hour, date, eventsCount, confirmedCount) {
    let formattedHour = moment(`${hour}:00`, "HH:mm").format("h:mm A");
    // Split the string into parts
let parts = formattedHour.split(" "); // Splits into ["08:30", "PM"]
let hourPart = parts[0].split(":")[0]; // Takes "08" from "08:30"

// Reconstruct the desired format
let resultTime = hourPart + " " + parts[1]; // "08 PM"
    // Assuming 'date' is a string in the format "YYYY-MM-DD"
    const verboseDate = moment(date).format('MMMM D, YYYY');
    const formattedHour24 = moment(formattedHour, ["h:mm A", "hh:mm A"]).format("HH:mm");
    return {
        allDay: true,
        start: date,
        end: date,
        manualConfirm: "No",
        tag: "fake",
        confirmBySms: "",
        confirmByEmail: "",
        confirmByVoice: "",
        time: `${formattedHour}`,
        time24: `${formattedHour24}`,
        resource: {
            name: `${resultTime} - Count: ${eventsCount}`,
            onlyConfirmedName: `${resultTime} - Count: ${confirmedCount}`,
            basicName: `${resultTime}`,
            phone: "555-555-5555",
            newDate: date,
            confirmBySms: "",
            confirmByEmail: "",
            confirmByVoice: "",
            currentUserData: `TimeSpacer on ${verboseDate} ${formattedHour}`,
            time: `${formattedHour}`
        },
    };
}

// Sort allEvents by time and name
allEvents = allEvents.sort((a, b) => {
    const timeA = moment(a.time24, "HH:mm");
    const timeB = moment(b.time24, "HH:mm");
    if (timeA.isBefore(timeB)) return -1;
    if (timeA.isAfter(timeB)) return 1;
    if (timeA.isSame(timeB)) {
        const nameA = (a.resource.name && a.resource.name[0].toLowerCase()) || "";
        const nameB = (b.resource.name && b.resource.name[0].toLowerCase()) || "";
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
    }
    return 0;
});

// Calculate the range of hours for the events
let minHour = 24, maxHour = 0;
allEvents.forEach(event => {
    const hour = moment(event.time24, "HH:mm").hour();
    minHour = Math.min(minHour, hour);
    maxHour = Math.max(maxHour, hour);
});



// Insert fake events at the start of each hour
for (let hour = minHour; hour <= maxHour; hour++) {
  // Filter the events for the current hour
  const eventsThisHour = allEvents.filter(event => {
      const eventHour = moment(event.time24, "HH").hour();
      return eventHour === hour;
  });
  
// console.log(eventsThisHour)

// eventsThisHour.forEach(event => {
//   if (event.start === "2024-01-03") {
//     console.log(event);
//     console.log(event.resource.name);
//     console.log(event.start);
//     console.log(event.resource.phone);
//   }
// });

let eventsByDate = new Map();
let confirmedEventsByDate = new Map();
let ignoreKeys = new Set(); // Set to store keys of events to be ignored

// Additional sets to track fake events and appointment events
let fakeEventKeys = new Set();
let fakeConfirmedEventKeys = new Set();
let appointmentEventKeys = new Set(); // Set to track appointment event keys

eventsThisHour.forEach(event => {
  const lowerCaseName = event.resource.name.toLowerCase()
    .replace(/-/g, ' ')  // Replace all hyphens with spaces
    .replace(/\s+/g, ' ');  // Replace multiple spaces with a single space

  let xTime = event.resource.newGroup || event.resource.time || "00:00 AM";
  // Standardize the AM/PM part and convert to 24-hour format
  xTime = xTime.replace(/\s*(a\.m\.|am|A\.M\.)/i, ' AM').replace(/\s*(p\.m\.|pm|P\.M\.)/i, ' PM');
  const timeParts = xTime.match(/(\d+):(\d+) (AM|PM)/i);
  if (timeParts) {
    let hours = parseInt(timeParts[1], 10);
    const minutes = timeParts[2];
    const ampm = timeParts[3].toUpperCase();
    if (ampm === 'PM' && hours < 12) hours += 12;
    if (ampm === 'AM' && hours === 12) hours = 0;
    xTime = `${hours.toString().padStart(2, '0')}:${minutes}`;
  }

  // Create a key for each event based on name, phone, and start date
  let key = `${lowerCaseName}-${event.resource.phone}-${event.start}-${xTime}`;

  // Track appointment events
  if (event.tag === 'appointment') {
    appointmentEventKeys.add(key);
  }

  // console.log(appointmentEventKeys)

  // // If the event is hidden or fake, add the key to the ignore set
  // if (event.resource.hidden === 'true' || event.tag === 'fake') {
  //   ignoreKeys.add(key);
  //   if (event.tag === 'fake') {
  //     fakeEventKeys.add(key); // Also track fake events separately
  //   }
  //   return;
  // }

  // If the event is hidden or fake, add the key to the ignore set
// if (('hidden' in event.resource && event.resource.hidden === 'true') || event.tag === 'fake') {

if (
  ('hidden' in event.resource && event.resource.hidden === 'true') || 
  event.tag === 'fake' || 
  event.manualConfirm === 'cancelled' || 
  (
      (event.confirmBySms === "cancelled" || event.confirmByEmail === "cancelled" || event.confirmByVoice === "cancelled") && 
      !(event.confirmBySms === "confirmed" || event.confirmByEmail === "confirmed" || event.confirmByVoice === "confirmed")
  )
) {
  ignoreKeys.add(key);
  if (event.tag === 'fake') {
    fakeEventKeys.add(key); // Also track fake events separately
  }
  return; // Skip further processing for this event
}


  // If this date is not already in the map and the key is not in the ignore set, add it with an empty Set
  if (!eventsByDate.has(event.start) && !ignoreKeys.has(key)) {
    eventsByDate.set(event.start, new Set());
  }

  // Add the unique key to the Set for this date if it's not in the ignore set
  if (!ignoreKeys.has(key)) {
    eventsByDate.get(event.start).add(key);
  }

  // For confirmed events
  if (event.resource.askConfirmation) {
    let confirmedKey = `${key}-confirmed`;
    if (!confirmedEventsByDate.has(event.start) && !ignoreKeys.has(confirmedKey)) {
      confirmedEventsByDate.set(event.start, new Set());
    }
    if (!ignoreKeys.has(confirmedKey)) {
      confirmedEventsByDate.get(event.start).add(confirmedKey);
    }
    if (event.tag === 'fake') {
      fakeConfirmedEventKeys.add(confirmedKey);
    }
  }
});

// Now modify the logic for counting events, considering appointment rules
eventsByDate.forEach((uniqueEvents, date) => {
  let eventCount = 0;
  let nonAppointmentEventCount = 0;
  let appointmentEventCount = 0;

  uniqueEvents.forEach(key => {
    if (!ignoreKeys.has(key)) {
      if (appointmentEventKeys.has(key)) {
        if (nonAppointmentEventCount === 0) {
          // Only count the appointment event if there are no other events with the same key
          appointmentEventCount++;
          eventCount = appointmentEventCount;
        }
      } else {
        // Count non-appointment events
        nonAppointmentEventCount++;
        eventCount = nonAppointmentEventCount;
      }
    }
  });

  // Adjust count for fake events
  if (Array.from(uniqueEvents).some(key => fakeEventKeys.has(key))) {
    eventCount = Math.max(0, eventCount - 1);
  }

  // if (date === "2024-01-24") {
  //   console.log(`Unique Events for 2024-01-24: ${Array.from(uniqueEvents).join(', ')}`);
  // }

  let confirmedCount = confirmedEventsByDate.has(date) ? confirmedEventsByDate.get(date).size : 0;
  // Subtract 1 if any fake confirmed event is found in this set
  if (confirmedEventsByDate.has(date) && Array.from(confirmedEventsByDate.get(date)).some(key => fakeConfirmedEventKeys.has(key))) {
    confirmedCount = Math.max(0, confirmedCount - 1);
  }

  const fakeEvent = createFakeEvent(hour, date, eventCount, confirmedCount);
  allEvents.push(fakeEvent);
});
}



// Sort all events again including fake events
    allEvents = allEvents.sort((a, b) => {
        const timeA = moment(a.time24, "HH:mm");
        const timeB = moment(b.time24, "HH:mm");
        if (timeA.isBefore(timeB)) return -1;
        if (timeA.isAfter(timeB)) return 1;
        if (timeA.isSame(timeB)) {
            const nameA = (a.resource.name && a.resource.name[0].toLowerCase()) || "";
            const nameB = (b.resource.name && b.resource.name[0].toLowerCase()) || "";
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
        }
        return 0;
}).map(event => {
    if (event.time !== "NO TIME") {
        event.time = moment(event.time24, "HH:mm").format("h:mm A");
    }
    delete event.time24;
    // console.log(event)
    return event;
});


  

    let groupedEventsObj = {};
    let counter = 0;
    let latestDateIds = {};
    
    allEvents.forEach(event => {
        // Convert event.resource.name to lowercase
        const lowerCaseName = event.resource.name.toLowerCase()
        .replace(/-/g, ' ')  // Replace all hyphens with spaces
        .replace(/\s+/g, ' ');  // Replace multiple spaces with a single space
    

        let xTime = event.resource.newGroup || event.resource.time;
        let formattedTime;

        if (xTime) {
          // Parsing the time considering various AM/PM formats
          // This will handle formats like HH:MM, H:MM, am/pm, AM/PM, a.m./p.m., A.M./P.M.
          formattedTime = moment(xTime, [
              'h:mm A', 'h:mm a', 
              'h:mmA', 'h:mma', 
              'HH:mm A', 'HH:mm a', 
              'HH:mmA', 'HH:mma', 
              'h:mm A.M.', 'h:mm a.m.', 
              'h:mm P.M.', 'h:mm p.m.',
              'HH:mm A.M.', 'HH:mm a.m.',
              'HH:mm P.M.', 'HH:mm p.m.'
          ]).format('HH:mm');
      
          // Use formattedTime as needed
          // console.log(formattedTime);
          
      } else {
          console.error('Still pending additional data for processing!');
          formattedTime = '';
      }



      let key;
      if (event.resource.phone) {
          // If phone is available, include it in the key, slicing to the last 10 digits
          const phone = event.resource.phone.slice(-10);
          key = `${lowerCaseName}-${event.resource.newDate || event.resource.date}-${phone}-${formattedTime}`;
      } else {
          // If phone is not available, construct the key without the phone part
          key = `${lowerCaseName}-${event.resource.newDate || event.resource.date}-${formattedTime}`;
      }

        // Construct the key
        // const key = `${lowerCaseName}-${event.resource.newDate || event.resource.date}-${event.resource.phone.slice(-10)}-${formattedTime}`;
        // const keyB = `${lowerCaseName}-${event.resource.newDate || event.resource.date}-${event.resource.phone.slice(-10)}`;
    
    
        // Check if this is a new group
        if (!groupedEventsObj[key]) {
            groupedEventsObj[key] = [];
            // Create a unique groupId for this new group
            const groupId = `${key}`;
            event.groupId = groupId;
        } else {
            // For existing groups, use the groupId of the first event in the group
            event.groupId = groupedEventsObj[key][0].groupId;
        }
    
        groupedEventsObj[key].push(event);


    });




// --- worked but not fully:

// if (selectedCalendar !== "mainCalendar") {
//   // Initial filtering for required tags
//   groupedEventsObj = Object.keys(groupedEventsObj).reduce((filteredObj, key) => {
//     const hasRequiredTag = groupedEventsObj[key].some(event => 
//       event.tag === "appointment"
//     );

//     if (hasRequiredTag) {
//       filteredObj[key] = groupedEventsObj[key];
//     }

//     return filteredObj;
//   }, {});
// }



// if (selectedCalendar !== "mainCalendar") {
//   groupedEventsObj = await processAndRegroupEvents(groupedEventsObj);

//   // Iterate over each group in groupedEventsObj to remove duplicates
//   Object.keys(groupedEventsObj).forEach(groupKey => {
//       const group = groupedEventsObj[groupKey];
//       const uniqueGroup = group.reduce((uniqueEvents, currentEvent) => {
//           // Using JSON.stringify might not be the most efficient for large datasets or complex objects
//           // but it's one of the simpler ways to deep compare objects in JavaScript
//           const isDuplicate = uniqueEvents.some(event => JSON.stringify(event) === JSON.stringify(currentEvent));
//           if (!isDuplicate) {
//               uniqueEvents.push(currentEvent);
//           }
//           return uniqueEvents;
//       }, []);

//       groupedEventsObj[groupKey] = uniqueGroup;
//   });
// }

// ---- end of worked but not fully.



    

    
        // Assigning tagNumber to each event
        Object.values(groupedEventsObj).forEach(group => {
          const nonAppointmentEvents = group.filter(event => event.tag !== "appointment" && event.tag !== "fake");
          const nonAppointmentCount = nonAppointmentEvents.length;          
          group.forEach(event => {
              event.tagNumber = nonAppointmentCount;
          });
      });

    // allEvents.forEach(event => {
    //     const key = `${event.resource.name}-${event.resource.newDate}-${event.resource.phone}`;
    //     if (!groupedEventsObj[key]) {
    //         groupedEventsObj[key] = [];
    //         groupId++;
    //     }
        
    //     event.groupId = groupId;
    //     groupedEventsObj[key].push(event);
    // });

    
    


    let groupedEventsArray = Object.values(groupedEventsObj);

    groupedEventsArray.forEach(group => {
        group.sort((a, b) => {
            const extractDateTime = (str) => {
                if (typeof str !== 'string') return null;
                const match = str.match(/on (.+?)(?: at )?(\d{1,2}:\d{2} [APMapm]{2})/);
                if (match) {
                    const [_, dateStr, timeStr] = match;
                    const dateTimeStr = `${dateStr} ${timeStr}`;
                    return new Date(dateTimeStr);
                }
                return null;
            };
            const dateA = extractDateTime(a.resource.currentUserData);
            const dateB = extractDateTime(b.resource.currentUserData);
            if (dateA && dateB) {
                return dateB - dateA;
            } else if (dateA) {
                return -1;
            } else {
                return 1;
            }
        });
    });

    // if (selectedCalendar !== "mainCalendar") {
    //   console.log(groupedEventsArray)
    // }

    let finalEvents = [];
for (let group of groupedEventsArray) {
    // Try to find an event with the appointment tag
    let representativeEvent = group.find(event => event.tag === "appointment" || event.tag === "fake");

    // If no event with the appointment tag is found, use the first event as before
    if (!representativeEvent) {
        representativeEvent = { ...group[0] };
    }

    // Count the number of events with the appointment tag
    let appointmentEvents = group.filter(event => event.tag === "appointment");
    
    // Check if the representativeEvent meets the criteria or find the most current event with a valid aptSpecLabel
    if (!(representativeEvent.resource && representativeEvent.resource.aptSpecLabel) ||
        (appointmentEvents.length > 1 && representativeEvent.tag === "appointment")) {
        let mostCurrentEvent = null;

        // If there are multiple appointment events, find the most current appointment event
        if (appointmentEvents.length > 1) {
            for (let event of appointmentEvents) {
                if (event.resource && event.resource.aptSpecLabel) {
                    if (!mostCurrentEvent || new Date(event.resource.dateId) > new Date(mostCurrentEvent.resource.dateId)) {
                        mostCurrentEvent = event;
                    }
                }
            }
        } else {
            // Otherwise, find the most current event with a valid aptSpecLabel
            for (let event of group) {
                if (event.resource && event.resource.aptSpecLabel) {
                    if (!mostCurrentEvent || new Date(event.resource.dateId) > new Date(mostCurrentEvent.resource.dateId)) {
                        mostCurrentEvent = event;
                    }
                }
            }
        }

        // If a most current event with a valid aptSpecLabel is found, use its aptSpecLabel
        if (mostCurrentEvent) {
            representativeEvent.resource = representativeEvent.resource || {};
            representativeEvent.resource.aptSpecLabel = mostCurrentEvent.resource.aptSpecLabel;
        }
    }

    // Push the chosen event to the finalEvents array
    finalEvents.push(representativeEvent);
}






  
// finalEvents.sort((a, b) => {
//     // Parse the times in their original format (with AM/PM)
//     const timeA = moment(a.time, ["h:mm A", "hh:mm A"]);
//     const timeB = moment(b.time, ["h:mm A", "hh:mm A"]);

//     if (timeA.isBefore(timeB)) return -1;
//     if (timeA.isAfter(timeB)) return 1;
//     return 0;
// });

finalEvents.sort((a, b) => {
  // Extract the hour part to compare if both events are within the same hour
  const hourA = moment(a.time, ["h:mm A", "hh:mm A"]).hour();
  const hourB = moment(b.time, ["h:mm A", "hh:mm A"]).hour();

  // If both events are within the same hour
  if (hourA === hourB) {
      // If one event is fake and the other is not, prioritize the fake event
      if (a.tag === "fake" && b.tag !== "fake") return -1;
      if (a.tag !== "fake" && b.tag === "fake") return 1;

      // If both are fake or both are not fake, sort by their full time
      const minuteA = moment(a.time, ["h:mm A", "hh:mm A"]).minutes();
      const minuteB = moment(b.time, ["h:mm A", "hh:mm A"]).minutes();
      if (minuteA < minuteB) return -1;
      if (minuteA > minuteB) return 1;
      
      // If both have the exact same time, prioritize the fake event
      // This is redundant given the earlier fake checks, but it's here for clarity
      if (a.tag === "fake") return -1;
      if (b.tag === "fake") return 1;
  } else {
      // If not within the same hour, simply sort by the hour
      return hourA - hourB;
  }

  // Given the checks above, this is theoretically unreachable for fake vs. non-fake within the same hour,
  // but it serves as a catch-all for identical times where neither is fake, or other unforeseen cases.
  return 0;
});




  



    
    // Filter out only 'fake' events and sort them by start time
const sortedFakeEvents = finalEvents.filter(event => event.tag === 'fake')
.sort((a, b) => moment(a.start).diff(moment(b.start)));

// Reset isFirstEventOfDay for all events
finalEvents.forEach(event => {
event.isFirstEventOfDay = false;
});

// Mark the earliest 'fake' event of each day
let currentDay = null;
sortedFakeEvents.forEach(event => {
const eventDay = moment(event.start).format('YYYY-MM-DD');
if (eventDay !== currentDay) {
event.isFirstEventOfDay = true;
currentDay = eventDay;
}
});

  

    // finalEvents.forEach((event, index, self) => {
    //     if (index === 0 || !moment(event.start).isSame(self[index - 1].start, 'day')) {
    //         event.isFirstEventOfDay = true;
    //     } else {
    //         event.isFirstEventOfDay = false;
    //     }
    // });

    // console.log(finalEvents)

    // setEventsForSpotSearch(finalEvents);

    return [finalEvents, allEventsForUpdatingCheck]; 

};




const handleEncounterTypeSelection = async (encounterType) => {
  const linksRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'encTypeLinks');
  const linksSnapshot = await getDocs(linksRef);
  let linkedTypesMap = new Map();

  // Fill the map with group names as keys and arrays of linked types as values
  linksSnapshot.forEach((linkDoc) => {
      linkDoc.data().linked.forEach((linkedType) => {
          if (!linkedTypesMap.has(linkDoc.id)) {
              linkedTypesMap.set(linkDoc.id, []);
          }
          linkedTypesMap.get(linkDoc.id).push(linkedType);
      });
  });

  // Determine if the selected encounter type is part of a linked group
let groupInfo = { groupName: encounterType, encounterTypes: [encounterType] };

for (let [groupName, types] of linkedTypesMap.entries()) {
    // Convert each item in `types` to lowercase and compare against the lowercase `encounterType`
    const lowerCaseTypes = types.map(type => type.toLowerCase());
    if (lowerCaseTypes.includes(encounterType.toLowerCase())) {
        groupInfo = {
            groupName: groupName,
            encounterTypes: types
        };
        break;
    }
}

  // // Determine if the selected encounter type is part of a linked group
  // let groupInfo = { groupName: encounterType, encounterTypes: [encounterType] };

  // for (let [groupName, types.toLowerCase] of linkedTypesMap.entries()) {
  //     if (types.includes(encounterType.toLowerCase)) {
  //         groupInfo = {
  //             groupName: groupName,
  //             encounterTypes: types
  //         };
  //         break;
  //     }
  // }

  // Return both the group name and the associated encounter types
  return groupInfo;
};


  const getSpotEncType = async () => {
    const encounterType = encounterTypeRef.current.value; // Capture the value at the start
    const apptDate = dateRef.current.value;
    const apptTime = timeRef.current.value;
    return [encounterType, apptDate, apptTime]
};

  const startAutoSpotSearch = async () => {
    handleAutoSpot()
  };

  const startAutoSpotSearchSameDay = async () => {
    handleAutoSpotSameDay()
  };


  //------------------------------------------------

  // const findNextSpot = async (encounterType, formattedDate) => {
  //   console.log("encounterType at NEW findNextSpot: ", encounterType);
  //   console.log("formattedDate at NEW findNextSpot: ", formattedDate);

  //   let encounterTypeSame = true

  //   if (lastEncounterTypeRef.current && encounterType.toLowerCase() !== lastEncounterTypeRef.current.value.toLowerCase()) {
  //     encounterTypeSame = false;
  //   }


  //   // setIsSearching(true);
  //   try {
  //     // const searchDate = lastSearchDate || new Date().toISOString().split('T')[0]; // Use last search date or today
      
  //       console.log(formattedDate);
  //       console.log(userEmail);
  //       console.log(physician);
  //       console.log(selectedCalendar);
  //       console.log(encounterType);

  //     const response = await axios({
  //       method: 'post',
  //       url: 'https://us-central1-notify-pro-accb3.cloudfunctions.net/findNextAvailableSpot',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       data: {
  //         userEmail: userEmail,
  //         physician: physician,
  //         selectedCalendar: selectedCalendar,
  //         encounterType: encounterType,
  //         apptDate: firstClickHappened && encounterTypeSame ? lastSearchDate : formattedDate,
  //         apptTime: "01:00 AM"
  //       }
  //     });
      
  //     // setSearchResult(response.data);
  //     if (response.data && shouldContinueRef.current === true) {
  //       setLastSearchDate(response.data.date); // Update last search date
  //       console.log("Spot Found!");
  //       console.log(response.data);
  //        // Update last encounter type
  //        encounterTypeRef.current.value = encounterType;
  //       return response.data;
        
  //     } else {
  //       console.error('Search cancelled by user!');
  //     }
  //   } catch (error) {
  //     console.error('Error finding next available spot:', error);
  //     alert("Failed to find next available spot. Please try again.");
  //     // setSearchResult({ error: 'Failed to find next available spot' });
  //   } finally {
  //     // setIsSearching(false);
  //     console.log("Spot Search Complete!");
  //   }
  // };

  const findNextSpotSameDay = async (encounterType, formattedDate, formattedTime) => {
    console.log("encounterType at NEW findNextSpot SD: ", encounterType);
    console.log("formattedDate at NEW findNextSpot SD: ", formattedDate);
    console.log("formattedTime at NEW findNextSpot SD: ", formattedTime);

    // if (dateRef.current.value === "" || !dateRef.current.value) {
    //   alert("Please select a date first!");
    //   return;
    // }

    let finalTime = "";

    if (formattedTime === "" || !formattedTime) {
      finalTime = "01:00 AM";
    } else {
      finalTime = formattedTime;
    }
  
    let encounterTypeSame = true;

    // console.log("lastEncounterTypeRef.current: ", lastEncounterTypeRef.current);

    if ( encounterType.toLowerCase() !== lastEncounterType.toLowerCase() ) {
      encounterTypeSame = false;
      console.log("Encounter Type Changed!");
    }
    
  
    try {
      console.log(formattedDate);
      console.log(finalTime);
      console.log(userEmail);
      console.log(physician);
      console.log(selectedCalendar);
      console.log(encounterType);
  
      const response = await axios({
        method: 'post',
        url: 'https://us-central1-notify-pro-accb3.cloudfunctions.net/findNextAvailableSpot',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          userEmail: userEmail,
          physician: physician,
          selectedCalendar: selectedCalendar,
          encounterType: encounterType,
          apptDate: formattedDate,
          apptTime: finalTime
        }
      });
      
      if (response.data && shouldContinueRef.current === true) {
        setLastSearchDate(response.data.date); //  Update last search date
        console.log("Spot Found!");
        console.log(response.data);

        return [ response.data, encounterType ];

        
      } else {
        console.error('Search cancelled by user!');
      }
    } catch (error) {
      console.error('Error finding next available spot:', error);
      alert("Failed to find next available spot. Please try again.");
    } finally {
      console.log("Spot Search Complete!");
    }
  };




  const findNextSpot = async (encounterType, formattedDate) => {
    console.log("encounterType at NEW findNextSpot: ", encounterType);
    console.log("formattedDate at NEW findNextSpot: ", formattedDate);
  
    let encounterTypeSame = true;

    // console.log("lastEncounterTypeRef.current: ", lastEncounterTypeRef.current);

    if ( encounterType.toLowerCase() !== lastEncounterType.toLowerCase() ) {
      encounterTypeSame = false;
      console.log("Encounter Type Changed!");
    }
    
  
    try {
      console.log(formattedDate);
      console.log(userEmail);
      console.log(physician);
      console.log(selectedCalendar);
      console.log(encounterType);
  
      const response = await axios({
        method: 'post',
        url: 'https://us-central1-notify-pro-accb3.cloudfunctions.net/findNextAvailableSpot',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          userEmail: userEmail,
          physician: physician,
          selectedCalendar: selectedCalendar,
          encounterType: encounterType,
          apptDate: firstClickHappened && encounterTypeSame ? lastSearchDate : formattedDate,
          apptTime: "01:00 AM"
        }
      });
      
      if (response.data && shouldContinueRef.current === true) {
        setLastSearchDate(response.data.date); // Update last search date
        console.log("Spot Found!");
        console.log(response.data);

        return [ response.data, encounterType ];

        
      } else {
        console.error('Search cancelled by user!');
      }
    } catch (error) {
      console.error('Error finding next available spot:', error);
      alert("Failed to find next available spot. Please try again.");
    } finally {
      console.log("Spot Search Complete!");
    }
  };
  

  async function formatDateForSpotSearch(dateString) {
    const date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }

  function isDateInThePast(formattedDate) {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
    return formattedDate < today;
  }


  const formatTimeForSpotSearch = (apptTime) => {
    if (typeof apptTime !== 'string') {
        return "";
    }




let [hours, minutes] = apptTime.split(':').map(Number); // Split the time into hours and minutes

hours += 1; // Add 1 hour

// Handle overflow of 24 hours (military time format)
if (hours === 24) {
  hours = 0;
}

// Format back to HH:MM format, ensuring two digits for hours and minutes
let time = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;






    // Remove periods and convert to upper case
    let cleanedTime = time.toUpperCase().replace(/\./g, '');

    // Check if it has AM/PM or looks like a 24-hour format
    const hasAmPm = /AM|PM/.test(cleanedTime);
    const looksLike24Hour = /^\d{1,2}:\d{2}$/.test(cleanedTime);

    // If it looks like a 24-hour format and doesn't have AM/PM, handle it as 24-hour time
    if (looksLike24Hour && !hasAmPm) {
        const momentTime = moment(cleanedTime, "HH:mm");
        if (momentTime.isValid()) {
            return momentTime.format("h:mm A");
        }
    } else {
        // Try to parse it with moment assuming various formats including AM/PM
        const momentTime = moment(cleanedTime, ["h:mm A", "h:mmA", "hA", "HH:mm"]);
        if (momentTime.isValid()) {
            return momentTime.format("h:mm A");
        }
    }

    return "";
};


  const handleAutoSpotSameDay = async () => {

    shouldContinueRef.current = true;
    const newName = firstNameRef.current.value + " " + lastNameRef.current.value;
    const newFirstName = firstNameRef.current.value;
    const newMiddleName = middleNameRef.current.value;
    const newLastName = lastNameRef.current.value;
    const newPhone = phoneRef.current.value;
    // const newDate = dateRef.current.value;
    // const newTime = timeRef.current.value;
    const newEmail = emailRef.current.value;
    const newEncounterType = encounterTypeRef.current.value;
    const newAptSpecLabel = aptSpecLabelRef.current.value;
    const newAptLang = aptLangRef.current.value;
    const newDateOfBirth = dateOfBirthRef.current.value;

    let newOtherInfo
    let newOrInfo
    let newPaDate
    let newPaTime
    if (showOrInfo === false) {
      newOtherInfo = otherInfoRef.current ? otherInfoRef.current.value : ''; // Assign value with safety check
  } else {
      newOrInfo = orInfoRef.current ? orInfoRef.current.value : ''; // Assign value with safety check
      newPaDate = paDateRef.current ? paDateRef.current.value : ''; // Assign value with safety check
      newPaTime = paTimeRef.current ? paTimeRef.current.value : ''; // Assign value with safety check
  }

      setShouldContinue(true);
      setFirstClickHappened(true);
      setName(newName);
      setFirstName(newFirstName);
      setMiddleName(newMiddleName);
      setLastName(newLastName);
      setPhone(newPhone);
      // setDate(newDate);
      // setTime(newTime);
      setEmail(newEmail);
      setEncounterType(newEncounterType);
      setAptSpecLabel(newAptSpecLabel)
      setOtherInfo(newOtherInfo);
      setAptLang(newAptLang);
      setDateOfBirth(newDateOfBirth);
      setOrInfo(newOrInfo);
      setPaDate(newPaDate);
      setPaTime(newPaTime);


    setIsClicked(true);
    console.log("Button clicked!!");

const [encounterType, apptDate, apptTime] = await getSpotEncType()

if (encounterType === "") {
  alert("You must first select the 'Encounter Type' to start the next spot search for this appointment!");
  return;
}

    setTimeout(() => {
        setIsClicked(false);
    }, 200);

    setIsLoadingSpotSearch(true);

    let formattedDate = "";

    if (apptDate.length > 0 && isDateInThePast(apptDate) || apptDate === "" || !apptDate) {  
      formattedDate = await formatDateForSpotSearch(new Date());
      console.log("The date is in the past. Using today's date instead.");
    } else {
      console.log("The date is not in the past.");


    // console.log("currentDate: ", apptDate);
    let date = new Date(apptDate);
    date.setDate(date.getDate() - 1);
    
    formattedDate = date.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
    }
    

    
    let formattedTime = formatTimeForSpotSearch(apptTime);

    console.log("formattedDate SD: ", formattedDate);
    console.log("apptTime SD: ", formattedTime);




    const [ res, lastEncounterType ] = await findNextSpotSameDay(encounterType, formattedDate, formattedTime);


    setSpotResult(res);
    setLastEncounterType(lastEncounterType);
    // console.log("Auto Spot Result: ", res)


};


const handleAutoSpot = async () => {

    shouldContinueRef.current = true;
    const newName = firstNameRef.current.value + " " + lastNameRef.current.value;
    const newFirstName = firstNameRef.current.value;
    const newMiddleName = middleNameRef.current.value;
    const newLastName = lastNameRef.current.value;
    const newPhone = phoneRef.current.value;
    // const newDate = dateRef.current.value;
    // const newTime = timeRef.current.value;
    const newEmail = emailRef.current.value;
    const newEncounterType = encounterTypeRef.current.value;
    const newAptSpecLabel = aptSpecLabelRef.current.value;
    const newAptLang = aptLangRef.current.value;
    const newDateOfBirth = dateOfBirthRef.current.value;

    let newOtherInfo
    let newOrInfo
    let newPaDate
    let newPaTime
    if (showOrInfo === false) {
      newOtherInfo = otherInfoRef.current ? otherInfoRef.current.value : ''; // Assign value with safety check
  } else {
      newOrInfo = orInfoRef.current ? orInfoRef.current.value : ''; // Assign value with safety check
      newPaDate = paDateRef.current ? paDateRef.current.value : ''; // Assign value with safety check
      newPaTime = paTimeRef.current ? paTimeRef.current.value : ''; // Assign value with safety check
  }

      setShouldContinue(true);
      setFirstClickHappened(true);
      setName(newName);
      setFirstName(newFirstName);
      setMiddleName(newMiddleName);
      setLastName(newLastName);
      setPhone(newPhone);
      // setDate(newDate);
      // setTime(newTime);
      setEmail(newEmail);
      setEncounterType(newEncounterType);
      setAptSpecLabel(newAptSpecLabel)
      setOtherInfo(newOtherInfo);
      setAptLang(newAptLang);
      setDateOfBirth(newDateOfBirth);
      setOrInfo(newOrInfo);
      setPaDate(newPaDate);
      setPaTime(newPaTime);


    setIsClicked(true);
    console.log("Button clicked!!");

const [encounterType, apptDate, apptTime] = await getSpotEncType()

if (encounterType === "") {
  alert("You must first select the 'Encounter Type' to start the next spot search for this appointment!");
  return;
}

    setTimeout(() => {
        setIsClicked(false);
    }, 200);

    setIsLoadingSpotSearch(true);

    console.log("currentDate: ", currentDate);
    let formattedDate = await formatDateForSpotSearch(currentDate);


    console.log("formattedDate: ", formattedDate);
    console.log("apptTime: ", apptTime);


if (isDateInThePast(formattedDate)) {
  formattedDate = await formatDateForSpotSearch(new Date());
  console.log("The date is in the past. Using today's date instead.");
  
} else {
  console.log("The date is not in the past.");
}



    const [ res, lastEncounterType ] = await findNextSpot(encounterType, formattedDate);


    setSpotResult(res);
    setLastEncounterType(lastEncounterType);
    // console.log("Auto Spot Result: ", res)


};

  //------------------------------------------------



//   const handleAutoSpot = async () => {

//     shouldContinueRef.current = true;
//     const newName = nameRef.current.value;
//     const newPhone = phoneRef.current.value;
//     // const newDate = dateRef.current.value;
//     // const newTime = timeRef.current.value;
//     const newEmail = emailRef.current.value;
//     const newEncounterType = encounterTypeRef.current.value;
//     const newAptSpecLabel = aptSpecLabelRef.current.value;
//     const newAptLang = aptLangRef.current.value;
//     const newDateOfBirth = dateOfBirthRef.current.value;

//     let newOtherInfo
//     let newOrInfo
//     let newPaDate
//     let newPaTime
//     if (showOrInfo === false) {
//       newOtherInfo = otherInfoRef.current ? otherInfoRef.current.value : ''; // Assign value with safety check
//   } else {
//       newOrInfo = orInfoRef.current ? orInfoRef.current.value : ''; // Assign value with safety check
//       newPaDate = paDateRef.current ? paDateRef.current.value : ''; // Assign value with safety check
//       newPaTime = paTimeRef.current ? paTimeRef.current.value : ''; // Assign value with safety check
//   }

//       setShouldContinue(true);
//       setFirstClickHappened(true);
//       setName(newName);
//       setPhone(newPhone);
//       // setDate(newDate);
//       // setTime(newTime);
//       setEmail(newEmail);
//       setEncounterType(newEncounterType);
//       setAptSpecLabel(newAptSpecLabel)
//       setOtherInfo(newOtherInfo);
//       setAptLang(newAptLang);
//       setDateOfBirth(newDateOfBirth);
//       setOrInfo(newOrInfo);
//       setPaDate(newPaDate);
//       setPaTime(newPaTime);


//     setIsClicked(true);
//     console.log("Button clicked!!");

// const [encounterType, apptDate, apptTime] = await getSpotEncType()

//     setTimeout(() => {
//         setIsClicked(false);
//     }, 200);

//     setIsLoadingSpotSearch(true);

//     const [finalEventsForSpotSearch, allEventsForUpdatingCheck] = await fetchEventsForSpotSearch();
//     console.log(finalEventsForSpotSearch);

    
//     const groupInfo = await handleEncounterTypeSelection(encounterType);

//     const newEncType = groupInfo.encounterTypes;
//     const newGroupName = [groupInfo.groupName];

//     console.log("Group Name:", groupInfo.groupName); // Will log the name of the group if the encounter type is part of one
//     console.log("Encounter Types:", groupInfo.encounterTypes); // Will log all linked types if the encounter type is part of a group

//     // const newEncType = [encounterType];
 
//     console.log(newEncType);

//     // Additional logic follows, using `encounterType` instead of `encounterTypeRef.current.value`...


//         let encTypeFilter = newEncType.map(element => element.toLowerCase());

//         // let groupNameFilter = groupInfo.groupName.toLowerCase();

//         let groupNameFilter = newGroupName.map(element => element.toLowerCase());

//         console.log(encTypeFilter);
//         console.log(groupNameFilter);

//         // let encTypeFilter = newEncType.flatMap(innerArray => 
//         //   innerArray.map(element => element.toLowerCase())
//         // );
        

//         if (newEncType.length === 1 && newEncType[0] === '') {
//           alert("You must first select the Encounter Type for this appointment!");
//           return;
//       }
      
//         // console.log(newState)
//         let filteredEvents = [...finalEventsForSpotSearch]; // Ensure eventsForSpotSearch is defined

//         // console.log(filteredEvents)
    
//         // Extract events with 'fake' tag before filtering
//         const fakeTaggedEvents = filteredEvents.filter(event => event.tag === "fake");
    
  
//         function containsCancelled(event) {
//           return event.confirmBySms === "cancelled" || 
//                  event.manualConfirm === "cancelled" || 
//                  event.confirmByEmail === "cancelled" || 
//                  event.confirmByVoice === "cancelled";
//       }
      
//       function containsConfirmed(event) {
//           return event.confirmBySms === "confirmed" || 
//                  event.manualConfirm === "confirmed" || 
//                  event.confirmByEmail === "confirmed" || 
//                  event.confirmByVoice === "confirmed";
//       }

//       if (encTypeFilter.length > 0) {
//         filteredEvents = filteredEvents.filter(event => {
//             // Directly allow events tagged as "fake"
//             if (event.tag === "fake") {
//                 return true;
//             }
    
//             // Proceed with more complex checks if not a "fake" event
//             if (event.resource) {
//                 const encTypeIsIncluded = encTypeFilter.includes(
//                     (event.resource.encType || event.resource.encounterType || "").toLowerCase()
//                 );
    
//                 const notHidden = event.resource.hidden !== "true";
//                 const notCancelledOrConfirmed = !containsCancelled(event.resource) || containsConfirmed(event.resource);
    
//                 return encTypeIsIncluded && notHidden && notCancelledOrConfirmed;
//             }
    
//             return false; // Exclude the event if it doesn't have a resource
//         });
    
//         console.log(newEncType);
//     } 
//       // if (encTypeFilter.length > 0) {
//       //     filteredEvents = filteredEvents.filter(
//       //         (event) =>
//       //             event.tag === "fake" || event.resource &&
//       //             encTypeFilter.includes((event.resource.encType || event.resource.encounterType || "").toLowerCase()) &&
//       //             event.resource.hidden !== "true" &&
//       //             (!containsCancelled(event.resource) || containsConfirmed(event.resource))
//       //     );
//       //     console.log(newEncType);
//       // }

      
      

//   //       // Filter by encType while keeping 'fake' tagged events and ignoring events where event.resource.hidden is "true"
//   // if (encTypeFilter.length > 0) {
//   //   filteredEvents = filteredEvents.filter(
//   //       (event) => 
//   //           (event.resource && 
//   //             encTypeFilter.includes((event.resource.encType || event.resource.encounterType || "").toLowerCase()) && 
//   //             event.resource.hidden !== "true")
//   //   );
//   //   console.log("Ignore hidden enc types!");
//   // }

//   // console.log(filteredEvents)

//       // Filter by encType while keeping 'fake' tagged events
//       if (encTypeFilter.length > 0) {
//         filteredEvents = filteredEvents.filter(
//             (event) => event.tag === "fake" || 
//                        (event.resource && 
//                         encTypeFilter.includes(
//                             (event.resource.encType || event.resource.encounterType || "").toLowerCase()
//                         )
//                        )
//         );
//         // console.log(filteredEvents)
//     }
  
    
//         // // Filter by encType while keeping 'fake' tagged events
//         // if (encTypeFilter.length > 0) {
//         //   console.log(encTypeFilter)
//         //   console.log(filteredEvents)
//         //     filteredEvents = filteredEvents.filter(
//         //         (event) => event.tag === "fake" || (event.resource && encTypeFilter.includes(event.resource.encType || event.resource.encounterType))
//         //     );
//         //     console.log(filteredEvents)
//         // }
    
    
//         // Function to convert 12-hour format to 24-hour format
//         function convertTo24Hour(time12h) {
//             const [time, modifier] = time12h.split(' ');
//             let [hours, minutes] = time.split(':');
//             if (hours === '12') {
//                 hours = '00';
//             }
//             if (modifier === 'PM' && hours !== '12') {
//                 hours = parseInt(hours, 10) + 12;
//             }
//             return `${hours}:${minutes}`;
//         }
    
//         // Function to extract hour from 24-hour format time (HH:MM)
//         function extractHour(time24) {
//             return time24.split(':')[0];
//         }
    
//         // Group and count events by date and hour
//         const groupCountMap = new Map();
//         filteredEvents.forEach(event => {
//             if (event.start && event.time) {
//                 const time24 = convertTo24Hour(event.time);
//                 const dateHourKey = event.start + '_' + extractHour(time24);
//                 if (!groupCountMap.has(dateHourKey)) {
//                     groupCountMap.set(dateHourKey, 0);
//                 }
//                 groupCountMap.set(dateHourKey, groupCountMap.get(dateHourKey) + 1);
//             }
//         });
    
//         // Add encTypeCountTag to each event
//         filteredEvents.forEach(event => {
//             if (event.start && event.time) {
//                 const dateHourKey = event.start + '_' + extractHour(convertTo24Hour(event.time));
//                 if (groupCountMap.has(dateHourKey)) {
//                   event.encTypeCountTag = groupCountMap.get(dateHourKey) - 1;
//                 }
//             }
//         });
    
//         // Re-include fake tagged events if they were filtered out
//         fakeTaggedEvents.forEach(fakeEvent => {
//             if (!filteredEvents.includes(fakeEvent)) {
//                 filteredEvents.push(fakeEvent);
//             }
//         });
    

//             // After all filtering, now filter out only 'fake' tagged events before setting the state
//     const fakeOnlyEvents = filteredEvents.filter(event => event.tag === "fake");
//     console.log(fakeOnlyEvents);

//     // Update the state with only the 'fake' tagged events
//     setFoundEncTypes(fakeOnlyEvents);

//     const res = await findNextSpot(fakeOnlyEvents, groupNameFilter, apptDate, apptTime); 

//     setSpotResult(res);
//     console.log("Auto Spot result: ", res)


// };

useEffect(() => {
  if (spotResult) {
    console.log(spotResult);

    // Convert the 24h time format "1300" to "13:00"
    // const formatted24HourTime = `${spotResult.time.slice(0, -2)}:${spotResult.time.slice(-2)}`;
    // Then convert "13:00" to a more readable format "1:00 PM"
    const convertedTime = moment(spotResult.time, "hh:mm A").format("HH:mm");

    setDate(spotResult.date);
    setTime(convertedTime);  // Set the state with converted time
    // dateRef.current.value = spotResult.date;  // Update the input field with the date
    // timeRef.current.value = convertedTime;  // Update the input field with the time
    setIsLoadingSpotSearch(false);
    // setShouldContinue(false)
  }
}, [spotResult]);

// useEffect(() => {
//   console.log(time);
//   if (time && timeRef.current) {  // Check if time is set and the ref is attached
//     timeRef.current.value = time;  // Use the ref to set the value
//   }
// }, [time]);


// useEffect(() => {
//   if (foundEncTypes) {
//     console.log(foundEncTypes)
//   }
// }, [foundEncTypes]);




async function incrementDay(date) {
  const result = new Date(date.getTime()); // Clone the date
  result.setDate(result.getDate() + 1);
  return result;
}

function determineWeekOfMonth(date) {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const dayOfMonth = date.getDate();
  const adjustedDay = firstDay.getDay() > 2 ? dayOfMonth + (7 - firstDay.getDay()) : dayOfMonth;
  const weekOfMonth = Math.ceil(adjustedDay / 7);

  return weekOfMonth === 5 ? 'fifth' : ['first', 'second', 'third', 'fourth'][weekOfMonth - 1];
}

async function getRelevantDate(apptDate) {
  if (firstClickHappened) {
// Split the input into year, month, and day components
const [year, month, day] = apptDate.split('-').map(Number);

// Create a date object for local time zone
const date = new Date(year, month - 1, day);
console.log(date)

    return date
  } else {

      // If the first click hasn't happened, use today's date
      let today = new Date();
      return today
  }
}



useEffect(() => {
  shouldContinueRef.current = shouldContinue;
}, [shouldContinue]);

// async function findNextSpot(data, encTypeFilter, apptDate, apptTime) {
//   console.log(apptDate, apptTime)
//   let today = await getRelevantDate(apptDate);
//   console.log(today)
//   today.setHours(0, 0, 0, 0);
//   let currentDate = await incrementDay(today);
//   let searchLimit = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
//   console.log(searchLimit)

//   while (currentDate <= searchLimit && shouldContinueRef.current === true) {
      
//       console.log("shouldContinue: ", shouldContinueRef.current)

//       const dateFormatted = currentDate.toISOString().split('T')[0];
//       // const eventsToday = data.filter(event => event.start === dateFormatted);

//       // let events = data.filter(event => event.start === dateFormatted);
//       // if (events.length === 0) {
//       //     // Add a default event object if there are no real events
//       //     events = [{ start: dateFormatted, encTypeCountTag: 0, time: 'allDay' }];
//       //     console.log("Default event: ", events)
//       // }
      
//       // for (const event of events) {

//       // for (const event of data.filter(event => event.start === dateFormatted)) {
//           // const militaryTime = convertToMilitaryTime(event.time);
//           // const eventEncTypeCountTag = event.encTypeCountTag;
//           // console.log(eventEncTypeCountTag)

//           const specialDayRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'rules', 'byDay', dateFormatted);
//           console.log(specialDayRef)
//           const specialDaySnapshot = await getDocs(specialDayRef);
//           console.log(specialDaySnapshot.docs)

//           // Determine the count tag based on the presence of events
//           // let eventEncTypeCountTag = eventsToday.length > 0 ? event.encTypeCountTag : 0;

//           if (specialDaySnapshot && !specialDaySnapshot.empty) {
//             const returnedData = await dayMeetsConditions(specialDaySnapshot, encTypeFilter, data, dateFormatted);

//               if (returnedData && returnedData.metCondition) {
//                   // console.log(dateFormatted, militaryTime, eventEncTypeCountTag)
//                   // console.log(dateFormatted, event.time, eventEncTypeCountTag)
//                   return { date: dateFormatted, time: returnedData.startTime, available_spot: true, source: 'special rules' };
//               } else {
//                 // console.log(dateFormatted, militaryTime, eventEncTypeCountTag, currentDate)
//                 // console.log(dateFormatted, event.time, eventEncTypeCountTag, currentDate)
//                   currentDate = await incrementDay(currentDate);
//                   console.log(`Special rules exist but conditions not met, skipping to the next day: ${currentDate}.`);
//                   continue;
//               }
//           }else{

//             // console.log(currentDate)

//           // Process general rules if no special rules or conditions not met in special rules
//           const generalRulesRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'rules', 'general', 'weeksOfMonth', determineWeekOfMonth(currentDate), currentDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase());
//           console.log("General Rules: ", generalRulesRef)
//           const querySnapshot = await getDocs(generalRulesRef);
//           console.log(querySnapshot.docs)
          

//             console.log(`specialDaySnapshot.empty for ${currentDate}:`, specialDaySnapshot.empty)
//             console.log(`querySnapshot.empty for ${currentDate}:`, querySnapshot.empty)
//             // console.log(`event.start for ${currentDate}:`, event.start)
                  

//                   if (querySnapshot && !querySnapshot.empty) {

//                   const returnedData = await dayMeetsConditions(querySnapshot, encTypeFilter, data, dateFormatted);

//                   // Check if returnedData is not null and meetsConditions is true
//                   if (returnedData && returnedData.metCondition) {
//                       // console.log(dateFormatted, militaryTime, eventEncTypeCountTag);
//                       // console.log(dateFormatted, event.time, eventEncTypeCountTag);
//                       return {
//                           date: dateFormatted,
//                           time: returnedData.startTime,
//                           available_spot: true,
//                           source: 'general rules'
//                       };
//                   }}
//           //  if (querySnapshot && await dayMeetsConditions(querySnapshot, encTypeFilter, eventEncTypeCountTag, data, dateFormatted)) {
//           //     console.log(dateFormatted, militaryTime, eventEncTypeCountTag)
//           //     console.log(dateFormatted, event.time, eventEncTypeCountTag)
//           //     return { date: dateFormatted, time: startTime, available_spot: true, source: 'general rules' };
//           // }
//         // }
    
  

//       let currentWeek = null

//       currentDate = await incrementDay(currentDate); // Move to the next day
//       console.log(`Moving to the next day: ${currentDate}.`);
//       if (currentDate.getDay() === 0 || currentDate.getDate() === 1) {
//           currentWeek = determineWeekOfMonth(currentDate);
//           console.log(`Current week of the month: ${currentWeek}.`);
//       }
//   }
//   }

//   return null; // If no available spots are found
// }


// async function findNextSpot(data, encTypeFilter) {
//   let today = new Date();
//   today.setHours(0, 0, 0, 0);  // Normalize today's date

//   let currentDate = incrementDay(today); // Start from the next day
//   let searchLimit = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()); // Set limit to one year from today

  
//   while (currentDate <= searchLimit) {
//       const dateFormatted = currentDate.toISOString().split('T')[0]; // Current future date in YYYY-MM-DD format

//       for (const event of data.filter(event => event.start === dateFormatted)) {
//         const militaryTime = convertToMilitaryTime(event.time);
//         const eventEncTypeCountTag = event.encTypeCountTag;

//       // Check if there are special rules for the current date
//       const specialDayRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'rules', 'byDay', dateFormatted);
//       const specialDaySnapshot = await getDocs(specialDayRef);

//       if (!specialDaySnapshot.empty) {
//           if (await dayMeetsConditions(specialDaySnapshot, encTypeFilter, event.time, event.encTypeCountTag)) {
//               return { date: dateFormatted, available_spot: true, source: 'special rules' };
//           } else {
//               // Special rules exist but conditions not met, skip to the next day
//               console.log(`Special rules exist but conditions not met, skipping to the next day: ${currentDate.toISOString()}.`);
//               currentDate = incrementDay(currentDate);
//               continue;
//           }
//       } else {
//           // Process general rules if no special rules or conditions not met in special rules
//           const generalRulesRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'rules', 'general', 'weeksOfMonth', determineWeekOfMonth(currentDate), currentDate.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase());
//           const querySnapshot = await getDocs(generalRulesRef);

//           for (const docSnap of querySnapshot.docs) {
//               const timeRangeData = docSnap.data();
//               const encounterTypes = timeRangeData.encounterTypes;

//               for (const encounter of encounterTypes) {
//                   if (encounter[encTypeFilter] !== undefined && event.encTypeCountTag < encounter[encTypeFilter]) {
//                       return {
//                           date: dateFormatted,
//                           time_range: docSnap.id,
//                           available_spot: true,
//                           source: 'general rules'
//                       };
//                   }
//               }
//           }
//           // If no available spot found in general rules, increment the day
//           currentDate = incrementDay(currentDate);
//       }

//       let currentWeek = null;
      
//       // Handle the incrementation of the day at the end of the while loop if needed
//       if (currentDate.getDay() === 0 || currentDate.getDate() === 1) { // Adjust week if necessary
//           currentWeek = determineWeekOfMonth(currentDate);
//       }
//   }
// }

//   return null; // If no available spots are found after looping through the search limit
// }

// function areThereOtherEvents(allEventsData, formattedDate, timeRange) {
//   const [start, end] = timeRange.split('-').map(time => convertToMilitaryTime(time.trim()));
//   return allEventsData.some(event => {
//       if (event.start !== formattedDate) return false; // Event is not on the required date
//       const eventTimeMilitary = convertToMilitaryTime(event.time);
//       return eventTimeMilitary >= start && eventTimeMilitary < end;
//   });
// }

async function dayMeetsConditions(daySnapshot, encTypeFilter, allEventsData, formattedDate) {
  // Ensure allEventsData is valid, if not, initialize as empty to avoid errors
  if (!Array.isArray(allEventsData)) {
    allEventsData = [];
  }

  // Loop through each document (time range) in the day's snapshot
  for (let doc of daySnapshot.docs) {
    const timeRange = doc.id; // This represents "0800-0859" format or similar
    const [startTime] = timeRange.split('-'); // Extract the start time from range
    const data = doc.data();
    let countToCompare = 0; // Default count to compare

    // Find the first event that matches the formattedDate and is in the current time range
    const matchingEvent = allEventsData.find(event => event.start === formattedDate && isTimeInRange(convertToMilitaryTime(event.time), timeRange));
    if (matchingEvent) {
      countToCompare = matchingEvent.encTypeCountTag; // Use the event's count tag
      console.log("Event Found that matches Time Range:", timeRange, "-->", matchingEvent);
    }

    console.log("Data in Time Range", timeRange, ":", data);
    console.log("Count to Compare:", countToCompare);

    // Check if any encounter type in the time range meets the condition
    const isConditionMet = data.encounterTypes && data.encounterTypes.some(encounterType => {
      const encounterValue = encounterType[encTypeFilter];
      console.log("Encounter Value for", encTypeFilter, "in Range", timeRange, ":", encounterValue, ". Against count tag:", countToCompare);
      return encounterValue !== undefined && countToCompare < encounterValue;
    });

    if (isConditionMet) {
      return { metCondition: true, startTime }; // Return true and the start time of the range
    }
  }

  console.log("No time ranges met the conditions.");
  return { metCondition: false, startTime: null }; // No time ranges met the condition
}


// async function dayMeetsConditions(daySnapshot, encTypeFilter, eventEncTypeCountTag, allEventsData, formattedDate) {
//   return daySnapshot.docs.some(doc => {
//       let countToCompare = null;
//       const timeRange = doc.id; // Document ID as time range, e.g., "0800-0859"
      
//       if (isTimeInRange(convertToMilitaryTime(eventTime), timeRange)) {
//           countToCompare = eventEncTypeCountTag; 
//       } else if (!isTimeInRange(convertToMilitaryTime(eventTime), timeRange)) {
//           console.log("No event matches the time and formattedDate for this timeRange");
//           countToCompare = 0;
//       } else {
//           //check the next time range in cronological order.

//           //then:
//           //once there are no time ranges to check:
//           return false; // There are other events, skip this range
//       }

//       const data = doc.data();
//       console.log("Data in Time Range", timeRange, ":", data);


      
//       return data.encounterTypes && data.encounterTypes.some(encounterType => {
//           const encounterValue = encounterType[encTypeFilter];
//           console.log("Encounter Value for", encTypeFilter, "in Range", timeRange, ":", encounterValue + ". Against count tag: " + countToCompare);
//           const [start, end] = timeRange.split('-');
//           console.log(start)
//           return encounterValue !== undefined && countToCompare < encounterValue;
//           //at the end if true I want to return also "start" from the matched time range.
//       });
//   });
// }


// async function dayMeetsConditions(daySnapshot, encTypeFilter, eventTime, eventEncTypeCountTag, allEventsData, formattedDate) {
//   return daySnapshot.docs.some(doc => {
//       let countToCompare = null;
//       const timeRange = doc.id; // Document ID as time range, e.g., "0800-0859"
//       if (!isTimeInRange(convertToMilitaryTime(eventTime), timeRange)) {
//           countToCompare = eventEncTypeCountTag; 
//       }else if(!***function to check if there are other events in allEvents for the formattedDate***){
//           console.log(allEventsData)
//           countToCompare = 0
//       }else{
//           return false;
//       }

//       // const [start, end] = timeRange.split('-');
      
//       const data = doc.data();
//       console.log("Data in Time Range", timeRange, ":", data);
      
//       return data.encounterTypes && data.encounterTypes.some(encounterType => {
//           const encounterValue = encounterType[encTypeFilter];
//           console.log("Encounter Value for", encTypeFilter, "in Range", timeRange, ":", encounterValue + ". Against event count tag: " + eventEncTypeCountTag);
//           return encounterValue !== undefined && countToCompare < encounterValue;
//       });
//   });
// }

function convertToMilitaryTime(timeStr) {

      // Check if the time indicates an all-day event
      if (timeStr === 'allDay') {
        return 'allDay';  // Return the string as is for all-day events
    }else{

  let [time, period] = timeStr.split(' ');
  let [hours, minutes] = time.split(':');
  hours = parseInt(hours);
  minutes = parseInt(minutes);

  if (hours === 12) hours = 0; // Adjust for midnight
  if (period === 'PM') hours += 12;

  return `${hours < 10 ? '0' + hours : hours}${minutes < 10 ? '0' + minutes : minutes}`;
  }}

//   function isTimeInRange(eventTime, range) {

//       return true; // Consider 'allDay' as fitting any time range
// }

function isTimeInRange(eventTime, range) {
      // Check if the event is marked as 'allDay'
      if (eventTime === 'allDay') {
        return true; // Consider 'allDay' as fitting any time range
    } else {
  const [start, end] = range.split('-');
  return eventTime >= start && eventTime < end;
}}


// --------------------------------------------------------------
// --------------------------------------------------------------
// --------------------------------------------------------------

// Helper function to format phone numbers
function formatPhoneNumberWhenUpdating(number) {
  let cleanNumber = String(number).replace(/\D/g, ''); // Remove non-digit characters
  if (!cleanNumber.startsWith('1')) {
      cleanNumber = '1' + cleanNumber; // Prepend '1' if not present
  }
  return cleanNumber;
}

// Function to convert 24-hour time to 12-hour time
function convertTime24to12WhenUpdating(time24) {
  const [hours, minutes] = time24.split(':');
  const hours12 = ((hours % 12) || 12); // Convert hour component
  const amPm = (hours < 12 || hours === '24') ? 'AM' : 'PM'; // Determine AM/PM
  return `${hours12}:${minutes} ${amPm}`;
}



const getSpotEncTypeAndTime = async (newName, newFirstName, newMiddleName, newLastName, newPhone, newDate, newTime, newEmail, newEncounterType, newAptSpecLabel, newOtherInfo, newAptLang, newDateOfBirth, newOrInfo, newPaDate, newPaTime) => {
    
  
    const encounterType = encounterTypeRef.current.value; // Capture the encounter type from a reference
    const time = timeRef.current.value; // Capture the time from a reference
    const date = dateRef.current.value; // Capture the date from a reference
    const name = (firstNameRef.current?.value?.trim() && lastNameRef.current?.value?.trim())
    ? `${firstNameRef.current.value} ${lastNameRef.current.value}`
    : nameRef.current?.value || "";
  
const phone = phoneRef.current.value; // Capture the phone from a reference

    console.log(newName)

    console.log("name: ", name)
    console.log("firstName: ", firstNameRef.current.value)
    console.log("lastName: ", lastNameRef.current.value)
    
    const dataForSpot = { encounterType, time, date, name, phone }; // Create an object with the values
    setSpecialUseDataForSpot(dataForSpot)

    setName(newName);
    setFirstName(newFirstName);
    setMiddleName(newMiddleName);
    setLastName(newLastName);
    setPhone(newPhone);
    setDate(newDate);
    setTime(newTime);
    setEmail(newEmail);
    setEncounterType(newEncounterType);
    setAptSpecLabel(newAptSpecLabel)
    setOtherInfo(newOtherInfo);
    setAptLang(newAptLang);
    setDateOfBirth(newDateOfBirth);
    setOrInfo(newOrInfo);
    setPaDate(newPaDate);
    setPaTime(newPaTime);

    return { encounterType, time, date, name, phone }; // Return an object containing the values
};


const handleSpotAvailabilityCheck = async (updating, dataForSpot) => {
  console.log("dataForSpot: ", dataForSpot);
  console.log("updating: ", updating);

  const candidateTime = dataForSpot.time;
  console.log(candidateTime);

  const candidateDate = dataForSpot.date;
  console.log(candidateDate);

  const [finalEventsForSpotSearch, allEventsForUpdatingCheck] = await fetchEventsForSpotSearch(candidateDate);

  const apptDate = dataForSpot.date;
  const apptTime = convertTime24to12WhenUpdating(dataForSpot.time);
  const apptName = dataForSpot.name.toLowerCase();
  const apptPhone = formatPhoneNumberWhenUpdating(dataForSpot.phone);
  const apptEncounterType = dataForSpot.encounterType.toLowerCase();

  console.log("apptName:", apptName);
  console.log("apptPhone:", apptPhone);
  console.log("apptTime:", apptTime);
  console.log("apptEncounterType:", apptEncounterType);
  console.log("apptDate:", apptDate);

  // Find the original encounter type object based on the lowercase comparison
  const matchingEncounter = encounterTypesForDetails.find(
    (encType) => encType.name.toLowerCase() === apptEncounterType
  );

  // If a match is found, assign the original format to origEncType
  const origEncType = matchingEncounter ? matchingEncounter.name : null;

  // Convert to ISO string (YYYY-MM-DDTHH:MM:SS.sssZ) and slice to get only the date part
  const dateString = currentDate.toISOString().slice(0, 10);

  console.log("dateString: ", dateString);
  console.log("candidateDate: ", candidateDate);

  if (updating === true && candidateDate === selectedEvent?.start) {
    console.log("Updating true!!!");
    console.log("candidateDate: ", candidateDate);
    console.log("dateString: ", dateString);

    let matchFound = false;
    for (let event of allEventsForUpdatingCheck) {
      const eventPhone = formatPhoneNumberWhenUpdating(event.resource.phone || event.resource["phone 2"]);
      const eventTime = event.time;
      const eventName = event.resource.name.toLowerCase();
      const eventEncounterType = (
        event.resource.encounterType ||
        event.resource.encType ||
        ""
      ).toLowerCase();
      const eventStart = event.start;

      console.log("Matching event on updating found.");
      console.log("eventName:", eventName, "| apptName:", apptName);
      console.log("eventPhone:", eventPhone, "| apptPhone:", apptPhone);
      console.log("eventStart:", eventStart, "| apptDate:", apptDate);
      console.log("eventEncounterType:", eventEncounterType, "| apptEncounterType:", apptEncounterType);

      // Check if the event matches the appointment details
      if (
        eventName.toLowerCase() === apptName.toLowerCase() &&
        eventPhone === apptPhone &&
        eventStart === apptDate &&
        eventEncounterType.toLowerCase() === apptEncounterType.toLowerCase()
      ) {
        // Log that a matching event is found and return true
        console.log("Matching event on updating found.");
        matchFound = true;
        return true;
      }
    }

    // Only if the loop completes with no match do we assess availability
    if (!matchFound) {
      console.log("No matching event on updating found. Assessing spot availability.");

      console.log("Assessing Spot Request Data:");
      console.log("Date:", apptDate);
      console.log("Time:", apptTime);
      console.log("Encounter Type:", origEncType);

      const response = await axios({
        method: "post",
        url: "https://us-central1-notify-pro-accb3.cloudfunctions.net/checkSpotAvailability",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          userEmail: userEmail,
          physician: physician,
          selectedCalendar: selectedCalendar,
          encounterType: origEncType,
          candidateTime: apptTime,
          candidateDate: apptDate,
        },
      });

      console.log("API response received:", response.data);
      return response.data.spotAvailable;
    }
  } else {
    console.log("Not updating!");

    console.log("Assessing Spot Request Data:");
    console.log("Date:", apptDate);
    console.log("Time:", apptTime);
    console.log("Encounter Type:", origEncType);

    const response = await axios({
      method: "post",
      url: "https://us-central1-notify-pro-accb3.cloudfunctions.net/checkSpotAvailability",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        userEmail: userEmail,
        physician: physician,
        selectedCalendar: selectedCalendar,
        encounterType: origEncType,
        candidateTime: apptTime,
        candidateDate: apptDate,
      },
    });

    console.log("API response received:", response.data);
    return response.data.spotAvailable;
  }}


// const handleSpotAvailabilityCheck = async (updating, dataForSpot) => {

//   console.log("dataForSpot: ", dataForSpot)
//   console.log("updating: ", updating)

//   const candidateTime = dataForSpot.time
//   console.log(candidateTime)

//   const candidateDate = dataForSpot.date
//   console.log(candidateDate)

//   const [finalEventsForSpotSearch, allEventsForUpdatingCheck] = await fetchEventsForSpotSearch(candidateDate);

//   const apptDate = dataForSpot.date;
//   const apptTime = convertTime24to12WhenUpdating(dataForSpot.time);
//   const apptName = dataForSpot.name.toLowerCase();
//   const apptPhone = formatPhoneNumberWhenUpdating(dataForSpot.phone);
//   const apptEncounterType = dataForSpot.encounterType.toLowerCase();

//   console.log("apptName:", apptName);
//   console.log("apptPhone:", apptPhone);
//   console.log("apptTime:", apptTime);
//   console.log("apptEncounterType:", apptEncounterType);
//   console.log("apptDate:", apptDate);

//   // Find the original encounter type object based on the lowercase comparison
//   const matchingEncounter = encounterTypesForDetails.find(
//     encType => encType.name.toLowerCase() === apptEncounterType
//   );
  
//   // If a match is found, assign the original format to origEncType
//   const origEncType = matchingEncounter ? matchingEncounter.name : null;

//   // Convert to ISO string (YYYY-MM-DDTHH:MM:SS.sssZ) and slice to get only the date part
// const dateString = currentDate.toISOString().slice(0, 10);

// console.log("dateString: ", dateString); // Outputs date in YYYY-MM-DD format
// console.log("candidateDate: ", candidateDate)
// // console.log("selectedEvent.start: ", selectedEvent.start)

//   // if (updating === true && candidateDate === dateString) {

//   if (updating === true && candidateDate === selectedEvent?.start) {
//     console.log("Updating true!!!");
//     console.log("candidateDate: ", candidateDate)
//     console.log("dateString: ", dateString)

    


//       // // Log the appointment details
//       // console.log("Appointment Details:");
//       // console.log("Date:", apptDate);
//       // console.log("Time:", apptTime);
//       // console.log("Name:", apptName);
//       // console.log("Phone:", apptPhone);
//       // console.log("Encounter Type:", apptEncounterType);

//       let matchFound = false;
//       for (let event of allEventsForUpdatingCheck) {
//           const eventPhone = formatPhoneNumberWhenUpdating(event.resource.phone || event.resource["phone 2"]);
//           const eventTime = event.time;
//           const eventName = event.resource.name.toLowerCase();
//           const eventEncounterType = (event.resource.encounterType || event.resource.encType || "").toLowerCase();
//           const eventStart = event.start;

//           console.log("Matching event on updating found.");
//           console.log("eventName:", eventName, "| apptName:", apptName);
//           console.log("eventPhone:", eventPhone, "| apptPhone:", apptPhone);
//           console.log("eventStart:", eventStart, "| apptDate:", apptDate);
//           console.log("eventEncounterType:", eventEncounterType, "| apptEncounterType:", apptEncounterType);


//           // Check if the event matches the appointment details
//           if (
//               eventName.toLowerCase() === apptName.toLowerCase() &&
//               eventPhone === apptPhone &&
//               eventStart === apptDate &&
//               eventEncounterType.toLowerCase() === apptEncounterType.toLowerCase()
//           ) {
//               // Log that a matching event is found and return true
//               console.log("Matching event on updating found.");
//               matchFound = true;
//               return true;
//           } 
      

//     } else {
//           // Log that no matching event was found if loop completes
//           console.log("No matching event on updating found. Assessing spot availability.");

//       console.log("Assesing Spot Request Data:");
//       console.log("Date:", apptDate);
//       console.log("Time:", apptTime);
//       console.log("Encounter Type:", origEncType);

//           const response = await axios({
//               method: 'post',
//               url: 'https://us-central1-notify-pro-accb3.cloudfunctions.net/checkSpotAvailability',
//               headers: {
//                   'Content-Type': 'application/json'
//               },
//               data: {
//                   userEmail: userEmail,
//                   physician: physician,
//                   selectedCalendar: selectedCalendar,
//                   encounterType: origEncType,
//                   candidateTime: apptTime,
//                   candidateDate: apptDate
//               }
//           });

//           console.log('API response received:', response.data);

//           return response.data.spotAvailable;
//       }
// };









function createDateFromDateString(dateString) {
  const parts = dateString.split('-'); // Split "YYYY-MM-DD"
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // JavaScript months are 0-indexed
  const day = parseInt(parts[2], 10);

  // Create a date object at midnight on the specified date in the local timezone
  return new Date(year, month, day);
}


async function checkPossibleSpot(data, encTypeFilter, candidateTime, candidateDate) {





      console.log(candidateDate)
      // console.log(candidateTime)

      const convertedTime = moment(candidateTime, "HH:mm").format("h:mm A");

      console.log(convertedTime)

      const dateObject = createDateFromDateString(candidateDate);

      console.log(dateObject)



      // const dateFormatted = candidateDate.toISOString().split('T')[0];


          const specialDayRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'rules', 'byDay', candidateDate);
          console.log(specialDayRef)
          const specialDaySnapshot = await getDocs(specialDayRef);
          console.log(specialDaySnapshot.docs)


          if (specialDaySnapshot && !specialDaySnapshot.empty) {
            const returnedData = await exactDateMeetsConditions(specialDaySnapshot, encTypeFilter, convertedTime, candidateDate, data);

              if (returnedData) {
                  

                  return true;
              }           

		}else{

          console.log("No special rules for this date, checking general rules...");

          // Process general rules if no special rules or conditions not met in special rules
          const generalRulesRef = collection(firestore, 'accounts', userEmail, 'physician', physician, 'calendars', selectedCalendar, 'rules', 'general', 'weeksOfMonth', determineWeekOfMonth(dateObject), dateObject.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase());
          console.log("General Rules: ", generalRulesRef)
          const querySnapshot = await getDocs(generalRulesRef);
          console.log(querySnapshot.docs)
          

            console.log(`specialDaySnapshot.empty for ${candidateDate}:`, specialDaySnapshot.empty)
            console.log(`querySnapshot.empty for ${candidateDate}:`, querySnapshot.empty)

                  

                  if (querySnapshot && !querySnapshot.empty) {

                  const returnedData = await exactDateMeetsConditions(querySnapshot, encTypeFilter, convertedTime, candidateDate, data);

                  // Check if returnedData is not null and meetsConditions is true
                  if (returnedData) {

                      console.log("Spot Available!")

                      return true;
                  }else{
   
 
  console.log("No spot available for this date and time")
  

  return false; // If no available spots are found
}}}}

     


async function exactDateMeetsConditions(daySnapshot, encTypeFilter, candidateTime, candidateDate, allEventsData) {
  // Ensure allEventsData is valid, if not, initialize as empty to avoid errors
  if (!Array.isArray(allEventsData)) {
    allEventsData = [];
  }

  console.log(allEventsData);
  console.log(encTypeFilter);
  console.log(daySnapshot.docs)

  // First, find a matching time range based on candidateTime and candidateDate that has the encounterTypeFilter
  let matchingTimeRange = null;
  for (let doc of daySnapshot.docs) {
    const timeRange = doc.id; // This represents "0800-0859" format or similar
    const data = doc.data();

    // Check if the current time range has the encounterTypeFilter
    const hasEncounterType = data.encounterTypes && data.encounterTypes.some(encounterType => encounterType.hasOwnProperty(encTypeFilter));

    // Check if time range matches and contains the necessary encounter type
    if (hasEncounterType && isTimeInRange(convertToMilitaryTime(candidateTime), timeRange)) {
      matchingTimeRange = { data, timeRange };
      console.log(data)
      console.log("Matching Time Range Found:", matchingTimeRange.timeRange);
      break;
    }
  }

  // If no matching time range is found that meets all criteria, return false
  if (!matchingTimeRange) {
    console.log("No matching time range found that meets all criteria.");
    return false;
  }

  console.log("Matching Time Range Found:", matchingTimeRange.timeRange);

  // Find if there is an event that matches the candidateDate and candidateTime
  let countToCompare = 0; // Default to 0 if no matching event is found
  const matchingEvent = allEventsData.find(event =>
    event.start === candidateDate &&
    event.time === candidateTime
  );

  if (matchingEvent) {
    countToCompare = matchingEvent.encTypeCountTag; // Use the event's count tag
    console.log("Matching event found:", matchingEvent);
  } else {
    console.log("No matching event found, using default countToCompare:", countToCompare);
  }

  // Final comparison to check if any encounter type's value is greater than countToCompare
  const isConditionMet = matchingTimeRange.data.encounterTypes && matchingTimeRange.data.encounterTypes.some(encounterType => {
    const encounterValue = encounterType[encTypeFilter];
    console.log(`Encounter Value for ${encTypeFilter} in Range ${matchingTimeRange.timeRange}: ${encounterValue}. Against count tag: ${countToCompare}`);
    return countToCompare < encounterValue;
  });

  if (isConditionMet) {
    return true; // Return true indicating a matching condition was found
  }

  console.log("No conditions met in the matching time range.");
  return false; // No matching conditions were found in the matching time range
}




  
// // Place this outside of your function, but within the same component
// useEffect(() => {

//   const event = selectedUpdateEvent; // Get the selected event from state

//   const dataForSpot = specialUseDataForSpot;
//   // This effect will trigger only when `allowProceed` becomes true
//   if (allowProceed === true) {
//     if (!isUpdating) {
//       continueAppointmentProcess(dataForSpot);
//     } else {
//       continueAppointmentUpdateProcess(dataForSpot, event);
//     }
//     // Optionally reset `allowProceed` here if it's intended for single use per component mount
//     setAllowProceed(false);
//   }
// }, [allowProceed]); // Include isUpdating in the dependencies array if its changes should also trigger this effect



useEffect(() => {
  const event = selectedUpdateEvent; // Get the selected event from state
  const dataForSpot = specialUseDataForSpot;

  // This effect will trigger only when `allowProceed` becomes true
  if (allowProceed === true && hasRunOnce.current === false) {
    if (isUpdating === false) {
      continueAppointmentProcess(dataForSpot);
    } else {
      continueAppointmentUpdateProcess(dataForSpot, event);
    }

    // // Set the ref to true after running the effect
    hasRunOnce.current = true; 
;

    // Optionally reset `allowProceed` here if it's intended for single use per component mount
    setAllowProceed(false);
  }
}, [allowProceed]);



const handlePreAptSave = async () => {

  

  if (encounterTypeRef.current.value === '') {
    alert("You must first select the Encounter Type for this appointment!");
    setIsInProcess(false);
    return;
  }

  if (firstNameRef.current.value === '') {
    alert("You must first enter the *First Name* for this appointment!");
    setIsInProcess(false);
    return;
  }

  if (lastNameRef.current.value === '') {
    alert("You must first enter the *Last Name* for this appointment!");
    setIsInProcess(false);
    return;
  }

  if (phoneRef.current.value === '') {
    alert("You must first enter the Phone Number for this appointment!");
    setIsInProcess(false);
    return;
  }

  if (dateRef.current.value === '') {
    alert("You must first enter the Date for this appointment!");
    setIsInProcess(false);
    return;
  }

  if (timeRef.current.value === '') {
    alert("You must first enter the Time for this appointment!");
    setIsInProcess(false);
    return;
  }

  if (dateOfBirthRef.current.value === '') {
    alert("You must first enter the Date of Birth for this appointment!");
    setIsInProcess(false);
    return;
  }
 

    const newName = firstNameRef.current.value + " " + lastNameRef.current.value; // Capture the name from a reference;
    const newFirstName = firstNameRef.current.value;
    const newLastName = lastNameRef.current.value;
    const newMiddleName = middleNameRef.current.value;
    const newPhone = phoneRef.current.value;
    const newDate = dateRef.current.value;
    const newTime = timeRef.current.value;
    const newEmail = emailRef.current.value;
    const newEncounterType = encounterTypeRef.current.value;
    const newAptSpecLabel = aptSpecLabelRef.current.value;
    const newAptLang = aptLangRef.current.value;
    const newDateOfBirth = dateOfBirthRef.current.value;

    console.log("newMiddleName:", newMiddleName);

    let newOtherInfo
    let newOrInfo
    let newPaDate
    let newPaTime
    if (showOrInfo === false) {
      newOtherInfo = otherInfoRef.current ? otherInfoRef.current.value : ''; // Assign value with safety check
  } else {
      newOrInfo = orInfoRef.current ? orInfoRef.current.value : ''; // Assign value with safety check
      newPaDate = paDateRef.current ? paDateRef.current.value : ''; // Assign value with safety check
      newPaTime = paTimeRef.current ? paTimeRef.current.value : ''; // Assign value with safety check
  }

console.log("newName:", newName);
console.log("newPhone:", newPhone);
console.log("newDate:", newDate);
console.log("newTime:", newTime);
console.log("newEmail:", newEmail);
console.log("newEncounterType:", newEncounterType);
console.log("newAptSpecLabel:", newAptSpecLabel);
console.log("newOtherInfo:", newOtherInfo);
console.log("newAptLang:", newAptLang);
console.log("newDateOfBirth:", newDateOfBirth);
console.log("newOrInfo:", newOrInfo);
console.log("newPaDate:", newPaDate);
console.log("newPaTime:", newPaTime);

      // setName(newName);
      // setPhone(newPhone);
      // setDate(newDate);
      // setTime(newTime);
      // setEmail(newEmail);
      // setEncounterType(newEncounterType);
      // setAptSpecLabel(newAptSpecLabel)
      // setOtherInfo(newOtherInfo);
      // setAptLang(newAptLang);
      // setDateOfBirth(newDateOfBirth);
      // setOrInfo(newOrInfo);
      // setPaDate(newPaDate);
      // setPaTime(newPaTime);

      const dataForSpot = await getSpotEncTypeAndTime(newName, newFirstName, newMiddleName, newLastName, newPhone, newDate, newTime, newEmail, newEncounterType, newAptSpecLabel, newOtherInfo, newAptLang, newDateOfBirth, newOrInfo, newPaDate, newPaTime)
      
      setIsUpdating(false);
      setIsInProcess(true);

      console.log("newName:", firstName + " " + lastName);
      console.log("newPhone:", phone);
      console.log("newDate:", date);
      console.log("newTime:", time);
      console.log("newEmail:", email);
      console.log("newEncounterType:", encounterType);
      console.log("newAptSpecLabel:", aptSpecLabel);
      console.log("newOtherInfo:", otherInfo);
      console.log("newAptLang:", aptLang);
      console.log("newDateOfBirth:", dateOfBirth);
      console.log("newOrInfo:", orInfo);
      console.log("newPaDate:", paDate);
      console.log("newPaTime:", paTime);
      


      

  const checkedDate = dateRef.current.value; // Expected in YYYY-MM-DD format
  const checkedTime = timeRef.current.value; // Expected in HH:MM format

  // Convert checkedDate and checkedTime from YYYY-MM-DD and HH:MM to a full ISO string with correct timezone
  const checkedDateWithTime = convertYYYYMMDDHHMMToISOWithTimezone(checkedDate, checkedTime);

  // Parse the ISO string back to a Date object for comparison
  const parsedCheckedDate = new Date(checkedDateWithTime);

  // Get the current date and time from the user's system
  const currentDate = new Date();

  // Ensure currentDate is not ahead due to the creation milliseconds difference
  currentDate.setSeconds(0, 0);

  console.log(parsedCheckedDate);
  console.log(currentDate);
  console.log(dataForSpot);

      // Conditional check for past date
      if (parsedCheckedDate < currentDate) {
        handleOverridePastEventModalOpen(); // Open modal for user decision
        return; // Early return to wait for user interaction
    }

    // Continue process if date is valid
    console.log(dataForSpot)
    continueAppointmentProcess(dataForSpot, newName, newFirstName, newMiddleName, newLastName, newPhone, newDate, newTime, newEmail, newEncounterType, newAptSpecLabel, newOtherInfo, newAptLang, newDateOfBirth, newOrInfo, newPaDate, newPaTime);
};

const continueAppointmentProcess = async (dataForSpot, newName, newFirstName, newMiddleName, newLastName, newPhone, newDate, newTime, newEmail, newEncounterType, newAptSpecLabel, newOtherInfo, newAptLang, newDateOfBirth, newOrInfo, newPaDate, newPaTime) => {
  
  console.log("newMiddleName:", newMiddleName);
  
  if (rulesStatus) {
      // setIsUpdating(false);
      const updating = false;
      
      console.log("continueAppointmentProcess dataForSpot: ", dataForSpot)
      console.log("continueAppointmentProcess updating: ", updating)

      const isSpotAvailable = await handleSpotAvailabilityCheck(updating, dataForSpot);
      if (!isSpotAvailable) {
          handleNoSpotModalOpen();
      } else {
        console.log("Before handleSaveAppointment newMiddleName:", newMiddleName);

          await handleSaveAppointment(newName, newFirstName, newMiddleName, newLastName, newPhone, newDate, newTime, newEmail, newEncounterType, newAptSpecLabel, newOtherInfo, newAptLang, newDateOfBirth, newOrInfo, newPaDate, newPaTime); // Proceed to save the appointment if the spot is available
          hasRunOnce.current = false
          return;
      }
  } else {
    console.log("Before handleSaveAppointment newMiddleName:", newMiddleName);

      await handleSaveAppointment(newName, newFirstName, newMiddleName, newLastName, newPhone, newDate, newTime, newEmail, newEncounterType, newAptSpecLabel, newOtherInfo, newAptLang, newDateOfBirth, newOrInfo, newPaDate, newPaTime); // Proceed to save the appointment if rulesStatus is false
      hasRunOnce.current = false; 
      return;
  }
};



// const handlePreAptSave = async () => {

//   const checkedDate = dateRef.current.value;  // Expected in YYYY-MM-DD format
//   const checkedTime = timeRef.current.value;  // Expected in HH:MM format

//   // Convert checkedDate and checkedTime from YYYY-MM-DD and HH:MM to a full ISO string with correct timezone
//   const checkedDateWithTime = convertYYYYMMDDHHMMToISOWithTimezone(checkedDate, checkedTime);
  
//   // Parse the ISO string back to a Date object for comparison
//   const parsedCheckedDate = new Date(checkedDateWithTime);
  
//   // Get the current date and time from the user's system
//   const currentDate = new Date();
  
//   // Ensure currentDate is not ahead due to the creation milliseconds difference
//   currentDate.setSeconds(0, 0);

//   console.log(parsedCheckedDate)
//   console.log(currentDate)
  
//   // Check if the provided date and time are in the past compared to the current system date and time
//   if (parsedCheckedDate < currentDate) {
//     alert("Appointments cannot be created in the past!");
//     return; // This stops the further execution of the function
//   }
  
  

//   if (rulesStatus) {
//     setIsUpdating(false);
//     const updating = false
//       const isSpotAvailable = await handleSpotAvailabilityCheck(updating);
//       if (!isSpotAvailable) {
//           handleNoSpotModalOpen()
//           // alert("This appointment date/time slot is not available! Please select another.");
//           // return; // Stop the function if the spot is not available
//       } else {
//           handleSaveAppointment(); // Proceed to save the appointment if the spot is available
//       }
//   } else {
//       handleSaveAppointment(); // Proceed to save the appointment if rulesStatus is false
//   }
// };

// useEffect(() => {
//   if (isUpdatingOldNotifications === true && readyToHideOldNotifications === true) {




  
  // } else {
  //   console.log("Not ready to hide old notifications yet.")
  // }

// }, [readyToHideOldNotifications]);

// useEffect(() => {
//   console.log(selectedUpdateEvent)
// }, [selectedUpdateEvent]); 



const handleBeforePreAptUpdate = async () => {
  const currentDate = new Date(); // Ensure current date is defined
  const checkedDateWithTime = convertYYYYMMDDHHMMToISOWithTimezone(openingDate, openingTime); // Convert date and time to ISO string
  const checkedNewDateWithTime = convertYYYYMMDDHHMMToISOWithTimezone(dateRef.current.value, timeRef.current.value); // Convert new date and time to ISO string

  // Parse the ISO string back to a Date object for comparison
  const parsedCheckedDate = new Date(checkedDateWithTime);
  const parsedNewCheckedDate = new Date(checkedNewDateWithTime);

  // Conditional check for past date
  if (parsedCheckedDate < currentDate && parsedNewCheckedDate < currentDate) {
    alert("Cannot update past appointments!"); // Alert user
  } else if (parsedCheckedDate < currentDate && parsedNewCheckedDate > currentDate) {
    alert("Cannot move past appointments. Will create a new appointment instead!"); // Alert user
    handlePreAptSave(); // Save new appointment
  } else {
    handlePreAptUpdate(); // Update appointment
  }
};

// const handleBeforePreAptUpdate = async () => {
//   const checkedDateWithTime = convertYYYYMMDDHHMMToISOWithTimezone(openingDate, openingTime); // Convert date and time to ISO string
//   const checkedNewDateWithTime = convertYYYYMMDDHHMMToISOWithTimezone(date, time); // Convert new date and time to ISO string   
//     // Parse the ISO string back to a Date object for comparison
//     const parsedCheckedDate = new Date(checkedDateWithTime);
//     const parsedNewCheckedDate = new Date(checkedNewDateWithTime);
//   // Conditional check for past date
//      if (parsedCheckedDate < currentDate && parsedNewCheckedDate < currentDate) {
//       alert("Cannot update past appointments!") // Open modal for user decision
//   }else if (parsedCheckedDate < currentDate && parsedNewCheckedDate > currentDate) {
//     alert("Cannot move past appointments. Will create a new appointment instead!") // Open modal for user decision
//     handlePreAptSave();
//   }else{
//     handlePreAptUpdate();
//   }
// }

const handlePreAptUpdate = async () => {

  


  if (encounterTypeRef.current.value === '') {
    alert("You must first select the Encounter Type for this appointment!");
    setIsInProcess(false);
    return;
  }

  if (nameRef.current.value === '') {
    alert("You must first enter the *First Name* for this appointment!");
    setIsInProcess(false);
    return;
  }

  // if (lastNameRef.current.value === '') {
  //   alert("You must first enter the *Last Name* for this appointment!");
  //   setIsInProcess(false);
  //   return;
  // }

  if (phoneRef.current.value === '') {
    alert("You must first enter the Phone Number for this appointment!");
    setIsInProcess(false);
    return;
  }

  if (dateRef.current.value === '') {
    alert("You must first enter the Date for this appointment!");
    setIsInProcess(false);
    return;
  }

  if (timeRef.current.value === '') {
    alert("You must first enter the Time for this appointment!");
    setIsInProcess(false);
    return;
  }

  if (dateOfBirthRef.current.value === '') {
    alert("You must first enter the Date of Birth for this appointment!");
    setIsInProcess(false);
    return;
  }

  // console.log(selectedEvent)
  const event = selectedEvent;


  const newName = (firstNameRef.current?.value?.trim() && lastNameRef.current?.value?.trim())
  ? `${firstNameRef.current.value} ${lastNameRef.current.value}`
  : nameRef.current?.value || "";
  const newFirstName = firstNameRef.current.value;
  const newLastName = lastNameRef.current.value;
  const newMiddleName = middleNameRef.current.value;
  const newPhone = phoneRef.current.value;
  const newDate = dateRef.current.value;
  const newTime = timeRef.current.value;
  const newEmail = emailRef.current.value;
  const newEncounterType = encounterTypeRef.current.value;
  const newAptSpecLabel = aptSpecLabelRef.current.value;
  const newAptLang = aptLangRef.current.value;
  const newDateOfBirth = dateOfBirthRef.current.value;

  let newOtherInfo
  let newOrInfo
  let newPaDate
  let newPaTime
  if (showOrInfo === false) {
    newOtherInfo = otherInfoRef.current ? otherInfoRef.current.value : ''; // Assign value with safety check
} else {
    newOrInfo = orInfoRef.current ? orInfoRef.current.value : ''; // Assign value with safety check
    newPaDate = paDateRef.current ? paDateRef.current.value : ''; // Assign value with safety check
    newPaTime = paTimeRef.current ? paTimeRef.current.value : ''; // Assign value with safety check
}


    // setName(newName);
    // setPhone(newPhone);
    // setDate(newDate);
    // setTime(newTime);
    // setEmail(newEmail);
    // setEncounterType(newEncounterType);
    // setAptSpecLabel(newAptSpecLabel)
    // setOtherInfo(newOtherInfo);
    // setAptLang(newAptLang);
    // setDateOfBirth(newDateOfBirth);
    // setOrInfo(newOrInfo);
    // setPaDate(newPaDate);
    // setPaTime(newPaTime);

      const dataForSpot = await getSpotEncTypeAndTime(newName, newFirstName, newMiddleName, newLastName, newPhone, newDate, newTime, newEmail, newEncounterType, newAptSpecLabel, newOtherInfo, newAptLang, newDateOfBirth, newOrInfo, newPaDate, newPaTime)

    setIsInProcess(true);
    setSelectedUpdateEvent(selectedEvent);
    setIsUpdating(true);
    setIsUpdatingOldNotifications(true);

  // Get the date and time input from the user
  const checkedDate = dateRef.current.value; // Expected in YYYY-MM-DD format
  const checkedTime = timeRef.current.value; // Expected in HH:MM format

  // Convert checkedDate and checkedTime from YYYY-MM-DD and HH:MM to a full ISO string with correct timezone
  const checkedDateWithTime = convertYYYYMMDDHHMMToISOWithTimezone(checkedDate, checkedTime);

  // Parse the ISO string back to a Date object for comparison
  const parsedCheckedDate = new Date(checkedDateWithTime);

  // Get the current date and time from the user's system
  const currentDate = new Date();
  currentDate.setSeconds(0, 0); // Ensure currentDate is not ahead due to the creation milliseconds difference

  // Log dates for debugging
  console.log("Parsed Checked Date:", parsedCheckedDate);
  console.log("Current Date:", currentDate);

     // Conditional check for past date
     if (parsedCheckedDate < currentDate) {
      handleOverridePastEventModalOpen(); // Open modal for user decision
      return; // Early return to wait for user interaction
  }

  console.log(event)
  continueAppointmentUpdateProcess(dataForSpot, event, newName, newFirstName, newMiddleName, newLastName, newPhone, newDate, newTime, newEmail, newEncounterType, newAptSpecLabel, newOtherInfo, newAptLang, newDateOfBirth, newOrInfo, newPaDate, newPaTime); // Proceed with the rest of the appointment update logic
};

// useEffect(() => {
//   console.log(hasRunOnce)
// }, [hasRunOnce]);

const continueAppointmentUpdateProcess = async (dataForSpot, event, newName, newFirstName, newMiddleName, newLastName, newPhone, newDate, newTime, newEmail, newEncounterType, newAptSpecLabel, newOtherInfo, newAptLang, newDateOfBirth, newOrInfo, newPaDate, newPaTime) => {
  // Check availability of the spot according to rules status
  if (rulesStatus) {
      // setIsUpdating(true);
      // let timer;
      const updating = true;

      console.log("continueAppointmentProcess dataForSpot: ", dataForSpot)
      console.log("continueAppointmentProcess updating: ", updating)

      const isSpotAvailable = await handleSpotAvailabilityCheck(updating, dataForSpot);
      console.log("Spot Availability Response: ", isSpotAvailable)
      if (!isSpotAvailable) {
          handleNoSpotModalOpen(); // Show a modal indicating no spot is available
      } else {
        await handleUpdateAppointment(newName, newFirstName, newMiddleName, newLastName, newPhone, newDate, newTime, newEmail, newEncounterType, newAptSpecLabel, newOtherInfo, newAptLang, newDateOfBirth, newOrInfo, newPaDate, newPaTime); // Call the update function if isUpdating is true
        console.log(event)
        await hideOldNotificationsOnApptUpdate(event);
        fetchEvents();
        setCurrentView('day');
        const newDateObj = newDate || date; // This seems redundant, you might want to use 'newDate' directly
    
        // Split the date string into components
        const [year, month, day] = newDateObj.split('-').map(Number);
    
        // Create a new date object using local time zone
        const adjustedDate = new Date(year, month - 1, day);
        setCurrentDate(adjustedDate);
        handleCloseExistentAptModal();
        console.log("Appointment updated successfully!");
        alert('Appointment updated successfully!');
        setIsUpdating(false);
        setIsInProcess(false);
              // Set a timeout to reset the ref after 5 seconds
        hasRunOnce.current = false
        return;
      }
  } else {
    await handleUpdateAppointment(newName, newFirstName, newMiddleName, newLastName, newPhone, newDate, newTime, newEmail, newEncounterType, newAptSpecLabel, newOtherInfo, newAptLang, newDateOfBirth, newOrInfo, newPaDate, newPaTime); // Call the update function if isUpdating is true
    console.log(event)
    await hideOldNotificationsOnApptUpdate(event);
    fetchEvents();
    setCurrentView('day');
    const newDateObj = newDate; // This seems redundant, you might want to use 'newDate' directly

    // Split the date string into components
    const [year, month, day] = newDateObj.split('-').map(Number);

    // Create a new date object using local time zone
    const adjustedDate = new Date(year, month - 1, day);
    setCurrentDate(adjustedDate);
    handleCloseExistentAptModal();
    console.log("Appointment updated successfully!");
    alert('Appointment updated successfully!');
    setIsUpdating(false);
    setIsInProcess(false);
    hasRunOnce.current = false
    return;
  }
};


// const handlePreAptUpdate = async () => {
//   // Start by setting `updating` to `true`
  

//   // Get the date and time input from the user
//   const checkedDate = dateRef.current.value;  // Expected in YYYY-MM-DD format
//   const checkedTime = timeRef.current.value;  // Expected in HH:MM format

//   // Convert checkedDate and checkedTime from YYYY-MM-DD and HH:MM to a full ISO string with correct timezone
//   const checkedDateWithTime = convertYYYYMMDDHHMMToISOWithTimezone(checkedDate, checkedTime);

//   // Parse the ISO string back to a Date object for comparison
//   const parsedCheckedDate = new Date(checkedDateWithTime);

//   // Get the current date and time from the user's system
//   const currentDate = new Date();

//   // Ensure currentDate is not ahead due to the creation milliseconds difference
//   currentDate.setSeconds(0, 0);

//   // Log dates for debugging
//   console.log("Parsed Checked Date:", parsedCheckedDate);
//   console.log("Current Date:", currentDate);

//   // Check if the provided date and time are in the past compared to the current system date and time
//   if (parsedCheckedDate < currentDate) {
//       alert("Appointments cannot be created in the past!");
//       return; // This stops the further execution of the function
//   }

  

//   // Check availability of the spot according to rules status
//   if (rulesStatus) {
//     setIsUpdating(true);
//     const updating = true
//       const isSpotAvailable = await handleSpotAvailabilityCheck(updating);
//       if (!isSpotAvailable) {
//           handleNoSpotModalOpen();  // Show a modal indicating no spot is available
//           // setUpdating(false);  // Reset `updating` status
//       } else {
//           handleUpdateAppointment(); // Proceed to save the appointment if the spot is available
//           // setUpdating(false);  // Reset `updating` status
//       }
//   } else {
//       handleUpdateAppointment(); // Proceed to save the appointment if rulesStatus is false
//       // setUpdating(false);  // Reset `updating` status
//   }
// };


// const handlePreAptUpdate = async () => {

//   setUpdating(true);

//   const checkedDate = dateRef.current.value;  // Expected in YYYY-MM-DD format
//   const checkedTime = timeRef.current.value;  // Expected in HH:MM format

//   // Convert checkedDate and checkedTime from YYYY-MM-DD and HH:MM to a full ISO string with correct timezone
//   const checkedDateWithTime = convertYYYYMMDDHHMMToISOWithTimezone(checkedDate, checkedTime);
  
//   // Parse the ISO string back to a Date object for comparison
//   const parsedCheckedDate = new Date(checkedDateWithTime);
  
//   // Get the current date and time from the user's system
//   const currentDate = new Date();
  
//   // Ensure currentDate is not ahead due to the creation milliseconds difference
//   currentDate.setSeconds(0, 0);

//   console.log(parsedCheckedDate)
//   console.log(currentDate)
  
//   // Check if the provided date and time are in the past compared to the current system date and time
//   if (parsedCheckedDate < currentDate) {
//     alert("Appointments cannot be created in the past!");
//     return; // This stops the further execution of the function
//   }
  
  

//   if (rulesStatus) {
//       setUpdating(true);
//       const isSpotAvailable = await handleSpotAvailabilityCheck();
//       if (!isSpotAvailable) {
//         handleNoSpotModalOpen()
//         setUpdating(false);
//         // alert("This appointment date/time slot is not available! Please select another.");
//         // return; // Stop the function if the spot is not available
//       } else {
//         handleUpdateAppointment(); // Proceed to save the appointment if the spot is available
//         setUpdating(false);
//       }
//   } else {
//     handleUpdateAppointment(); // Proceed to save the appointment if rulesStatus is false
//     setUpdating(false);
//   }
// };



const modalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: modalSize === 'default' ? '50%' : 'auto',
  left: modalSize === 'minimized' ? 'calc(20% + 160px)' : '50%', // Position in the second fifth of the screen width
  transform: modalSize === 'default' ? 'translate(-50%, -50%)' : 'translateX(-50%)',
  width: modalSize === 'minimized' ? '320px' : '80%', // Set fixed width when minimized
  maxWidth: '800px',
  height: modalSize === 'minimized' ? '60px' : 'auto',
  overflow: 'hidden',
  bottom: modalSize === 'minimized' ? '0' : 'auto',
  transition: 'all 0.5s ease',
  zIndex: 1300 // Ensure it stays on top
};

const handleModalClose = (event, reason) => {
  if (reason !== 'backdropClick' || modalSize === 'default') {
      handleCloseNewAptModal();
  }
};

const handleModalMinimize = () => {
  const newName = nameRef.current.value ?? ''; // Assign value with safety check
  const newFirstName = firstNameRef.current.value ?? '';
  const newMiddleName = middleNameRef.current.value ?? '';
  const newLastName = lastNameRef.current.value ?? '';
  const newPhone = phoneRef.current.value ?? ''; // Assign value with safety check
  const newDate = dateRef.current.value ?? ''; // Assign value with safety check
  const newTime = timeRef.current.value ?? ''; // Assign value with safety check
  const newEmail = emailRef.current.value ?? ''; // Assign value with safety check
  const newEncounterType = encounterTypeRef.current.value ?? ''; // Assign value with safety check
  const newAptSpecLabel = aptSpecLabelRef.current.value ?? ''; // Assign value with safety check
  const newAptLang = aptLangRef.current.value ?? ''; // Assign value with safety check
  const newDateOfBirth = dateOfBirthRef.current.value ?? ''; // Assign value with safety check

  let newOtherInfo
  let newOrInfo
  let newPaDate
  let newPaTime
  if (showOrInfo === false) {
    newOtherInfo = otherInfoRef.current ? otherInfoRef.current.value : ''; // Assign value with safety check
} else {
    newOrInfo = orInfoRef.current ? orInfoRef.current.value : ''; // Assign value with safety check
    newPaDate = paDateRef.current ? paDateRef.current.value : ''; // Assign value with safety check
    newPaTime = paTimeRef.current ? paTimeRef.current.value : ''; // Assign value with safety check
}

console.log("newName:", newName);
console.log("newPhone:", newPhone);
console.log("newDate:", newDate);
console.log("newTime:", newTime);
console.log("newEmail:", newEmail);
console.log("newEncounterType:", newEncounterType);
console.log("newAptSpecLabel:", newAptSpecLabel);
console.log("newOtherInfo:", newOtherInfo);
console.log("newAptLang:", newAptLang);
console.log("newDateOfBirth:", newDateOfBirth);
console.log("newOrInfo:", newOrInfo);
console.log("newPaDate:", newPaDate);
console.log("newPaTime:", newPaTime);

setName(newName);
setFirstName(newFirstName);
setMiddleName(newMiddleName);
setLastName(newLastName);
setPhone(newPhone);
setDate(newDate);
setTime(newTime);
setEmail(newEmail);
setEncounterType(newEncounterType);
setAptSpecLabel(newAptSpecLabel)
setAptLang(newAptLang);
setDateOfBirth(newDateOfBirth);

if (showOrInfo === false) {
  setOtherInfo(newOtherInfo);
} else {
  setOrInfo(newOrInfo);
  setPaDate(newPaDate);
  setPaTime(newPaTime);
}

  setModalSize('minimized')
};


const secondModalStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: secondModalSize === 'default' ? '50%' : 'auto',
  left: secondModalSize === 'minimized' ? 'calc(60% + 160px)' : '50%', // Position in the fourth fifth of the screen
  transform: secondModalSize === 'default' ? 'translate(-50%, -50%)' : 'translateX(-50%)',
  width: secondModalSize === 'minimized' ? '320px' : '80%', // Fixed width when minimized
  maxWidth: '800px',
  height: secondModalSize === 'minimized' ? '60px' : 'auto',
  overflow: 'hidden',
  bottom: secondModalSize === 'minimized' ? '0' : 'auto',
  transition: 'all 0.5s ease',
  zIndex: 1300 // Ensure it stays on top
};

const handleSecondModalClose = (event, reason) => {
  if (reason !== 'backdropClick' || secondModalSize === 'default') {
      handleCloseExistentAptModal();
  }
};

const handleSecondModalMinimize = () => {
  const newName = nameRef.current.value ?? ''; // Assign value with safety check
  const newFirstName = firstNameRef.current.value ?? '';
  const newMiddleName = middleNameRef.current.value ?? '';
  const newLastName = lastNameRef.current.value ?? '';
  const newPhone = phoneRef.current.value ?? ''; // Assign value with safety check
  const newDate = dateRef.current.value ?? ''; // Assign value with safety check
  const newTime = timeRef.current.value ?? ''; // Assign value with safety check
  const newEmail = emailRef.current.value ?? ''; // Assign value with safety check
  const newEncounterType = encounterTypeRef.current.value ?? ''; // Assign value with safety check
  const newAptSpecLabel = aptSpecLabelRef.current.value ?? ''; // Assign value with safety check
  const newAptLang = aptLangRef.current.value ?? ''; // Assign value with safety check
  const newDateOfBirth = dateOfBirthRef.current.value ?? ''; // Assign value with safety check

  let newOtherInfo
  let newOrInfo
  let newPaDate
  let newPaTime
  if (showOrInfo === false) {
    newOtherInfo = otherInfoRef.current ? otherInfoRef.current.value : ''; // Assign value with safety check
} else {
    newOrInfo = orInfoRef.current ? orInfoRef.current.value : ''; // Assign value with safety check
    newPaDate = paDateRef.current ? paDateRef.current.value : ''; // Assign value with safety check
    newPaTime = paTimeRef.current ? paTimeRef.current.value : ''; // Assign value with safety check
}

console.log("newName:", newName);
console.log("newPhone:", newPhone);
console.log("newDate:", newDate);
console.log("newTime:", newTime);
console.log("newEmail:", newEmail);
console.log("newEncounterType:", newEncounterType);
console.log("newAptSpecLabel:", newAptSpecLabel);
console.log("newOtherInfo:", newOtherInfo);
console.log("newAptLang:", newAptLang);
console.log("newDateOfBirth:", newDateOfBirth);
console.log("newOrInfo:", newOrInfo);
console.log("newPaDate:", newPaDate);
console.log("newPaTime:", newPaTime);

    setName(newName);
    setFirstName(newFirstName);
    setMiddleName(newMiddleName);
    setLastName(newLastName);
    setPhone(newPhone);
    setDate(newDate);
    setTime(newTime);
    setEmail(newEmail);
    setEncounterType(newEncounterType);
    setAptSpecLabel(newAptSpecLabel)
    setAptLang(newAptLang);
    setDateOfBirth(newDateOfBirth);

    if (showOrInfo === false) {
      setOtherInfo(newOtherInfo);
} else {
      setOrInfo(newOrInfo);
      setPaDate(newPaDate);
      setPaTime(newPaTime);
}

  setSecondModalSize('minimized')
};


// const backdropStyle = {
//   backgroundColor: modalSize === 'minimized' ? 'transparent' : undefined,
//   pointerEvents: modalSize === 'minimized' ? 'none' : undefined,
// };





const filteredResults = useMemo(() => {
  const appointmentResults = searchResults.filter(result => result.tag === 'appointment');
  const nonAppointmentResults = searchResults.filter(result => !result.tag || result.tag !== 'appointment');

  const getLastTenDigits = (phoneNumber) => {
    const digits = phoneNumber.replace(/\D/g, '');
    return digits.slice(-10);
  };

  const isAssociatedWithAppointment = (result, appointmentResult) => {
    return result.resource.name === appointmentResult.resource.name &&
           result.start === appointmentResult.start &&
           result.time === appointmentResult.time &&
           (result.resource.encType || result.resource.encounterType) === (appointmentResult.resource.encType || appointmentResult.resource.encounterType) &&
           getLastTenDigits(result.resource.phone) === getLastTenDigits(appointmentResult.resource.phone);
  };

  const groupedNonAppointmentResults = nonAppointmentResults.reduce((groups, result) => {
    const matchingAppointment = appointmentResults.find(appointmentResult => 
      isAssociatedWithAppointment(result, appointmentResult)
    );

    if (matchingAppointment) {
      // If associated with an appointment, skip it
      return groups;
    }

    const groupKey = `${result.resource.name}-${result.start}-${result.time}-${result.resource.encType || result.resource.encounterType}-${getLastTenDigits(result.resource.phone)}`;
    
    if (!groups[groupKey] || new Date(result.resource.dateId) > new Date(groups[groupKey].resource.dateId)) {
      groups[groupKey] = result;
    }

    return groups;
  }, {});

  const mostRecentNonAppointmentResults = Object.values(groupedNonAppointmentResults);

  return [...appointmentResults, ...mostRecentNonAppointmentResults];
}, [searchResults]);
   









  


    return (
      <>


    <Box
  sx={{
    position: 'sticky',
    top: -1,
    backgroundColor: 'white',
    zIndex: 9999
  }}
>
        
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '1.4%' }}>

<Button 
    aria-controls="user-popover" 
    aria-haspopup="true" 
    onClick={handleOpenNewAptModal}
    style={{
        backgroundColor: '#3f51b5',
        whiteSpace: 'nowrap', 
        color: '#fff'
    }}
    // disabled={currentView !== 'day'} // disables the button when not in 'day' view
>
    Add New Appointment
</Button>



<Button 
    aria-controls="enctype-popover" 
    aria-haspopup="true" 
    onClick={handleEncTypePopoverOpen}
    style={{
        backgroundColor: '#2C2C2C',
        color: '#fff',
        marginLeft: '2%', 
        whiteSpace: 'nowrap' // prevent line breaks
    }}
    // disabled={currentView !== 'day'} // disables the button when not in 'day' view
>
    Filter by Enc. Type
</Button>
<Popover
    id="enctype-popover"
    open={Boolean(encTypeAnchorEl)}
    anchorEl={encTypeAnchorEl}
    onClose={handleEncTypePopoverClose}
    anchorReference="anchorPosition"
    anchorPosition={ anchorPosition }
>
    <EncTypeMenuItems encTypeFilterRef={encTypeFilterRef} handleEncTypeFilterChange={handleEncTypeFilterChange} />
    <MenuItem
        style={{
            backgroundColor: '#3f51b5',
            color: '#fff',
            display: 'flex',
            justifyContent: 'center'
        }}
        onClick={handleFinalEncTypeFilterChange}
    >
        Apply
    </MenuItem>
</Popover>



                    </div>
                    {/* <div class="toolbar-label-container">
                        <Typography variant="h6" style={{ fontSize: '22px' }}>{toolbar.label}</Typography>
                    </div> */}

<div>
            <div className="toolbar-label-container">

<Tooltip title={isLoading ? "Loading..." : "Click to change the date!"}>
  <Typography
    variant="h6"
    style={{
      fontSize: '22px',
      cursor: isLoading ? 'not-allowed' : 'pointer',
      color: isLoading ? 'gray' : 'inherit', // Optional: change text color when disabled
    }}
    onClick={(e) => {
      if (!isLoading) {
        handleOpenDatePicker(e);
      }
    }}
  >
    {toolbar.label} {/* Assuming toolbar.label is defined */}
  </Typography>
</Tooltip>
            {/* <Tooltip title="Click to change the date!">
                <Typography variant="h6" style={{ fontSize: '22px', cursor: 'pointer' }} onClick={handleOpenDatePicker}>
                    {toolbar.label} 
                </Typography>
            </Tooltip> */}

            </div>

            <Dialog
    open={openDatePicker}
    onClose={() => setOpenDatePicker(false)}
    PaperProps={{
        style: {
            width: '100%',
            height: '100%',         // Ensures dialog takes the full width of the screen/container
            maxWidth: '140px',     // Limits maximum width to 400px on larger screens
            minWidth: '140px',
            maxHeight: '46px',     
            minHeight: '46px',
            margin: '0 auto',      // Centers the dialog on larger screens and removes default margins
        }
    }}
>
    <DialogContent style={{ overflow: 'hidden', padding: 0 }}>
        {/* Adding padding:0 to DialogContent removes padding around the input, allowing it to fill the space */}
        <input
            type="date"
            value={currentDate}
            onChange={handleDatePickerChange}
            style={{
                width: 'calc(100% - 24px)', // Adjusts width to account for potential padding or borders
                margin: '12px',             // Optionally adds margin for visual spacing inside the dialog
                boxSizing: 'border-box',    // Ensures the width includes any padding and border widths
            }}
        />
    </DialogContent>
</Dialog>


        </div>

                    <div style={{ opacity: 0, paddingRight: '14.1rem'}}> {/* This is a placeholder to balance the flex layout */}
                        <FormControl variant="filled" style={{ margin: '0 10px'}}>
                            <InputLabel>Placeholder</InputLabel>
                            <Select disabled>
                                <MenuItem value=""></MenuItem>
                            </Select>
                        </FormControl>
                        
                    </div>
                </div>
                
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0.5%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>

                    <div style={{ display: 'flex', alignItems: 'center', gap: '0px' }}>  {/* Adjust gap as needed */}
                    <Tooltip title="Print">
            <span> {/* Tooltip requires a non-disabled element as a child */}
                <Button 
                    disabled={currentView === 'month' || currentView === 'week'} 
                    onClick={handlePrint}
                    style={{ marginLeft: '5%' }}
                >
                    <PrintIcon />
                </Button>
            </span>
        </Tooltip>
        <Tooltip title="Export">
                <span>
                    <Button
                        disabled={currentView === 'month' || currentView === 'week'}
                        onClick={handleExportClick}
                        style={{ paddingRight: '15%', marginLeft: '-26%' }}
                    >
                        <GetAppIcon />
                    </Button>
                </span>
            </Tooltip>
            <Popover
                id="export-options-popover"
                open={Boolean(exportAnchorPosition)}
                anchorReference="anchorPosition"
                anchorPosition={exportAnchorPosition ? { top: exportAnchorPosition.top, left: exportAnchorPosition.left } : undefined}
                onClose={() => setExportAnchorPosition(null)}
            >
                <MenuItem onClick={() => handleExportOption('noShow')}>
                    Export 'No Show' only
                </MenuItem>
                <MenuItem onClick={() => handleExportOption('cancellations')}>
                    Export cancellations
                </MenuItem>
                <MenuItem onClick={() => handleExportOption('unconfirmed')}>
                    Export unconfirmed
                </MenuItem>
                <MenuItem onClick={() => handleExportOption('allCurrentDay')}>
                    Export all current day events
                </MenuItem>
                {/* Add your toggle options here similar to your existing popover */}
            </Popover>

                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClickOptions}
                            style={{ marginLeft: '-12%' }}
                        >
                            Options <MoreVertIcon />
                        </Button>
                    

            <Popover
    id="options-popover"
    open={Boolean(optionsAnchorPosition)}
    anchorReference="anchorPosition"
    anchorPosition={optionsAnchorPosition ? { top: optionsAnchorPosition.top, left: optionsAnchorPosition.left } : undefined}
    onClose={() => setOptionsAnchorPosition(null)}
>
    <MenuItem>
        <FormControlLabel
            control={<Switch checked={showConfirmed} onChange={() => setShowConfirmed(!showConfirmed)} />}
            label="Confirm Requested Only"
        />
    </MenuItem>
    <MenuItem>
        <FormControlLabel
            control={<Switch checked={showByColor} onChange={() => setShowByColor(!showByColor)} />}
            label="Show by Encounter Type"
        />
    </MenuItem>
    <MenuItem>
        <FormControlLabel
            control={<Switch checked={showAllEvents} onChange={() => setShowAllEvents(!showAllEvents)} />}
            label="Show Hidden"
        />
    </MenuItem>
</Popover>

        </div>
                    <div style={{ marginLeft: '6%' }}>
<Button onClick={goToBack} disabled={isLoading}>
  <FastRewindIcon />
</Button>
<Button onClick={goToToday} disabled={isLoading}>
  <Typography variant="h6" style={{ fontSize: '16px' }}>Today</Typography>
</Button>
<Button onClick={goToNext} disabled={isLoading}>
  <FastForwardIcon />
</Button>
    {/* <Button onClick={goToBack}>
    <FastRewindIcon />
    </Button>
    <Button onClick={goToToday}>
        <Typography variant="h6" style={{ fontSize: '16px' }}>Today</Typography>
    </Button>
    <Button onClick={goToNext}>
    <FastForwardIcon />
    </Button> */}
</div>
<div style={{ display: 'flex', paddingRight: '3.3%' }}>
    <Button onClick={goToMonth} style={{
        backgroundColor: currentView === 'month' ? '#fff' : '#3f51b5',
        color: currentView === 'month' ? '#3f51b5' : '#fff',
        marginLeft: '2%', 
        marginRight: '2%',
        whiteSpace: 'nowrap'
    }}>
        <Typography variant="h6" style={{ fontSize: '13px' }}>Month</Typography>
    </Button>
    <Button onClick={goToWeek} style={{
        backgroundColor: currentView === 'week' ? '#fff' : '#3f51b5',
        color: currentView === 'week' ? '#3f51b5' : '#fff',
        marginLeft: '2%', 
        marginRight: '2%',
        whiteSpace: 'nowrap'
    }}>
        <Typography variant="h6" style={{ fontSize: '13px' }}>Week</Typography>
    </Button>
    <Button onClick={goToDay} style={{
        backgroundColor: currentView === 'day' ? '#fff' : '#3f51b5',
        color: currentView === 'day' ? '#3f51b5' : '#fff',
        marginLeft: '2%', 
        marginRight: '2%',
        whiteSpace: 'nowrap'
    }}>
        <Typography variant="h6" style={{ fontSize: '13px' }}>Day</Typography>
    </Button>
</div>

</div>
</div>

</Box>





<Modal
  open={openDialog}
  onClose={handleDialogClose}
  closeAfterTransition
  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <Fade in={openDialog}>
  
  <Box sx={{ width: '80%', maxWidth: 800, padding: 4, background: 'white', borderRadius: 2 }}>

<Box>
<Tooltip title="Notification Details" arrow>
  <IconButton style={{ color: 'red', float: 'right' }} onClick={() => setActiveScreen('notification')} color={activeScreen === 'notification' ? "primary" : "default"}>
    <NotificationsActiveIcon />
  </IconButton>
</Tooltip>
<Tooltip title="History Log" arrow>
  <IconButton style={{ color: 'black', float: 'right' }} onClick={() => setActiveScreen('logHistory')}>
    <HistoryIcon />
  </IconButton>
  </Tooltip>
  
      {
  activeScreen === 'notification' && 
  <Tooltip title="Print">
    <IconButton onClick={printEventDialog}>
      <PrintIcon style={{ color: '#1167b1', float: 'right' }}/>
    </IconButton>
  </Tooltip>
}
    </Box>


    <Box sx={{ display: activeScreen === 'notification' || '' || false ? 'block' : 'none', padding: 4, background: 'white', borderRadius: 2 }}>      
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <Grid item xs={10}>
          <h2 style={{ textTransform: 'uppercase', textDecoration: 'underline' }}>
            NOTIFICATION DETAILS
          </h2>
        </Grid>
        {/* <Grid item xs={2} container justifyContent="flex-end">
          <Tooltip title="Print">
            <IconButton onClick={printEventDialog}>
              <PrintIcon style={{ color: '#1167b1', fontSize: 40 }}/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Close">
            <IconButton onClick={handleDialogClose}>
              <CancelIcon style={{ color: 'black', fontSize: 40 }}/>
            </IconButton>
          </Tooltip>
        </Grid> */}
      </Grid>

      <FormControl fullWidth variant="filled" style={{ marginBottom: '20px' }} disabled={isSingleAppointment}>
      <Select
        value={selectedEventIndex}
        onChange={(e) => setSelectedEventIndex(e.target.value)}
        displayEmpty
      >
        {isSingleAppointment ? (
          <MenuItem value="" disabled>
            No notifications yet!
          </MenuItem>
        ) : gEvents ? (
          gEvents
            .filter(event => event.tag !== "appointment")
            .map((event, index) => (
              <MenuItem value={index} key={index}>
                {event.resource.currentUserData || 'Older Type Notification'}
              </MenuItem>
            ))
        ) : (
          <MenuItem value="">Loading...</MenuItem>
        )}
      </Select>
    </FormControl>



{/* Displaying Event Details based on Dropdown Selection */}
{
groupedEvents[selectedGroupIndex] &&
groupedEvents[selectedGroupIndex][selectedEventIndex] ? (
        <div style={{ border: '2px solid #000', padding: '20px' }}>
          <Typography variant="h6" component="div">
            -<u>Patient Name:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.name || ' '}</span>
          </Typography>

          <Typography variant="h6" component="div">
              -<u>Appointment Date:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>
                {groupedEvents[selectedGroupIndex][selectedEventIndex].resource.newDate 
                  ? moment(groupedEvents[selectedGroupIndex][selectedEventIndex].resource.newDate).format('MMMM D, YYYY')
                  : ' '}
              </span>
          </Typography>


          <Typography variant="h6" component="div">
              -<u>Phone:</u> 
              <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>
                  {groupedEvents[selectedGroupIndex][selectedEventIndex].resource.phone 
                      ? `${groupedEvents[selectedGroupIndex][selectedEventIndex].resource.phone.slice(0, 3)}-${groupedEvents[selectedGroupIndex][selectedEventIndex].resource.phone.slice(3, 6)}-${groupedEvents[selectedGroupIndex][selectedEventIndex].resource.phone.slice(6)}`
                      : 'No email used'}
              </span>
          </Typography>


          <Typography variant="h6" component="div">
            -<u>Email:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.email || 'No email used'}</span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Confirmation Requested:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{(groupedEvents[selectedGroupIndex][selectedEventIndex].resource.askConfirmation || 'No').toString()}</span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Text Message Confirmation Status:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.confirmBySms || '...pending'}</span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Voice Confirmation Status:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>
              {groupedEvents[selectedGroupIndex][selectedEventIndex].resource.confirmByVoice === 'noInput' ? 'no input' : groupedEvents[selectedGroupIndex][selectedEventIndex].resource.confirmByVoice || 'no answer'}
            </span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Email Confirmation Status:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.confirmByEmail || '...pending'}</span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Manual Confirmation:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.manualConfirm || 'No'}</span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Notified By:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.currentUserData || ' '}</span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Encounter Type:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.encounterType || groupedEvents[selectedGroupIndex][selectedEventIndex].resource.encType || ' '}</span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Sent Message:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.finalMessage || ' '}</span>
          </Typography>
          </div>
            )
            : <div>Loading Event Details...</div>
          }
        </Box>
        {activeScreen === 'logHistory' && (
  <Box sx={{ display: 'block', padding: 4, background: 'white', borderRadius: 2 }}>
    <h2>Event History Log</h2>
    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
    <ul>
      {eventLogs.map((log, index) => {

        // console.log(log.data)
        // Assuming each log might have multiple data entries and you want to process all
        const actionDetails = log.data.map((dataItem, dataIndex) => {
          const changedData = dataItem.changedData || {}; // Fallback to empty object if changedData is missing

          // Extract the properties from changedData
          const manualConfirm = changedData.manualConfirm ? 'N/A' : '';
          const hidden = changedData.hidden ? 'N/A' : '';
          const aptSpecLabel = changedData.aptSpecLabel ? `${changedData.aptSpecLabel}` : '';
          const noShow = changedData.noShow ? 'N/A' : '';


          // Combine the details and filter out empty parts
          const details = [manualConfirm, hidden, aptSpecLabel, noShow].filter(part => part).join(', ');

// Splitting the log.id to extract the date and time
const [date, utcTime] = log.id.split('T');
// Removing the 'Z-000000' part to parse the date-time string correctly
const dateTimeString = `${date}T${utcTime.split('.')[0]}Z`; // Includes 'Z' to specify UTC

// Creating a Date object from the UTC date-time string
const dateTime = new Date(dateTimeString);

// Formatting the date and time in the local time zone
// You can use toLocaleDateString and toLocaleTimeString for more control over the appearance
const formattedDate = dateTime.toLocaleDateString('en-US'); // Adjust 'en-US' as needed
const formattedTime = dateTime.toLocaleTimeString('en-US', {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false // Set to true if you prefer AM/PM
});

// Now formattedDate and formattedTime are adjusted to the user's local time zone


          // Return a string for each dataItem's details
          return `${formattedDate} - ${formattedTime} - ${log.user} - ${log.action} - Data: ${details || 'No data'}`;
        }).join('; '); // Join multiple dataItem details with a semicolon for separation

        // Render the actionDetails for each log entry
        return (
          <li key={index} style={{ cursor: 'pointer', width: 'auto', minWidth: '95%' }} onClick={() => alert(`Details: ${actionDetails}`)}>
            {actionDetails}
          </li>
        );
      })}
    </ul>
    </div>
  </Box>
          )}
        </Box>
      </Fade>
    </Modal>

   
    <Modal 
      open={isSearchModalOpen} 
      onClose={closeSearchModal} 
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={{ 
        position: 'relative',
        width: '80%', 
        maxWidth: 800, 
        padding: 4, 
        background: 'white', 
        borderRadius: 2 
      }}>
        <Tooltip title="Close">
          <CancelIcon
            sx={{ 
              position: 'absolute',
              top: 15,
              right: 20,
              fontSize: 40, 
              cursor: 'pointer' 
            }}
            onClick={closeSearchModal}
            id="cancelSearchModalIcon"
          />
        </Tooltip>

        <Typography variant="h6" style={{ paddingTop: '40px' }}>
          <span style={{ color: 'black' }}>Search for Client Appointment(s) in:</span>
          <span style={{ color: '#007bff' }}> {calendars.find(calendar => calendar.docId === selectedCalendar)?.name}</span>
        </Typography>
        
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              inputRef={nameAptSearchRef}
              defaultValue={nameAptSearch}
              onClick={setIsResultsVisible(true)}
              onChange={handleChangeAndKeyDownAptSearch}
              fullWidth
              margin="normal"
              variant="outlined"
              inputProps={{
                style: { paddingLeft: '15px' },
                autoComplete: "new-password",
                name: "uniqueName" + Math.random()
              }}
              label={<span style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px' }}>Name</span>}
            />
            <div ref={patientListRef}></div>
          </Grid>

          <Grid item xs={6}>
            <TextField
              inputRef={phoneAptSearchRef}
              defaultValue={phoneAptSearch}
              fullWidth
              margin="normal"
              variant="outlined"
              inputProps={{ style: { paddingLeft: '15px' }}}
              label={<span style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px' }}>Phone</span>}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              type="date"
              label="From"
              inputRef={fromDateRef}
              defaultValue={fromDate}
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="date"
              label="To"
              inputRef={toDateRef}
              defaultValue={toDate}
              fullWidth
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center" style={{ marginTop: '10px' }}>
          <Grid item xs={2}>
            <Button variant="contained" color="primary" onClick={handleSearchClick}>
              Search
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              style={{
                backgroundColor: 'black',
                color: 'white',
                marginLeft: -30
              }}
              onClick={handleClearSearchClick}
            >
              Clear
            </Button>
          </Grid>
          {isLoadingAptSearch && (
            <Grid item xs={7} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%' }}>
              <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-start', paddingRight: '30%' }}>
                <LinearProgress style={{ width: '100%' }} />
              </div>
            </Grid>
          )}
        </Grid>

        {isResultsVisible && (
          <Box sx={{ maxHeight: 300, maxWidth: 1000, overflowY: 'auto', overflowX: 'auto', mt: 2 }}>
            {filteredResults.map((result, index) => (
              <Tooltip title="Go to event day!" key={index}>
                <Box sx={{
                  p: 1,
                  borderBottom: '1px solid #ccc',
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'lightblue'
                  }
                }}
                onClick={() => handleItemClick(result.start)}>
                  <Typography variant="body1" style={{ display: 'inline-flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                    {result.resource.hidden === 'true' && (
                      <VisibilityOffIcon style={{ color: 'purple', marginRight: '5px', verticalAlign: 'middle' }} />
                    )}
                    {(result.resource.confirmBySms === 'cancelled' || 
                      result.resource.confirmByEmail === 'cancelled' || 
                      result.resource.confirmByVoice === 'cancelled' || 
                      result.confirmBySms === 'cancelled' || 
                      result.confirmByEmail === 'cancelled' || 
                      result.confirmByVoice === 'cancelled' || 
                      result.resource.manualConfirm === 'cancelled' || 
                      result.manualConfirm === 'cancelled') && (
                      <HighlightOffIcon style={{ color: 'red', marginRight: '5px', verticalAlign: 'middle' }} />
                    )}
                    {result.tag === 'appointment' && (
                      <EventNoteIcon style={{ color: 'black', marginRight: '5px', verticalAlign: 'middle' }} />
                    )}
                    {result.resource.name} / {result.start} @ {result.time}
                    {(result.resource.encType || result.resource.encounterType) && 
                      ` / ${result.resource.encType || result.resource.encounterType}`}
                    / {result.resource.phone}
                    {result.resource.dateOfBirth && ` / DOB: ${result.resource.dateOfBirth}`}
                  </Typography>
                </Box>
              </Tooltip>
            ))}
          </Box>
        )}
      </Box>
    </Modal>
            
    


        <Modal
            open={openNewAptModal}
            onClose={handleModalClose}
            style={modalStyle}
            slotProps={{ backdrop: { sx: { backgroundColor: 'transparent' } } }}  // Using slotProps for the backdrop            disableEnforceFocus
            sx={{
              border: '1px solid black',   // Adds a black border
              borderRadius: '8px',         // Ensures the border has rounded corners.
          }}
            closeAfterTransition
            disableEnforceFocus
            disableAutoFocus
            disableRestoreFocus
        >
            <Box sx={{
                width: '100%',
                padding: modalSize === 'minimized' ? 1 : 4,
                background: 'white',
                borderRadius: modalSize === 'minimized' ? 0 : 2
            }}>
<div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    {modalSize !== 'minimized' && (
        <FormControlLabel
            control={<Switch checked={showOrInfo} onChange={handleOrInfoChange} name="orProcedure" color="primary" />}
            label="OR Procedure"
            style={{ marginRight: 'auto' }} // This will push the switch to the left
        />
    )}
    <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
        {!rulesStatus && modalSize !== 'minimized' && (
            <Tooltip title="Manage Encounter Types" arrow>
                <IconButton onClick={handleOpenSettingsModal}>
                    <SettingsIcon />
                </IconButton>
            </Tooltip>
        )}
        {modalSize !== 'minimized' && (
            <Tooltip title="Minimize" arrow>
                <IconButton onClick={handleModalMinimize} style={{ marginRight: '8px' }}>
                    <MinimizeIcon />
                </IconButton>
            </Tooltip>
        )}
        {modalSize === 'minimized' && (
            <>
                <Typography variant="subtitle1" style={{ marginRight: "24px" }}>New Appointment Window</Typography>
                <Tooltip title="Maximize" arrow>
                    <IconButton onClick={() => setModalSize('default')} style={{ marginRight: '8px' }}>
                        <FullscreenIcon />
                    </IconButton>
                </Tooltip>
            </>
        )}
        {modalSize !== 'minimized' && (
            <>
        <Tooltip title="Close">
            <IconButton onClick={handleModalClose}>
                <CancelIcon />
            </IconButton>
        </Tooltip>
        </>
        )}
    </div>
</div>



                {modalSize !== 'minimized' && (
          <>
            {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

              {!rulesStatus && (
                <Tooltip title="Manage Encounter Types" arrow>
                  <IconButton onClick={handleCloseNewAptModal}>
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div> */}
            <h2 style={{ color: 'red', marginTop: '20px' }}>New Appointment</h2>
            <form>

      <Grid container xs={12} spacing={2}>

      <Grid item xs={6}>
      <TextField
    inputRef={firstNameRef}
    defaultValue={firstName}
    onChange={handleChangeAndKeyDown}
    fullWidth
    margin="normal"
    variant="outlined"
    inputProps={{
        style: { paddingLeft: '15px' },
        autoComplete: "new-password", // Disabling autocomplete
        name: "uniqueName" + Math.random() // Randomizing name attribute
    }}
    InputLabelProps={{ }}
    label={<span style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px' }}> First Name or Search</span>}
/>

    <div ref={patientListRef}></div> {/* This div will be updated manually */}
</Grid>

        <Grid item xs={6}>
          <TextField inputRef={middleNameRef} defaultValue={middleName} fullWidth margin="normal" variant="outlined" inputProps={{ style: { paddingLeft: '15px' }}} InputLabelProps={{ }} label={ <span style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px', }}> Middle Name </span> }/>
        </Grid>

        <Grid item xs={6}>
          <TextField inputRef={lastNameRef} defaultValue={lastName} fullWidth margin="normal" variant="outlined" inputProps={{ style: { paddingLeft: '15px' }}} InputLabelProps={{ }} label={ <span style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px', }}> Last Name </span> }/>
        </Grid>

        <Grid item xs={6}>
          <TextField inputRef={phoneRef} defaultValue={phone} fullWidth margin="normal" variant="outlined" inputProps={{ style: { paddingLeft: '15px' }}} InputLabelProps={{ }} label={ <span style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px', }}> Phone </span> }/>
        </Grid>


        <Grid item xs={6} style={{ marginBottom: '-13px' }}>
          <TextField inputRef={emailRef} defaultValue={email} fullWidth margin="normal" variant="outlined" inputProps={{ style: { paddingLeft: '15px' }}} InputLabelProps={{ }} label={ <span style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px', }}> Email </span> }/>
        </Grid>
        <Grid item xs={6} style={{ marginBottom: '-13px' }}>
        <FormControl fullWidth margin="normal" variant="outlined">
  <InputLabel id="encounter-type-label">
    <span style={{ 
      backgroundColor: 'white', 
      paddingRight: '5px', 
      paddingLeft: '5px' 
    }}>
      Encounter Type
    </span>
  </InputLabel>
  <Select
    labelId="encounter-type-label"
    inputRef={encounterTypeRef}
    defaultValue={encounterType}
    label="Encounter Type"
    style={{
      height: '51.5px', // Adjust height
      fontSize: '0.9rem', // Optional: adjust font size if needed
    }}
>
    {encounterTypes.map((type) => (
        <MenuItem value={type.name}>{type.name}</MenuItem>
    ))}
</Select>

</FormControl>

        </Grid>

        <Grid item xs={6} style={{ position: 'relative' }}>
            <Box display="flex" alignItems="center" style={{ paddingTop: '18px' }}>
                <TextField
                    inputRef={dateRef}
                    defaultValue={date}
                    type="date"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    id="appointmentDate"
                    inputProps={{ style: { paddingLeft: '15px' }}}
                />
                {!date && (
                    <span style={{
                        position: 'absolute',
                        backgroundColor: 'white',
                        paddingTop: '9px',
                        top: '0',
                        left: '33px', // Keep the text position
                        zIndex: '1',
                        pointerEvents: 'none',
                        color: '#707070',
                        fontSize: '12px',
                        transform: 'translateY(32px)',
                        paddingLeft: '4px', // Increase padding to extend background
                        paddingRight: '5px',
                        marginLeft: '-4px' // Apply negative margin to shift background to the left
                    }}>
                        Appointment Date
                    </span>
                )}
{rulesStatus === true && (
                <Tooltip title="Click to automatically find the next available spot!" arrow>
<Button 
    variant="contained"
    onClick={startAutoSpotSearch}  // Left-click
    onContextMenu={(e) => {
        e.preventDefault();  // Prevent the default right-click menu
        startAutoSpotSearchSameDay();  // Right-click action
    }}
    style={{
        marginLeft: 8,
        backgroundColor: '#f0f0f0', // Constant background color
        minWidth: '35px', // Fixed width for square shape
        width: '35px', // Ensure the button is square
        height: '40px', // Matching height for square shape
        padding: 0, // Remove padding to center icon
        marginTop: 8,
        borderRadius: '4px', // Rounded corners
        border: '2px solid #c4c4c4', // Constant border color
        boxShadow: 'none', // Optional: remove shadow if desired
        transition: 'all 0.3s ease',
        transform: isClicked ? 'scale(0.95)' : 'scale(1)' // Scale transformation for click effect
    }}
>
    <AutoAwesomeIcon style={{ color: '#000000' }}/>
</Button>

                </Tooltip>
            )}
            </Box>
        </Grid>

        {/* <Grid item xs={6} style={{ position: 'relative' }}>
    <TextField
        inputRef={dateRef}
        defaultValue={date}
        type="date"
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: false }}
        id="appointmentDate"
        inputProps={{ style: { paddingLeft: '15px' }}}
        style={{ paddingTop: '18px' }}
    />
    {!date && (
        <span style={{
          position: 'absolute',
          backgroundColor: 'white',
          paddingTop: '9px',
          top: '0',
          left: '33px', // Keep the text position
          zIndex: '1',
          pointerEvents: 'none',
          color: '#707070',
          fontSize: '12px',
          transform: 'translateY(32px)',
          paddingLeft: '4px', // Increase padding to extend background
          paddingRight: '5px',
          marginLeft: '-4px' // Apply negative margin to shift background to the left
        }}>
            Appointment Date
        </span>
    )}
</Grid> */}

<Grid item xs={6} style={{ position: 'relative' }}>
    <TextField
        inputRef={timeRef}
        defaultValue={showOrInfo ? '06:00' : time}
        type="time"
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: false }}
        id="appointmentTime"
        inputProps={{ style: { paddingLeft: '15px' }}}
        style={{ paddingTop: '18px' }}
    />
    {!time && (
        <span style={{
          position: 'absolute',
          backgroundColor: 'white',
          paddingTop: '9px',
          top: '0',
          left: '33px', // Keep the text position
          zIndex: '1',
          pointerEvents: 'none',
          color: '#707070',
          fontSize: '12px',
          transform: 'translateY(32px)',
          paddingLeft: '4px', // Increase padding to extend background
          paddingRight: '5px',
          marginLeft: '-4px' // Apply negative margin to shift background to the left
        }}>
            Appointment Time
        </span>
    )}
</Grid>



<Grid item xs={12} style={{ marginBottom: '15px' }}> {/* Increase bottom margin */}
<div style={{ position: 'relative' }}>
      <TextField
        inputRef={aptSpecLabelRef}
        defaultValue={aptSpecLabel} // No state changes for the value
        fullWidth
        margin="normal"
        variant="outlined"
        onChange={handleInputChange} // Update on every change
        inputProps={{
          style: { paddingLeft: '15px' },
          maxLength: maxChars, // Enforce max length
        }}
        label={<span style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px', fontSize: '14px' }}>Appointment Specific Label</span>}
      />
      {/* Character Count */}
      <div
        ref={charCountRef}
        style={{ position: 'absolute', right: '10px', bottom: '10px', fontSize: '12px', color: '#888' }}
      >
        0/{maxChars}
      </div>
    </div>
</Grid>

{
  showOrInfo === false ? (
    <Grid item xs={12} style={{ marginBottom: '0px' }}>
      <TextareaAutosize 
        ref={otherInfoRef}
        defaultValue={otherInfo}
        minRows={4} 
        maxRows={4}
        overflowY='auto'
        placeholder="Client Notes" 
        style={{ width: '96%', padding: '15px', borderRadius: '4px', border: '1px solid #ccc' }} 
      />
    </Grid>
  ) : (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12} md={8} style={{ marginBottom: '-10px' }}>
        <TextareaAutosize
          ref={orInfoRef}
          defaultValue={orInfo}
          minRows={4}
          maxRows={4}
          overflowY='auto'
          placeholder="Procedure Notes"
          style={{ width: '85%', marginLeft: '15px', padding: '15px', marginTop: '18px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </Grid>

      <Grid item xs={12} md={4} style={{ position: 'relative' }}>
        <Grid container direction="column">
        <Grid item xs={12} md={6}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', paddingTop: '7px' }}> {/* Ensures label is to the left of the field */}
      <label htmlFor="paDate" style={{ color: '#707070', fontSize: '0.875rem' }}>
        Pre-Adm. Date:
      </label>
      <TextField
        inputRef={paDateRef}
        defaultValue={paDate}
        type="date"
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: true, style: { display: 'none' } }} // Hide the default label
        id="paDate"
        // Adjust inputProps and style as necessary
        inputProps={{
          style: {
            height: '40px', // Reduces the height of the input field
            padding: '0 14px', // Adjust padding to ensure text is aligned properly
          }
        }}
        style={{
          width: '100%', // Adjust width if necessary
          // Additional style adjustments for the TextField component
        }}
      />
    </div>
  </Grid>

  <Grid item xs={12} md={4}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}> {/* Ensures label is to the left of the field */}
      <label htmlFor="paTime" style={{ color: '#707070', fontSize: '0.875rem' }}>
        Pre-Adm. Time:
      </label>
      <TextField
        inputRef={paTimeRef}
        defaultValue={paTime}
        type="time"
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: true, style: { display: 'none' } }} // Hide the default label
        id="paTime"
        // Adjust inputProps and style as necessary
        inputProps={{
          style: {
            height: '40px', // Reduces the height of the input field
            padding: '0 14px', // Adjust padding to ensure text is aligned properly
          }
        }}
        style={{
          width: '100%', // Adjust width if necessary
          // Additional style adjustments for the TextField component
        }}
      />
    </div>
  </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

{/* {
  showOrInfo === false ? (
    <Grid item xs={12} style={{ marginBottom: '0px' }}>
<TextField
  ref={otherInfoRef}
  defaultValue={otherInfo}
  label="Client Notes"
  multiline
  rows={4}
  variant="outlined"
  fullWidth
  InputProps={{
    style: {
      fontSize: '16px',
      padding: '15px',
      overflowY: 'auto',
    },
  }}
  InputLabelProps={{
    shrink: true,
    style: { backgroundColor: 'white', paddingLeft: '5px', paddingRight: '5px' }
  }}
  style={{
    width: '100%',
    borderRadius: '4px',
    border: '1px solid #ccc',
  }}
/>

    </Grid>
  ) : (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12} md={8} style={{ marginBottom: '-10px' }}>
      <TextField
  ref={orInfoRef}
  defaultValue={orInfo}
  label="Procedure Notes"
  multiline
  rows={4}
  variant="outlined"
  fullWidth
  InputProps={{
    style: {
      fontSize: '16px',
      padding: '15px',
      overflowY: 'auto',
    },
  }}
  InputLabelProps={{
    shrink: true,
    style: { backgroundColor: 'white', paddingLeft: '5px', paddingRight: '5px' }
  }}
  style={{
    width: '85%',
    marginLeft: '15px',
    marginTop: '18px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  }}
/>

      </Grid>

      <Grid item xs={12} md={4} style={{ position: 'relative' }}>
        <Grid container direction="column">
        <Grid item xs={12} md={6}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', paddingTop: '7px' }}> 
      <label htmlFor="paDate" style={{ color: '#707070', fontSize: '0.875rem' }}>
        Pre-Adm. Date:
      </label>
      <TextField
        inputRef={paDateRef}
        defaultValue={paDate}
        type="date"
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: true, style: { display: 'none' } }}
        inputProps={{
          style: {
            height: '40px',
            padding: '0 14px',
          }
        }}
        style={{
          width: '100%',
        }}
      />
    </div>
  </Grid>

  <Grid item xs={12} md={4}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <label htmlFor="paTime" style={{ color: '#707070', fontSize: '0.875rem' }}>
        Pre-Adm. Time:
      </label>
      <TextField
        inputRef={paTimeRef}
        defaultValue={paTime}
        type="time"
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: true, style: { display: 'none' } }}
        id="paTime"

        inputProps={{
          style: {
            height: '40px',
            padding: '0 14px',
          }
        }}
        style={{
          width: '100%',
        }}
      />
    </div>
  </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
} */}


{/* <Grid item xs={12} style={{ marginBottom: '-10px' }}>
  <TextareaAutosize 
    ref={otherInfoRef}
    defaultValue={otherInfo}
    minRows={3} 
    placeholder="Client Notes" 
    style={{ width: '96%', padding: '15px', borderRadius: '4px', border: '1px solid #ccc' }} 
  />
</Grid> */}



        <Grid item xs={6} style={{ position: 'relative' }}>
    <TextField
        inputRef={dateOfBirthRef}
        defaultValue={dateOfBirth}
        type="date"
        fullWidth
        required
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: false }}
        id="dateOfBirth"
        inputProps={{ style: { paddingLeft: '15px' }}}
        style={{ paddingTop: '4px' }}
    />
    {!dateOfBirth && (
        <span style={{
            position: 'absolute',
            backgroundColor: 'white',
            paddingTop: '9px',
            top: '-14px',
            left: '33px', // Keep the text position
            zIndex: '1',
            pointerEvents: 'none',
            color: '#707070',
            fontSize: '12px',
            transform: 'translateY(32px)',
            paddingLeft: '4px', // Increase padding to extend background
            paddingRight: '5px',
            marginLeft: '-4px' // Apply negative margin to shift background to the left
        }}>
            Date of Birth
        </span>
    )}
</Grid>



<Grid item xs={6}>
    <FormControl 
        fullWidth
        margin="normal"
        variant="outlined"
        style={{ paddingTop: '4px' }}
    >
        <InputLabel 
            id="language-label"
            style={{
                paddingRight: '5px',
                paddingLeft: '13px',
                paddingTop: '37px',
                fontSize: '12px', // Match font size
                transform: 'translateY(-50%)', // Center vertically
            }}
        >
            <span style={{ 
                position: 'relative', // or 'absolute' depending on its container's positioning
                top: '-14px', // Move up by 5px; adjust as needed
                backgroundColor: 'white', 
                padding: '0 3px', // Adjust padding as needed
            }}>
                Language
            </span>
        </InputLabel>
        <Select
            labelId="language-label"
            inputRef={aptLangRef}
            defaultValue="Spanish"
            label="Language"
        >
            <MenuItem value="Spanish">Spanish</MenuItem>
            <MenuItem value="English">English</MenuItem>
        </Select>
    </FormControl>
</Grid>






      </Grid>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <Button variant="contained" color="primary" style={{ width: '140px', marginRight: '70px' }} onClick={handlePreAptSave}>
          Save
        </Button>
        <Button variant="contained" style={{ width: '120px', backgroundColor: 'black' }} onClick={handleCloseNewAptModal}>
          Close
        </Button>
      </div>
    </form>         
    </>
     )}
  </Box>
</Modal>

    

<Modal
            open={openExistentAptModal}
            onClose={handleCloseExistentAptModal}
            style={secondModalStyle}
            slotProps={{ backdrop: { sx: { backgroundColor: 'transparent' } } }}  // Using slotProps for the backdrop            disableEnforceFocus
            sx={{
              border: '1px solid black',   // Adds a black border
              borderRadius: '8px',         // Ensures the border has rounded corners
          }}
          closeAfterTransition
          disableEnforceFocus
          disableAutoFocus
          disableRestoreFocus
        >
            <Box sx={{
                width: '100%',
                maxWidth: secondModalSize === 'minimized' ? 'none' : 800,
                padding: secondModalSize === 'minimized' ? 1 : 4,
                background: 'white',
                borderRadius: secondModalSize === 'minimized' ? 0 : 2
            }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>

                    {secondModalSize === 'minimized' && (
                        <>
                         <Typography variant="subtitle1" style={{ flex: 1, marginLeft: '25px' }}>Existing Appointment Window</Typography>                            <Tooltip title="Maximize" arrow>
                            <IconButton onClick={() => setSecondModalSize('default')}>
                                <FullscreenIcon />
                            </IconButton>
                            </Tooltip>
                        </>
                    )}
                </div>
                
                {secondModalSize !== 'minimized' && (
                  <>
    <Box>
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
  
  {/* <FormGroup row style={{ marginLeft: '10px' }}> */}
    {/* <FormControlLabel
      control={
        <Switch
          checked={showOrInfo}
          onChange={handleOrInfoChange}
          name="orProcedure"
          color="primary"
        />
      }
      label="OR Procedure"
    /> */}
  {/* </FormGroup> */}


  <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
  <div style={{ marginLeft: 'auto', display: 'flex', gap: '10px' }}> {/* Pushes everything to the right */}
  {(activeScreen === 'appointment' && rulesStatus && showOrInfo === false) && (
  <Tooltip title="Send Reschedule Link" arrow>
    <IconButton onClick={() => handleRescheduleLinkButton()}>
      <TextsmsIcon style={{ color: 'grey', paddingTop: '3px' }} />
    </IconButton>
  </Tooltip>
)}
    <Tooltip title="Appointment Details" arrow>
      <IconButton onClick={() => setActiveScreen('appointment')} color={activeScreen === 'appointment' ? "primary" : "default"}>
        <EventNoteIcon style={{ color: '#1167b1' }} />
      </IconButton>
    </Tooltip>
    <Tooltip title="Notification Details" arrow>
      <IconButton onClick={() => setActiveScreen('notification')} color={activeScreen === 'notification' ? "primary" : "default"}>
        <NotificationsActiveIcon style={{ color: 'red' }} />
      </IconButton>
    </Tooltip>
    <Tooltip title="History Log" arrow>
      <IconButton onClick={() => setActiveScreen('logHistory')}>
        <HistoryIcon style={{ color: 'black' }} />
      </IconButton>
    </Tooltip>
    {!rulesStatus && (
      <Tooltip title="Manage Encounter Types" arrow>
        <IconButton onClick={handleOpenSettingsModal}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
    )}
    {activeScreen === 'notification' && (
      <Tooltip title="Print" arrow>
        <IconButton onClick={printEventDialog}>
          <PrintIcon style={{ color: '#1167b1' }}/>
        </IconButton>
      </Tooltip>
    )}
    {secondModalSize !== 'minimized' && (
            <Tooltip title="Minimize" arrow>
      <IconButton onClick={handleSecondModalMinimize} style={{ marginRight: '0px' }}>
        <MinimizeIcon />
      </IconButton>
      </Tooltip>
    )}
    {modalSize !== 'minimized' && (
            <>
        <Tooltip title="Close">
            <IconButton onClick={handleCloseExistentAptModal}>
                <CancelIcon />
            </IconButton>
        </Tooltip>
        </>
    )}
  </div>
</div>

  
</div>

    </Box>

    {/* Appointment Details */}
    <Box sx={{ display: activeScreen === 'appointment' ? 'block' : 'none', padding: 0, background: 'white', borderRadius: 2 }}>      
    <h2>Appointment Details</h2>
      <form>
        
        <Grid container xs={12} spacing={2}>
          {/* Existing Fields Updated with New Styling from Second Modal */}
          {/* Name Field */}


          <Grid item xs={6}>
    <TextField 
      inputRef={nameRef}
      defaultValue={wmName}
      label="Name" 
      fullWidth 
      margin="normal" 
      variant="outlined" 
      inputProps={{
        style: { paddingLeft: '15px' },
        readOnly: true, // Make the input read-only
      }}
      InputLabelProps={{
        shrink: true,
        style: {
          backgroundColor: 'white',
          paddingLeft: '5px',
          paddingRight: '5px',
        },
      }}
      onClick={() =>
        alert(
          `To update the name of this client for this appointment and all future appointments, please navigate to the main dashboard. Select the "TOOLS & INFO" option, then choose "REGISTERED CLIENTS." In the Registered Clients window, locate the client whose name you wish to update and make the necessary changes there.`
        )
      }
    />
  </Grid>


  <Grid 
  item xs={6}
  style={{
    position: 'absolute',
    left: '-9999px',
    top: '-9999px',
  }}
>
  <TextField
    inputRef={firstNameRef}
    defaultValue={firstName}
    fullWidth
    margin="normal"
    variant="outlined"
    inputProps={{ style: { paddingLeft: '15px' } }}
    InputLabelProps={{}}
    label={
      <span style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px' }}>
        First Name
      </span>
    }
  />
</Grid>

<Grid
  item xs={6}
  style={{
    position: 'absolute',
    left: '-9999px',
    top: '-9999px',
  }}
>
  <TextField
    inputRef={middleNameRef}
    defaultValue={middleName}
    fullWidth
    margin="normal"
    variant="outlined"
    inputProps={{ style: { paddingLeft: '15px' } }}
    InputLabelProps={{}}
    label={
      <span style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px' }}>
        Middle Name
      </span>
    }
  />
</Grid>

<Grid
  item xs={6}
  style={{
    position: 'absolute',
    left: '-9999px',
    top: '-9999px',
  }}
>
  <TextField
    inputRef={lastNameRef}
    defaultValue={lastName}
    fullWidth
    margin="normal"
    variant="outlined"
    inputProps={{ style: { paddingLeft: '15px' } }}
    InputLabelProps={{}}
    label={
      <span style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px' }}>
        Last Name
      </span>
    }
  />
</Grid>






          <Grid item xs={6}>
  <TextField
    inputRef={phoneRef}
    defaultValue={phone}
    fullWidth
    margin="normal"
    variant="outlined"
    inputProps={{
      style: { paddingLeft: '15px' },
      readOnly: true, // Make the input read-only
    }}
    InputLabelProps={{}}
    label={
      <span
        style={{
          backgroundColor: 'white',
          paddingRight: '5px',
          paddingLeft: '5px',
        }}
      >
        Phone
      </span>
    }
    onClick={() =>
      alert(
        `To update this client's phone number for this appointment and all future appointments, please navigate to the main dashboard. Select the "TOOLS & INFO" option, then choose "REGISTERED CLIENTS." In the Registered Clients window, locate the client whose phone number you wish to update and make the necessary changes there.`
      )
    }
  />
</Grid>


          {/* <Grid item xs={6}>
          <TextField inputRef={phoneRef} defaultValue={phone} fullWidth margin="normal" variant="outlined" inputProps={{ style: { paddingLeft: '15px' }}} InputLabelProps={{ }} label={ <span style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px', }}> Phone </span> }/>
        </Grid> */}


<Grid item xs={6} style={{ marginBottom: '-13px' }}>
  <TextField 
    inputRef={emailRef} 
    defaultValue={email} 
    fullWidth 
    margin="normal" 
    variant="outlined" 
    inputProps={{
      style: { paddingLeft: '15px' },
      readOnly: true, // Make the input read-only
    }}
    InputLabelProps={{ 
      shrink: true, 
      style: { backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px' },
    }}
    label={
      <span 
        style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px' }}
      >
        Email
      </span>
    }
    onClick={() =>
      alert(
        `To update the email address of this client for this appointment and all future appointments, please navigate to the main dashboard. Select the "TOOLS & INFO" option, then choose "REGISTERED CLIENTS." In the Registered Clients window, locate the client whose email you wish to update and make the necessary changes there.`
      )
    }
  />
</Grid>



        {/* <Grid item xs={6} style={{ marginBottom: '-13px' }}>
          <TextField inputRef={emailRef} defaultValue={email} fullWidth margin="normal" variant="outlined" inputProps={{ style: { paddingLeft: '15px' }}} InputLabelProps={{ }} label={ <span style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px', }}> Email </span> }/>
        </Grid> */}


        <Grid item xs={6} style={{ marginBottom: '-13px' }}>
        <FormControl fullWidth margin="normal" variant="outlined">
  <InputLabel id="encounter-type-label">
    <span style={{ 
      backgroundColor: 'white', 
      paddingRight: '5px', 
      paddingLeft: '5px' 
    }}>
      Encounter Type
    </span>
  </InputLabel>

  <Select
    labelId="encounter-type-label"
    inputRef={encounterTypeRef}
    defaultValue={encounterType.toUpperCase()}
    label="Encounter Type"
    style={{
      height: '51.5px', // Adjust height
      fontSize: '0.9rem', // Optional: adjust font size if needed
    }}
>
    {encounterTypesForDetails.map((type) => (
        <MenuItem value={type.name.toUpperCase()}>{type.name}</MenuItem>
    ))}
</Select>

  {/* <Select
    labelId="encounter-type-label"
    inputRef={encounterTypeRef}
    defaultValue={encounterType}
    label="Encounter Type"
    style={{
        height: '51.5px', // Adjust height
        fontSize: '0.9rem', // Optional: adjust font size if needed
    }}
>
    {encounterTypes.map((type) => (
        <MenuItem value={type.name}>{type.name}</MenuItem>
    ))}
</Select> */}

</FormControl>

        </Grid>

        <Grid item xs={6} style={{ position: 'relative' }}>
            <Box display="flex" alignItems="center" style={{ paddingTop: '18px' }}>
                <TextField
                    inputRef={dateRef}
                    defaultValue={date}
                    type="date"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{ shrink: false }}
                    id="appointmentDate"
                    inputProps={{ style: { paddingLeft: '15px' }}}
                />
                {!date && (
                    <span style={{
                        position: 'absolute',
                        backgroundColor: 'white',
                        paddingTop: '9px',
                        top: '0',
                        left: '33px', // Keep the text position
                        zIndex: '1',
                        pointerEvents: 'none',
                        color: '#707070',
                        fontSize: '12px',
                        transform: 'translateY(32px)',
                        paddingLeft: '4px', // Increase padding to extend background
                        paddingRight: '5px',
                        marginLeft: '-4px' // Apply negative margin to shift background to the left
                    }}>
                        Appointment Date
                    </span>
                )}
                {rulesStatus === true && (
                <Tooltip title="Click to automatically find the next available spot!" arrow>
<Button 
    variant="contained"
    onClick={startAutoSpotSearch}  // Left-click
    onContextMenu={(e) => {
        e.preventDefault();  // Prevent the default right-click menu
        startAutoSpotSearchSameDay();  // Right-click action
    }}
    style={{
        marginLeft: 8,
        backgroundColor: '#f0f0f0', // Constant background color
        minWidth: '35px', // Fixed width for square shape
        width: '35px', // Ensure the button is square
        height: '40px', // Matching height for square shape
        padding: 0, // Remove padding to center icon
        marginTop: 8,
        borderRadius: '4px', // Rounded corners
        border: '2px solid #c4c4c4', // Constant border color
        boxShadow: 'none', // Optional: remove shadow if desired
        transition: 'all 0.3s ease',
        transform: isClicked ? 'scale(0.95)' : 'scale(1)' // Scale transformation for click effect
    }}
>
    <AutoAwesomeIcon style={{ color: '#000000' }}/>
</Button>

                </Tooltip>
            )}
            </Box>
        </Grid>

        {/* <Grid item xs={6} style={{ position: 'relative' }}>
    <TextField
        inputRef={dateRef}
        defaultValue={date}
        type="date"
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: false }}
        id="appointmentDate"
        inputProps={{ style: { paddingLeft: '15px' }}}
        style={{ paddingTop: '18px' }}
    />
    {!date && (
        <span style={{
          position: 'absolute',
          backgroundColor: 'white',
          paddingTop: '9px',
          top: '0',
          left: '33px', // Keep the text position
          zIndex: '1',
          pointerEvents: 'none',
          color: '#707070',
          fontSize: '12px',
          transform: 'translateY(32px)',
          paddingLeft: '4px', // Increase padding to extend background
          paddingRight: '5px',
          marginLeft: '-4px' // Apply negative margin to shift background to the left
        }}>
            Appointment Date
        </span>
    )}
</Grid> */}

<Grid item xs={6} style={{ position: 'relative' }}>
    <TextField
        inputRef={timeRef}
        defaultValue={time}
        type="time"
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: false }}
        id="appointmentTime"
        inputProps={{ style: { paddingLeft: '15px' }}}
        style={{ paddingTop: '18px' }}
    />
    {!time && (
        <span style={{
          position: 'absolute',
          backgroundColor: 'white',
          paddingTop: '9px',
          top: '0',
          left: '33px', // Keep the text position
          zIndex: '1',
          pointerEvents: 'none',
          color: '#707070',
          fontSize: '12px',
          transform: 'translateY(32px)',
          paddingLeft: '4px', // Increase padding to extend background
          paddingRight: '5px',
          marginLeft: '-4px' // Apply negative margin to shift background to the left
        }}>
            Appointment Time
        </span>
    )}
</Grid>



        <Grid item xs={12} style={{ marginBottom: '15px' }}> {/* Increase bottom margin */}
        <div style={{ position: 'relative' }}>
      <TextField
        inputRef={aptSpecLabelRef}
        defaultValue={aptSpecLabel} // No state changes for the value
        fullWidth
        margin="normal"
        variant="outlined"
        onChange={handleInputChange} // Update on every change
        inputProps={{
          style: { paddingLeft: '15px' },
          maxLength: maxChars, // Enforce max length
        }}
        label={<span style={{ backgroundColor: 'white', paddingRight: '5px', paddingLeft: '5px', fontSize: '14px' }}>Appointment Specific Label</span>}
      />
      {/* Character Count */}
      <div
        ref={charCountRef}
        style={{ position: 'absolute', right: '10px', bottom: '10px', fontSize: '12px', color: '#888' }}
      >
        0/{maxChars}
      </div>
    </div>
</Grid>

{
  showOrInfo === false ? (
<Grid container spacing={2} style={{ marginTop: '6px'}}>
  <Grid item xs={11} style={{ marginBottom: '0px' }}>
    <TextareaAutosize 
      ref={otherInfoRef}
      defaultValue={otherInfo}
      minRows={4} 
      maxRows={4}
      overflowY='auto'
      placeholder="Client Notes" 
      style={{ width: '94%', marginLeft: '2.4%', padding: '15px', borderRadius: '4px', border: '1px solid #ccc' }} 
    />
  </Grid>
  <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
    <Tooltip title="Update Information from Database" placement="right">
      <IconButton onClick={handleUpdateOtherInfo}>
        <ReplayCircleFilledIcon />
      </IconButton>
    </Tooltip>
  </Grid>
</Grid>
  ) : (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12} md={8} style={{ marginBottom: '-10px' }}>
        <TextareaAutosize
          ref={orInfoRef}
          defaultValue={orInfo}
          minRows={4}
          maxRows={4}
          overflowY='auto'
          placeholder="Procedure Notes"
          style={{ width: '85%', marginLeft: '15px', padding: '15px', marginTop: '18px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
      </Grid>

      <Grid item xs={12} md={4} style={{ position: 'relative' }}>
        <Grid container direction="column">
        <Grid item xs={12} md={6}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', paddingTop: '7px' }}> {/* Ensures label is to the left of the field */}
      <label htmlFor="paDate" style={{ color: '#707070', fontSize: '0.875rem' }}>
        Pre-Adm. Date:
      </label>
      <TextField
        inputRef={paDateRef}
        defaultValue={paDate}
        type="date"
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: true, style: { display: 'none' } }} // Hide the default label
        id="paDate"
        // Adjust inputProps and style as necessary
        inputProps={{
          style: {
            height: '40px', // Reduces the height of the input field
            padding: '0 14px', // Adjust padding to ensure text is aligned properly
          }
        }}
        style={{
          width: '100%', // Adjust width if necessary
          // Additional style adjustments for the TextField component
        }}
      />
    </div>
  </Grid>

  <Grid item xs={12} md={4}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}> {/* Ensures label is to the left of the field */}
      <label htmlFor="paTime" style={{ color: '#707070', fontSize: '0.875rem' }}>
        Pre-Adm. Time:
      </label>
      <TextField
        inputRef={paTimeRef}
        defaultValue={paTime}
        type="time"
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: true, style: { display: 'none' } }} // Hide the default label
        id="paTime"
        // Adjust inputProps and style as necessary
        inputProps={{
          style: {
            height: '40px', // Reduces the height of the input field
            padding: '0 14px', // Adjust padding to ensure text is aligned properly
          }
        }}
        style={{
          width: '100%', // Adjust width if necessary
          // Additional style adjustments for the TextField component
        }}
      />
    </div>
  </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

{/* {
  showOrInfo === false ? (
<Grid container spacing={2} style={{ marginTop: '6px'}}>
  <Grid item xs={11} style={{ marginBottom: '0px' }}>
  <TextField
  ref={otherInfoRef}
  defaultValue={otherInfo}
  label="Client Notes"
  multiline
  rows={4}
  variant="outlined"
  fullWidth
  InputProps={{
    style: {
      fontSize: '16px',
      padding: '15px',
      overflowY: 'auto',
    },
  }}
  InputLabelProps={{
    shrink: true,
    style: { backgroundColor: 'white', paddingLeft: '5px', paddingRight: '5px' }
  }}
  style={{
    width: '94%',
    marginLeft: '2.4%',
    borderRadius: '4px',
    border: '1px solid #ccc',
  }}
/>

  </Grid>
  <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
    <Tooltip title="Update Information from Database" placement="right">
      <IconButton onClick={handleUpdateOtherInfo}>
        <ReplayCircleFilledIcon />
      </IconButton>
    </Tooltip>
  </Grid>
</Grid>
  ) : (
    <Grid container spacing={2} alignItems="flex-start">
      <Grid item xs={12} md={8} style={{ marginBottom: '-10px' }}>
      <TextField
  ref={orInfoRef}
  defaultValue={orInfo}
  label="Procedure Notes"
  multiline
  rows={4}
  variant="outlined"
  fullWidth
  InputProps={{
    style: {
      fontSize: '16px',
      padding: '15px',
      overflowY: 'auto',
    },
  }}
  InputLabelProps={{
    shrink: true,
    style: { backgroundColor: 'white', paddingLeft: '5px', paddingRight: '5px' }
  }}
  style={{
    width: '85%',
    marginLeft: '15px',
    marginTop: '18px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  }}
/>


      </Grid>

      <Grid item xs={12} md={4} style={{ position: 'relative' }}>
        <Grid container direction="column">
        <Grid item xs={12} md={6}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', paddingTop: '7px' }}>
      <label htmlFor="paDate" style={{ color: '#707070', fontSize: '0.875rem' }}>
        Pre-Adm. Date:
      </label>
      <TextField
        inputRef={paDateRef}
        defaultValue={paDate}
        type="date"
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: true, style: { display: 'none' } }}
        id="paDate"

        inputProps={{
          style: {
            height: '40px', 
            padding: '0 14px', 
          }
        }}
        style={{
          width: '100%', 
        }}
      />
    </div>
  </Grid>

  <Grid item xs={12} md={4}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}> 
      <label htmlFor="paTime" style={{ color: '#707070', fontSize: '0.875rem' }}>
        Pre-Adm. Time:
      </label>
      <TextField
        inputRef={paTimeRef}
        defaultValue={paTime}
        type="time"
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: true, style: { display: 'none' } }} 
        id="paTime"
      
        inputProps={{
          style: {
            height: '40px',
            padding: '0 14px',
          }
        }}
        style={{
          width: '100%',
        }}
      />
    </div>
  </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
} */}


<Grid item xs={6} style={{ position: 'relative' }}>
  <TextField
    inputRef={dateOfBirthRef}
    defaultValue={dateOfBirth}
    type="date"
    fullWidth
    margin="normal"
    variant="outlined"
    InputLabelProps={{ shrink: false }}
    id="dateOfBirth"
    inputProps={{
      style: { paddingLeft: '15px' },
      readOnly: true, // Make the input read-only
    }}
    style={{ paddingTop: '5px' }}
    onClick={() =>
      alert(
        `To update the date of birth of this client for this appointment and all future appointments, please navigate to the main dashboard. Select the "TOOLS & INFO" option, then choose "REGISTERED CLIENTS." In the Registered Clients window, locate the client whose email you wish to update and make the necessary changes there.`
      )
    }
  />
  {!dateOfBirth && (
    <span
      style={{
        position: 'absolute',
        backgroundColor: 'white',
        paddingTop: '9px',
        top: '-14px',
        left: '33px', // Keep the text position
        zIndex: '1',
        pointerEvents: 'none',
        color: '#707070',
        fontSize: '12px',
        transform: 'translateY(32px)',
        paddingLeft: '4px', // Increase padding to extend background
        paddingRight: '5px',
        marginLeft: '-4px', // Apply negative margin to shift background to the left
      }}
    >
      Date of Birth
    </span>
  )}
</Grid>



        {/* <Grid item xs={6} style={{ position: 'relative' }}>
    <TextField
        inputRef={dateOfBirthRef}
        defaultValue={dateOfBirth}
        type="date"
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{ shrink: false }}
        id="dateOfBirth"
        inputProps={{ style: { paddingLeft: '15px' }}}
        style={{ paddingTop: '5px' }}
    />
    {!dateOfBirth && (
        <span style={{
            position: 'absolute',
            backgroundColor: 'white',
            paddingTop: '9px',
            top: '-14px',
            left: '33px', // Keep the text position
            zIndex: '1',
            pointerEvents: 'none',
            color: '#707070',
            fontSize: '12px',
            transform: 'translateY(32px)',
            paddingLeft: '4px', // Increase padding to extend background
            paddingRight: '5px',
            marginLeft: '-4px' // Apply negative margin to shift background to the left
        }}>
            Date of Birth
        </span>
    )}
</Grid> */}



<Grid item xs={6}>
    <FormControl 
        fullWidth
        margin="normal"
        variant="outlined"
        style={{ paddingTop: '5px' }}
    >
        <InputLabel 
            id="language-label"
            style={{
                paddingRight: '5px',
                paddingLeft: '13px',
                paddingTop: '37px',
                fontSize: '12px', // Match font size
                transform: 'translateY(-50%)', // Center vertically
            }}
        >
<span style={{ 
    position: 'relative', // or 'absolute' depending on its container's positioning
    top: '-14px', // Move up by 5px; adjust as needed
    backgroundColor: 'white', 
    padding: '0 3px', // Adjust padding as needed
}}>
    Language
</span>

        </InputLabel>
        <Select
            labelId="language-label"
            inputRef={aptLangRef}
            defaultValue="Spanish"
            label="Language"
        >
            <MenuItem value="Spanish">Spanish</MenuItem>
            <MenuItem value="English">English</MenuItem>
        </Select>
    </FormControl>
</Grid>

          {/* Buttons */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button variant="contained" color="primary" style={{ width: '120px', marginRight: '50px' }} onClick={handleBeforePreAptUpdate}>
                Update
              </Button>

{currentActiveUser === "admin" ? (
    <Button
      variant="contained"
      color="secondary"
      style={{ width: '120px', marginRight: '50px' }}
      onClick={handleDeleteAppointment}
    >
      Delete
    </Button>
  ) : selectedEvent && selectedEvent.resource && selectedEvent.resource.hidden === "true" ? (
    <Button
      variant="contained"
      color="secondary"
      style={{ width: '120px', marginRight: '50px' }}
      onClick={handleUnhideAppointment}
    >
      Unhide
    </Button>
  ) : (
    <Button
      variant="contained"
      color="secondary"
      style={{ width: '120px', marginRight: '50px' }}
      onClick={handleHideAppointment}
    >
      Hide
    </Button>
  )}
  


              <Button variant="contained" style={{ width: '120px', backgroundColor: 'black' }} onClick={handleCloseExistentAptModal}>
                Close
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>

    {/* Notification Details */}
    <Box sx={{ display: activeScreen === 'notification' ? 'block' : 'none', overflowY: 'scroll', maxHeight: '80%', width: '100%' }}>      
    <Grid container>
        <Grid item xs={10}>
          <h2 style={{ textTransform: 'uppercase', textDecoration: 'underline' }}>Notification Details</h2>
        </Grid>
      </Grid>

      {/* Notification Details Content */}
    <FormControl fullWidth variant="filled" style={{ marginBottom: '20px' }} disabled={isSingleAppointment}>
      <Select
        value={selectedEventIndex}
        onChange={(e) => setSelectedEventIndex(e.target.value)}
        displayEmpty
      >
        {isSingleAppointment ? (
          <MenuItem value="" disabled>
            No notifications yet!
          </MenuItem>
        ) : gEvents ? (
          gEvents
            .filter(event => event.tag !== "appointment")
            .map((event, index) => (
              <MenuItem value={index} key={index}>
                {event.resource.currentUserData || 'Older Type Notification'}
              </MenuItem>
            ))
        ) : (
          <MenuItem value="">Loading...</MenuItem>
        )}
      </Select>
    </FormControl>

    {isSingleAppointment ? (
        <Box sx={{ textAlign: 'center', padding: '20px' }}>
          <Typography variant="h5" component="div">
            No notifications yet for this appointment!
          </Typography>
        </Box>
      ) : (
        groupedEvents[selectedGroupIndex] && groupedEvents[selectedGroupIndex][selectedEventIndex] && (
          <Box sx={{ border: '2px solid #000', padding: '20px' }}>
          <Typography variant="h6" component="div">
            -<u>Patient Name:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.name || ' '}</span>
          </Typography>

          <Typography variant="h6" component="div">
              -<u>Appointment Date:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>
                {groupedEvents[selectedGroupIndex][selectedEventIndex].resource.newDate 
                  ? moment(groupedEvents[selectedGroupIndex][selectedEventIndex].resource.newDate).format('MMMM D, YYYY')
                  : ' '}
              </span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Email:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.email || 'No email used'}</span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Confirmation Requested:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{(groupedEvents[selectedGroupIndex][selectedEventIndex].resource.askConfirmation || 'No').toString()}</span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Text Message Confirmation Status:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.confirmBySms || '...pending'}</span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Voice Confirmation Status:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>
              {groupedEvents[selectedGroupIndex][selectedEventIndex].resource.confirmByVoice === 'noInput' ? 'no input' : groupedEvents[selectedGroupIndex][selectedEventIndex].resource.confirmByVoice || 'no answer'}
            </span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Email Confirmation Status:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.confirmByEmail || '...pending'}</span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Manual Confirmation:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.manualConfirm || 'No'}</span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Notified By:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.currentUserData || ' '}</span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Encounter Type:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.encounterType || groupedEvents[selectedGroupIndex][selectedEventIndex].resource.encType || ' '}</span>
          </Typography>

          <Typography variant="h6" component="div">
            -<u>Sent Message:</u> <span style={{ fontWeight: 'normal', marginLeft: '2%' }}>{groupedEvents[selectedGroupIndex][selectedEventIndex].resource.finalMessage || ' '}</span>
          </Typography>
          </Box>


        )
      )}
    </Box>

    {activeScreen === 'logHistory' && (
  <Box sx={{ display: 'block', padding: 4, background: 'white', borderRadius: 2 }}>
    <h2>Event History Log</h2>
    <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
    <ul>
      {eventLogs.map((log, index) => {

        // console.log(log.data)

        const apptActionDetails = log.data.map((item, index) => 
        // Assuming each item is an object with properties to display
        Object.entries(item)
          .map(([key, value]) => `${key}: ${value}`)
          .join(', ')
      ).join('; '); // Join each item's details with a semicolon for separation
      
        // Assuming each log might have multiple data entries and you want to process all
        const actionDetails = log.data.map((dataItem, dataIndex) => {
          const changedData = dataItem.changedData || {}; // Fallback to empty object if changedData is missing

          // Extract the properties from changedData
          const manualConfirm = changedData.manualConfirm ? 'N/A' : '';
          const hidden = changedData.hidden ? 'N/A' : '';
          const aptSpecLabel = changedData.aptSpecLabel ? `${changedData.aptSpecLabel}` : '';
          const noShow = changedData.noShow ? 'N/A' : '';

          // Combine the details and filter out empty parts
          const details = [manualConfirm, hidden, aptSpecLabel, noShow].filter(part => part).join(', ');

// Splitting the log.id to extract the date and time
const [date, utcTime] = log.id.split('T');
// Removing the 'Z-000000' part to parse the date-time string correctly
const dateTimeString = `${date}T${utcTime.split('.')[0]}Z`; // Includes 'Z' to specify UTC

// Creating a Date object from the UTC date-time string
const dateTime = new Date(dateTimeString);

// Formatting the date and time in the local time zone
// You can use toLocaleDateString and toLocaleTimeString for more control over the appearance
const formattedDate = dateTime.toLocaleDateString('en-US'); // Adjust 'en-US' as needed
const formattedTime = dateTime.toLocaleTimeString('en-US', {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false // Set to true if you prefer AM/PM
});

// Now formattedDate and formattedTime are adjusted to the user's local time zone


 // Conditional return based on log.eventType
 return log.eventType === 'Appointment'
 ? `${formattedDate} - ${formattedTime} - ${log.user} - ${log.action}`
 : `${formattedDate} - ${formattedTime} - ${log.user} - ${log.action} - Data: ${details || 'No data'}`;

        }).join('; '); // Join multiple dataItem details with a semicolon for separation

        // Render the actionDetails for each log entry
        return (
            <li key={index} style={{ cursor: 'pointer' }} 
                onClick={() => {
                  // Conditional alert message based on log.eventType
                  if (log.eventType === 'Appointment') {
                    alert(`${apptActionDetails}`);
                  } else {
                    alert(`${actionDetails}`);
                  }
                }}>
              {log.eventType === 'Appointment' ? actionDetails : actionDetails}
            </li>
        );
      })}
    </ul>
    </div>
  </Box>
)}

</>
     )}
  </Box>
</Modal>





    {/* <Modal open={openSettingsModal} onClose={handleCloseSettingsModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <Box sx={{ width: 500, padding: 4, background: 'white', borderRadius: 2 }}>
    <h2>Manage Encounter Types</h2> */}

<Modal open={openSettingsModal} onClose={handleCloseSettingsModal} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <Box sx={{ position: 'relative', width: 500, padding: 4, background: 'white', borderRadius: 2 }}>
    
    <h2>Manage Encounter Types</h2>
    <form>
    <FormControl fullWidth margin="normal" variant="outlined">
  <InputLabel id="existing-types-label">Existing Types</InputLabel>
  <Box display="flex" alignItems="center">
    <Select
      onChange={(e) => {
        setSelectedEncounterType(e.target.value);
        const selectedType = encounterTypes.find(type => type.name === e.target.value);
        nameEncRef.current = selectedType.name;
        colorEncTypeRef.current = selectedType.color;
        categoryRef.current = selectedType.category;
      }}
      labelId="existing-types-label"
      label="Existing Types"
      style={{ flex: 1 }} // Make Select take up the available space
    >
      {encounterTypes.map((type, index) => (
        <MenuItem key={index} value={type.name}>{type.name}</MenuItem>
      ))}
    </Select>

    <Tooltip title="Clear">
      <RemoveCircleOutlineIcon
        style={{ cursor: 'pointer', marginLeft: '10px' }} // Add some spacing between the icon and the Select
        onClick={unloadSelectedEncTypeItem}
      />
    </Tooltip>
  </Box>
</FormControl>


<TextField 
    label="Type Name" 
    fullWidth required 
    margin="normal" 
    variant="outlined" inputProps={{ style: { paddingLeft: '15px' }}} InputLabelProps={{
            shrink: true, style: { backgroundColor: 'white', paddingLeft: '5px', paddingRight: '5px' }}}
    defaultValue={nameEncRef.current}
    onChange={(e) => nameEncRef.current = e.target.value}
    disabled={selectedEncounterType ? true : false}
/>

{/* Native HTML Color Picker with Color Preview */}
<Box display="flex" alignItems="center" marginTop={2} marginBottom={2}>
      <Typography variant="body1" style={{ marginRight: '10px' }}>
        Color:
      </Typography>
      <input 
        type="color"
        defaultValue={colorEncTypeRef.current}
        onChange={(e) => {
          colorEncTypeRef.current = e.target.value;
          // updateColorPreview();
        }}
        style={{ width: 250, height: 40, marginRight: '10px' }}
      />

    </Box>


<FormControl fullWidth margin="normal" variant="outlined">
  <InputLabel id="category-label">Category</InputLabel>
  <Select
    defaultValue={categoryRef.current}
    onChange={(e) => categoryRef.current = e.target.value}
    labelId="category-label"
    label="Category"
  >
    <MenuItem value="Regular Appointment">Regular Appointment</MenuItem>
    <MenuItem value="Procedure Appointment">Procedure Appointment</MenuItem>
  </Select>
</FormControl>




      <Box display="flex" justifyContent="space-between" marginTop={2}>
        <Button variant="contained" color="primary" style={{ width: '120px', marginRight: '20px' }} onClick={handleSaveOrUpdateEncounterType} id='ecnType-update-button'>
          {selectedEncounterType ? 'Update' : 'Save'}
        </Button>
        <Button variant="contained" style={{ width: '120px', backgroundColor: 'red', marginRight: '20px' }} onClick={handleDeleteEncounterType}>
          Delete
        </Button>
        <Button 
          variant="contained" 
          style={{ width: '120px', backgroundColor: 'black' }} 
          onClick={handleCloseSettingsModal}
          id="cancel-button">
          Cancel
        </Button>
      </Box>
    </form>
  </Box>
</Modal>










        </>

    );
    
};




return (
  <>
  <Modal open={open} onClose={handleClose}>
  <Box
    sx={{
      backgroundColor: 'white',
      padding: '16px',
      position: 'relative',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80vw',
      height: '80vh',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    {/* Fixed toolbar and top section */}
    <Box sx={{ flexShrink: 0, backgroundColor: 'white' }}>
      <Grid container xs={12}>
        <Grid container>
          {/* Empty grid item for spacing or additional content */}
          <Grid item xs={0.7}></Grid>

          {/* Grid container for select menu and icons */}
          <Grid container alignItems="center" spacing={2} >
            {/* Select Menu for Calendars */}
            {calendars && (
              <Grid item xs={2.5} style={{ marginTop: '0%', backgroundColor: 'white' }}>
                <FormControl fullWidth style={{ marginLeft: '10%' }}>
                  <Select
                    value={
                      calendars.find(
                        (calendar) =>
                          calendar.docId === selectedCalendar && calendar.active
                      )
                        ? calendars.find(
                            (calendar) => calendar.docId === selectedCalendar
                          ).name
                        : ''
                    }
                    displayEmpty
                    onChange={handleCalendarChange}
                    style={{ height: '33px' }} // Adjust the height as needed
                  >
                    {calendars
                      .filter((calendar) => calendar.active === true) // Filter for active calendars
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((calendar) => (
                        <MenuItem key={calendar.docId} value={calendar.name}>
                          {calendar.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  style={{   marginLeft: '10%',
                    padding: '8px 0 6px 0', // top:8, right:0, bottom:6, left:0
                    backgroundColor: 'white',
                    //  zIndex: '9999', position: 'relative'
                     }}  
                >
                  <Select
                    value={selectedCalendarView}
                    displayEmpty
                    onChange={handleCalendarViewChange}
                    style={{ height: '33px' }} // Adjust the height as needed
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="Regular Appointment">
                      Regular Appointments
                    </MenuItem>
                    <MenuItem value="Procedure Appointment">
                      Procedure Appointments
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={1} style={{ marginTop: '0%', backgroundColor: 'white' }}></Grid>

            {isLoading && (
              <Grid
                item
                xs={6}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div
                  className="fixed-progress-bar"
                  style={{ width: '99.9%', margin: '0 auto' }}
                >
                  <LinearProgress style={{ height: '10px' }} />
                </div>
              </Grid>
            )}

            {/* Spacer to push icons to the right */}
            <Grid item xs />

            <Grid item>
              <Tooltip title="Appointment Search">
                <div>
                  <SearchCircleIcon
                    sx={{ fontSize: 40 }}
                    onClick={openSearchModal}
                  />
                </div>
              </Tooltip>
            </Grid>

            {/* Replay icon */}
            <Grid item>
              <Tooltip title="Refresh data and clear all filters!">
                <ReplayCircleFilledIcon
                  sx={{ fontSize: 40, cursor: 'pointer' }}
                  onClick={() => {
                    // Reset states and fetch events
                    setEncTypeFilter(['No Filter']);
                    setUserFilter(['No Filter']);
                    setOriginalEvents([]);
                    setUserAnchorEl(null);
                    setEncTypeAnchorEl(null);
                    setShowConfirmed(false);
                    fetchEvents();
                    fetchDatesAndDayNames();
                    setEncTypeView(false);
                  }}
                />
              </Tooltip>
            </Grid>

            {/* Close icon */}
            <Grid item>
              <Tooltip title="Close">
                <CancelIcon
                  sx={{ fontSize: 40, cursor: 'pointer' }}
                  onClick={handleClose}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>

      {/* Overlay for the first 5px
  <Box
    sx={{
      position: 'absolute',
      top: 93,
      left: 0,
      right: 0,
      height: '5px',
      backgroundColor: 'white',
      zIndex: 9999, // Ensure it stays above the scrollable content
    }}
  /> */}

    {/* Scrollable calendar section */}
    <Box
      sx={{

        backgroundColor: 'white'
        
      }}
    >
      <Grid item xs={12}>
        <Calendar
          localizer={localizer}
          events={events}
          views={['month', 'week', 'day']}
          startAccessor="start"
          endAccessor="end"
          style={{
            height: `${calendarHeight}px`, // Dynamically set height in pixels
            margin: '0%',
          }}
          onSelectEvent={handleEventClick}
          popup={false}
          eventPropGetter={eventPropGetter}
          components={{
            dateCellWrapper: (props) => (
              <CustomDateCellWrapper
                {...props}
                events={events}
                view={currentView}
                showAllEvents={showAllEvents}
                showConfirmed={showConfirmed}
                datesAndDayNamesState={datesAndDayNamesState}
              />
            ),
            event: (props) => (
              <CustomEvent
                {...props}
                triggerRender={triggerCustomEventRender}
                onEventRightClick={handleEventRightClick}
                showAllEvents={showAllEvents}
                showConfirmed={showConfirmed}
                encTypeView={encTypeView}
              />
            ),
            toolbar: CustomToolbar,
          }}
          date={currentDate}
          view={currentView}
          onView={(view) => setCurrentView(view)}
          onNavigate={(date) => setCurrentDate(date)}
          onDrillDown={handleDrillDown}
        />
      </Grid>
    </Box>
    {popoverState.show && (
      <RightClickPopover
        position={popoverState.position}
        event={popoverState.event}
        open={popoverState.show}
        onClose={closePopover}
        firestore={firestore}
        userEmail={userEmail}
        physician={physician}
        groupedEvents={groupedEvents}
        events={events}
        setEvents={setEvents}
        filteredEvents={filteredEvents}
        setFilteredEvents={setFilteredEvents}
        originalEvents={originalEvents}
        setOriginalEvents={setOriginalEvents}
        selectedCalendar={selectedCalendar}
        currentActiveUser={currentActiveUser}
        selectedEvent={selectedEvent}
        // ...other props
      />
    )}
  </Box>
</Modal>;


  <ProgressOverlaySpotSearch
  isLoadingSpotSearch={isLoadingSpotSearch}
  onStopSearch={handleStopSearch} // You need to define handleStopSearch in your component
/>

  <BasicProgressOverlay
    isInProcess={isInProcess} 
  />

  <NoSpotModal
    isOpen={noSpotModalOpen}
    onRequestClose={handleNoSpotModalClose}
    onOverride={handleOverrideClick}
  />

  <OverrideModal
    isOpen={overrideModalOpen}
    onRequestClose={handleOverrideModalClose}
    onVerified={handleAppointmentAction}
    accountEmail={userEmail}
    providerName={physician}
  />

<OverridePastEventModal
    isOpen={overridePastEventModalOpen}
    onRequestClose={handleOverridePastEventModalClose}
    onOverride={handlePastOverrideClick}
  />

<PastOverrideModal
    isOpen={pastOverrideModalOpen}
    onRequestClose={handlePastOverrideModalClose}
    onVerified={handlePastAppointmentAction}
    accountEmail={userEmail}
    providerName={physician}
  />

  </>
    );
    
};

export default CalendarModalPlus;